
import React, { memo } from 'react';
import { Tooltip, Typography } from '@mui/material';


export const QuestionOwnerCell = memo(({ row }) => {
  const orgName = row.cleanRoomQuestionOwner?.length ? row.cleanRoomQuestionOwner : '';
  return (
    <Tooltip
      title={orgName ? `Clean Room Questions Owner: ${orgName}` : ''}
      disableHoverListener={!orgName}
      arrow
      placement='top'
    >
      <Typography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: 2,
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
        }}
      >
        {orgName}
      </Typography>
    </Tooltip>
  );
});

QuestionOwnerCell.displayName = 'QuestionOwnerCell';
