import { Parameters } from 'components/CleanRooms/constants';
import { get } from 'lodash';


// TODO: DV-10383 Deprecate OPJA feature but keep the code for now
const getCleanRoomParamters = (parameters) => {
  const crowdSize = get(parameters.find(p => p.name === Parameters.CROWD_SIZE), 'value');
  const dataDecibel = get(parameters.find(p => p.name === Parameters.DATA_DECIBEL), 'value');
  const enableExport = get(parameters.find(p => p.name === Parameters.ENABLE_EXPORT), 'value');
  const enablePair = get(parameters.find(p => p.name === Parameters.ENABLE_PAIR), 'value');
  const enableOPJA = get(parameters.find(p => p.name === Parameters.ENABLE_OPJA), 'value');
  const enableHabuIntelligence = get(parameters.find(p => p.name === Parameters.ENABLE_HABU_INTELLIGENCE), 'value');
  const partnerID = get(parameters.find(p => p.name === Parameters.PARTNER_ID), 'value');
  const numberOfDailyRuns = get(parameters.find(p => p.name === Parameters.NUMBER_OF_DAILY_RUNS), 'value');
  const bqBillingProjectID = get(parameters.find(p => p.name === Parameters.BQ_BILLING_PROJECT_ID), 'value');
  const bqPrincipalForOutputAccess = get(parameters.find(p => p.name === Parameters.BQ_PRINCIPAL_FOR_OUTPUT_ACCESS), 'value');
  const bqCrServiceAccount = get(parameters.find(p => p.name === Parameters.BQ_CR_SERVICE_ACCOUNT), 'value');
  const maxPrivacyLoss = get(parameters.find(p => p.name === Parameters.MAX_PRIVACY_LOSS), 'value');
  const maxQuestionRuns = get(parameters.find(p => p.name === Parameters.maxQuestionRuns), 'value');
  const timeHorizon = get(parameters.find(p => p.name === Parameters.TIME_HORIZON), 'value');
  const cloud = get(parameters.find(p => p.name === Parameters.CLOUD), 'value');
  const region = get(parameters.find(p => p.name === Parameters.REGION), 'value');
  const subRegion = get(parameters.find(p => p.name === Parameters.SUB_REGION), 'value');
  const snowflakeWarehouse = get(parameters.find(p => p.name === Parameters.SNOWFLAKE_CLEAN_ROOM_WAREHOUSE), 'value');
  const enableDatasetMaterialization = get(parameters.find(p => p.name === Parameters.ENABLE_DATASET_MATERIALIZATION), 'value');

  return {
    crowdSize,
    dataDecibel,
    enableExport,
    enablePair,
    enableOPJA,
    enableHabuIntelligence,
    partnerID,
    numberOfDailyRuns,
    bqBillingProjectID,
    bqPrincipalForOutputAccess,
    bqCrServiceAccount,
    maxPrivacyLoss,
    maxQuestionRuns,
    timeHorizon,
    cloud,
    region,
    subRegion,
    snowflakeWarehouse,
    enableDatasetMaterialization,
  };
};

export default getCleanRoomParamters;
