import React from 'react';
import { axiosInstance } from 'utils/http';
import { question } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';


export const useFetchQuestionActivationOptions = (cleanRoomID, cleanRoomQuestionID) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [data, setData] = React.useState([]);
  const {
    isLoading, isFetching, isError, isSuccess,
  } = useQuery(
    ['QuestionActivationOptions', cleanRoomQuestionID],
    async () => axiosInstance.get(
      question(orgId).activation.options(cleanRoomID, cleanRoomQuestionID)),
    {
      enabled: !!cleanRoomQuestionID,
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading,
    isFetching,
    isError,
    isSuccess,
    data,
  };
};
