import DatasetChip from 'components/CleanRooms/CurrentQuestions/DatasetChip';
import Divider from '@mui/material/Divider';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';


const QuestionDatasetRow = ({ question }) => (
  <>
    <Box display='flex' p={1}>
      <Box width='40%' mr={1}>
        <Tooltip placement='top-start' title='DATASET'>
          <Typography variant='subtitle4'>
            DATASET
          </Typography>
        </Tooltip>
      </Box>
      <Box width='60%'>
        <DatasetChip question={question} />
      </Box>
    </Box>
    <Divider />
  </>
);

export default QuestionDatasetRow;
