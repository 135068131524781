import React from 'react';
import clsx from 'clsx';
import { MotifDialogActions } from 'BaseComponents/MotifFullScreenDialog/MotifDialogActions';
import { pxToRem } from 'MotifTheme';
import { styled } from '@mui/material';


const FullScreenDialogActionsStyled = styled(MotifDialogActions)(({ theme }) => ({
  borderTop: `${pxToRem(1)} solid ${theme.palette.secondary.light}`,
  padding: `${pxToRem(16)} ${pxToRem(32)}`,
  transition: theme.transitions.common?.enteringScreen,
  '&[class*="FullScreenDialogActions-scrollShadow-"]': {
    boxShadow: '0px -1px 2px rgba(0, 0, 0, 0.16)',
    transition: theme.transitions.common?.leavingScreen,
    zIndex: theme.zIndex.dialog,
  },
}));

export const MotifFullScreenDialogActions = React.forwardRef(({ className, ...props }, ref) => (
  <FullScreenDialogActionsStyled
    className={clsx('FullScreenDialogActions-root', className)}
    ref={ref}
    data-testid={props['data-testid'] ?? 'FullScreenDialogActions'}
    {...props}
  />
));
