import React, { useRef, useState } from 'react';
import { EditTaxonomyFullScreenDialog } from 'components/CleanRooms/Taxonomy/EditTaxonomyFullScreenDialog';
import { HButton, HDataTable, HTableControlPanel } from 'BaseComponents';
import { Typography, useTheme } from '@mui/material';
import { UsedInQuestionsSidePanel } from 'components/CleanRooms/Taxonomy/UsedInQuestionsSidePanel';
import { cleanRoom } from 'utils/urlConstants';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


export const Taxonomy = () => {
  const theme = useTheme();
  const orgID = useSelector((state) => state.session.user.primaryOrganization.organization.ID);
  const { crId } = useParams();
  const dataContextRef = useRef();
  const dataURL = cleanRoom(orgID).taxonomy(crId);
  const [panel, openPanel] = useState({
    taxonomy: false,
    questions: false,
    row: null,
  });

  const ActionButton = ({ row }) => (
    <HButton
      variant='outlined'
      disableElevation
      onClick={(e) => {
        e.stopPropagation();
        openPanel({ taxonomy: true, questions: false, row });
      }}
    >
      <Typography variant='body1' fontWeight={600}>
        Edit
      </Typography>
    </HButton>
  );

  const columns = [
    {
      field: 'datasetName' || 'field',
      name: 'Dataset/Field Macro Name',
      width: 220,
      component: ({ row }) => `@${row.datasetName || row.field}`,
    },
    {
      field: 'type' || 'dataType',
      name: 'Expected Type',
      width: 140,
      component: ({ row }) => row.type || row.dataType,
    },
    {
      field: 'format',
      name: 'Expected Format',
      width: 140,
      component: ({ row }) => row.format || '-',
    },
    {
      field: 'description',
      name: 'Description',
      width: 280,
    },
    {
      field: 'questions',
      name: 'Used In',
      width: 280,
      component: ({ row }) => {
        const questionsCount = row.datasetName && row?.questions?.length;

        return questionsCount ? (
          <Typography
            style={{
              color: theme.palette.info.main,
              textDecoration: 'underline',
              cursor: 'pointer',
              width: 'max-content',
            }}
            onClick={(e) => {
              e.stopPropagation();
              openPanel({ taxonomy: false, questions: true, row });
            }}
          >
            {questionsCount}
            {' '}
            Question
            {questionsCount === 1 ? '' : 's'}
          </Typography>
        ) : (
          '-'
        );
      },
    },
  ];

  return (
    <>
      <HDataTable
        // TODO set filters URL after Taxonomy Filters are implemented
        filtersURL=''
        dataURL={dataURL}
        tableName='clean-room-taxonomy'
        dataBaseField='cleanRoomTaxonomy'
        columns={columns}
        actionButton={ActionButton}
        subRowsProp='datasetTypes'
        controlPanel={(
          <HTableControlPanel
            showFiltersButton={false}
            createPermissions={[]}
            dataContextRef={dataContextRef}
            onRefresh={() => dataContextRef.current.refreshListData()}
          />
        )}
      />
      <EditTaxonomyFullScreenDialog
        open={panel.taxonomy}
        row={panel.row}
        onClose={() => openPanel({ taxonomy: false, questions: false })}
        onSuccess={() => dataContextRef.current.refreshListData()}
      />
      <UsedInQuestionsSidePanel
        open={panel.questions}
        row={panel.row}
        onClose={() => openPanel({ taxonomy: false, questions: false })}
      />
    </>
  );
};
