import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PermissionService from 'components/Common/PermissionService';
import React from 'react';
import Switch from 'components/Common/Switch';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { StyledRows } from 'components/Common/CardStructures';
import { cleanRoomPermissions } from 'utils/appConstants';
import { status as statusEnums } from 'utils/ignoramusEnums';


const QuestionStatusRow = ({ question, updateStatus }) => {
  const [status, toggleStatus] = React.useState(question.status);
  const authorized = PermissionService.isAuthorized([cleanRoomPermissions.manageQuestions]);

  React.useEffect(() => {
    toggleStatus(statusEnums[question.status].key);
  }, [question, toggleStatus]);

  const changeStatus = (record, isActive) => {
    const newStatus = isActive ? statusEnums.ACTIVE.key : statusEnums.PAUSED.key;
    toggleStatus(newStatus);
    updateStatus(record, newStatus);
  };

  return (
    <>
      <StyledRows display='flex' justifyContent='initial'>
        <Box
          alignSelf='center'
          textAlign='left'
          minWidth='40%'
          maxWidth='40%'
        >
          <Tooltip placement='top-start' title='Status'>
            <Typography variant='subtitle4'>
              STATUS
            </Typography>
          </Tooltip>
        </Box>
        <Box width='60%'>
          <Switch
            label={<Typography variant='caption'>{statusEnums[status].key}</Typography>}
            onChange={e => changeStatus(question, e.target.checked)}
            checked={status === statusEnums.ACTIVE.key}
            disabled={!authorized}
            size='small'
          />
        </Box>
      </StyledRows>
      <Divider />
    </>
  );
};

export default QuestionStatusRow;
