import BasicCard from 'pages/InternalAdmin/components/BasicCard';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import PageHeader from 'components/Common/PageHeader';
import PermissionService from 'components/Common/PermissionService';
import React from 'react';
import Typography from '@mui/material/Typography';
import { HABU_ENVIRONMENT, productPerm } from 'utils/appConstants';
import { LinkComponent } from 'BaseComponents';
import { ListItem, ListItemIcon } from '@mui/material';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { internalAdmin } from 'utils/spaUrls';
import { styled } from '@mui/material/styles';


const StyledListItemIconWrap = styled(ListItemIcon, {
  label: 'UnorderedListLink',
})({
  minWidth: 20,
});
const StyledFiberManualRecordIcon = styled(FiberManualRecordIcon, {
  label: 'UnorderedListLink',
})({
  color: 'black',
  fontSize: '10px',
});

const UnorderedListLink = ({ text, link }) => (
  <ListItem>
    <StyledListItemIconWrap>
      <StyledFiberManualRecordIcon />
    </StyledListItemIconWrap>
    <Typography variant='body2'>
      <LinkComponent to={link}>
        {text}
      </LinkComponent>
    </Typography>
  </ListItem>
);

const InternalAdminLanding = () => {
  const isAlertsEnabled = PermissionService.isAuthorizedSuperUser([productPerm.alerts]);
  const isIdentityResolutionEnabled = PermissionService.isProductEnabled([
    productPerm.IdentityResolution]);
  const isOGSU = PermissionService.isOGSU();

  return (
    <>
      <PageHeader title='Internal Administration' breadcrumbs={[crumbs.helium]} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <BasicCard title='Application Level Administration'>
            <Typography variant='body2'>
              Habu Internal Administrators can create and modify parameters and components
              that exist at the application level and are not specific to a single account or
              organization.
            </Typography>
            <List component='nav'>
              <UnorderedListLink text='Clean Room Questions' link={internalAdmin.application.cleanRoomQuestions} />
              <UnorderedListLink text='Credential Sources' link={internalAdmin.application.credentialSources} />
              <UnorderedListLink text='Snowflake CLI Dashboard' link={internalAdmin.application.snowflakeCliDashboard} />
              {
                HABU_ENVIRONMENT !== 'staging' && (
                  <UnorderedListLink text='Snowflake Account Usage' link={internalAdmin.application.snowflakeAccountUsage} />
                )
              }
            </List>
          </BasicCard>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <BasicCard title='Account Level Administration'>
            <Typography variant='body2'>
              Habu Internal Administrators can create and modify parameters
              and components that exist at the account level only.
            </Typography>
            <List component='nav'>
              <UnorderedListLink text='Product Access' link={internalAdmin.account.accountProductAccess} />
              <UnorderedListLink text='Clean Room Types' link={internalAdmin.account.cleanRoomTypes} />
            </List>
          </BasicCard>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <BasicCard title='Organization Level Administration'>
            <Typography variant='body2'>
              Habu Internal Administrators can create and modify parameters and components
              that exist at the organization level only.
            </Typography>
            <List component='nav'>
              <UnorderedListLink text='Import Sources Access' link={internalAdmin.organization.importDataSourceAccess} />
              <UnorderedListLink text='Import Data Types Access' link={internalAdmin.organization.importDataTypeAccess} />
              <UnorderedListLink text='Organization Product Access' link={internalAdmin.organization.organizationProductAccess} />
              <UnorderedListLink text='Organization Properties' link={internalAdmin.organization.organizationProperties} />
              <UnorderedListLink text='Demo Data' link={internalAdmin.organization.demoData} />
              <UnorderedListLink text='Synthetic Data' link={internalAdmin.organization.syntheticData} />
              <UnorderedListLink text='Organization Parameters' link={internalAdmin.organization.organizationParameters} />
              <UnorderedListLink text='Organization Clean Room Types' link={internalAdmin.organization.cleanRoomTypes} />
              {isOGSU && <UnorderedListLink text='Organization Clean Rooms' link={internalAdmin.organization.cleanRooms} />}
              {isOGSU && <UnorderedListLink text='Organization Clean Room Questions' link={internalAdmin.organization.cleanRoomQuestions} />}
              {isAlertsEnabled && <UnorderedListLink text='Alerts (Alpha)' link={internalAdmin.organization.alerts('')} />}
              {isAlertsEnabled && <UnorderedListLink text='Alerts Request' link={internalAdmin.organization.alertsRequest('')} />}
              {isIdentityResolutionEnabled && (
                <UnorderedListLink text='Identity Resolution Configuration' link={internalAdmin.organization.identityConfiguration} />)}
              <UnorderedListLink text='Rate Limiting' link={internalAdmin.organization.rateLimiting} />
            </List>
          </BasicCard>
        </Grid>
      </Grid>
    </>
  );
};

export default InternalAdminLanding;
