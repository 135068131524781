import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { HTagsList } from 'BaseComponents/HTagsTextField';
import { StyledRows } from 'components/Common/CardStructures';


const QuestionTagsRow = ({ question }) => (
  <>
    <StyledRows display='flex' justifyContent='initial'>
      <Box
        alignSelf='center'
        textAlign='left'
        minWidth='40%'
        maxWidth='40%'
      >
        <Tooltip placement='top-start' title='Tags'>
          <Typography variant='subtitle4'>
            TAGS
          </Typography>
        </Tooltip>
      </Box>
      <Box width='60%'>
        <HTagsList values={question.tags} />
      </Box>
    </StyledRows>
    <Divider />
  </>
);

export default QuestionTagsRow;
