import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import NoData from 'components/Common/NoData';
import React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import orderBy from 'lodash/orderBy';
import partitionIcon from 'assets/partition.svg';
import usePreviousProps from 'hooks/usePreviousProps';
import {
  END_DATE_PARAMETER,
  START_DATE_PARAMETER,
  isDateParametersAdded,
} from 'components/InternalAdmin/QuestionConfigure/QuestionParameters';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { isEqual } from 'lodash';


const INTERVAL_TYPE = {
  DAYS: 'DAYS',
  MONTHS: 'MONTHS',
};

export const PARTITION_PARAMETER = 'PARTITION_PARAMETER';

const getInitialIntervalType = ({ START_DATE = {}, END_DATE = {} } = {}) => {
  if (!START_DATE.qualifier || !END_DATE.qualifier) {
    return INTERVAL_TYPE.DAYS;
  }
  return START_DATE.qualifier;
};

const getNegativeValue = (value) => (String(value ?? '').trim() === '0' ? '0' : value * -1 || '');

const DateParameter = ({
  parameter, details, updateDetails, parameterType,
}) => (
  <>
    <Grid item xs={5}>
      <Box display='flex'>
        <Typography variant='subtitle2' sx={{ overflowWrap: 'anywhere' }}>
          {parameter?.displayName || parameter.name}
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={7}>
      <Box display='flex'>
        <Typography variant='subtitle2'>
          {details.parameters[parameter.name]?.isStatic ? ' ' : '# Days before Run Date'}
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={5}>
      <Box display='flex' alignItems='center' height='100%'>
        <ToggleButtonGroup
          color='primary'
          value={details.parameters[parameter.name]?.isStatic || false}
          exclusive
          onChange={(e, value) => updateDetails({
            ...details,
            parameters: {
              ...details.parameters,
              [parameter.name]: {
                ...details.parameters[parameter.name],
                isStatic: value,
                value: null,
              },
            },
          })}
          aria-label='Platform'
          size='small'
        >
          <ToggleButton value={false} color='primary'>Relative Date</ToggleButton>
          {/* eslint-disable-next-line react/jsx-boolean-value */}
          <ToggleButton value={true}>Fixed Date</ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Grid>
    <Grid item xs={5}>
      {details.parameters[parameter.name]?.isStatic ? (
        <TextField
          fullWidth
          margin='dense'
          onChange={e => updateDetails({
            ...details,
            parameters: {
              ...details.parameters,
              [parameter.name]: {
                ...details.parameters[parameter.name],
                value: e.target.value,
                name: parameter.name,
                type: parameter?.type || parameter?.dataType,
                qualifier: parameter?.qualifier,
                parameterType,
              },
            },
          })}
          type={parameter.dataType === 'TIMESTAMP' ? 'datetime-local' : 'date'}
          inputProps={{
            step: 1,
          }}
          value={details.parameters[parameter.name]?.value || ''}
          variant='outlined'
          required
          size='small'
        />
      ) : (
        <TextField
          fullWidth
          margin='dense'
          onChange={e => updateDetails({
            ...details,
            parameters: {
              ...details.parameters,
              [parameter.name]: {
                ...details.parameters[parameter.name],
                value: e.target.value ? String(e.target.value * -1) : e.target.value,
                name: parameter.name,
                type: parameter?.type || parameter?.dataType,
                qualifier: parameter?.qualifier,
                parameterType,
              },
            },
          })}
          required
          value={getNegativeValue(details.parameters[parameter.name]?.value)}
          variant='outlined'
          type='number'
          size='small'
          inputProps={{
            min: 0,
          }}
        />
      )}
    </Grid>
    <Grid item xs={2} />
  </>
);

const MonthsParameters = ({
  parameter, details, updateDetails,
}) => {
  const handleValueChange = (e) => {
    const newValue = e.target.value ? String(e.target.value * -1) : e.target.value;
    updateDetails({
      ...details,
      parameters: {
        ...details.parameters,
        [parameter.name]: {
          ...details.parameters[parameter.name],
          value: newValue,
          name: parameter.name,
          type: parameter?.type || parameter?.dataType,
          qualifier: parameter?.qualifier,
          parameterType: '',
        },
      },
    });
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant='subtitle2'>
          # of previous calendar months
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant='outlined'
          size='small'
          type='number'
          required
          onChange={handleValueChange}
          value={getNegativeValue(details.parameters[parameter.name]?.value)}
          sx={{ width: '66%' }}
        />
      </Grid>
    </>
  );
};

const Parameters = ({
  details, updateDetails, questionDetails, crqParameters,
}) => {
  const [question, updateQuestion] = React.useState(questionDetails);
  const [intervalType, setIntervalType] = React.useState(
    () => getInitialIntervalType(details.parameters),
  );
  const prevParameters = usePreviousProps(details.parameters);

  const handleIntervalChange = (e, interval) => {
    if (interval === null) {
      return;
    }

    const { parameters } = details;
    const updatedParams = {};

    Object.entries(parameters).forEach(([key, param]) => {
      if (param.type === 'DATE' && param.parameterType === '') {
        updatedParams[key] = {
          ...param,
          qualifier: interval,
          value: (key === 'END_DATE' && interval === INTERVAL_TYPE.MONTHS) ? '0' : '',
        };
      }
    });

    if (!Object.hasOwn(updatedParams, 'END_DATE')) {
      updatedParams.END_DATE = {
        name: END_DATE_PARAMETER.name,
        type: END_DATE_PARAMETER.type,
        qualifier: interval,
        value: '0',
        parameterType: '',
      };
    }

    updateDetails({
      ...details,
      parameters: {
        ...parameters,
        ...updatedParams,
      },
    });
    setIntervalType(interval);
  };

  React.useEffect(() => {
    if (!isEqual(details.parameters, prevParameters) && Boolean(details.ID)) {
      setIntervalType(getInitialIntervalType(details.parameters));
    }
  }, [prevParameters, details.ID, details.parameters]);

  React.useEffect(() => {
    if (!questionDetails) {
      return;
    }
    const { parameters } = questionDetails;
    if (questionDetails?.hasDateParameters && !isDateParametersAdded(parameters)) {
      updateQuestion({
        ...questionDetails,
        parameters: [
          {
            ...START_DATE_PARAMETER,
            qualifier: intervalType,
          },
          {
            ...END_DATE_PARAMETER,
            qualifier: intervalType,
          },
          ...parameters.map(param => ({ ...param, qualifier: intervalType })),
        ],
      });
    }
    else {
      updateQuestion(questionDetails);
    }
  }, [intervalType, questionDetails]);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant='subtitle2'>Runtime Parameters</Typography>
      </Grid>
      {question?.parameters?.length > 0
        ? (
          <>
            <Grid item xs={12}>
              <Typography variant='subtitle2'>Interval Type</Typography>
            </Grid>
            <Grid item xs={12}>
              <ToggleButtonGroup
                exclusive
                color='primary'
                size='small'
                aria-label='Interval'
                value={intervalType}
                onChange={handleIntervalChange}
              >
                <ToggleButton value={INTERVAL_TYPE.DAYS}>Days</ToggleButton>
                <ToggleButton value={INTERVAL_TYPE.MONTHS}>Months</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <NoData text='No Runtime Parameters for this Question.' />
          </Grid>
        )}
      {orderBy(question.parameters, ['index'], ['asc']).map(parameter => {
        if (intervalType === INTERVAL_TYPE.MONTHS) {
          if (parameter.name === 'START_DATE') {
            return (
              <MonthsParameters
                details={details}
                updateDetails={updateDetails}
                parameter={parameter}
                key={parameter.name}
                parameterType=''
              />
            );
          }
          if (parameter.name === 'END_DATE') {
            return null;
          }
        }
        if (String(parameter.type).toLowerCase() === 'date') {
          return (
            <DateParameter
              details={details}
              updateDetails={updateDetails}
              parameter={parameter}
              key={parameter.name}
              parameterType=''
            />
          );
        }
        return (
          <>
            <Grid item xs={10} key={parameter.name}>
              <Typography variant='subtitle2'>
                {parameter.displayName || parameter.name}
              </Typography>
              <TextField
                fullWidth
                margin='dense'
                onChange={e => updateDetails({
                  ...details,
                  parameters: {
                    ...details.parameters,
                    [parameter.name]: {
                      ...details.parameters[parameter.name],
                      value: e.target.value,
                      name: parameter.name,
                      type: parameter.type,
                      parameterType: '',
                    },
                  },
                })}
                required
                value={details.parameters[parameter.name]?.value || ''}
                variant='outlined'
                size='small'
              />
            </Grid>
            <Grid item xs={2} />
          </>
        );
      })}
      {crqParameters?.length > 0
        && (
          <>
            <Grid item xs={12}>
              <Box display='flex' gap={1} alignItems='center'>
                <img alt='' height='20px' src={partitionIcon} width='20px' />
                <Typography variant='subtitle2'>Partition Parameters</Typography>
              </Box>
            </Grid>
            {orderBy(crqParameters, ['index'], ['asc']).map(parameter => {
              if (['date', 'timestamp'].includes(String(parameter.dataType).toLowerCase())) {
                return (
                  <DateParameter
                    details={details}
                    updateDetails={updateDetails}
                    parameter={parameter}
                    key={parameter.name}
                    parameterType={PARTITION_PARAMETER}
                  />
                );
              }
              return (
                <>
                  <Grid item xs={10} key={parameter.name}>
                    <Typography variant='subtitle2'>
                      {parameter.name}
                    </Typography>
                    <TextField
                      fullWidth
                      margin='dense'
                      onChange={e => updateDetails({
                        ...details,
                        parameters: {
                          ...details.parameters,
                          [parameter.name]: {
                            ...details.parameters[parameter.name],
                            value: e.target.value,
                            name: parameter.name,
                            type: parameter.dataType,
                            parameterType: PARTITION_PARAMETER,
                          },
                        },
                      })}
                      required
                      value={details.parameters[parameter.name]?.value || ''}
                      variant='outlined'
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={2} />
                </>
              );
            })}
          </>
        )}
    </>
  );
};

export default Parameters;

