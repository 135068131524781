import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { HButton } from 'BaseComponents';
import { habuColors } from 'Theme';
import { styled } from '@mui/material/styles';


const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (props) => props !== 'styleLastRow',
})(({ styleLastRow }) => ({
  ...(styleLastRow ? { '&:last-child td, &:last-child th': { border: 0 } } : {}),
}));

const ShareQuestionResultsTable = ({
  queries, partners, data, onUpdateData,
}) => {
  const handleSelection = (isChecked, queryId, partnerId) => {
    const currentData = [...data];
    const idx = currentData.findIndex(d => d.ID === queryId);
    if (isChecked) {
      if (!currentData[idx].sharedWith.includes(partnerId)) {
        currentData[idx].sharedWith.push(partnerId);
      }
    }
    else {
      currentData[idx].sharedWith = currentData[idx].sharedWith.filter(s => s !== partnerId);
    }
    onUpdateData(currentData);
  };

  const isSharedAllQueriesWithPartner = (partnerId) => data.every(
    e => e.sharedWith.includes(partnerId),
  );

  // Handle select/de-select all
  const handleAllSelection = (partnerId) => {
    const isAllSelected = isSharedAllQueriesWithPartner(partnerId);
    queries.forEach(q => handleSelection(!isAllSelected, q.ID, partnerId));
  };

  return (
    <TableContainer>
      <Table aria-label='share question results table' size='small'>
        <TableHead>
          <TableRow>
            <TableCell />
            {
              partners.map(
                p => (
                  <TableCell key={p.ID} align='center'>
                    <Typography variant='subtitle2' sx={{ color: habuColors.neutral['60'] }}>{p.name}</Typography>
                  </TableCell>
                ),
              )
            }
          </TableRow>
        </TableHead>

        <TableBody>
          {
            data.map((d) => (
              <StyledTableRow
                key={d.ID}
                styleLastRow={data.length === 1}
              >
                <TableCell>
                  <Typography variant='subtitle2' data-testid='query-name'>{d.name}</Typography>
                </TableCell>
                {
                  partners.map(partner => (
                    <TableCell
                      align='center'
                      key={partner.ID}
                    >
                      <Checkbox
                        key={partner.ID}
                        checked={d.sharedWith.includes(partner.ID)}
                        onChange={
                          (e) => handleSelection(e.target.checked, d.ID, partner.ID)
                        }
                        data-testid='partner-checkbox'
                      />
                    </TableCell>
                  ))
                }
              </StyledTableRow>
            ))
          }

          {/* Select/Deselect all row */}
          {data.length > 1
            && (
              <StyledTableRow
                styleLastRow={data.length > 1}
              >
                <TableCell />
                {
                  partners.map(partner => (
                    <TableCell
                      align='center'
                      key={partner.ID}
                    >
                      <HButton
                        size='small'
                        variant='text'
                        sx={{
                          color: (theme) => theme.palette.link.main,
                        }}
                        onClick={() => handleAllSelection(partner.ID)}
                        data-testid='partner-select-all'
                      >
                        {isSharedAllQueriesWithPartner(partner.ID) ? 'Deselect' : 'Select all'}
                      </HButton>
                    </TableCell>
                  ))
                }
              </StyledTableRow>
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ShareQuestionResultsTable;

ShareQuestionResultsTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  queries: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  partners: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onUpdateData: PropTypes.func.isRequired,
};
