
import React, { memo } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { getBestMatchDesc } from 'components/QueryEditor/utils';


export const DescriptionCell = memo(({ row }) => {
  const desc = row.descriptions?.length ? getBestMatchDesc(row.descriptions) : row.description;
  return (
    <Tooltip
      title={desc}
      disableHoverListener={!desc}
      arrow
      placement='top'
    >
      <Typography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: 2,
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
        }}
      >
        {desc}
      </Typography>
    </Tooltip>
  );
});

DescriptionCell.displayName = 'DescriptionCell';
