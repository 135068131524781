import * as monaco from 'monaco-editor';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Editor, { loader } from '@monaco-editor/react';
import React, {
  useEffect, useState,
} from 'react';
import TextFieldInput from 'components/Common/TextFieldInput';
import {
  EDITOR_KEYWORDS,
} from 'utils/constants/question.constant';


const QuestionQueryBuilder = ({
  question, setQuestion,
}) => {
  const [showModernEditor, setShowModernEditor] = useState(false);
  useEffect(() => {
    setShowModernEditor(true);
  }, []);

  const updateQuestionInputField = (value) => {
    setQuestion?.(prevState => ({ ...prevState, queryTemplates: [value] }));
  };

  // Generate suggestions for standard sql keywords
  useEffect(() => {
    const { dispose } = monaco.languages.registerCompletionItemProvider('sql', {
      provideCompletionItems: (model, position, context) => {
        if (context?.triggerCharacter !== '@') {
          const standardKeywords = EDITOR_KEYWORDS.sql.map(keyword => (
            {
              label: keyword.name,
              insertText: keyword.name,
              kind: monaco.languages.CompletionItemKind.Keyword,
              detail: `Keyword: ${keyword.details}`,
              documentation: keyword.details,
            }
          ));
          return { suggestions: [...standardKeywords] };
        }
        else {
          return { suggestions: [] };
        }
      },
    });

    loader.config({ monaco });
    return (() => {
      dispose();
    });
  }, []);

  // Generate suggestions for dataset types, datasets and parameters
  useEffect(() => {
    const { dispose } = monaco.languages.registerCompletionItemProvider('sql', {
      triggerCharacters: ['@'],
      provideCompletionItems: (model, position, context) => {
        if (context?.triggerCharacter === '@') {
          const datasetTypes = question.dataTypes.filter(dt => dt.datasetID.length === 0)
            .map(datasetType => (
              {
                label: datasetType.macro,
                insertText: datasetType.macro,
                kind: monaco.languages.CompletionItemKind.Text,
                detail: 'Dataset type',
              }
            ));

          const datasets = question.dataTypes.filter(dt => dt.datasetID.length > 0)
            .map(dataset => (
              {
                label: dataset.macro,
                insertText: dataset.macro,
                kind: monaco.languages.CompletionItemKind.Text,
                detail: 'Dataset',
              }
            ));

          const parameters = question.parameters.map(param => (
            {
              label: param.name,
              insertText: param.name,
              kind: monaco.languages.CompletionItemKind.Text,
              detail: 'Parameter',
            }
          ));

          return { suggestions: [...datasetTypes, ...datasets, ...parameters] };
        }
        else {
          return { suggestions: [] };
        }
      },
    });

    loader.config({ monaco });
    return (() => {
      dispose();
    });
  }, [question]);

  // Monaco Editor onMount config and event handling
  const handleEditorMount = (editor) => {
    /**
     * 'editor.onDidBlurEditorWidget' is an event emitted when the text inside the current query
     * editor loses focus. We need to update/set the query state with the updated query text on
     * the editor blur event
     */
    editor.onDidBlurEditorWidget(() => {
      updateQuestionInputField(editor.getValue());
    });
  };

  return (
    <>
      <Card>
        {showModernEditor
          ? (
            <Box positon='inherit'>
              <Editor
                height='604px'
                defaultValue=''
                language='sql'
                options={{
                  minimap: { enabled: false },
                  wordWrap: true,
                  fontSize: 15,
                  readOnly: true,
                  contextmenu: false,
                }}
                value={question.queryTemplates[0]}
                onMount={handleEditorMount}
              />
            </Box>
          )
          : (
            <TextFieldInput
              value={question.queryTemplates[0]}
              onBlur={updateQuestionInputField}
              multiline
              rows={25}
            />
          )}
      </Card>
    </>
  );
};

export default QuestionQueryBuilder;
