import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const useValidateExclusiveDatasetMode = () => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);
  return useMutation(
    ({ crId, crqId }) => axiosInstance.post(cleanRoom(orgId)
      .validateExclusiveDatasetMode(crId, crqId)),
    {
      onError: (error) => dispatch(showAlert({ message: error.response.data.message, type: 'error' })),
    },
  );
};
