import PermissionService from 'components/Common/PermissionService';
import { CLEAN_ROOM_PERMISSIONS } from 'components/CleanRooms/constants';
import { productPerm } from 'utils/appConstants';


const cleanRoomActivationPerms = [
  CLEAN_ROOM_PERMISSIONS.ProvisionActivationChannels,
  CLEAN_ROOM_PERMISSIONS.ActivateListQuestions,
];

const hasAnyCleanRoomPerm = (userRolePermissions) => {
  const perms = userRolePermissions ?? [];
  return perms.some(p => cleanRoomActivationPerms.includes(p));
};

const hasCleanRoomProvisionPerm = (userRolePermissions) => {
  const perms = userRolePermissions ?? [];
  return perms.includes(CLEAN_ROOM_PERMISSIONS.ProvisionActivationChannels);
};

const hasCleanRoomActivationPerm = (userRolePermissions) => {
  const perms = userRolePermissions ?? [];
  return perms.includes(CLEAN_ROOM_PERMISSIONS.ActivateListQuestions);
};

const isCleanRoomPermissionEnhancementEnabled = () => PermissionService.isProductEnabled(
  [productPerm.CleanRoomPermissionEnhancement]);

const isProductEnabled = () => PermissionService.isProductEnabled(
  [productPerm.cleanRoomActivation]);

export const isViewListAuthorized = (userRolePermissions) => {
  const productEnabled = isProductEnabled();
  const permEnhancementEnabled = isCleanRoomPermissionEnhancementEnabled();
  const hasEssentialPerm = hasAnyCleanRoomPerm(userRolePermissions);
  return permEnhancementEnabled ? hasEssentialPerm : productEnabled;
};

export const isProvisionAuthorized = (userRolePermissions) => {
  const productEnabled = isProductEnabled();
  const permEnhancementEnabled = isCleanRoomPermissionEnhancementEnabled();
  const hasEssentialPerm = hasCleanRoomProvisionPerm(userRolePermissions);
  return permEnhancementEnabled ? hasEssentialPerm : productEnabled;
};

export const isActivationAuthorized = (userRolePermissions) => {
  const productEnabled = isProductEnabled();
  const permEnhancementEnabled = isCleanRoomPermissionEnhancementEnabled();
  const hasEssentialPerm = hasCleanRoomActivationPerm(userRolePermissions);
  return permEnhancementEnabled ? hasEssentialPerm : productEnabled;
};
