import React from 'react';
import { Button } from '@mui/material';
import {
  HDialog,
  HDialogActions,
  HDialogContent,
  HDialogTitle,
} from 'BaseComponents';
import { QuestionViewModalLayout } from 'components/Question/QuestionDetails/QuestionViewModal/QuestionViewModalLayout';
import { QuestionViewModalProvider } from 'components/Question/QuestionDetails/QuestionViewModal/QuestionViewModalProvider';


export const QuestionViewModal = ({
  open, defaultTab, onClose, questionId, orgId, crId, crqID,
}) => (
  <HDialog
    open={open}
    onClose={onClose}
    scroll='paper'
    fullWidth
    maxWidth='lg'
  >
    <HDialogTitle onClose={onClose}>
      Question Details
    </HDialogTitle>

    <HDialogContent sx={{ minHeight: '680px' }}>
      <QuestionViewModalProvider
        questionId={questionId}
        orgId={orgId}
        crId={crId}
        crqID={crqID}
      >
        <QuestionViewModalLayout defaultTab={defaultTab} />
      </QuestionViewModalProvider>
    </HDialogContent>

    <HDialogActions>
      <Button onClick={onClose} color='primary' variant='outlined'>
        Close
      </Button>
    </HDialogActions>
  </HDialog>
);
