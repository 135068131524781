import React from 'react';
import { AllQuestionsContextProvider } from './AllQuestionsContextProvider';
import { AllQuestionsTableLayout } from 'components/CleanRooms/AllQuestions/AllQuestionsTableLayout';


const AllQuestions = ({ goToCurrentQuestions }) => (
  <AllQuestionsContextProvider goToCurrentQuestions={goToCurrentQuestions}>
    <AllQuestionsTableLayout />
  </AllQuestionsContextProvider>
);

export default AllQuestions;
