import Bouncer from 'components/Common/Bouncer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DatasetChip from 'components/CleanRooms/CurrentQuestions/DatasetChip';
import InfoIcon from '@mui/icons-material/Info';
import React, { memo, useContext } from 'react';
import Stack from '@mui/material/Stack';
import get from 'lodash/get';
import { HButton } from 'BaseComponents';
import { IconButton, Tooltip } from '@mui/material';
import { IdContext } from 'pages/CleanRooms/QuestionDetails';
import { Link, useHistory } from 'react-router-dom';
import { cleanRoom } from 'utils/spaUrls';
import { cleanRoomPermissions } from 'utils/appConstants';
import { crQuestionStage } from 'utils/ignoramusEnums';


export const DatasetsManagementCell = memo(({ row }) => {
  const history = useHistory();
  const { crId } = useContext(IdContext);


  return (
    <Stack direction='row' spacing={1}>
      <DatasetChip question={row} />
      <Bouncer allowList={[cleanRoomPermissions.manageDatasets]}>
        <HButton
          size='small'
          data-testid='current-question-manage-dataset-button'
          component={Link}
          to='#'
          onClick={() => history.push(cleanRoom.assignDatasetsWizard(crId, row.ID))}
          variant='outlined'
        >
          Manage Datasets
        </HButton>
      </Bouncer>
      <Tooltip title={get(crQuestionStage, `${row.stage}.label`)} placement='top'>
        <IconButton data-testid='check-circle-icon'>
          {(row.stage === crQuestionStage.READY.key) ? (
            <CheckCircleIcon color='success' />
          ) : (
            <InfoIcon color='action' />
          )}
        </IconButton>
      </Tooltip>
    </Stack>
  );
});

DatasetsManagementCell.displayName = 'DatasetsManagementCell';
