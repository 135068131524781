import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import React, { memo } from 'react';
import Select from '@mui/material/Select';
import clsx from 'clsx';
import { Box, Chip, Typography } from '@mui/material';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const HMultipleSelect = memo(({
  label,
  menuItems,
  value,
  onChange,
  ...props
}) => {
  const isAllSelected = Array.isArray(value) && value.length === menuItems.length;

  const handleOnChange = (event) => {
    const newValue = event.target.value;
    if (onChange && onChange instanceof Function) {
      if (newValue[newValue.length - 1] === 'all') {
        onChange(value.length === menuItems.length ? [] : menuItems);
        return;
      }
      onChange(newValue);
    }
  };

  return (
    <>
      <FormControl {...props}>
        <InputLabel id='multiple-checkbox-label' sx={{ typography: 'body2' }}>{label}</InputLabel>
        <Select
          labelId='multiple-checkbox-label'
          id='multiple-checkbox'
          label={label}
          multiple
          value={value}
          onChange={handleOnChange}
          renderValue={(selected) => {
            const maxVisibleChips = 2;
            const visibleChips = selected.length <= maxVisibleChips ? selected
              : selected.slice(0, 1);
            const hiddenCount = selected.length > maxVisibleChips ? selected.length - 1 : 0;
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {visibleChips.map((v) => (
                  <Chip
                    key={v}
                    label={v}
                    size='small'
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    onDelete={(e) => {
                      e.stopPropagation();
                      handleOnChange({ target: { value: selected.filter(item => item !== v) } });
                    }}
                  />
                ))}
                {hiddenCount > 0 && (
                  <Typography
                    variant='body3'
                    alignContent='center'
                  >
                    {`+${hiddenCount} more`}
                  </Typography>
                )}
              </Box>
            );
          }}
          sx={{ typography: 'body2' }}
          MenuProps={MenuProps}
          variant='outlined'
        >
          <MenuItem
            key='select-all'
            value='all'
            className={clsx({
              [menuItemClasses.selected]: isAllSelected,
            })}
            disabled={menuItems.length === 0}
            sx={{ paddingY: '4px' }}
          >
            <Checkbox
              checked={isAllSelected}
              indeterminate={
              value.length > 0 && value.length < menuItems.length
            }
            />
            <ListItemText
              primary='ALL'
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
          <Divider />
          {menuItems.map((item) => (
            <MenuItem key={item} value={item} sx={{ paddingY: '4px' }}>
              <Checkbox checked={value ? value.indexOf(item) > -1 : false} />
              <ListItemText
                primary={item}
                primaryTypographyProps={{ variant: 'body2' }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
});

HMultipleSelect.displayName = 'HMultipleSelect';

export default HMultipleSelect;
