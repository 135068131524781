import React, { useContext } from 'react';
import { ActionButton } from 'components/CleanRooms/TemplateProvisionQuestion/ActionButton';
import { HDataTable, HTableControlPanel } from 'BaseComponents';
import { TemplateProvisionQuestionContext } from 'components/CleanRooms/TemplateProvisionQuestion/TemplateProvisionQuestionProvider';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { columns } from 'components/CleanRooms/TemplateProvisionQuestion/columns';
import { questionManagement } from 'utils/urlConstants';
import { useSelector } from 'react-redux';


const defaultFilter = (type) => ([
  { attribute: 'CLEAN_ROOM_TYPE', values: [type] },
]);

export const TemplateProvisionQuestionTable = () => {
  const accountId = useSelector(state => state.session.user.primaryAccount.ID);
  const { ID: orgID } = useSelector(activeOrgSelector);
  const cleanRoomType = useSelector((state) => state.cleanRooms.type.name);
  const { dataContextRef } = useContext(TemplateProvisionQuestionContext);
  const dataURL = questionManagement.orgProvisionedQuestions(accountId, orgID);
  const filtersURL = questionManagement.orgListFilters(accountId, orgID);

  return (
    <HDataTable
      dataURL={dataURL}
      filtersURL={filtersURL}
      tableName='clean-room-provision-questions'
      dataBaseField='questionDetails'
      columns={columns}
      actionButton={ActionButton}
      activeQuery={false}
      defaultFilter={defaultFilter(cleanRoomType)}
      controlPanel={(
        <HTableControlPanel
          searchAttribute
          createPermissions={[]}
          dataContextRef={dataContextRef}
        />
      )}
    />
  );
};
