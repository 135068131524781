import * as spaUrls from 'utils/spaUrls';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FunctionsIcon from '@mui/icons-material/Functions';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PermissionService from 'components/Common/PermissionService';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import {
  AdminPanelSettings, BubbleChart, DataThresholding, ExitToApp, Explore,
  GridView, MeetingRoom, NotificationsActiveOutlined,
  People, PieChart, Storage, ViewInAr,
} from '@mui/icons-material';
import { CleanRoomTypes, Parameters } from 'components/CleanRooms/constants';
import {
  PRODUCT,
  userPermissions,
  userTypes,
} from 'utils/appConstants';
import { get } from 'lodash';


const sideNavMenuItems = () => {
  const isTranscodingEnabled = PermissionService.isProductEnabled([userPermissions.Transcoding]);
  const isUdfEnabled = PermissionService.isProductEnabled([
    userPermissions.UserDefinedFunctionSupport]);

  return [
    {
      title: 'Clean Rooms',
      icon: ViewInAr,
      allowList: [userPermissions.crAdmin, userPermissions.crReadOnly],
      items: [
        {
          title: 'Clean Rooms',
          path: spaUrls.cleanRoom.landing,
          paths: [spaUrls.cleanRoom.landing, spaUrls.cleanRoom.landingTemplate],
          allowList: [
            userPermissions.crAdmin, userPermissions.crReadOnly,
          ],
        },
        {
          title: 'Inbound Invitations',
          path: spaUrls.cleanRoom.inboundInvitations,
          allowList: [userPermissions.crAdmin],
        },
      ],
    },
    {
      title: 'Data Management',
      icon: Storage,
      path: '',
      allowList: [userPermissions.dataImportJobsAdmin, userPermissions.dataImportJobsReadOnly],
      items: [
        {
          title: `${PRODUCT.DATA_CONNECTION.name}s`,
          path: spaUrls.dataIn.landing,
          allowList: [userPermissions.dataImportJobsAdmin, userPermissions.dataImportJobsReadOnly],
        },
        ...(isUdfEnabled ? [{
          title: 'User Defined Functions',
          path: spaUrls.dataIn.userDefinedFunctions,
          allowList: [userPermissions.UserDefinedFunctionSupport],
        }] : []),
        {
          title: 'Credentials',
          path: spaUrls.organizationManagement.organization.credentials.list,
          allowList: [userPermissions.orgAdmin, userPermissions.dataImportJobsAdmin],
        },
        {
          title: 'Data Source Locations',
          path: spaUrls.organizationManagement.organization.dataSourceLocations,
          allowList: [userPermissions.orgAdmin, userPermissions.dataImportJobsAdmin],
        },
        ...(isTranscodingEnabled ? [{
          title: 'Identity',
          path: spaUrls.organizationManagement.organization.identity.list,
          allowList: [userPermissions.Transcoding],

        }] : []),
      ],
    },
    {
      title: 'Questions',
      icon: Explore,
      path: spaUrls.questionManagement.orgQuestions('available-questions'),
      paths: [
        spaUrls.questionManagement.orgQuestions('available-questions'),
        spaUrls.questionManagement.orgQuestions('clean-room'),
        spaUrls.questionManagement.orgQuestions('account'),
        spaUrls.questionManagement.orgQuestions('platform'),
      ],
      allowList: [userPermissions.questionManagementAdmin, userPermissions.questionBuilderAdmin],
    },
    {
      title: 'Intelligence',
      icon: BubbleChart,
      path: spaUrls.metabase.habuintelligenceEmbed,
      allowList: [
        userPermissions.superuser,
        userPermissions.habuIntelligenceAdmin,
        userPermissions.accountAdmin,
      ],
    },
    {
      title: 'Destinations & Integrations',
      icon: GridView,
      path: '',
      allowList: [
        userPermissions.dataOutAdmin,
        userPermissions.dataOutReadOnly,
        userPermissions.LegacyHabuActivationChannels,
      ],
      items: [
        {
          title: 'User List Builder',
          allowList: [userPermissions.userListAdmin, userPermissions.userListReadOnly],
          accessList: [userPermissions.LegacyHabuActivationChannels],
          path: spaUrls.userList.list,
        },
        {
          title: 'User List Activation',
          path: spaUrls.dataOut.userListsActivation,
          allowList: [userPermissions.dataOutAdmin, userPermissions.dataOutReadOnly],
          accessList: [userPermissions.LegacyHabuActivationChannels],
        },
        {
          title: 'Offline Conversions',
          path: spaUrls.dataOut.offlineConversions,
          allowList: [userPermissions.dataOutAdmin, userPermissions.dataOutReadOnly],
          accessList: [userPermissions.LegacyHabuActivationChannels],
        },
        {
          title: 'Data Exports',
          path: spaUrls.dataOut.dataExports,
          allowList: [userPermissions.dataOutAdmin, userPermissions.dataOutReadOnly],
        },
        {
          title: 'Destinations',
          path: spaUrls.activationChannels.landing(),
          allowList: [userPermissions.dataOutAdmin, userPermissions.dataOutReadOnly],
          accessList: [userPermissions.ActivationChannels],
        },
      ],
    },
  ];
};

const sideUserNavMenuItems = () => {
  // TODO: DV-10680, deprecate GPG Encryption but keep the code for now
  const isGPGEncryptionEnabled = PermissionService.isProductEnabled([
    userPermissions.GPGEncryption,
  ]);

  const isTrustCenterEnabled = PermissionService.isProductEnabled([
    userPermissions.EnableTrustCenter,
  ]);

  const defaultUserSettingsItems = [
    {
      title: 'Admin',
      icon: SettingsIcon,
      path: '',
      allowList: [
        userPermissions.orgAdmin, userPermissions.accountAdmin,
        userPermissions.UserTrustCenterAdmin, userPermissions.UserTrustCenterReadOnly,
      ],
      items: [
        ...(isTrustCenterEnabled ? ([{
          title: 'Trust Center',
          path: spaUrls.trustCenter.landing,
          paths: [spaUrls.trustCenter.landing, spaUrls.trustCenter.datasets],
          allowList: [
            userPermissions.UserTrustCenterAdmin,
            userPermissions.UserTrustCenterReadOnly,
          ],
        }]) : []),
        {
          title: 'Account Admins',
          path: spaUrls.iam.accountAdmin,
          allowList: [userPermissions.accountAdmin],
        },
        {
          title: 'Organizations',
          path: spaUrls.iam.organizationList,
          allowList: [userPermissions.orgAdmin, userPermissions.accountAdmin],
        },
        {
          title: 'Roles',
          path: spaUrls.organizationManagement.organization.roles.list,
          allowList: [userPermissions.orgAdmin, userPermissions.accountAdmin],
        },
        {
          title: 'Users',
          path: spaUrls.organizationManagement.organization.users.list,
          allowList: [userPermissions.orgAdmin, userPermissions.accountAdmin],
        },
        {
          title: 'Clean Room Customization',
          path: spaUrls.organizationManagement.organization.customization.landing,
          allowList: [userPermissions.orgAdmin, userPermissions.accountAdmin],
        },
        {
          title: 'API Keys',
          path: spaUrls.apiKeyManagement.landing,
          allowList: [userPermissions.accountAdmin, userPermissions.orgAdmin],
          accessList: [userPermissions.ExternalAPIAccess],
        },
        // TODO: DV-10680, deprecate GPG Encryption but keep the code for now
        ...(isGPGEncryptionEnabled ? ([{
          title: 'GPG Key',
          path: spaUrls.organizationManagement.organization.gpgKeyManagement,
          allowList: [userPermissions.orgAdmin, userPermissions.accountAdmin],
        }]) : []),
      ],
    },
    {
      title: 'Internal',
      icon: PieChart,
      path: '',
      allowList: [userTypes.superuser],
      items: [
        {
          title: 'Accounts',
          path: spaUrls.iam.accountList,
          allowList: [userPermissions.superuser],
        },
        {
          title: 'Internal Admin',
          path: spaUrls.internalAdmin.landing,
          allowList: [userPermissions.superuser],
        },
        {
          title: 'Clean Room API Linking',
          path: spaUrls.organizationManagement.cleanRoom.OrgApiLinking,
          allowList: [userPermissions.superuser],
        },
      ],
    },
  ];

  return defaultUserSettingsItems;
};

const userMenuItems = [
  {
    title: 'Logout',
    icon: MeetingRoom,
    path: spaUrls.auth.logout,
  }];

const helpMenuItems = [
  {
    title: 'Help Documentation ',
    icon: ContactSupportIcon,
    path: spaUrls.redirect.helpDocumentation,
  },
  {
    title: 'Support Portal',
    icon: SupportAgentIcon,
    path: spaUrls.redirect.supportPortal,
  },
];

const crOwnerNav = (crId) => [

  {
    title: 'Access',
    path: '',
    icon: People,
    allowList: [userPermissions.cleanRoomManagement, userPermissions.partnerManagement],
    items: [
      {
        title: 'Partners & Users',
        path: spaUrls.cleanRoom.partnersAndUsers(crId),
        allowList: [
          userPermissions.cleanRoomManagement,
          userPermissions.partnerManagement,
        ],
      },
      {
        title: 'Outbound Invitations',
        path: spaUrls.cleanRoom.partnerInvitations(crId),
        allowList: [
          userPermissions.partnerManagement,
        ],
      },
    ],
  },
  {
    title: 'Destinations',
    icon: GridView,
    path: spaUrls.cleanRoom.activationChannels(crId),
    allowList: [userPermissions.cleanRoomActivation],
  },
  {
    title: 'Details',
    icon: ViewInArIcon,
    path: spaUrls.cleanRoom.cleanRoomSettings(crId),
  },
  {
    title: 'Exit',
    icon: ExitToApp,
    path: spaUrls.cleanRoom.landing,
  },
];

const crPartnerNav = (crId, details) => {
  const menuItems = [
    {
      title: 'Access',
      path: '',
      icon: People,
      allowList: [userPermissions.cleanRoomManagement],
      items: [
        {
          title: 'Users',
          path: spaUrls.cleanRoom.partnersAndUsers(crId),
        },
        {
          title: 'Roles',
          path: spaUrls.cleanRoom.manageRoles(crId, 'current'),
        },
      ],
    },
    {
      title: 'Destinations',
      icon: GridView,
      path: spaUrls.cleanRoom.activationChannels(crId),
      allowList: [userPermissions.cleanRoomActivation],
    },
    {
      title: 'Details',
      icon: ViewInArIcon,
      path: spaUrls.cleanRoom.cleanRoomSettings(crId),
    },
    {
      title: 'Exit',
      icon: ExitToApp,
      path: spaUrls.cleanRoom.landing,
    },
  ];

  if (details.type === CleanRoomTypes.AWS_CLEAN_ROOM) {
    menuItems[0].items.push({
      title: 'AWS Credential',
      icon: ManageAccountsIcon,
      path: spaUrls.cleanRoom.awsSettings(crId),
    });
  }

  return menuItems;
};

const getHabuIntelligenceParam = (parameters) => get(parameters?.find(p => p.name === Parameters.ENABLE_HABU_INTELLIGENCE), 'value');

const cleanRoomItems = (crId, crOwner, details = {}) => {
  const isAlertsEnabled = PermissionService.isProductEnabled([userPermissions.alerts]);
  const isFlowEnabled = PermissionService.isProductEnabled([userPermissions.Flow]);
  const isCrRunSummaryEnabled = PermissionService.isProductEnabled([userPermissions.CrRunSummary]);
  const isDataExportsV2Enabled = PermissionService.isProductEnabled(
    [userPermissions.DataExportsV2],
  );
  const isUdfEnabled = PermissionService.isProductEnabled([
    userPermissions.UserDefinedFunctionSupport]);
  const parameters = details.parameters || undefined;
  const enableHabuIntelligence = getHabuIntelligenceParam(parameters);
  const isHabuIntelligenceEnabled = Boolean(enableHabuIntelligence === 'true');

  const habuIntelligenceItem = isHabuIntelligenceEnabled ? {
    title: 'Intelligence',
    icon: BubbleChart,
    path: spaUrls.metabase.crHabuIntelligence(crId),
    allowList: [
      userPermissions.habuIntelligenceRead,
      userPermissions.habuIntelligenceWrite,
    ],
  } : null;
  const questionsItem = {
    title: 'Questions',
    icon: Explore,
    path: spaUrls.cleanRoom.question(crId),
    allowList: [
      userPermissions.manageQuestions,
      userPermissions.questionBuilder,
      userPermissions.viewReports,
      userPermissions.manageReports,
      userPermissions.scheduleRuns,
      userPermissions.manageDatasets,
    ],
  };

  const defaultCrNavItems = [
    questionsItem,
    habuIntelligenceItem,
    {
      title: 'Datasets',
      icon: Storage,
      path: spaUrls.cleanRoom.manageDatasets(crId),
      allowList: [
        userPermissions.manageDatasets,
      ],
    },
    isCrRunSummaryEnabled && {
      title: 'Run Summary',
      icon: DataThresholding,
      path: spaUrls.cleanRoom.runSummary(crId),
    },
    isUdfEnabled && (details.type === CleanRoomTypes.SNOWFLAKE_CR) && {
      title: 'User Defined Functions',
      icon: FunctionsIcon,
      path: spaUrls.cleanRoom.userDefinedFunctions(crId),
      allowList: [
        userPermissions.UserDefinedFunctionSupport,
      ],
    },
    isAlertsEnabled && {
      title: 'Alerts',
      icon: NotificationsActiveOutlined,
      path: spaUrls.cleanRoom.alertNotifications(crId),
      allowList: [userPermissions.alerts],
    },
    isFlowEnabled && {
      title: 'Flows',
      icon: HubOutlinedIcon,
      path: spaUrls.cleanRoom.flowLanding(crId),
      items: [],
      allowList: [userPermissions.crFlowsBuilder],
      alphaMessage: 'Private',
    },
    isDataExportsV2Enabled && {
      title: 'Exports',
      icon: FullscreenIcon,
      path: spaUrls.cleanRoom.exports(crId),
      items: [],
    },
  ].filter(Boolean);

  return crOwner
    ? defaultCrNavItems.concat(crOwnerNav(crId))
    : defaultCrNavItems.concat(crPartnerNav(crId, details));
};

const internalAdminItems = [
  {
    title: 'Platform Level Administration',
    items: [{
      title: 'Clean Room Questions',
      path: spaUrls.internalAdmin.application.cleanRoomQuestions,
    },
    {
      title: 'Credential Sources',
      path: spaUrls.internalAdmin.application.credentialSources,
    }],
  },
  {
    title: 'Account Level Administration',
    items: [{
      title: 'Product Access',
      path: spaUrls.internalAdmin.account.accountProductAccess,
    }],
  },
  {
    title: 'Organization Level Administration',
    items: [{
      title: 'Import Data Source Access',
      path: spaUrls.internalAdmin.organization.importDataSourceAccess,
    }, {
      title: 'Import Data Type Access',
      path: spaUrls.internalAdmin.organization.importDataTypeAccess,
    }, {
      title: 'Organization Product Access',
      path: spaUrls.internalAdmin.organization.organizationProductAccess,
    }, {
      title: 'Organization Properties',
      path: spaUrls.internalAdmin.organization.organizationProperties,
    }],
  },
];

export const getTree = (menuList = []) => menuList.filter(
  i => PermissionService.isAuthorized(i.allowList, i.blockList, i.accessList),
);

export const getPermissionFilteredTree = (menuList = []) => {
  if (!Array.isArray(menuList) || menuList.length === 0) {
    return [];
  }
  const filtered = menuList.filter(i => PermissionService.isAuthorized(i.allowList, i.blockList));
  filtered.forEach((j) => {
    // eslint-disable-next-line no-param-reassign
    j.items = getPermissionFilteredTree(j.items);
  });
  return filtered;
};

export const getUserMenu = () => {
  const supportMode = [{
    title: 'Support Mode',
    icon: AdminPanelSettings,
    path: spaUrls.supportMode.enable,
  }];
  const userItems = getTree(userMenuItems);
  return PermissionService.isCXUser() ? supportMode.concat(userItems) : userItems;
};

export const getHelpMenuItems = () => getTree(helpMenuItems);

export const getCleanRoomItems = (
  crId, crOwner, details,
) => getPermissionFilteredTree(
  cleanRoomItems(crId, crOwner, details),
);

export const getInternalAdminItems = () => getTree(internalAdminItems);

export const getSideNavMenu = () => getTree(sideNavMenuItems())
  .map(subtree => ({ ...subtree, items: getTree(subtree.items) }));

export const getSideUserMenu = () => getTree(sideUserNavMenuItems())
  .map(subtree => ({ ...subtree, items: getTree(subtree.items) }));
