import React, { useState } from 'react';
import { activationChannels, dataOut } from 'utils/urlConstants';
import { axiosInstance } from 'utils/http';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from 'react-query';


export const useFetchPlatformPartners = () => {
  const dispatch = useDispatch();
  const {
    data, isLoading, isError, isSuccess,
  } = useQuery(
    ['PlatformPartners'],
    async () => axiosInstance.get(activationChannels().platformPartners),
    {
      refetchOnWindowFocus: false,
      onError: (err) => dispatch(showAlert({
        message: err.response.data.message,
        type: 'error',
      })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
  };
};

export const useFetchPlatformPartnerById = (id) => {
  const dispatch = useDispatch();
  const {
    data, isLoading, isError, isSuccess, isFetched,
  } = useQuery(
    ['PlatformPartner', id],
    async () => axiosInstance.get(activationChannels().platformPartner(id)),
    {
      enabled: Boolean(id),
      refetchOnWindowFocus: false,
      onError: (err) => dispatch(showAlert({
        message: err.response.data.message,
        type: 'error',
      })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    isFetched,
    data,
  };
};

export const useFetchOrgPartners = (orgId, searchQuery) => {
  const dispatch = useDispatch();
  const {
    data, isLoading, isError, isSuccess,
  } = useQuery(
    ['OrgPartners', orgId, searchQuery],
    async () => axiosInstance.get(activationChannels(orgId).orgPartners(searchQuery)),
    {
      refetchOnWindowFocus: false,
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
  };
};


export const useFetchOrgPartnerAccounts = (orgId, payload) => {
  const dispatch = useDispatch();
  const {
    data, isLoading, isError, isSuccess, refetch,
  } = useQuery(
    ['OrgPartnerAccounts', orgId, payload],
    async () => axiosInstance.post(activationChannels(orgId).orgPartnerAccounts, payload),
    {
      refetchOnWindowFocus: false,
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    refetch,
  };
};

export const useCreateChannel = (orgId) => {
  const dispatch = useDispatch();

  const {
    isLoading, isError, isSuccess, mutateAsync,
  } = useMutation(
    (payload) => axiosInstance.post(
      activationChannels(orgId).createChannel, payload,
    ),
    {
      refetchOnWindowFocus: false,
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message ?? err.response.statusText,
          type: 'error',
        }));
      },
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutateAsync,
  };
};

export const useUpdateChannel = (orgId, channelId) => {
  const dispatch = useDispatch();

  const {
    isLoading, isError, isSuccess, mutateAsync,
  } = useMutation(
    (payload) => axiosInstance.put(
      activationChannels(orgId).updateChannel(channelId), payload,
    ),
    {
      refetchOnWindowFocus: false,
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message ?? err.response.statusText,
          type: 'error',
        }));
      },
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutateAsync,
  };
};

// TODO: DV-9991 remove the logic
export const useCreateCustomChannel = (orgId) => {
  const [data, setData] = useState(null);
  const dispatch = useDispatch();

  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    (payload) => axiosInstance.post(
      activationChannels(orgId).createCustomActivationChannel, payload,
    ),
    {
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        setData(res.data);
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message ?? err.response.statusText,
          type: 'error',
        }));
      },
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

export const useDeletePartnerAccount = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    async ({ orgId, id }) => axiosInstance
      .delete(activationChannels(orgId).deletePartnerAccount(id)),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Partner account has been deleted.', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
  };
};

export const useFetchDataOutJobs = (orgId, partnerId) => {
  const [data, setData] = React.useState();
  const dispatch = useDispatch();

  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    (payload) => axiosInstance.post(dataOut(orgId).activationsPartnerList(partnerId), payload),
    {
      onSuccess: (res) => {
        setData(res.data);
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message ?? err.response.statusText,
          type: 'error',
        }));
      },
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

export const useDeleteDataOutJob = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    async ({ orgId, jobId }) => axiosInstance
      .delete(dataOut(orgId).deleteActivation(jobId)),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Data Exports Job has been deleted.', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
  };
};

export const useUpdateActivationStatus = () => {
  const dispatch = useDispatch();

  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async ({ orgId, jobId, payload }) => axiosInstance.post(
      dataOut(orgId).updateActivationStatus(jobId), payload,
    ),
    {
      refetchOnWindowFocus: false,
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message ?? err.response.statusText,
          type: 'error',
        }));
      },
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
  };
};

export const useFetchProvisionedPartnerAccountsCount = (enabled, orgId, partnerAccountID) => {
  const dispatch = useDispatch();
  return useQuery(
    ['PartnerAccountsCount', partnerAccountID],
    () => axiosInstance.get(
      activationChannels(orgId).provisionedPartnerAccountsCount(partnerAccountID),
    ),
    {
      enabled: enabled && Boolean(partnerAccountID),
      refetchOnWindowFocus: false,
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};

export const useFetchCRPartnerActivationsCount = (
  enabled, orgId, cleanRoomID, partnerAccountID) => {
  const dispatch = useDispatch();
  return useQuery(
    ['CRPartnerActivationsCount', cleanRoomID, partnerAccountID],
    () => axiosInstance.get(
      activationChannels(orgId).crPartnerActivationsCount(cleanRoomID, partnerAccountID),
    ),
    {
      enabled: enabled && Boolean(partnerAccountID),
      refetchOnWindowFocus: false,
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};
