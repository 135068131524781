import QuestionVersionDiffViewer from './QuestionVersionDiffViewer';
import QuestionVersionHistorySelect from './QuestionVersionHistorySelect';
import React, { useEffect, useState } from 'react';
import { Alert, Stack } from '@mui/material';
import { useFetchQuestion } from 'hooks/questionAPIs';
import { useParams } from 'react-router-dom';


const QuestionVersionHistory = ({ question, isDarkMode = false }) => {
  const { crId } = useParams();
  const [selectedVersion, setSelectedVersion] = useState(0);

  useEffect(() => {
    if (question?.version) {
      setSelectedVersion(question.version || 0);
    }
  }, [question?.version]);

  const {
    data: questionByVersion,
    isFetching: isLoadingQuestionByVersion,
    isError: isQuestionByVersionError,
  } = useFetchQuestion(question?.ID, crId, selectedVersion);

  const handleVersionChange = (version) => {
    setSelectedVersion(version);
  };

  // Conditional rendering based on error
  if (isQuestionByVersionError) {
    return (
      <Alert severity='error'>
        Error while fetching question version details
      </Alert>
    );
  }

  return (
    <Stack spacing={2}>
      <QuestionVersionHistorySelect
        question={question}
        selectedVersion={selectedVersion}
        onVersionChange={handleVersionChange}
      />
      <QuestionVersionDiffViewer
        selectedQuestion={question}
        questionByVersion={questionByVersion}
        isLoadingQuestionByVersion={isLoadingQuestionByVersion}
        isDarkMode={isDarkMode}
      />
    </Stack>
  );
};

export default QuestionVersionHistory;
