
import React, { memo } from 'react';
import { HTagsList } from 'BaseComponents/HTagsTextField';


export const TagsCell = memo(({ row }) => (
  <HTagsList values={row.tags} />
));

TagsCell.displayName = 'TagsCell';
