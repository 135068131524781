import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useContext, useState } from 'react';
import TextFieldInput from 'components/Common/TextFieldInput';
import Typography from '@mui/material/Typography';
import { CurrentQuestionsPageContext } from 'components/CleanRooms/CurrentQuestions/CurrentQuestionsPageContextProvider';
import { HButton, HSpinner } from 'BaseComponents';


export const CloneQuestionToHIDialog = ({ error }) => {
  const [targetQuestionId, setTargetQuestionId] = useState('');
  const [cardId, setCardId] = useState('');

  const {
    cloneQuestion,
    crId,
    orgId,
    setCloneQuestion,
    cloneQuestionToHI,
  } = useContext(CurrentQuestionsPageContext);

  const loading = cloneQuestionToHI.isLoading;

  const handleClose = () => setCloneQuestion(null);

  const onSubmit = (event) => {
    event.preventDefault();
    const { displayID: sourceCRQDisplayID } = cloneQuestion;
    const payload = { cardID: cardId, sourceCRQDisplayID, targetCRQDisplayID: targetQuestionId };
    cloneQuestionToHI.mutate({
      orgId, crId, qId: cloneQuestion.ID, payload,
    });
  };

  return (
    <>
      {cloneQuestion && (
      <Dialog
        open={!!cloneQuestion}
        onClose={handleClose}
        fullWidth
        maxWidth='xs'
        aria-labelledby='confirmation-dialog'
        aria-describedby='confirmation-dialog'
      >
        <form onSubmit={onSubmit}>
          <Card>
            <DialogTitle>
              Clone Question to Intelligence
            </DialogTitle>
            <DialogContent>
              <Box pb={3}>Are you sure you want to clone this question to Intelligence?</Box>
              <TextFieldInput
                label='Source CR Question'
                value={cloneQuestion?.displayID}
                disabled
              />
              <TextFieldInput
                label='Target CR Question'
                value={targetQuestionId}
                onBlur={value => setTargetQuestionId(value)}
                required
              />
              <TextFieldInput
                label='Card ID'
                value={cardId}
                onBlur={value => setCardId(value)}
                required
              />
            </DialogContent>
            <Box display='flex' justifyContent='flex-end'>
              <CardActions sx={{ padding: 2 }}>
                {handleClose && (
                  <HButton variant='outlined' onClick={handleClose} size='small' data-testid='cancel-button'>
                    Cancel
                  </HButton>
                )}
                <HButton
                  variant='contained'
                  color='primary'
                  size='small'
                  endIcon={loading ? <HSpinner isButton /> : null}
                  disabled={loading}
                  data-testid='confirm-button'
                  type='submit'
                >
                  Clone
                </HButton>
              </CardActions>
            </Box>
            {error && (<Typography color='error'>{error}</Typography>)}
          </Card>
        </form>
      </Dialog>
      )}
    </>
  );
};
