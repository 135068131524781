import PermissionService from 'components/Common/PermissionService';
import { AllQuestionsContext } from 'components/CleanRooms/AllQuestions/AllQuestionsContextProvider';
import { productPerm } from 'utils/appConstants';
import { useContext } from 'react';


export const useActionMenuItems = () => {
  const {
    seeQuestionDetails,
  } = useContext(AllQuestionsContext);
  const isQuestionVersioningEnabled = PermissionService.isProductEnabled(
    [productPerm.questionVersioningV1],
  );

  return (row) => ([{
    label: 'See Details',
    action: () => seeQuestionDetails(row.ID),
  }, isQuestionVersioningEnabled && {
    label: 'See Version History',
    action: () => seeQuestionDetails(row.ID, 'versionHistory'),
  }].filter(Boolean));
};
