
export const PLACEHOLDER_FORMAT = { label: 'Select Format', value: 'Select Format' };

export const FIELD_FORMATS = {
  DATE: [
    {
      label: 'YYYY-MM-DD',
      value: 'YYYY-MM-DD',
    },
    {
      label: 'MM-DD-YYYY',
      value: 'MM-DD-YYYY',
    },
    {
      label: 'DD-MM-YYYY',
      value: 'DD-MM-YYYY',
    },
    {
      label: 'YYYY/MM/DD',
      value: 'YYYY/MM/DD',
    },
    {
      label: 'YYYY/DD/MM',
      value: 'YYYY/DD/MM',
    },
    {
      label: 'MM/DD/YYYY',
      value: 'MM/DD/YYYY',
    },
    {
      label: 'DD/MM/YYYY',
      value: 'DD/MM/YYYY',
    },
  ],
  EMAIL: [
    {
      label: 'lower_SHA256 Hash',
      value: 'lower_SHA256 Hash',
    },
    {
      label: 'lower_MD5 Hash',
      value: 'lower_MD5 Hash',
    },
    {
      label: 'lower_SHA1 Hash',
      value: 'lower_SHA1 Hash',
    },
    {
      label: 'upper_SHA256 Hash',
      value: 'upper_SHA256 Hash',
    },
    {
      label: 'upper_MD5 Hash',
      value: 'upper_MD5 Hash',
    },
    {
      label: 'upper_SHA1 Hash',
      value: 'upper_SHA1 Hash',
    },
  ],
};
