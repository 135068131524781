import AddRateLimitingConfigDialog from 'pages/InternalAdmin/organization/pages/RateLimiting/AddRateLimitingConfigDialog';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PageHeader from 'components/Common/PageHeader';
import React, { useState } from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Add as AddIcon } from '@mui/icons-material';
import { HButton } from 'BaseComponents';
import {
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { useDeleteRateLimitConfig, useFetchRateLimitConfigAll } from 'api/internalAdmin/rateLimiting';


const RateLimiting = () => {
  const [openAddConfigDialog, setOpenAddConfigDialog] = useState({
    isOpen: false,
    data: null,
  });
  const [deleteConfigConfirmationDialog, setDeleteConfigConfirmationDialog] = useState({
    isOpen: false,
    data: null,
  });

  const {
    isLoading,
    data,
  } = useFetchRateLimitConfigAll();

  const { mutateAsync: deleteRateLimitConfig } = useDeleteRateLimitConfig();

  const handleConfirmationClose = () => {
    setDeleteConfigConfirmationDialog({
      isOpen: false,
      data: null,
    });
  };

  const handleConfirm = () => {
    deleteRateLimitConfig(deleteConfigConfirmationDialog.data);
    handleConfirmationClose();
  };

  return (
    <>
      <PageHeader
        title='Rate Limiting'
        breadcrumbs={[crumbs.helium, crumbs.internalAdmin]}
      />

      <Paper
        elevation={0}
        sx={{
          p: 2,
          mb: 2,
          textAlign: 'right',
        }}
      >
        <HButton
          variant='contained'
          color='secondary'
          startIcon={<AddIcon />}
          size='small'
          onClick={() => setOpenAddConfigDialog({
            isOpen: true,
            data: null,
          })}
        >
          Add
        </HButton>
      </Paper>

      <Paper>
        <TableContainer>
          {isLoading ? (
            <Skeleton variant='rectangular' width='100%' height={320} />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>API</TableCell>
                  <TableCell>Method</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>
                    Replenish Rate
                    <Tooltip title='The frequency at which API tokens are added to a bucket, allowing for a controlled number of requests per second.'>
                      <IconButton>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    Burst Capacity
                    <Tooltip title='The maximum number of concurrent requests an API can handle per second.'>
                      <IconButton>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.length > 0 ? (
                  data?.map?.((row) => (
                    <TableRow key={row.key}>
                      <TableCell>{row.key}</TableCell>
                      <TableCell>{row.method}</TableCell>
                      <TableCell>{row.type}</TableCell>
                      <TableCell>{row.replenishRate}</TableCell>
                      <TableCell>{row.burstCapacity}</TableCell>
                      <TableCell align='right'>
                        <Stack direction='row' gap={1} justifyContent='flex-end'>
                          <HButton
                            variant='outlined'
                            startIcon={<EditOutlinedIcon />}
                            onClick={() => setOpenAddConfigDialog({
                              isOpen: true,
                              data: row,
                            })}
                          >
                            Edit
                          </HButton>
                          <HButton
                            variant='outlined'
                            color='error'
                            startIcon={<DeleteIcon />}
                            onClick={() => setDeleteConfigConfirmationDialog({
                              isOpen: true,
                              data: row,
                            })}
                          >
                            Delete
                          </HButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align='center'>
                      <Typography>No data available</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Paper>

      {
        openAddConfigDialog && (
          <AddRateLimitingConfigDialog
            open={openAddConfigDialog.isOpen}
            initialValues={openAddConfigDialog.data}
            onClose={() => setOpenAddConfigDialog({
              isOpen: false,
              data: null,
            })}
          />
        )
      }

      <ConfirmationDialog
        open={deleteConfigConfirmationDialog.isOpen}
        title={
          (
            <Stack direction='row' gap={1} alignItems='center'>
              <WarningAmberIcon color='warning' />
              <Typography variant='h3'>Confirm</Typography>
            </Stack>
          )
        }
        message='Are you sure you want to delete this rate limiting configuration?'
        confirmActionLabel='Delete'
        onConfirm={handleConfirm}
        onCancel={handleConfirmationClose}
      />
    </>
  );
};

export default RateLimiting;
