import AnalysisRuleRunStatusDialog from 'components/CleanRooms/CurrentQuestions/AnalysisRuleRunStatusDialog';
import ComputeCapacityModal from 'components/QuestionManagement/ComputeCapacityModal';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import DeleteAssetModal from 'components/CleanRooms/DeleteAssetModal';
import ManageQuestionPermissionsModal from 'components/CleanRooms/CurrentQuestions/ManageQuestionPermissionsModal';
import NewReportRunModal from 'components/CleanRooms/CurrentQuestions/NewReportRunModal';
import PermissionService from 'components/Common/PermissionService';
import QuestionPermissionsOverridesModal from 'components/CleanRooms/CurrentQuestions/QuestionPermissionsOverridesModal';
import React, { useContext } from 'react';
import SelectRampIdModal from 'components/CleanRooms/SelectRampIdModal';
import ShareQuestionResultsModal from 'components/CleanRooms/CurrentQuestions/ShareQuestionResultsModal';
import { ActivationDialog } from 'components/CleanRooms/Reporting/ActivationDialog';
import { Box } from '@mui/material';
import { CloneQuestionToHIDialog } from 'components/CleanRooms/CurrentQuestions/CloneQuestionToHIDialog';
import { CurrentQuestionsPageContext } from 'components/CleanRooms/CurrentQuestions/CurrentQuestionsPageContextProvider';
import { QuestionViewModal } from 'components/Question/QuestionDetails/QuestionViewModal';
import { QuestionsTableLayout } from 'components/CleanRooms/CurrentQuestions/QuestionsTableLayout';
import { productPerm } from 'utils/appConstants';
import { useDeleteCleanRoomQuestion } from 'api/cleanrooms';


export const CurrentQuestionsPageLayout = () => {
  const {
    duplicateQuestionId,
    removeQuestionId,
    showShareQuestionResultsModal,
    fetchQuestions,
    shareablePartners,
    qDetailModal,
    qDetailModalTab,
    qDetailsID,
    crQuestionID,
    onClose,
    advSettingsModal,
    onAdvSettingsClose,
    crId,
    runModalDetails,
    setRemoveQuestionId,
    setDuplicateQuestionId,
    orgId,
    meta,
    setShowShareQuestionResultsModal,
    dataContextRef,
    isDuplicating,
    handleDuplicateQuestion,
    managePermissions,
    analysisRuleRunStatus,
    handleCloseAnalysisRuleRunStatus,
    showSelectRampIdModal,
    setShowSelectRampIdModal,
    showDeleteAssetsModal,
    setShowDeleteAssetsModal,
  } = useContext(CurrentQuestionsPageContext);

  const isCrQuestionPermissionsEnabled = PermissionService.isProductEnabled(
    [productPerm.crQuestionPermissions],
  );

  const { mutateAsync: deleteCrQuestion } = useDeleteCleanRoomQuestion();

  const handleOnConfirm = async () => {
    await deleteCrQuestion({ crId, removeQuestionId });
    dataContextRef.current.refreshListData();
    setRemoveQuestionId(undefined);
  };

  return (
    <>
      <Box mt={3} mx={1}>
        <QuestionsTableLayout />
      </Box>
      {qDetailModal && (
        <QuestionViewModal
          open={qDetailModal}
          defaultTab={qDetailModalTab}
          onClose={onClose}
          questionId={qDetailsID}
          orgId={orgId}
          crId={crId}
          crqID={crQuestionID}
        />
      )}
      {advSettingsModal ? (
        <ComputeCapacityModal
          open={advSettingsModal}
          onClose={onAdvSettingsClose}
          questionId={qDetailsID}
          crqId={crQuestionID}
        />
      ) : null}
      {runModalDetails && (<NewReportRunModal onReportAdded={fetchQuestions} />)}

      <ActivationDialog />

      <ConfirmationDialog
        open={!!removeQuestionId}
        title='Delete?'
        message='Are you sure you want to delete Clean Room Question?'
        onCancel={() => setRemoveQuestionId(undefined)}
        onConfirm={handleOnConfirm}
        loading={meta && meta.deleting}
        error={meta && meta.deleteError}
        confirmActionLabel='Delete'
      />

      <ConfirmationDialog
        open={!!duplicateQuestionId}
        title='Duplicate?'
        message='Are you sure you want to duplicate Clean Room Question?'
        onCancel={() => setDuplicateQuestionId(null)}
        onConfirm={handleDuplicateQuestion}
        loading={isDuplicating}
        confirmActionLabel='Duplicate'
      />

      {
        isCrQuestionPermissionsEnabled ? (
          <QuestionPermissionsOverridesModal
            crId={crId}
            open={!!managePermissions.managePermissionsDetails}
            onCancel={() => managePermissions.setManagePermissionsDetails(null)}
            questionID={managePermissions.managePermissionsDetails?.questionID}
            questionName={managePermissions.managePermissionsDetails?.questionName}
          />
        ) : (
          <ManageQuestionPermissionsModal
            crId={crId}
            open={!!managePermissions.managePermissionsDetails}
            onCancel={() => managePermissions.setManagePermissionsDetails(null)}
            onConfirm={() => null}
            confirmActionLabel='Manage'
            questionID={managePermissions.managePermissionsDetails?.questionID}
            questionPermissions={managePermissions.managePermissionsDetails?.permissions}
          />
        )
      }

      <CloneQuestionToHIDialog />

      {
        showShareQuestionResultsModal && (
          <ShareQuestionResultsModal
            orgId={orgId}
            crId={crId}
            question={showShareQuestionResultsModal}
            partners={shareablePartners}
            onClose={() => setShowShareQuestionResultsModal(false)}
          />
        )
      }
      {
        analysisRuleRunStatus && (
          <AnalysisRuleRunStatusDialog
            open={!!analysisRuleRunStatus}
            onClose={handleCloseAnalysisRuleRunStatus}
            crId={crId}
            questionId={qDetailsID}
            analysisRuleStatus={analysisRuleRunStatus}
          />
        )
      }
      {
        showSelectRampIdModal && (
          <SelectRampIdModal
            crId={crId}
            question={showSelectRampIdModal}
            dataContextRef={dataContextRef}
            onClose={() => setShowSelectRampIdModal(false)}
          />
        )
      }
      {
        showDeleteAssetsModal && (
          <DeleteAssetModal
            crId={crId}
            question={showDeleteAssetsModal}
            dataContextRef={dataContextRef}
            onClose={() => setShowDeleteAssetsModal(false)}
          />
        )
      }
    </>
  );
};
