import React from 'react';
import clsx from 'clsx';
import { styled } from '@mui/material';


const SidePanelContentStyled = styled('div')`
  display: grid;
  gap: 16px;
  padding: 16px 24px;
  background-color: white;
  overflow: auto;
  background-image:

    /* Shadows */ linear-gradient(to bottom, white, white),
    linear-gradient(to bottom, white, white),
    /* Shadow covers */ linear-gradient(to bottom, rgba(0, 0, 0, 0.16), rgba(255, 255, 255, 0)),
    linear-gradient(to top, rgba(0, 0, 0, 0.16), rgba(255, 255, 255, 0));

  background-position: top center, bottom center, top center, bottom center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 3px, 100% 3px, 100% 3px, 100% 3px;

  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
`;

export function MotifSidePanelContent({ className, ...props }) {
  return (
    <SidePanelContentStyled
      className={clsx('SidePanelContent-root', className)}
      data-testid={props['data-testid'] ?? 'sidepanel-content'}
      {...props}
    />
  );
}
