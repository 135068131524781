import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import React, { useContext, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { CLEAN_ROOM_LOGOS } from 'components/CleanRooms/constants';
import {
  DESCRIPTION_TYPES,
  DISPLAY_Q_STATUS,
} from 'utils/constants/question.constant';
import { HAvatarGroup } from 'BaseComponents';
import { QuestionViewModalContext } from 'components/Question/QuestionDetails/QuestionViewModal/QuestionViewModalProvider';
import { Tab } from '@mui/material';
import { UI_DATE_FORMAT } from 'utils/appConstants';
import { v4 as uuidGen } from 'uuid';


export const GeneralInfoPage = () => {
  const {
    question,
    ownerOrganizationId,
    descriptionCount,
  } = useContext(QuestionViewModalContext);

  const [value, setValue] = useState();

  const handleDescriptionTabChange = (e, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const initialDesc = Object.values(DESCRIPTION_TYPES).find(
      val => (question?.descriptions.find(qd => qd.type === val.key)?.description),
    )?.key;
    setValue(initialDesc);
  }, [question?.descriptions]);

  return (
    <>
      <Grid container justifyContent='space-between' spacing={1}>
        <Grid item xs={12}><Typography variant='h5' style={{ fontWeight: 'bold' }}>{question.title}</Typography></Grid>
        <Grid item xs={12}>
          {!!descriptionCount && (
            <>
              <Tabs
                value={value}
                onChange={handleDescriptionTabChange}
                aria-label='description tabs'
                sx={{ border: 'none', minHeight: '2.2rem' }}
              >
                {question.descriptions.find(
                  qd => qd.type === DESCRIPTION_TYPES.BUSINESS.key,
                )?.description
                  && (
                    <Tab
                      value={DESCRIPTION_TYPES.BUSINESS.key}
                      sx={{ p: 1, minHeight: '2rem' }}
                      label={<Typography variant='subtitle2'>{DESCRIPTION_TYPES.BUSINESS.label}</Typography>}
                    />
                  )}
                {question.descriptions.find(
                  qd => qd.type === DESCRIPTION_TYPES.DATA_SCIENCE.key,
                )?.description
                  && (
                    <Tab
                      value={DESCRIPTION_TYPES.DATA_SCIENCE.key}
                      sx={{ p: 1, minHeight: '2rem' }}
                      label={<Typography variant='subtitle2'>{DESCRIPTION_TYPES.DATA_SCIENCE.label}</Typography>}
                    />
                  )}
                {question.descriptions.find(
                  qd => qd.type === DESCRIPTION_TYPES.TECHNICAL.key,
                )?.description
                  && (
                    <Tab
                      value={DESCRIPTION_TYPES.TECHNICAL.key}
                      sx={{ p: 1, minHeight: '2rem' }}
                      label={<Typography variant='subtitle2'>{DESCRIPTION_TYPES.TECHNICAL.label}</Typography>}
                    />
                  )}
              </Tabs>
              <Typography
                variant='body2'
                p={1}
                sx={{
                  height: 125,
                  overflow: 'auto',
                  borderRadius: 1.5,
                  background: theme => theme.palette.background.light,
                }}
              >
                {question?.descriptions?.find(
                  qd => qd.type === value,
                )?.description}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent='space-between' mt={0.5} spacing={1}>
        {
          question?.category
          && (
            <>
              <Grid item xs={5}><Typography variant='bold'>Category</Typography></Grid>
              <Grid item xs={7}>
                {question.category.split(', ').map(t => (
                  <Chip key={uuidGen()} label={t} size='small' variant='outlined' sx={{ mr: 0.5 }} />
                ))}
              </Grid>

            </>
          )
        }
        {
          question?.tags
          && (
            <>
              <Grid item xs={5}><Typography variant='bold'>Tags</Typography></Grid>
              <Grid item xs={7}>
                {question.tags.map(t => (
                  <Chip key={uuidGen()} label={t} size='small' variant='outlined' sx={{ mr: 0.5 }} />
                ))}
              </Grid>

            </>
          )
        }
        <Grid item xs={5}><Typography variant='bold'>Status & Version</Typography></Grid>
        <Grid item xs={7}>
          <Typography variant='body2'>
            {`${DISPLAY_Q_STATUS[question.status].label}, V. ${question.version}`}
          </Typography>
        </Grid>

        <Grid item xs={5}><Typography variant='bold'>Created At</Typography></Grid>
        <Grid item xs={7}>
          <Typography variant='body2'>{moment(question.timeAudit.createdAt).format(UI_DATE_FORMAT)}</Typography>
        </Grid>

        <Grid item xs={5}><Typography variant='bold'>Authored by</Typography></Grid>
        <Grid item xs={7}>
          <Typography variant='body2'>{ownerOrganizationId}</Typography>
        </Grid>

        <Grid item xs={5}><Typography variant='bold'>Updated At</Typography></Grid>
        <Grid item xs={7}>
          <Typography variant='body2'>{moment(question.timeAudit.updatedAt).format(UI_DATE_FORMAT)}</Typography>
        </Grid>

        <Grid item xs={5}><Typography variant='bold'>Dataset Providers</Typography></Grid>
        <Grid item xs={7}>
          <HAvatarGroup
            list={question.cleanRoomTypes.map(cr => ({
              src: CLEAN_ROOM_LOGOS[cr]?.logoSrc,
              label: CLEAN_ROOM_LOGOS[cr]?.label,
            }))}
          />
        </Grid>
      </Grid>
    </>
  );
};
