import React from 'react';
import { axiosInstance } from 'utils/http';
import { question } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';


export const useDeleteQuestionActivation = (cleanRoomQuestionID) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async (payload) => axiosInstance.post(question().activation.delete, payload),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries({ queryKey: ['QuestionActivationList', cleanRoomQuestionID] });
        setData(res.data);
        dispatch(showAlert({ message: 'Question activation delete successfully.', type: 'success' }));
      },
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};
