import React, { useContext } from 'react';
import { HButton } from 'BaseComponents';
import { TemplateProvisionQuestionContext } from 'components/CleanRooms/TemplateProvisionQuestion/TemplateProvisionQuestionProvider';
import { Typography } from '@mui/material';


export const ActionButton = ({ row }) => {
  const {
    questionIds,
    handleProvisionQuestion,
  } = useContext(TemplateProvisionQuestionContext);

  const isProvisioned = questionIds.includes(row.questionID);

  const handleOnClick = () => {
    handleProvisionQuestion(row.questionID);
  };

  return (
    <HButton
      variant='outlined'
      onClick={handleOnClick}
      disabled={isProvisioned}
    >
      <Typography variant='body1' fontWeight={600}>
        Provision
      </Typography>
    </HButton>
  );
};
