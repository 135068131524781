import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useFetchComputeCapacity, useUpdateComputeCapacity } from 'hooks/questionAPIs';


const useCapacityManagement = ({
  crId, crqId, questionId, question,
}) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  const [currVal, setCurrVal] = useState(null);

  const {
    data,
    isSuccess: isFetchSuccess,
  } = useFetchComputeCapacity(questionId, crqId);

  const {
    isLoading: isCapacityUpdating,
    isSuccess: isCapacityUpdated,
    mutate: updateCapacity,
  } = useUpdateComputeCapacity();

  useEffect(() => {
    if (questionId && isFetchSuccess) {
      setCurrVal(data.computeCapacity);
    }
    else if (!questionId) {
      setCurrVal(question.computeCapacity);
    }
  }, [data, isFetchSuccess, question, questionId]);

  const saveCapacity = () => {
    updateCapacity({
      questionID: questionId,
      payload: {
        organizationID: orgId,
        cleanRoomID: crId,
        cleanRoomQuestionID: crqId,
        computeCapacity: currVal,
      },
    }, {
      onSuccess: () => {
        dispatch(showAlert({ message: `Processing capacity updated for "${data.questionTitle}"`, type: 'success' }));
      },
    });
  };

  return {
    isCapacityUpdating,
    isCapacityUpdated,
    currVal,
    setCurrVal,
    saveCapacity,
  };
};

export default useCapacityManagement;
