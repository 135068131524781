import { CleanRoomQuestionConfigParameters } from 'components/CleanRooms/constants';
import {
  useCreateCrQuestionConfigParams,
  useGetCrQuestionConfigParam,
  useUpdateCrQuestionConfigParams,
} from 'api/cleanrooms';
import { useEffect, useState } from 'react';


const useDisableIdentifierProjection = ({
  crId,
  crqId,
  enabled,
}) => {
  const [isDisableIdentifier, setIsDisableIdentifier] = useState(true);

  const {
    isFetching: isDIFetching,
    isSuccess,
    data: { cleanroomQuestionParameter } = {},
  } = useGetCrQuestionConfigParam(
    crId, crqId, CleanRoomQuestionConfigParameters.DISABLE_IDENTIFIER_PROJECTION, enabled);

  const {
    isLoading: isDICreating,
    isSuccess: isDICreated,
    mutate: createParams,
  } = useCreateCrQuestionConfigParams(
    crId, CleanRoomQuestionConfigParameters.DISABLE_IDENTIFIER_PROJECTION,
  );

  const {
    isLoading: isDIUpdating,
    isSuccess: isDIUpdated,
    mutate: updateParams,
  } = useUpdateCrQuestionConfigParams(
    crId, CleanRoomQuestionConfigParameters.DISABLE_IDENTIFIER_PROJECTION,
  );

  const saveDisableIdentifier = () => {
    const payload = {
      cleanRoomID: crId,
      cleanRoomQuestionID: crqId,
      parameterName: CleanRoomQuestionConfigParameters.DISABLE_IDENTIFIER_PROJECTION,
      parameterValue: isDisableIdentifier.toString(),
    };
    if (cleanroomQuestionParameter.parameterValue) {
      updateParams(payload);
    }
    else {
      createParams(payload);
    }
  };


  useEffect(() => {
    if (isSuccess && cleanroomQuestionParameter?.parameterValue) {
      setIsDisableIdentifier(cleanroomQuestionParameter.parameterValue === 'true');
    }
  }, [isSuccess, cleanroomQuestionParameter]);

  return {
    isDIFetching,
    isDICreating,
    isDIUpdating,
    isDICreated,
    isDIUpdated,
    isDisableIdentifier,
    setIsDisableIdentifier,
    saveDisableIdentifier,
  };
};

export default useDisableIdentifierProjection;
