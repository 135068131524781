import * as Enums from 'utils/ignoramusEnums';
import FlowRender from 'pages/Flow/FlowRender';
import MotifStepIcon from 'BaseComponents/MotifStepIcon';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
  menuItemClasses,
  styled,
} from '@mui/material';
import { DEFAULT_PAUSE_LEVEL_VALUE, DEFAULT_START_LEVEL_VALUE } from 'redux/reducer/FlowReducer/constants';
import { FlowCachedRunParameters } from './FlowCachedRunParameters';
import { MotfiFullScreenDialogTitle } from 'BaseComponents/MotifFullScreenDialog/MotfiFullScreenDialogTitle';
import { MotifFullScreenDialog } from 'BaseComponents/MotifFullScreenDialog/MotifFullScreenDialog';
import { MotifFullScreenDialogActions } from 'BaseComponents/MotifFullScreenDialog/MotifFullScreenDialogActions';
import { MotifFullScreenDialogContent } from 'BaseComponents/MotifFullScreenDialog/MotifFullScreenDialogContent';
import { pxToRem } from 'MotifTheme';
import {
  selectPauseLevelForCachedRun,
  selectStartLevelForCachedRun,
  setFlowCachedMode,
  setFlowDetailsSuccess,
} from 'redux/actions/FlowAction';
import { useDispatch, useSelector } from 'react-redux';
import {
  useFetchFlowDetails, useNewCachedFlowRun, useResumeCachedFlowRun, useStartFlowCachedRun,
} from 'api/flows';
import { useParams } from 'react-router-dom';

// 1 to Offset the Pause After Level for pauseLevelID parameter,
// For example, if user wants to pause run after level 1, pause selector will provide value 1,
// but the value sent to BE should be 2, as this level that will not run, hence paused
export const PAUSE_OFFSET = 1;

// Styles override to allow MenuItem to display a tooltip as content
const StyledMenuItem = styled(MenuItem)((({ theme }) => ({
  // Override to enable pointer events on hover for tooltip
  [`&.${menuItemClasses.disabled}`]: {
    pointerEvents: 'initial',
    // Only prevents click on active/on mouse click
    '&:active': {
      pointerEvents: 'none',
    },
  },
  // Override the paddings to show a tooltip on hover of entire item element
  [`&.${menuItemClasses.root}`]: {
    padding: theme.spacing(0),
    '& > *': {
      paddingTop: pxToRem(10),
      paddingBottom: pxToRem(10),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
})));

const UNREUSABLE_FLOW_RUN_STATUS = [
  Enums.FLOW_RUN_STATUS.QUEUED.key,
  Enums.FLOW_RUN_STATUS.RUNNING.key,
  Enums.FLOW_RUN_STATUS.CANCELED.key,
  Enums.FLOW_RUN_STATUS.TERMINATED.key,
  Enums.FLOW_RUN_STATUS.CONTINUED_AS_NEW.key,
  Enums.FLOW_RUN_STATUS.TIMED_OUT.key,
];

const getCachedRunParameter = (parameter) => ({
  [parameter.name]: {
    value: parameter.value,
    parameterType: parameter.parameterType,
  },
});

const isPrecededLevelNotCompleted = ({
  level, flowRunLevel, flowRunLevels, forStatuses,
}) => {
  const prevRunLevel = level - 1;
  const hasPrevRunLevel = prevRunLevel >= 0 && prevRunLevel <= flowRunLevels?.length;
  if (
    forStatuses.includes(flowRunLevel?.status)
    && hasPrevRunLevel
  ) {
    const prevFlowRunLevel = flowRunLevels?.find(({ level: runLevel }) => (
      runLevel === prevRunLevel
    ));

    // Check if not completed
    return prevFlowRunLevel?.status !== Enums.FLOW_RUN_STATUS.COMPLETED.key;
  }
  // Means, it's completed
  return false;
};

export const FlowCachedRun = ({
  open, onClose, runID, isCachedRun, isPausedRun, pausedAtLevel,
}) => {
  const { crId, crFlowId } = useParams();
  const { data: response, isLoading, isSuccess } = useNewCachedFlowRun({ crId, runId: runID });
  const {
    mutate: startFlowCachedRun,
    isError: isStartFlowError,
    isSuccess: isStartFlowSuccess,
  } = useStartFlowCachedRun({ crId, crFlowId, runId: runID });
  const {
    mutate: resumeCachedFlowRun,
    isError: isResumeFlowError,
    isSuccess: isResumeFlowSuccess,
  } = useResumeCachedFlowRun({ crId, crFlowId, runId: runID });
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const selectedStartLevel = useSelector((state) => state.flow.selectedStartLevel);
  const selectedPauseLevel = useSelector((state) => state.flow.selectedPauseLevel);
  const isFlowNodesAndEdgesLoaded = useSelector((state) => state.flow.flowNodesAndEdgesLoaded);
  const [runParameters, setRunParameters] = useState([]);
  const [flowRunLevels, setFlowRunLevels] = useState([]);
  const levels = useMemo(() => flowRunLevels.map(rl => rl.level ?? 0), [flowRunLevels]);
  const pauseLevels = useMemo(() => levels.filter((level) => {
    if (selectedStartLevel !== DEFAULT_START_LEVEL_VALUE) {
      return level >= selectedStartLevel && level < levels.at(-1);
    }
    return [];
  }), [levels, selectedStartLevel]);
  // Resume run will start at Minimum from paused level,
  // so it's need to be filtered out from dropdown selection
  const resumeRunLevels = useMemo(
    () => levels.filter((level) => level >= pausedAtLevel && level < levels.at(-1)),
    [levels, pausedAtLevel]);
  const disablePauseLevel = selectedStartLevel === DEFAULT_START_LEVEL_VALUE
    || selectedStartLevel === levels[levels.length - 1];
  const pauseAfterValue = selectedPauseLevel === DEFAULT_PAUSE_LEVEL_VALUE
    ? DEFAULT_PAUSE_LEVEL_VALUE
    : selectedPauseLevel - 1;

  useFetchFlowDetails({ crId, crFlowId });

  const onDialogClose = useCallback(() => {
    onClose();
    dispatch(selectStartLevelForCachedRun(DEFAULT_START_LEVEL_VALUE));
    dispatch(selectPauseLevelForCachedRun(DEFAULT_PAUSE_LEVEL_VALUE));
    dispatch(setFlowCachedMode(false));
    dispatch(setFlowDetailsSuccess({
      flowNodesAndEdgesLoaded: false,
      flow: { edges: [], nodes: [] },
    }));
  }, [dispatch, onClose]);

  dispatch(setFlowCachedMode(true));

  useEffect(() => {
    if (isPausedRun) {
      dispatch(selectStartLevelForCachedRun(pausedAtLevel));
      dispatch(selectPauseLevelForCachedRun(DEFAULT_PAUSE_LEVEL_VALUE));
    }
  }, [dispatch, isPausedRun, pausedAtLevel]);

  const flowParameters = useMemo(() => {
    const rawParameters = [...(runParameters ?? [])];
    return rawParameters.reduce((acc, parameter) => {
      const nodeIdIdx = acc.findIndex((flowParam) => flowParam.nodeID === parameter.nodeID);
      const hasNodeIdBeenAdded = nodeIdIdx !== -1;

      // If added, update the parameters to include the current parameter
      if (hasNodeIdBeenAdded) {
        acc[nodeIdIdx] = {
          ...acc[nodeIdIdx],
          parameters: {
            ...acc[nodeIdIdx].parameters,
            ...getCachedRunParameter(parameter),
          },
        };
        return acc;
      }
      else {
        // Add node ID and parameter
        return [...acc, {
          nodeID: parameter.nodeID,
          parameters: {
            ...getCachedRunParameter(parameter),
          },
        }];
      }
    }, []);
  }, [runParameters]);

  const isLevelDisabled = useCallback((level) => {
    // Disable the first option to avoid a full flow restart
    const isFirstLevel = level === 0;

    const flowRunLevel = flowRunLevels?.find(({ level: runLevel }) => (
      runLevel === level
    ));
    const isLevelUnreusable = UNREUSABLE_FLOW_RUN_STATUS.includes(flowRunLevel?.status);

    // Only disable if level is not preceded by a COMPLETED level
    const isPrecededLvlNotCompleted = isPrecededLevelNotCompleted({
      level,
      flowRunLevel,
      flowRunLevels,
      forStatuses: [
        Enums.FLOW_RUN_STATUS.FAILED.key,
        Enums.FLOW_RUN_STATUS.RUN_STATUS_UNKNOWN.key,
      ],
    });

    return isFirstLevel || isLevelUnreusable || isPrecededLvlNotCompleted;
  }, [flowRunLevels]);

  const getLevelDisableReason = useCallback((level) => {
    const isFirstLevel = level === 0;
    if (isFirstLevel) {
      return 'This is the first level. Reusing it will restart the entire flow which is not permitted.';
    }

    const flowRunLevel = flowRunLevels?.find(({ level: runLevel }) => runLevel === level);

    switch (flowRunLevel?.status) {
      case Enums.FLOW_RUN_STATUS.RUNNING.key: {
        return 'This level is currently running and cannot be selected.';
      }
      case Enums.FLOW_RUN_STATUS.QUEUED.key: {
        return 'This level is currently queued to run and cannot be selected.';
      }
      case Enums.FLOW_RUN_STATUS.RUN_STATUS_UNKNOWN.key: {
        return "This level didn't run in this flow.";
      }
      default: {
        return '';
      }
    }
  }, [flowRunLevels]);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setRunParameters(response?.data?.parameters ?? []);
      setFlowRunLevels(response?.data?.runLevels ?? []);
    }
  }, [response, isLoading, isSuccess]);

  // Prevent dialog close if request throws an error
  useEffect(() => {
    if (isCachedRun) {
      if (isStartFlowError) return;

      if (isStartFlowSuccess) {
        onDialogClose();
      }
    }

    if (isPausedRun) {
      if (isResumeFlowError) return;

      if (isResumeFlowSuccess) {
        onDialogClose();
      }
    }
  }, [
    isCachedRun,
    isPausedRun,
    isStartFlowError,
    isStartFlowSuccess,
    isResumeFlowError,
    isResumeFlowSuccess,
    onDialogClose,
  ]);

  const handleParameterValueChange = (parameter) => {
    setRunParameters((prevParams) => prevParams.map((prevParam) => {
      // Updates all the parameters value with the same macro
      if (parameter.macro && prevParam?.macro?.ID === parameter?.macro?.ID) {
        return {
          ...prevParam,
          value: parameter.value,
        };
      }

      // For Run-Time and Partition Parameters
      return (prevParam.ID === parameter.ID)
        ? { ...prevParam, value: parameter.value }
        : prevParam;
    }));
  };

  const handleStartRun = () => {
    const pauseLevel = selectedPauseLevel > 0 ? { pauseLevelID: `Level-${selectedPauseLevel}` } : {};

    if (isCachedRun) {
      startFlowCachedRun({
        startLevelID: `Level-${selectedStartLevel}`,
        flowParameters,
        ...pauseLevel,
      });
    }

    if (isPausedRun) {
      resumeCachedFlowRun(pauseLevel);
    }
  };

  return (
    <MotifFullScreenDialog
      open={open}
      onClose={onDialogClose}
      anchor='bottom'
      style={{ margin: '6.25rem 0px 0px' }}
    >
      <MotfiFullScreenDialogTitle style={{ paddingLeft: '0px' }} onClose={onDialogClose}>
        {isCachedRun ? 'Reuse Cached Report' : 'Resume Paused Run'}
      </MotfiFullScreenDialogTitle>
      <MotifFullScreenDialogContent style={{ paddingTop: 0 }}>
        <Grid container justifyContent='space-between' spacing={1} gridArea='one'>
          <Grid item xs={3} pt='22px !important'>
            <Stepper
              orientation='vertical'
              activeStep={step}
              connector={<StepConnector />}
              style={{ padding: 0 }}
            >
              <Step>
                <StepLabel StepIconComponent={MotifStepIcon}>{isCachedRun ? 'Reuse Settings' : 'Resume Paused Run Settings'}</StepLabel>
              </Step>
              {
                isCachedRun && (
                  <Step>
                    <StepLabel StepIconComponent={MotifStepIcon}>Set Parameters</StepLabel>
                  </Step>
                )
              }
            </Stepper>
          </Grid>
          <Grid item xs={9} pt='32px !important' pl='0px !important' sx={{ borderLeft: (theme) => `1px solid ${theme.palette.secondary.light}` }}>
            {step === 0 && isFlowNodesAndEdgesLoaded && (
              <>
                <Box marginLeft='30px'>
                  {isCachedRun && <Typography variant='h4'>Reuse Settings</Typography>}
                  {isPausedRun && <Typography variant='h4'>Resume Paused Run Settings</Typography>}
                  {isCachedRun
                    && (
                      <Typography style={{ marginTop: '10px' }}>
                        Caching saves processing time.
                        Use caching to reduce cost, preserve output values,
                        and starting from a Level of a flow.
                      </Typography>
                    )}
                  {isCachedRun && (
                    <Select
                      label={DEFAULT_START_LEVEL_VALUE}
                      value={selectedStartLevel}
                      onChange={(e) => {
                        const resetPauseLevel = e.target.value === DEFAULT_START_LEVEL_VALUE
                          || e.target.value === levels.at(-1);

                        if (resetPauseLevel) {
                          dispatch(selectPauseLevelForCachedRun(DEFAULT_PAUSE_LEVEL_VALUE));
                        }
                        dispatch(selectStartLevelForCachedRun(e.target.value));
                      }}
                      style={{ marginTop: '20px', marginRight: '20px' }}
                    >
                      <MenuItem key={DEFAULT_START_LEVEL_VALUE} value={DEFAULT_START_LEVEL_VALUE}>
                        {DEFAULT_START_LEVEL_VALUE}
                      </MenuItem>
                      {levels.map((level) => (
                        <StyledMenuItem
                          key={`level-${level}`}
                          value={level}
                          disabled={isLevelDisabled(level)}
                        >
                          <Tooltip title={isLevelDisabled(level) && getLevelDisableReason(level)}>
                            <Box width='100%' height='100%'>
                              Level
                              {' '}
                              {level}
                            </Box>
                          </Tooltip>
                        </StyledMenuItem>
                      ))}
                    </Select>
                  )}
                  <FormControl>
                    <Tooltip title={(
                      isPausedRun && !resumeRunLevels.length ? 'Only one last level left to run' : '')
                      || (isCachedRun && !pauseLevels.length ? 'Last level is selected to run ' : '')}
                    >
                      <Select
                        label={DEFAULT_PAUSE_LEVEL_VALUE}
                        value={pauseAfterValue}
                        onChange={(e) => {
                          const pauseValue = e.target.value === DEFAULT_PAUSE_LEVEL_VALUE
                            ? DEFAULT_PAUSE_LEVEL_VALUE
                            : e.target.value + PAUSE_OFFSET;
                          dispatch(selectPauseLevelForCachedRun(pauseValue));
                        }}
                        style={{ marginTop: '20px' }}
                        disabled={disablePauseLevel}
                      >
                        <MenuItem
                          key={DEFAULT_PAUSE_LEVEL_VALUE}
                          value={DEFAULT_PAUSE_LEVEL_VALUE}
                        >
                          {DEFAULT_PAUSE_LEVEL_VALUE}
                        </MenuItem>
                        {(isCachedRun ? pauseLevels : resumeRunLevels).map((level) => (
                          <MenuItem key={`level-${level}`} value={level}>
                            Level
                            {' '}
                            {level}
                          </MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                    <FormHelperText>
                      *All remaining levels will run if no choice is made.
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box style={{ marginTop: '20px' }}>
                  <FlowRender isCachedRun={isCachedRun || isPausedRun} />
                </Box>
              </>
            )}
            {step === 1 && (
              <FlowCachedRunParameters
                levels={levels}
                selectedStartLevel={selectedStartLevel}
                selectedPauseLevel={selectedPauseLevel}
                runParameters={runParameters}
                onParameterValueChange={handleParameterValueChange}
              />
            )}
          </Grid>
        </Grid>
      </MotifFullScreenDialogContent>
      <MotifFullScreenDialogActions>
        <Button
          onClick={step === 1 ? () => setStep(0) : onDialogClose}
          color='primary'
          variant='outlined'
        >
          {step === 1 ? 'Back' : 'Cancel'}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            if (step === 0 && isCachedRun) {
              setStep(1);
            }
            else {
              handleStartRun();
            }
          }}
          disabled={step === 0 && selectedStartLevel === DEFAULT_START_LEVEL_VALUE}
        >
          {(step === 1 || isPausedRun) ? 'Start Run' : 'Next'}
        </Button>
      </MotifFullScreenDialogActions>
    </MotifFullScreenDialog>
  );
};
