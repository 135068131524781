import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import ListFilters from 'components/Common/ListFilters';
import ListViewTable from 'components/Common/ListViewTable';
import PageHeader from 'components/Common/PageHeader';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import TableContainer from '@mui/material/TableContainer';
import moment from 'moment/moment';
import {
  Box, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle,
  Table, TableBody, TableCell, TableHead, TableRow, TextField,
} from '@mui/material';
import {
  CR_CONFIG_TYPES,
  getCrConfigs,
} from 'pages/InternalAdmin/organization/pages/CleanRooms/getCrConfigs';
import { CleanRoomTypeCell } from 'components/CleanRooms/CrListLayout/componentsCell';
import { FilterList } from '@mui/icons-material';
import { HButton, HTablePagination } from 'BaseComponents';
import { US_DATE_FORMAT } from 'utils/appConstants';
import { cleanRoom } from 'utils/urlConstants';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { debounce } from 'lodash';
import { getFilterFromQueryParams } from 'utils/filterUtils';
import { useDeleteCleanRoomParameter } from 'api/cleanrooms/useDeleteCleanRoomParameter';
import { useFetchCleanRoom } from 'hooks/cleanRoomAPIs';
import {
  useListData,
  useListFilters,
  useSearchUrl,
} from 'BaseComponents/HDataTable/utils';
import { useQuery } from 'utils/jsUtils';
import { useSelector } from 'react-redux';
import { useUpsertCleanRoom } from 'api/cleanrooms/useUpsertCleanRoom';
import { useUpsertCleanRoomParameters } from 'api/cleanrooms/useUpsertCleanRoomParameters';


const getFilter = (query, defaultFilter, activeQuery) => {
  const filtersObject = getFilterFromQueryParams(query, activeQuery);
  return {
    ...filtersObject,
    filters: [...filtersObject.filters, ...defaultFilter],
  };
};

function Row({
  row, open, handleOnClick, configList, handleDelete, handleEdit, handleAddNewConfig,
  handleOnChange, selectedConfig, disabled, isFetchingCleanRoomDetails, isUpsertParamsSuccess,
}) {
  const [newConfig, setNewConfig] = useState({ name: '', value: '' });
  const [deleteConfigName, setDeleteConfigName] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    if (isUpsertParamsSuccess) {
      setNewConfig({ name: '', value: '' });
    }
  }, [isUpsertParamsSuccess]);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={handleOnClick}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {row.name}
        </TableCell>
        <TableCell align='right'>{row.displayID}</TableCell>
        <TableCell align='right'><CleanRoomTypeCell row={row} /></TableCell>
        <TableCell align='right'>{row.cleanRoomAdmin?.email}</TableCell>
        <TableCell align='right'>{row.ownership}</TableCell>
        <TableCell align='right'>{row.questionsCount}</TableCell>
        <TableCell align='right'>{row.stage}</TableCell>
        <TableCell align='right'>{moment.utc(row.timeAudit?.createdAt).format(US_DATE_FORMAT)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1, width: '100%' }}>
              <ListViewTable list={!isFetchingCleanRoomDetails && configList} skeletonHeight={350}>
                <Table size='small' aria-label='clean room config'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Configuration</TableCell>
                      <TableCell>Value</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {configList.map((config) => (
                      <TableRow key={config.name}>
                        <TableCell component='th' scope='row'>
                          {config.name}
                        </TableCell>
                        <TableCell>
                          {selectedConfig?.name === config?.name ? (
                            <TextField
                              type='text'
                              value={selectedConfig.value}
                              onChange={(e) => handleOnChange(e.target.value)}
                              disabled={disabled}
                            />
                          ) : (
                            config.value
                          )}
                        </TableCell>
                        <TableCell>
                          {config.type !== CR_CONFIG_TYPES.CR_PRIVACY_PARAMETER
                            && (
                            <Button
                              onClick={() => handleEdit(config)}
                              disabled={disabled && selectedConfig?.name === config?.name}
                            >
                              {selectedConfig?.name === config?.name ? 'Save' : 'Edit'}
                            </Button>
                            )}
                          {config.type === CR_CONFIG_TYPES.CR_PARAMETER
                          && (
                            <Button
                              onClick={() => {
                                setOpenDeleteDialog(true);
                                setDeleteConfigName(config?.name);
                              }}
                              disabled={disabled && selectedConfig?.name === config?.name}
                            >
                              Delete
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        <TextField
                          type='text'
                          placeholder='Config Name'
                          value={newConfig.name}
                          onChange={(e) => setNewConfig({ ...newConfig, name: e.target.value })}
                          disabled={disabled}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type='text'
                          placeholder='Config Value'
                          value={newConfig.value}
                          onChange={(e) => setNewConfig({ ...newConfig, value: e.target.value })}
                          disabled={disabled}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => handleAddNewConfig(newConfig)}
                          disabled={disabled || newConfig.name === '' || newConfig.value === ''}
                        >
                          Add
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </ListViewTable>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        componentsProps={{
          backdrop: {
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        }}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete
            {' '}
            {deleteConfigName}
            {' '}
            config?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDelete(deleteConfigName);
              setOpenDeleteDialog(false);
            }}
            variant='contained'
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const CleanRooms = () => {
  const updateQueryUrl = useSearchUrl(true);
  const query = useQuery();
  const [filters, setFilters] = useState(getFilter(query, [], true));
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [crId, setCrId] = useState(null);
  const [openRowId, setOpenRowId] = useState(null);
  const [selectedConfig, setSelectedConfig] = useState({});
  const [showFilters, toggleShowFilters] = React.useState(false);

  const dataURL = cleanRoom(orgId).list;
  const filtersURL = cleanRoom(orgId).listFilters;

  const {
    data: dataFilters,
    isLoading: isFilterLoading,
    isFetching: isFilterFetching,
  } = useListFilters(filtersURL, 'clean-rooms-internal-admin');
  const {
    data: dataList,
    isLoading: isListLoading,
    mutate: mutateList,
    isFetching: isListFetching,
  } = useListData(dataURL, 'clean-rooms-internal-admin');

  const {
    isLoading: isDeleteLoading, mutateAsync: deleteCrParamAsync,
  } = useDeleteCleanRoomParameter(crId);

  const {
    isLoading: isUpsertParamsLoading,
    isSuccess: isUpsertParamsSuccess,
    mutate: upsertParams,
  } = useUpsertCleanRoomParameters(crId);

  const {
    isLoading: isUpsertCrLoading,
    mutate: upsertCleanroom,
  } = useUpsertCleanRoom(crId);

  const {
    data: cleanRoomDetails,
    isFetching: isFetchingCleanRoomDetails,
  } = useFetchCleanRoom(orgId, crId);

  const configList = getCrConfigs(cleanRoomDetails);

  useEffect(() => {
    mutateList(filters);
    // adding this line for the useEffect to run only once on the load of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = async (paramName) => {
    await deleteCrParamAsync({ orgId, crID: crId, paramName });
  };

  const handleAddNewConfig = (param) => {
    const payload = {
      cleanRoomID: crId,
      organizationID: orgId,
      parameters: [
        {
          name: param.name,
          value: param.value,
        },
      ],
    };
    upsertParams(payload);
  };

  const handleEdit = (config) => {
    if (config?.name === selectedConfig?.name) {
      if (config.type === CR_CONFIG_TYPES.CR_DETAIL) {
        const payload = {
          cleanRoom: {
            ID: crId,
            organizationID: orgId,
            [selectedConfig.name]: selectedConfig.value,
          },
        };
        upsertCleanroom(payload, { onSuccess: () => setSelectedConfig({}) });
      }
      else if (config.type === CR_CONFIG_TYPES.CR_PARAMETER) {
        const payload = {
          cleanRoom: {
            ID: crId,
            organizationID: orgId,
            parameters: [
              {
                name: selectedConfig.name,
                value: selectedConfig.value,
              },
            ],
          },
        };
        upsertCleanroom(payload, { onSuccess: () => setSelectedConfig({}) });
      }
      else {
        let finalValue = selectedConfig.value;
        if (selectedConfig?.dataType === 'number') {
          finalValue = Number(selectedConfig.value);
        }
        const payload = {
          cleanRoom: {
            ID: crId,
            organizationID: orgId,
            cleanRoomPrivacyParameter: {
              [selectedConfig.name]: finalValue,
            },
          },
        };
        upsertCleanroom(payload, { onSuccess: () => setSelectedConfig({}) });
      }
    }
    else {
      setSelectedConfig(config);
    }
  };

  const handleOnChange = val => {
    setSelectedConfig({ ...selectedConfig, value: val });
  };

  const handlePaginationChange = (newFilters) => {
    mutateList(newFilters);
    setFilters(newFilters);

    updateQueryUrl(newFilters);
  };

  const handleFilterChange = (newFilters) => {
    const updatedFilters = {
      ...newFilters,
      offset: 0,
    };
    setFilters(updatedFilters);
    mutateList(updatedFilters);

    updateQueryUrl(updatedFilters);
  };

  const debouncedUpdateSearch = useMemo(() => debounce(mutateList, 500), [mutateList]);

  const handleSearchChange = useCallback(
    (attribute, search) => {
      const newFilters = {
        ...filters,
        offset: 0,
        search,
      };

      updateQueryUrl(newFilters);

      setFilters(newFilters);

      debouncedUpdateSearch(newFilters);
    },
    [debouncedUpdateSearch, filters, updateQueryUrl],
  );

  return (
    <>
      <PageHeader title='Manage Clean Rooms' breadcrumbs={[crumbs.home, crumbs.internalAdmin]} />
      <Card>
        <CardContent>
          <HButton
            onClick={() => toggleShowFilters(!showFilters)}
            size='small'
            variant='outlined'
          >
            <FilterList fontSize='small' style={{ marginRight: '5px' }} />
            Filters & Search
          </HButton>
          {showFilters
        && (
        <ListFilters
          allFilters={dataFilters?.data}
          appliedFilters={filters}
          onChange={handleFilterChange}
          searchAttribute
          onSearch={handleSearchChange}
          searchText={filters.search}
        />
        )}
          <ListViewTable
            list={!(isListLoading && isFilterLoading || isListFetching && isFilterFetching)
          && dataList.cleanRooms}
            skeletonHeight={350}
          >
            <TableContainer>
              <Table aria-label='collapsible table'>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell width='18%'>Name</TableCell>
                    <TableCell align='right'>Display ID</TableCell>
                    <TableCell align='right'>Type</TableCell>
                    <TableCell align='right'>Admin</TableCell>
                    <TableCell align='right'>Ownership</TableCell>
                    <TableCell align='right'>Questions</TableCell>
                    <TableCell align='right'>Stage</TableCell>
                    <TableCell align='right'>Created On</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataList.cleanRooms?.map((row) => (
                    <Row
                      key={row.ID}
                      row={row}
                      setCrId={setCrId}
                      open={openRowId === row.ID}
                      handleOnClick={() => {
                        setCrId(row.ID);
                        setOpenRowId(openRowId === row.ID ? null : row.ID);
                      }}
                      cleanRoomDetails={cleanRoomDetails}
                      configList={configList}
                      handleDelete={handleDelete}
                      handleOnChange={handleOnChange}
                      selectedConfig={selectedConfig}
                      handleEdit={handleEdit}
                      handleAddNewConfig={handleAddNewConfig}
                      disabled={isUpsertCrLoading || isDeleteLoading || isUpsertParamsLoading}
                      isFetchingCleanRoomDetails={isFetchingCleanRoomDetails}
                      isUpsertParamsSuccess={isUpsertParamsSuccess}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <HTablePagination
              count={dataList.count}
              filters={filters}
              onChange={handlePaginationChange}
            />
          </ListViewTable>
        </CardContent>
      </Card>
    </>
  );
};

export default CleanRooms;
