import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const useDeleteCleanRoomQuestion = () => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useMutation(({ crId, removeQuestionId }) => axiosInstance
    .delete(cleanRoom(orgId).deleteQuestion(crId, removeQuestionId)),
  {
    onSuccess: () => {
      dispatch(showAlert({
        message: 'Clean room Question deleted.',
        type: 'success',
      }));
    },
    onError: (err) => {
      dispatch(showAlert({
        message: err.response.data.message,
        type: 'error',
      }));
    },
  },
  );
};
