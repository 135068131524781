import * as monaco from 'monaco-editor';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  Backdrop, CircularProgress, Paper, Skeleton, Stack, ToggleButton, ToggleButtonGroup, Typography,
} from '@mui/material';
import { DiffEditor, loader } from '@monaco-editor/react';
import { QUESTION_STATUS } from 'utils/constants/question.constant';
import { UI_DISPLAY_DATE_TIME_FORMAT } from 'utils/appConstants';


const EDITOR_CONFIG = {
  readOnly: true,
  minimap: { enabled: false },
  wordWrap: true,
  fontSize: 14,
  padding: {
    top: 14,
  },
  contextmenu: false,
  lineNumbers: false,
  renderLineHighlight: 'none',
  scrollBeyondLastLine: false,
  scrollbar: {
    alwaysConsumeMouseWheel: false,
  },
  suggest: {
    preview: true,
  },
  renderOverviewRuler: false,
};

const QuestionVersionDiffViewer = ({
  selectedQuestion,
  questionByVersion,
  isLoadingQuestionByVersion = false,
  isDarkMode = false,
}) => {
  // Diff editor layout state
  const [isSplitLayout, setIsSplitLayout] = useState(true);

  // load monaco config
  useEffect(() => {
    loader.config({ monaco });
  }, []);

  const formattedTimestamp = useMemo(
    () => moment(questionByVersion?.versionTimestamp).format(UI_DISPLAY_DATE_TIME_FORMAT),
    [questionByVersion?.versionTimestamp]);

  return (
    <Stack spacing={2}>
      {
        isLoadingQuestionByVersion ? (
          <Stack>
            <Skeleton variant='text' width={100} height={20} />
            <Skeleton variant='text' width={200} height={20} />
          </Stack>
        ) : (
          <Stack>
            {
              questionByVersion?.version && (
                <Typography variant='body2'>
                  {questionByVersion?.questionStatus === QUESTION_STATUS.PUBLISHED.key ? 'Published ' : 'Draft '}
                  {`v${questionByVersion.version}`}
                </Typography>
              )
            }
            <Typography variant='body2' fontStyle='italic'>{formattedTimestamp}</Typography>
          </Stack>
        )
      }
      <Stack direction='row' justifyContent='flex-end'>
        <ToggleButtonGroup
          value={isSplitLayout}
          exclusive
          size='small'
          onChange={() => setIsSplitLayout(prev => !prev)}
          aria-label='diff layout'
        >
          <ToggleButton value aria-label='split diff'>
            Split
          </ToggleButton>
          <ToggleButton value={false} aria-label='unified diff'>
            Unified
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Paper sx={{ position: 'relative' }}>
        <DiffEditor
          height='500px'
          language='sql'
          theme={isDarkMode ? 'vs-dark' : ''}
          options={{
            ...EDITOR_CONFIG,
            renderSideBySide: isSplitLayout,
          }}
          original={selectedQuestion?.queryDetails?.[0]?.query || ''}
          modified={questionByVersion?.queryDetails?.[0]?.query || ''}
        />
        <Backdrop open={isLoadingQuestionByVersion} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute' }}>
          <Stack spacing={2} justifyContent='center' alignItems='center'>
            <CircularProgress />
            <Typography variant='body2' color={theme => theme.palette.light.main}>Loading diff...</Typography>
          </Stack>
        </Backdrop>
      </Paper>
    </Stack>
  );
};

export default QuestionVersionDiffViewer;
