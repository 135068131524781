import React from 'react';
import { Commit } from '@mui/icons-material';
import {
  Grid, Tooltip, Typography, styled,
} from '@mui/material';


const QuestionNameTypography = styled(Typography)(({
  maxWidth: '200px',
  // If bigger than max width, add ellipsis
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export const ParameterQuestionList = ({ questionsDetails = [] }) => (
  <Grid container gap={1}>
    {questionsDetails.map((details) => (
      <Grid container item gap='6px' alignItems='center' key={details.name}>
        <Commit color='secondary' fontSize='small' />
        <Tooltip title={details.name.length > 27 ? details.name : null}>
          <QuestionNameTypography
            variant='body2'
            color={(theme) => theme.palette.secondary.dark}
          >
            {details.name}
          </QuestionNameTypography>
        </Tooltip>
        <Typography variant='subtitle2' color='secondary'>
          {details.ID}
        </Typography>
      </Grid>
    ))}
  </Grid>
);
