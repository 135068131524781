import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import InfoIcon from '@mui/icons-material/Info';
import NoData from 'components/Common/NoData';
import React, { useMemo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  HButton, HChip, HDialog, HDialogActions, HDialogContent, HDialogTitle,
} from 'BaseComponents';
import { crqAnalysisRuleStatus } from 'utils/ignoramusEnums';
import { getChipColorStyles } from 'components/CleanRooms/CurrentQuestions/AnalysisRuleRunStatusChip';
import { habuColors } from 'Theme';
import { useGetAnalysisRuleRunStatus } from 'api/datasetAnalysisRule';
import { v4 as uuidGen } from 'uuid';


const LoadingSkeleton = () => Array.from({ length: 6 },
  (_, i) => <Skeleton variant='rectangular' key={i} height={24} sx={{ mb: 2 }} />);

const STATUS_COLOR_MAP = {
  [crqAnalysisRuleStatus.COMPLETED.key]: habuColors.signal.success[80],
  [crqAnalysisRuleStatus.RUNNING.key]: habuColors.signal.warning[80],
  [crqAnalysisRuleStatus.QUEUED.key]: habuColors.signal.warning[80],
  [crqAnalysisRuleStatus.FAILED.key]: habuColors.signal.alert[80],
};

export const STATUS_MESSAGES = {
  [crqAnalysisRuleStatus.COMPLETED.key]: 'It is ready!',
  [crqAnalysisRuleStatus.RUNNING.key]: 'Analysis rule check in progress. This may take a few minutes.',
  [crqAnalysisRuleStatus.QUEUED.key]: 'Analysis rule check in progress. This may take a few minutes.',
  [crqAnalysisRuleStatus.FAILED.key]: 'This question did not pass dataset analysis rules.',
};

const AnalysisRuleRunStatusDialog = ({
  open,
  onClose,
  crId,
  questionId,
  analysisRuleStatus,
}) => {
  const {
    isLoading,
    isError,
    isSuccess,
    data: { analysisRuleRunStatus = [] } = {},
  } = useGetAnalysisRuleRunStatus(crId, questionId);

  const chipStyle = useMemo(() => getChipColorStyles(analysisRuleStatus),
    [analysisRuleStatus]);

  return (
    <HDialog
      fullWidth
      open={open}
      maxWidth='sm'
      submitText='Ok'
      onClose={onClose}
    >
      <HDialogTitle onClose={onClose}>
        Analysis Rule Run Status
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
          <HChip
            label={crqAnalysisRuleStatus[analysisRuleStatus].label}
            size='large'
            sx={chipStyle}
          />
          {analysisRuleStatus && (
            <Tooltip title={STATUS_MESSAGES[analysisRuleStatus] || 'No status message available'} placement='bottom'>
              <InfoIcon fontSize='small' sx={{ ml: 1, color: habuColors.neutral[60] }} />
            </Tooltip>
          )}
        </Box>
      </HDialogTitle>
      <HDialogContent>
        {isLoading && <LoadingSkeleton />}
        {isError && <NoData text='Error in Analysis Rule Run Status' />}
        {isSuccess && (analysisRuleRunStatus.length === 0 ? (
          <NoData />
        ) : (
          analysisRuleRunStatus.map((runStatus, index) => (
            <Stack key={uuidGen()} gap={1}>
              <Typography variant='body1' fontWeight='bolder'>
                {runStatus.analysisRuleName}
              </Typography>
              {
                runStatus.status === crqAnalysisRuleStatus.FAILED.key && (
                  <Box mt={1}>
                    <Typography variant='body2' fontWeight='bolder'>
                      Recommended actions
                    </Typography>
                    <Typography variant='body2'>
                      Adjust the question, skip analysis rules or assign another dataset to run the
                      question successfully.
                    </Typography>
                  </Box>
                )
              }
              {runStatus.status === crqAnalysisRuleStatus.FAILED.key && (
              <Alert
                icon={false}
                severity='error'
                sx={{
                  bgcolor: habuColors.signal.alert[10],
                  color: habuColors.signal.alert[80],
                }}
              >
                {runStatus.errorMessage}
              </Alert>
              )}
              <Stack direction='row' spacing={2.5}>
                <Box flex='1' minWidth={0}>
                  <Typography variant='caption'>
                    Status
                  </Typography>
                  <Typography variant='body2' color={STATUS_COLOR_MAP[runStatus.status]} noWrap>
                    {crqAnalysisRuleStatus[runStatus.status].label}
                  </Typography>
                </Box>
                <Box flex='1' minWidth={0}>
                  <Typography variant='caption'>
                    Data
                  </Typography>
                  <Tooltip title={runStatus.datasetName}>
                    <Typography variant='body2' noWrap>
                      {runStatus.datasetName}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box flex='1' minWidth={0}>
                  <Typography variant='caption'>
                    Macro
                  </Typography>
                  <Tooltip title={runStatus.macro}>
                    <Typography variant='body2' noWrap>
                      {runStatus.macro}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box flex='1' minWidth={0}>
                  <Typography variant='caption'>
                    Owner
                  </Typography>
                  <Tooltip title={runStatus.owner}>
                    <Typography variant='body2' noWrap>
                      {runStatus.owner}
                    </Typography>
                  </Tooltip>
                </Box>
              </Stack>
              {
                analysisRuleRunStatus.length - 1 !== index && <Divider sx={{ mt: 1, mb: 2 }} />
              }
            </Stack>
          )))
        )}
      </HDialogContent>
      <HDialogActions>
        <HButton variant='contained' onClick={onClose}>Ok</HButton>
      </HDialogActions>
    </HDialog>
  );
};

export default AnalysisRuleRunStatusDialog;
