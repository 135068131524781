import Logger from 'components/Common/Logger';
import http from 'utils/http';
import {
  call, put,
} from 'redux-saga/effects';
import { connectNodesWithEdgeError, connectNodesWithEdgeSuccess } from 'redux/actions/FlowAction';
import { showAlert } from 'redux/actions/AlertActions';


export function* createConnectNodesWithEdge({ url, payload }) {
  const { error, edge } = yield call(http.post, url, payload);

  if (error) {
    Logger.error(error, `Could not create edge to node. Url: ${url}. Error: ${error}`);
    yield put(showAlert({ message: error, type: 'error' }));
    return yield put(connectNodesWithEdgeError(payload));
  }

  yield put(showAlert({ message: 'Connected edge to node successfully', type: 'success' }));
  return yield put(connectNodesWithEdgeSuccess(edge));
}
