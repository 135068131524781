import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { flow } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';


export const useFetchCleanRoomFlowRunActionStatuses = ({ crId, crFlowId, enabled }) => {
  const { ID: orgId } = useSelector(activeOrgSelector);
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, data,
  } = useQuery(
    ['Cr-Flow-Runs-Actions', crFlowId, orgId, enabled],
    async () => axiosInstance.get(flow(orgId).actionsRunList(crId, crFlowId)),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Flow run action statuses fetched successfully.', type: 'success' })),
      retry: false,
      refetchOnWindowFocus: false,
      enabled,
    },
  );

  return {
    isLoading, isError, isSuccess, data,
  };
};
