import { useEffect, useState } from 'react';
import { useFetchCleanRoomTemplateQuestionsIdList } from 'api/cleanRoomsTemplate';


export const useFetchCRTQuestionsIdList = (crId) => {
  const {
    mutateAsync,
  } = useFetchCleanRoomTemplateQuestionsIdList(crId);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const dataD = await mutateAsync();
    setData(dataD?.data?.questionIds ?? []);
  };

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    mutate: fetchData,
  };
};
