import { axiosInstance } from 'utils/http';
import { flow } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const useDeleteEdge = (flowId) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);

  const {
    data,
    isLoading,
    isSuccess,
    mutate,
  } = useMutation(
    ['edge-deletion', orgId, flowId],
    async (edgeId) => axiosInstance.delete(flow(orgId).deleteEdge(flowId, edgeId)),
    {
      onSuccess: () => {
        dispatch(showAlert({ message: 'Edge has been deleted.', type: 'success' }));
      },
      onError: (error) => dispatch(showAlert({ message: error.response.data.message, type: 'error' })),
    },
  );

  return {
    data, isLoading, isSuccess, mutate,
  };
};
