import AnalysisRuleRunStatusChip
  from 'components/CleanRooms/CurrentQuestions/AnalysisRuleRunStatusChip';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


const AnalysisRuleRunStatusRow = ({ question }) => (
  <>
    <Box display='flex' p={1}>
      <Box width='40%' mr={1}>
        <Tooltip placement='top-start' title='Analysis Rule Status'>
          <Typography variant='subtitle4'>
            ANALYSIS RULE STATUS
          </Typography>
        </Tooltip>
      </Box>
      <Box width='60%'>
        <AnalysisRuleRunStatusChip question={question} />
      </Box>
    </Box>
    <Divider />
  </>
);

export default AnalysisRuleRunStatusRow;
