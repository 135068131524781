// TODO: deprecate DV-6093
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  ACTIVATION_TYPE, CLEAN_ROOM_STATUS, CleanRoomTypes, Ownership,
} from 'components/CleanRooms/constants';
import {
  Alert, Box, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Grid, MenuItem, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead,
  TablePagination, TableRow, TextField, Tooltip,
} from '@mui/material';
import { CurrentQuestionsPageContext } from 'components/CleanRooms/CurrentQuestions/CurrentQuestionsPageContextProvider';
import { HButton } from 'BaseComponents';
import { LINKEDIN_MATCHED_AUDIENCE } from 'utils/constants/partners.constant';
import { Link } from 'react-router-dom';
import { cleanRoom } from 'utils/spaUrls';
import { isEmpty } from 'lodash';
import { rowsPerPageOptions } from 'utils/appConstants';
import {
  useFetchCRProvisionChannels,
  useFetchQuestionActivations,
} from 'hooks/activationAPI';
import { useFetchQuestion } from 'hooks/questionAPIs';
import { useSelector } from 'react-redux';


export const ActivationDialog = () => {
  const {
    isCRPairEnabled,
    onActivate,
    crId,
    activateTarget,
    setActivatTarget,
    orgId,
  } = useContext(CurrentQuestionsPageContext);

  const rowsPerPage = rowsPerPageOptions[0];
  const identityTypeOptions = useMemo(() => ['HEM', 'MAID'], []);

  if (isCRPairEnabled) {
    identityTypeOptions.push('PAIR');
  }

  const [page, setPage] = useState(0);
  const [selectedChannel, setSelectedChannel] = useState([]);
  const [showIDFieldOptions, setShowIDFieldOptions] = useState(false);
  const [channelList, setChannelList] = useState([]);
  const [identityType, setIdentyType] = useState(identityTypeOptions[0]);
  const [identityField, setIdentityField] = useState('');
  const [identityFieldOptions, setIdentityFieldOptions] = useState([]);
  const [questionID, setQuestionID] = useState();
  const [crQuestionID, setCrQuestionID] = useState();
  const [partnerAccountList, setPartnerAccountList] = useState([]);
  const crDetail = useSelector(state => state.cleanRooms.detail);
  const isExpired = crDetail?.status === CLEAN_ROOM_STATUS.EXPIRED.key;
  const tooltipTitle = isExpired ? 'Clean Room Expired. Unable to activate.' : '';

  const {
    mutate: fetchChannels, data, isSuccess,
  } = useFetchCRProvisionChannels(crId);

  const {
    data: questionActivations,
  } = useFetchQuestionActivations(crQuestionID);

  const {
    data: questionDetail,
  } = useFetchQuestion(questionID);

  // only showing Linked Matched Audience partner for LinkedIn CleanRoom Partners
  useEffect(() => {
    if (!isEmpty(crDetail) && crDetail.type === CleanRoomTypes.LINKEDIN_CLEAN_ROOM
      && crDetail.ownership === Ownership.PARTNER.key) {
      const newData = data.filter(
        pa => pa.partnerAccount.partner.name === LINKEDIN_MATCHED_AUDIENCE,
      );
      setPartnerAccountList(newData);
    }
    else {
      setPartnerAccountList(data);
    }
  }, [crDetail, data]);

  useEffect(() => {
    if (!!activateTarget && crId && orgId) {
      const payload = {
        cleanRoomID: crId,
        organizationID: orgId,
      };

      fetchChannels(payload);
    }
  }, [activateTarget, crId, orgId, fetchChannels]);

  useEffect(() => {
    if (activateTarget?.type === ACTIVATION_TYPE.QUESTION) {
      setCrQuestionID(activateTarget.ID);
      setQuestionID(activateTarget.questionID);
    }
  }, [activateTarget]);

  useEffect(() => {
    if (isEmpty(questionDetail) || isEmpty(activateTarget)) {
      return;
    }

    const { userListOutputs } = questionDetail;

    if (!(userListOutputs.length > 0)) {
      setShowIDFieldOptions(false);
      setIdentityFieldOptions([]);
      return;
    }

    setShowIDFieldOptions(true);
    const options = userListOutputs;
    setIdentityFieldOptions(options);
  }, [activateTarget, questionDetail]);

  useEffect(() => {
    if (!activateTarget || isEmpty(partnerAccountList)) {
      return;
    }

    partnerAccountList.sort((a, b) => {
      const nameA = a.partnerAccount.partner.name.toUpperCase();
      const nameB = b.partnerAccount.partner.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    if (activateTarget.type === ACTIVATION_TYPE.QUESTION) {
      if (!isEmpty(questionActivations)) {
        const idType = questionActivations[0].identityType;
        if (identityTypeOptions.includes(idType)) {
          setIdentyType(idType);
        }
        const idField = questionActivations[0].identityFieldID;
        if (identityFieldOptions.find(ifo => ifo.ID === idField) !== undefined) {
          setIdentityField(idField);
        }
        const activatedChannels = questionActivations.map(
          qa => qa.partnerAccount.ID,
        );
        setSelectedChannel(activatedChannels);
      }
      else {
        setIdentyType(identityTypeOptions[0]);
        setIdentityField('');
        setSelectedChannel([]);
      }
    }

    const flattenList = [];
    partnerAccountList.forEach(pa => {
      const accountInfo = { ...pa.partnerAccount, isUsedForActivation: pa.isUsedForActivation };
      flattenList.push(accountInfo);
    });

    setChannelList(flattenList);
  }, [
    activateTarget, identityTypeOptions, identityFieldOptions,
    partnerAccountList, questionActivations]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClose = () => {
    setSelectedChannel([]);
    // Reset the states
    setQuestionID(null);
    setCrQuestionID(null);
    setIdentityField('');
    setActivatTarget(null);
  };

  const handleConfirm = () => {
    onActivate({ identityField, identityType, selectedChannel });
    handleClose();
  };

  const isSelected = (channelID) => selectedChannel.indexOf(channelID) !== -1;

  const handleSelect = (channelID) => {
    const selected = isSelected(channelID);
    let newSelected = [];

    if (!selected) {
      newSelected = newSelected.concat(selectedChannel, channelID);
    }
    else {
      newSelected = selectedChannel.filter(sc => sc !== channelID);
    }
    setSelectedChannel(newSelected);
  };

  if (!isSuccess) {
    return (
      <>
        {activateTarget && (
          <Dialog
            fullWidth
            maxWidth='lg'
            open={!!activateTarget}
            onClose={handleClose}
          >
            <DialogTitle>Activation Configuration</DialogTitle>
            <DialogContent>
              <Skeleton height={50} />
              <Skeleton height={50} />
              <Skeleton height={50} />
            </DialogContent>
          </Dialog>
        )}
      </>
    );
  }

  return (
    <>
      {activateTarget && (
      <Dialog
        fullWidth
        maxWidth='lg'
        open={!!activateTarget}
        onClose={handleClose}
      >
        <DialogTitle>Activation Configuration</DialogTitle>
        <Alert severity='info'>To activate partners correctly, place the identity field as the first column in your SELECT statement.</Alert>
        {(isEmpty(channelList)) ? (
          <DialogContent>
            <Box>
              You will be required to provision at least one channel into this Clean Room from your
              existing activation channels.
            </Box>
            <HButton component={Link} to={cleanRoom.activationChannels(crId)} variant='outlined'>Go To Channel Provision</HButton>
          </DialogContent>
        ) : (
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DialogContentText>
                  Select Identity Type
                </DialogContentText>
                <TextField
                  value={identityType}
                  sx={{ width: '50%' }}
                  label='Identity Type'
                  margin='dense'
                  onChange={(e) => setIdentyType(e.target.value)}
                  select
                  size='small'
                  variant='outlined'
                  data-testid='identity-type-selector'
                >
                  {identityTypeOptions.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {showIDFieldOptions && (
              <Grid item xs={6}>
                <DialogContentText>
                  Select Identity Field
                </DialogContentText>
                <TextField
                  value={identityField}
                  sx={{ width: '50%' }}
                  label='Identity Field'
                  margin='dense'
                  onChange={(e) => setIdentityField(e.target.value)}
                  select
                  size='small'
                  variant='outlined'
                  data-testid='identity-field-selector'
                >
                  {identityFieldOptions.map((s) => (
                    <MenuItem key={s.ID} value={s.ID}>
                      {s.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              )}
            </Grid>
            <Box mt={2}>
              <DialogContentText>
                Select one or multiple channels
              </DialogContentText>
              <TableContainer component={Paper}>
                <Table
                  aria-label='activation channels'
                  size='small'
                >
                  <TableHead>
                    <TableRow>
                      <TableCell padding='checkbox' />
                      <TableCell>Channel</TableCell>
                      <TableCell>Partner</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {channelList.slice(
                      page * rowsPerPage, page * rowsPerPage + rowsPerPage,
                    ).map((ac) => {
                      const selected = isSelected(ac.ID);
                      return (
                        <TableRow
                          hover
                          onClick={() => { handleSelect(ac.ID); }}
                          role='checkbox'
                          key={ac.ID}
                          data-testid={ac.ID}
                          aria-checked={selected}
                          selected={selected}
                        >
                          <TableCell padding='checkbox'>
                            <Checkbox
                              color='primary'
                              checked={selected}
                            />
                          </TableCell>
                          <TableCell>{ac.name}</TableCell>
                          <TableCell>{ac.partner.displayName}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component='div'
                rowsPerPageOptions={[rowsPerPage]}
                count={partnerAccountList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
              />
            </Box>
          </DialogContent>
        )}
        <DialogActions>
          <HButton variant='outlined' onClick={handleClose} size='small'>
            Cancel
          </HButton>
          <Tooltip title={tooltipTitle}>
            <span>
              <HButton
                variant='contained'
                color='primary'
                size='small'
                onClick={handleConfirm}
                disabled={isExpired}
              >
                Activate
              </HButton>
            </span>
          </Tooltip>
        </DialogActions>
      </Dialog>
      )}
    </>
  );
};
