import CheckIcon from '@mui/icons-material/Check';
import Grid from '@mui/material/Grid';
import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import { DISPLAY_Q_INPUT_TYPES } from 'utils/constants/question.constant';
import { QuestionViewModalContext } from 'components/Question/QuestionDetails/QuestionViewModal/QuestionViewModalProvider';


export const ConfigurationPage = () => {
  const {
    question,
  } = useContext(QuestionViewModalContext);

  return (
    <>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid item xs={5}>
          <Typography variant='bold'>
            Data Types
          </Typography>
        </Grid>

        <Grid item xs={7}>
          {question.dataTypes.length > 0 ? (
            question.dataTypes.map(x => (
              <Grid container spacing={1} key={x.ID}>
                <Grid item xs={12}>
                  <Typography variant='body2'>
                    {`${x.macro} (${x.importDataType.displayName})`}
                  </Typography>
                </Grid>
                <Grid item xs={12} />
              </Grid>
            ))) : <Typography variant='body2'>None</Typography>}
        </Grid>

        {question.isUserListQuestion ? (
          <>
            <Grid item xs={5}>
              <Typography variant='bold'>List Fields</Typography>
            </Grid>

            <Grid item xs={7}>
              {question.userListOutputs.length > 0 ? (
                question.userListOutputs.map(x => (
                  <Grid container spacing={1} key={x.ID}>
                    <Grid item xs={12}>
                      <Typography variant='body2'>
                        {`${x.name} (${DISPLAY_Q_INPUT_TYPES[x.type].label})`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} />
                  </Grid>
                ))) : <Typography variant='body2'>None</Typography>}
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={5}>
              <Typography variant='bold'>Dimensions</Typography>
            </Grid>

            <Grid item xs={7}>
              {question.dimensions.length > 0 ? (
                question.dimensions.map(x => (
                  <Grid container spacing={1} key={x.ID}>
                    <Grid item xs={12}>
                      <Typography variant='body2'>
                        {`${x.name} - ${DISPLAY_Q_INPUT_TYPES[x.type].label}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} />
                  </Grid>
                ))) : <Typography variant='body2'>None</Typography>}
            </Grid>

            <Grid item xs={5}>
              <Typography variant='bold'>Metrics</Typography>
            </Grid>

            <Grid item xs={7}>
              {question.metrics.length > 0 ? (
                question.metrics.map(x => (
                  <Grid container spacing={1} key={x.ID}>
                    <Grid item xs={12}>
                      <Typography variant='body2'>
                        {`${x.displayName} (${x.name}) - ${DISPLAY_Q_INPUT_TYPES[x.type]?.label}`}
                      </Typography>
                    </Grid>
                    {(x.metricConfig.isIdentity || x.metricConfig.noise)
                      && (
                      <Grid item xs={12}>
                        {x.metricConfig.isIdentity && (
                          <Typography
                            variant='body2'
                            style={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              marginRight: '10px',
                            }}
                          >
                            <CheckIcon sx={{
                              fontSize: '14px',
                              marginRight: '8px',
                              color: (theme) => theme.palette.primary.main,
                            }}
                            />
                            {' '}
                            K-Min
                          </Typography>
                        )}

                        {x.metricConfig.noise && (
                          <Typography
                            variant='body2'
                            style={{
                              display: 'inline-flex',
                              alignItems: 'center',
                            }}
                          >
                            <CheckIcon sx={{
                              fontSize: '14px',
                              marginRight: '8px',
                              color: (theme) => theme.palette.primary.main,
                            }}
                            />
                            {' '}
                            Noise
                          </Typography>
                        )}
                      </Grid>
                      )}
                    <Grid item xs={12} />
                  </Grid>
                ))) : <Typography variant='body2'>None</Typography>}
            </Grid>
          </>
        )}

        <Grid item xs={5}>
          <Typography variant='bold'>
            Runtime Parameters
          </Typography>
        </Grid>

        <Grid item xs={7}>
          {question.parameters.length > 0 ? (
            question.parameters.map(x => (
              <Grid container spacing={1} key={x.ID}>
                <Grid item xs={7}>
                  <Typography variant='body2'>
                    {`${x.displayName} (${DISPLAY_Q_INPUT_TYPES[x.type].label})`}
                  </Typography>
                </Grid>
              </Grid>
            ))) : <Typography variant='body2'>None</Typography>}
        </Grid>
      </Grid>
    </>
  );
};
