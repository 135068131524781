import InfoIcon from '@mui/icons-material/Info';
import React, { useContext, useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';
import {
  CurrentQuestionsPageContext,
} from 'components/CleanRooms/CurrentQuestions/CurrentQuestionsPageContextProvider';
import { HChip } from 'BaseComponents';
import { STATUS_MESSAGES } from 'components/CleanRooms/CurrentQuestions/AnalysisRuleRunStatusDialog';
import { crqAnalysisRuleStatus } from 'utils/ignoramusEnums';
import { habuColors } from 'Theme';


export const getChipColorStyles = (status) => {
  const colorMap = {
    [crqAnalysisRuleStatus.COMPLETED.key]: {
      borderColor: '#4CAF50',
      background: '#E2F8D9',
      color: '#1B5E20',
      '&:hover': {
        borderColor: '#4CAF50',
        background: '#E2F8D9',
        color: '#1B5E20',
      },
    },
    [crqAnalysisRuleStatus.RUNNING.key]: {
      borderColor: '#FF9800',
      background: '#FEEDCB',
      color: '#E65100',
      '&:hover': {
        borderColor: '#FF9800',
        background: '#FEEDCB',
        color: '#E65100',
      },
    },
    [crqAnalysisRuleStatus.FAILED.key]: {
      borderColor: '#EB5269',
      background: '#FFD2D9',
      color: '#D22842',
      '&:hover': {
        borderColor: '#EB5269',
        background: '#FFD2D9',
        color: '#D22842',
      },
    },
  };

  return colorMap[status] || {};
};

const AnalysisRuleRunStatusChip = ({ question }) => {
  const { handleOpenAnalysisRuleRunStatus } = useContext(CurrentQuestionsPageContext);
  const validatingStatus = question.analysisRuleStatus !== crqAnalysisRuleStatus.QUEUED.key
    && question.analysisRuleStatus !== crqAnalysisRuleStatus.RUNNING.key;

  const chipStyle = useMemo(() => getChipColorStyles(question.analysisRuleStatus),
    [question.analysisRuleStatus]);

  const label = crqAnalysisRuleStatus[question.analysisRuleStatus]?.label || '-';
  let tooltipTitle = '';

  if (question.analysisRuleStatus) {
    tooltipTitle = validatingStatus ? 'For more information on the status, click here.' : '';
  }
  else {
    tooltipTitle = 'No analysis rule configuration found for the question';
  }

  const isClickable = question.analysisRuleStatus
    && validatingStatus;

  const handleClick = () => {
    if (isClickable) {
      handleOpenAnalysisRuleRunStatus(question.ID, question.analysisRuleStatus);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <HChip
        label={label}
        sx={{ ...chipStyle }}
        tooltipTitle={tooltipTitle}
        onClick={handleClick}
        clickable={isClickable}
      />
      {question.analysisRuleStatus && (
        <Tooltip title={STATUS_MESSAGES[question.analysisRuleStatus] || 'No status message available'} placement='bottom'>
          <InfoIcon fontSize='small' sx={{ ml: 1, color: habuColors.neutral[60] }} />
        </Tooltip>
      )}
    </Box>
  );
};

export default AnalysisRuleRunStatusChip;
