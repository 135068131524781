import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { useState } from 'react';


export const useFetchCleanRoomDefaultQuestionPermissions = ({
  orgId, crId, enabled = true,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const queryData = useQuery(
    ['CleanRoomDefaultQuestionPermissions', orgId, crId],
    async () => axiosInstance.get(cleanRoom(orgId).crDefaultQuestionPermissions(crId)),
    {
      enabled: Boolean(orgId && crId && enabled),
      onSuccess: (res) => setData(res?.data?.defaultQuestionPermissions || []),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
  return {
    ...queryData,
    data,
  };
};
