import AnalysisRuleRunStatusRow from 'components/CleanRooms/CurrentQuestions/AnalysisRuleRunStatusRow';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PermissionService from 'components/Common/PermissionService';
import QuestionDatasetRow from 'components/CleanRooms/CurrentQuestions/QuestionDatasetRow';
import QuestionStatus from 'components/CleanRooms/CurrentQuestions/QuestionStatus';
import QuestionStatusRow from 'components/CleanRooms/CurrentQuestions/QuestionStatusRow';
import QuestionTagsRow from 'components/CleanRooms/CurrentQuestions/QuestionTagsRow';
import React from 'react';
import {
  BlankCard, KeyValueRow, OverflowTooltip, QuestionTitleRowDescription, StyledEyebrow,
} from 'components/Common/CardStructures';
import {
  Box,
  Divider,
  Grid,
  useTheme,
} from '@mui/material';
import { CleanRoomTypes } from 'components/CleanRooms/constants';
import { CurrentQuestionsContext } from 'components/CleanRooms/CurrentQuestions/CurrentQuestionsContextProvider';
import { DropdownMenu } from 'BaseComponents';
import { getBestMatchDesc } from 'components/QueryEditor/utils';
import { habuColors } from 'Theme';
import { productPerm } from 'utils/appConstants';
import { usePublishQuestion } from 'hooks/questionAPIs';
import { useSelector } from 'react-redux';


const QuestionHeaderRow = ({ question, handlePublish, getActionMenuItems }) => (
  <Grid container direction='row' justifyContent='space-between'>
    <Grid item xs={6}>
      <QuestionStatus
        question={question}
        handlePublish={handlePublish}
      />
    </Grid>
    <StyledEyebrow item xs={6}>
      <DropdownMenu icon={<MoreVertIcon />} menuItems={getActionMenuItems(question)} hideEmpty />
    </StyledEyebrow>
  </Grid>
);

const CurrentQuestionCard = ({ details }) => {
  const {
    getActionMenuItems, getDatasetsManagement, getReportMenuItems, updateStatus,
    getReportBtnTooltipTitle, fetchQuestions,
  } = React.useContext(CurrentQuestionsContext);
  const theme = useTheme();
  const crDetail = useSelector(state => state.cleanRooms.detail);
  const isAnalysisRule = PermissionService.isProductEnabled([productPerm.DatasetAnalysisRule]);
  const isAWSCleanRoom = crDetail?.type === CleanRoomTypes.AWS_CLEAN_ROOM;
  const analysisRuleEnabled = isAnalysisRule && !isAWSCleanRoom;

  const { isSuccess: isPublished, mutate: publishQuestion } = usePublishQuestion();

  React.useEffect(() => {
    if (isPublished) {
      fetchQuestions();
    }
  }, [fetchQuestions, isPublished]);

  const handlePublish = (questionId) => {
    publishQuestion({ questionId });
  };

  const generateCardActions = (question) => (
    <>
      {getDatasetsManagement(question)}
      <DropdownMenu
        label='Report'
        variant='contained'
        color='secondary'
        menuItems={getReportMenuItems(question)}
        tooltipText={getReportBtnTooltipTitle(question)}
      />
    </>
  );

  return (
    <BlankCard
      sxProps={{
        sx: {
          height: '100%',
        },
      }}
      header={(
        <QuestionHeaderRow
          question={details}
          handlePublish={handlePublish}
          getActionMenuItems={getActionMenuItems}
        />
      )}
      actionButtons={generateCardActions(details)}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box p={1}>
            <QuestionTitleRowDescription
              isListQuestion={details.isUserListQuestion}
              displayID={details.displayID}
            />
            <OverflowTooltip
              text={details.title}
              placement='right-start'
              lines={3}
              typographyProps={{
                sx: {
                  fontWeight: theme.typography.fontWeightMedium,
                  '&:hover': {
                    color: habuColors.secondary[60],
                  },
                },
              }}
            />
          </Box>
          <Box p={1}>
            <OverflowTooltip
              text={getBestMatchDesc(details.descriptions)}
              isListQuestion={details.isUserListQuestion}
              displayID={details.displayID}
              placement='right-start'
              lines={3}
              typographyProps={{
                sx: {
                  fontWeight: theme.typography.fontWeightMedium,
                  color: habuColors.neutral[60],
                  '&:hover': {
                    color: habuColors.secondary[60],
                  },
                },
              }}
            />
          </Box>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <QuestionStatusRow
            question={details}
            updateStatus={updateStatus}
          />
        </Grid>
        <Grid item xs={12}>
          <KeyValueRow title='DISPLAY ID' value={details.displayID} />
        </Grid>
        <Grid item xs={12}>
          <KeyValueRow title='USE CASE' value={details.category} />
        </Grid>
        <Grid item xs={12}>
          <QuestionTagsRow question={details} />
        </Grid>
        <Grid item xs={12}>
          <QuestionDatasetRow question={details} />
        </Grid>
        {analysisRuleEnabled && (
        <Grid item xs={12}>
          <AnalysisRuleRunStatusRow question={details} />
        </Grid>
        )}
      </Grid>
    </BlankCard>
  );
};

export default CurrentQuestionCard;
