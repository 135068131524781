import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import QuestionTagsRow from 'components/CleanRooms/CurrentQuestions/QuestionTagsRow';
import React from 'react';
import { ActionButton } from 'components/CleanRooms/AllQuestions/ActionButton';
import {
  BlankCard, HeaderRow, KeyValueRow, OverflowTooltip, QuestionTitleRowDescription,
} from 'components/Common/CardStructures';
import { DataTypes } from 'components/CleanRooms/AllQuestions/DataTypes';
import { DropdownMenu } from 'BaseComponents';
import { VerifiedUser } from '@mui/icons-material';
import { habuColors } from 'Theme';
import { useActionMenuItems } from 'components/CleanRooms/AllQuestions/useActionMenuItems';
import { useTheme } from '@mui/material';


const AllQuestionsCard = ({ details }) => {
  const theme = useTheme();
  const getActionMenuItems = useActionMenuItems();

  return (
    <BlankCard
      header={(
        <Box display='flex' justifyContent='space-between' alignItems='flex-end'>
          <HeaderRow
            tagTitle='QUESTION'
            Icon={VerifiedUser}
          />
          <DropdownMenu
            icon={<MoreVertIcon />}
            menuItems={getActionMenuItems(details)}
          />
        </Box>
      )}
      actionButtons={<ActionButton row={details} />}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box p={1}>
            <QuestionTitleRowDescription
              isListQuestion={details.isUserListQuestion}
              displayID={details.displayID}
            />
            <OverflowTooltip
              text={details.title}
              placement='right-start'
              lines={3}
              typographyProps={{
                sx: {
                  fontWeight: theme.typography.fontWeightMedium,
                  '&:hover': {
                    color: habuColors.secondary[60],
                  },
                },
              }}
            />
          </Box>
          <Box p={1}>
            <OverflowTooltip
              text={details.description}
              placement='right-start'
              lines={3}
              typographyProps={{
                sx: {
                  fontWeight: theme.typography.fontWeightMedium,
                  color: habuColors.neutral[60],
                  '&:hover': {
                    color: habuColors.secondary[60],
                  },
                },
              }}
            />
          </Box>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <KeyValueRow title='USE CASE' value={details.category} />
        </Grid>
        <Grid item xs={12}>
          <KeyValueRow
            title='REQUIRED DATASETS'
            value={<DataTypes details={details} />}
            valueTooltip={details.dataTypes.map(i => i.displayName).join(', ')}
          />
        </Grid>
        <Grid item xs={12}>
          <QuestionTagsRow question={details} />
        </Grid>
      </Grid>
    </BlankCard>
  );
};

export default AllQuestionsCard;
