import React from 'react';
import Typography from '@mui/material/Typography';
import { datasetExists } from 'components/CleanRooms/AllQuestions/utils';
import { styled } from '@mui/material/styles';


const StyledTypography = styled(Typography, {
  shouldForwardProp: (props) => props !== 'noDatasets',
})(({ noDatasets }) => ({
  ...(noDatasets ? { color: 'rgba(0,0,0,0.3)' } : {}),
}));

export const DataTypes = ({ details }) => {
  const { dataTypes } = details;

  return (
    <>
      {dataTypes.map((d) => (
        <StyledTypography
          key={details.ID}
          variant='body2'
          noDatasets={!datasetExists(d)}
        >
          {d.displayName}
        </StyledTypography>
      ))}
    </>
  );
};
