import PermissionService from 'components/Common/PermissionService';
import React, { useContext } from 'react';
import useCapacityManagement from 'components/QuestionManagement/ComputeCapacityModal/useCapacityManagement';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Skeleton,
  Typography,
} from '@mui/material';
import {
  CLEAN_ROOMS_WITH_EXTENDED_CAPACITY,
  COMPUTE_CAPACITY,
} from 'utils/constants/question.constant';
import { CleanRoomTypes, Ownership } from 'components/CleanRooms/constants';
import { ExpandMore } from '@mui/icons-material';
import { FlowBuilderContext } from 'pages/Flow/FlowBuilder/FlowBuilderContextProvider';
import { HButton, HSpinner } from 'BaseComponents';
import { NODE_ACCESS_TYPE } from 'utils/ignoramusEnums';
import { habuColors } from 'Theme';
import {
  productPerm,
  userTypes,
} from 'utils/appConstants';
import { useGetFlowNodeAccess, useUpdateFlowNodeAccess } from 'api/flows';
import { useSelector } from 'react-redux';


const QuestionSidePanelPrivacy = ({ node, question }) => {
  const { crFlowId, crId } = useContext(FlowBuilderContext);
  const [listOfOrgs, setListOfOrgs] = React.useState([]);
  const [orgsEditable, setOrgsEditable] = React.useState(false);
  const countOfOrgsSelected = listOfOrgs
    .filter((org) => org.accessType === NODE_ACCESS_TYPE.FULL_ACCESS).length;
  const cleanroomDetail = useSelector(state => state.cleanRooms.detail);
  const cleanroomType = cleanroomDetail?.type;
  const isSuperUser = PermissionService.isAuthorized([userTypes.superuser]);
  const isSupportModeUser = PermissionService.isCXUser();
  const ENABLED_CAPACITY_MANAGEMENT = PermissionService.isProductEnabled(
    [productPerm.CapacityManagement],
  );
  const ENABLED_CAPACITY_MANAGEMENT_SF = PermissionService.isProductEnabled(
    [productPerm.CapacityManagementSnowflakePattern],
  );

  const isCROwner = cleanroomDetail?.ownership === Ownership.OWNER.key;
  const isCapacityManagementEnabled = cleanroomType === CleanRoomTypes.SNOWFLAKE_CR
    ? ENABLED_CAPACITY_MANAGEMENT_SF
    : ENABLED_CAPACITY_MANAGEMENT;

  const allowedToEditComputeCapacity = isSuperUser
    || (isCapacityManagementEnabled && (isCROwner || isSupportModeUser));

  const {
    isLoading, isError, isSuccess, data,
  } = useGetFlowNodeAccess({ crId, crFlowId, nodeId: node.id });
  const {
    isLoading: isFlowNodeAccessUpdating,
    mutate: updateNodeAccess,
  } = useUpdateFlowNodeAccess({ crId, crFlowId, nodeId: node.id });

  const {
    isCapacityUpdating,
    currVal,
    setCurrVal,
    saveCapacity,
  } = useCapacityManagement({
    crId, crqId: node.cleanRoomQuestionID, questionId: question.ID, question,
  });

  const handleSubmit = () => {
    if (allowedToEditComputeCapacity) {
      saveCapacity();
    }

    if (orgsEditable) {
      updateNodeAccess({ nodeAccess: listOfOrgs });
    }
  };

  React.useEffect(() => {
    if (isSuccess) {
      setListOfOrgs(data?.data.nodeAccess);
      setOrgsEditable(data?.data.editable);
    }
  }, [data?.data.nodeAccess, data?.data.editable, isSuccess]);

  const handleOrgAccessChange = (orgId, accessType) => {
    const updatedList = listOfOrgs.map((org) => {
      if (org.organizationID === orgId) {
        return {
          ...org,
          accessType: accessType === NODE_ACCESS_TYPE.NO_ACCESS
            ? NODE_ACCESS_TYPE.FULL_ACCESS
            : NODE_ACCESS_TYPE.NO_ACCESS,
        };
      }
      return org;
    });
    setListOfOrgs(updatedList);
  };

  if (isLoading || isError) {
    return (
      <Grid container spacing={0} sx={{ paddingTop: 3 }}>
        <Grid item xs={12}>
          <Skeleton height={300} />
        </Grid>
      </Grid>
    );
  }

  const getFilteredComputeCapacity = Object.values(COMPUTE_CAPACITY)
    .filter(item => ![COMPUTE_CAPACITY.MEDIUM.value, COMPUTE_CAPACITY.XLARGE.value]
      .includes(item.value)
      || CLEAN_ROOMS_WITH_EXTENDED_CAPACITY.includes(cleanroomType));

  return (
    <>
      <Grid container spacing={1} sx={{ paddingTop: 4 }}>
        <Accordion
          defaultExpanded
          disableGutters
          elevation={0}
          style={{ width: '100%', marginBottom: '16px' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{ background: (theme) => theme.palette.secondary.light }}
          >
            <Typography variant='subtitle1'>Report access</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='body3'>
              Only selected partner organizations can view the output of this node.
            </Typography>
            <Typography variant='subtitle2'>
              {`${countOfOrgsSelected} of ${listOfOrgs.length} partners selected`}
            </Typography>

            {orgsEditable && (
              <FormGroup>
                {listOfOrgs.map((org) => (
                  <FormControlLabel
                    key={org.organizationID}
                    control={<Checkbox checked={org.accessType === NODE_ACCESS_TYPE.FULL_ACCESS} />}
                    onChange={() => handleOrgAccessChange(org.organizationID, org.accessType)}
                    label={org.organizationName}
                  />
                ))}
              </FormGroup>
            )}
          </AccordionDetails>
        </Accordion>
        {allowedToEditComputeCapacity && (
          <Accordion
            defaultExpanded
            disableGutters
            elevation={0}
            style={{ width: '100%' }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{ background: (theme) => theme.palette.secondary.light }}
            >
              <Typography variant='subtitle1'>Processing Capacity (Optional)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='body3'>
                Choose the processing capacity for question runs.
                You can edit this option after publishing the flow.
              </Typography>
              {currVal ? (
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <RadioGroup>
                      {getFilteredComputeCapacity.map(item => (
                        <FormControlLabel
                          key={item.name}
                          value={item.value}
                          control={<Radio checked={currVal === item.value} />}
                          color='primary'
                          onChange={e => setCurrVal(e.target.value)}
                          label={item.name}
                          componentsProps={{
                            typography: { variant: 'body2' },
                          }}
                        />
                      ))}
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Box bgcolor={habuColors.neutral['5']} p={2}>
                      <Typography variant='subtitle2' mb={1}>
                        {COMPUTE_CAPACITY[currVal]?.subtitle}
                      </Typography>
                      <Typography variant='body2'>
                        {COMPUTE_CAPACITY[currVal]?.description}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              ) : <Skeleton height={150} width='100%' variant='rectangular' />}
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
      <Box position='absolute' left={20} bottom={20}>
        <HButton
          variant='contained'
          color='primary'
          onClick={handleSubmit}
          disabled={
            (!orgsEditable && !allowedToEditComputeCapacity)
            || isCapacityUpdating
            || isFlowNodeAccessUpdating
          }
          endIcon={(isCapacityUpdating || isFlowNodeAccessUpdating) ? <HSpinner isButton /> : null}
        >
          Submit
        </HButton>
      </Box>
    </>
  );
};

export default QuestionSidePanelPrivacy;
