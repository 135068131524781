import PermissionService from 'components/Common/PermissionService';
import { DeleteFlowListContext } from 'pages/Flow/DeleteFlowListContextProvider';
import { FlowContext } from 'pages/Flow/FlowContextProvider';
import { FlowVersionHistoryContext } from 'pages/Flow/FlowRun/FlowVersionHistoryProvider';
import { cleanRoom } from 'utils/spaUrls';
import { crPrivacyType } from 'components/CleanRooms/Wizard/constants';
import { productPerm } from 'utils/appConstants';
import { updateSelectedFlow } from 'redux/actions/FlowAction';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';


export const useActionItems = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const crDetail = useSelector(state => state.cleanRooms.detail);
  const { setDeleteFlowDescription } = useContext(DeleteFlowListContext);
  const { handleAdvancedSettingOpen } = useContext(FlowContext);
  const { openModal: openVersionModal } = useContext(FlowVersionHistoryContext);
  const { crId } = useParams();

  const isDifferentialPrivacyEnabled = PermissionService
    .isProductEnabled([productPerm.DifferentialPrivacy]);
  const customDiffPrivacyCleanRoom = crDetail.cleanRoomPrivate
    && crDetail.cleanRoomPrivacyType === crPrivacyType.CUSTOM_DIFFERENTIAL_PRIVACY;
  const isFlowVersioningEnabled = PermissionService.isProductEnabled(
    [productPerm.FlowVersioning],
  );

  // Todo - will add once view feature is fully implemented
  // const viewActionItem = {
  //   label: 'View',
  //   action: () => {
  //     dispatch(updateSelectedFlow({ flowID: details.ID }));
  //     history.push(cleanRoom.flowView(crId, details.ID, details.flow?.ID));
  //   },
  // };

  return ({ details }) => [{
    label: 'Edit',
    action: () => {
      dispatch(updateSelectedFlow({ flowID: details.ID }));
      history.push(cleanRoom.flowBuilder(crId, details.ID, details.flow?.ID));
    },
  },
  {
    label: 'Delete',
    action: () => {
      setDeleteFlowDescription({
        ID: details.flowID,
        name: details.flow?.name,
        displayID: details.flow?.displayID,
        crID: details.cleanRoomID,
      });
    },
  },
  ...(isFlowVersioningEnabled ? [{
    label: 'See Version History',
    action: () => {
      openVersionModal(details);
    },
  }] : []),
  ...(isDifferentialPrivacyEnabled && customDiffPrivacyCleanRoom ? [{
    label: 'Advanced Settings',
    action: () => {
      handleAdvancedSettingOpen({
        crID: details.cleanRoomID,
        cleanRoomFlowID: details.ID,
        name: details.flow?.name,
      });
    },
  }] : []),
  ];
};
