import * as Enums from 'utils/ignoramusEnums';
import PermissionService from 'components/Common/PermissionService';
import React, { memo, useContext, useState } from 'react';
import Switch from 'components/Common/Switch';
import { CurrentQuestionsContext } from 'components/CleanRooms/CurrentQuestions/CurrentQuestionsContextProvider';
import { DataContext } from 'BaseComponents/HDataTable/DataContextProvider';
import { cleanRoomPermissions } from 'utils/appConstants';


export const StatusCell = memo(({ row }) => {
  const [status, setStatus] = useState(row.status);
  const authorizedQM = PermissionService.isAuthorized([cleanRoomPermissions.manageQuestions]);
  const {
    updateStatus,
  } = useContext(CurrentQuestionsContext);
  const {
    refreshListData,
  } = useContext(DataContext);

  const changeStatus = (rowL, isActive) => {
    const newStatus = isActive ? Enums.status.ACTIVE : Enums.status.PAUSED;
    updateStatus(row, newStatus.key);
    setStatus(newStatus.key);
    refreshListData();
  };

  return (
    <Switch
      size='small'
      label={Enums.status[status].label}
      onChange={e => changeStatus(row, e.target.checked)}
      checked={status === Enums.status.ACTIVE.key}
      disabled={!authorizedQM}
    />
  );
});

StatusCell.displayName = 'StatusCell';
