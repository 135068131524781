export const AttributeDataTypes = {
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
  DECIMAL: 'DECIMAL',
  DOUBLE: 'DOUBLE',
  INTEGER: 'INTEGER',
  LONG: 'LONG',
  STRING: 'STRING',
  TIMESTAMP: 'TIMESTAMP',
  DATATYPE_UNKNOWN: 'UNKNOWN',
};

export const PARTITION_DATATYPES = [
  AttributeDataTypes.DATE,
  AttributeDataTypes.INTEGER,
  AttributeDataTypes.STRING,
  AttributeDataTypes.TIMESTAMP,
];

export const DataSourceParameters = {
  FileFormat: 'FileFormat',
};

export const JobCategories = {
  pageData: 'Page Data',
};

export const gcsEmail = {
  staging: 'stage-habu-data-in-sa@habu-stage.iam.gserviceaccount.com',
  production: 'prod-habu-data-in-sa@habu-prod.iam.gserviceaccount.com',
  development: 'example-habu-data-in-sa@habu-prod.iam.gserviceaccount.com',
};

export const gam = 'client-gam-sa@habu-client.iam.gserviceaccount.com';

export const Usage = {
  MULTIPLE: 'MULTIPLE',
  SINGLE: 'SINGLE',
};

export const fileFormatDataSources = {
  HABU_AWS: 'Habu AWS S3',
  HABU_LOCAL: 'Local Upload',
  CLIENT_AWS: 'Client AWS S3',
  CLIENT_GCS_SA: 'Google Cloud Storage(with SA)',
  CLIENT_GCS: 'Google Cloud Storage',
  CLIENT_AZURE_STORAGE: 'Azure Storage',
  HABU_GCS: 'Habu Google Cloud Storage',
};

export const DATA_SOURCE_NAME = {
  CLIENT_GCS_SA: 'CLIENT_GCS_SA',
  CLIENT_GCS: 'CLIENT_GCS',
  GAM_LOGS: 'GAM_LOGS',
  HABU_AWS: 'HABU_AWS',
  HABU_LOCAL: 'HABU_LOCAL',
  CLIENT_AWS: 'CLIENT_AWS',
  HABU_GCS: 'HABU_GCS',
  HABU_JS_TAG: 'HABU_JS_TAG',
  HUBSPOT: 'HUBSPOT',
  SNOWFLAKE: 'Snowflake',
  CSV_CATALOG: 'CSV_CATALOG',
};
