import {
  AnalysisRuleRunStatusCell,
  DatasetsManagementCell,
  DescriptionCell,
  PublishStatusCell,
  QuestionOwnerCell,
  QuestionTypeCell,
  StatusCell,
  TagsCell,
} from 'components/CleanRooms/CurrentQuestions/QuestionsTableLayout/TableCellComponents';
import { cellTypesEnum } from 'BaseComponents';


export const getColumns = (analysisRuleEnabled) => [{
  field: 'status',
  name: 'Status',
  width: 120,
  component: StatusCell,
}, {
  field: 'displayID',
  name: 'Display Id',
  width: 140,
}, {
  field: 'title',
  name: 'Name',
  type: cellTypesEnum.LINE_3,
  width: 120,
}, {
  field: 'isUserListQuestion',
  name: 'Type',
  width: 70,
  component: QuestionTypeCell,
}, {
  field: 'description',
  name: 'Description',
  width: 280,
  component: DescriptionCell,
}, {
  field: 'cleanRoomQuestionOwner',
  name: 'Clean Room Question Owner',
  width: 280,
  component: QuestionOwnerCell,
}, {
  field: 'tags',
  name: 'Tags',
  width: 120,
  component: TagsCell,
}, {
  field: 'Publish Status',
  name: 'Publish Status',
  width: 120,
  component: PublishStatusCell,
}, {
  field: 'category',
  name: 'Use Case',
  width: 140,
}, {
  field: 'Created On',
  name: 'Created On',
  width: 225,
  type: cellTypesEnum.US_DATE_FORMAT,
  valueGetter: ({ row }) => (row.timeAudit.createdAt),
}, {
  field: 'Last Modified Date',
  name: 'Last Modified Date',
  width: 225,
  type: cellTypesEnum.US_DATE_FORMAT,
  valueGetter: ({ row }) => (row.timeAudit.updatedAt),
}, analysisRuleEnabled && ({
  field: 'analysisRuleStatus',
  name: 'Analysis Rule Status',
  width: 160,
  component: AnalysisRuleRunStatusCell,
}),
{
  field: '',
  name: 'Datasets',
  width: 225,
  component: DatasetsManagementCell,
}].filter(Boolean);
