import DisableIdentifierProjectionSwitch
  from 'components/QuestionManagement/ComputeCapacityModal/DisableIdentifierProjectionSwitch';
import FederatedQuerySwitch from 'components/QuestionManagement/ComputeCapacityModal/FederatedQuerySwitch';
import PermissionService from 'components/Common/PermissionService';
import React, { useEffect, useMemo } from 'react';
import getCleanRoomParamters from 'components/CleanRooms/Wizard/helper';
import useCapacityManagement from 'components/QuestionManagement/ComputeCapacityModal/useCapacityManagement';
import useDisableIdentifierProjection
  from 'components/QuestionManagement/ComputeCapacityModal/useDisableIdentifierProjection';
import useFederatedQuery from 'components/QuestionManagement/ComputeCapacityModal/useFederatedQuery';
import {
  Alert, Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel,
  Grid, Radio, RadioGroup, Skeleton, Typography,
} from '@mui/material';
import {
  CLEAN_ROOMS_WITH_EXTENDED_CAPACITY,
  COMPUTE_CAPACITY,
} from 'utils/constants/question.constant';
import { CleanRoomTypes } from 'components/CleanRooms/constants';
import { HButton, HSpinner } from 'BaseComponents';
import {
  SESSION_STORAGE_KEYS, productPerm, userPermissions, userTypes,
} from 'utils/appConstants';
import { habuColors } from 'Theme';
import { showAlert } from 'redux/actions/AlertActions';
import { updateQuestion } from 'redux/actions/QuestionBuilderActions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


const ComputeCapacityModal = ({
  open, onClose, questionId, crqId,
}) => {
  const dispatch = useDispatch();
  const question = useSelector(state => state.questionBuilder.question);

  const { crId } = useParams();
  const cleanRoomID = crId || sessionStorage.getItem(SESSION_STORAGE_KEYS.QB_CR_ID);
  const cleanroomDetail = useSelector(state => state.cleanRooms.detail);
  const crParams = cleanroomDetail?.parameters ?? [];
  const cleanroomType = cleanroomDetail?.type;

  const isSuperUser = PermissionService.isAuthorized([userTypes.superuser]);
  const isDatasetMaterializationEnabled = PermissionService.isProductEnabled(
    [userPermissions.DatasetMaterialization],
  );
  const isDisableIdentifierProjectionEnabled = PermissionService.isProductEnabled([
    productPerm.DisableIdentifierProjection]);

  const isCrMaterializationEnabled = getCleanRoomParamters(crParams)?.enableDatasetMaterialization === 'true';

  const federatedQueryEnabled = useMemo(() => {
    const isCrTypeValid = cleanroomType && ![
      CleanRoomTypes.LINKEDIN_CLEAN_ROOM,
      CleanRoomTypes.HABU_CONFIDENTIAL_COMPUTING_CLEAN_ROOM,
    ].includes(cleanroomType);

    return isSuperUser && !!crId && !!crqId && isCrTypeValid;
  }, [isSuperUser, crId, crqId, cleanroomType]);

  const isFQEnabledWithMaterialization = federatedQueryEnabled
    && (!isDatasetMaterializationEnabled || !isCrMaterializationEnabled);

  const disableIdentifierEnabled = useMemo(() => (
    isSuperUser && isDisableIdentifierProjectionEnabled && !!crId && !!crqId
  ), [isSuperUser, isDisableIdentifierProjectionEnabled, crId, crqId]);

  // Federated query logic
  const {
    isFQFetching,
    isFQCreating,
    isFQUpdating,
    isFQCreated,
    isFQUpdated,
    isFederatedQuery,
    setIsFederatedQuery,
    saveFederatedQuery,
  } = useFederatedQuery({
    crId,
    crqId,
    enabled: isFQEnabledWithMaterialization,
  });

  // Capacity management logic
  const {
    isCapacityUpdating,
    isCapacityUpdated,
    currVal,
    setCurrVal,
    saveCapacity,
  } = useCapacityManagement({
    crId, crqId, questionId, question,
  });

  const {
    isDIFetching,
    isDICreating,
    isDIUpdating,
    isDICreated,
    isDIUpdated,
    isDisableIdentifier,
    setIsDisableIdentifier,
    saveDisableIdentifier,
  } = useDisableIdentifierProjection({
    crId, crqId, enabled: disableIdentifierEnabled,
  });

  useEffect(() => {
    const isFQActionComplete = isFQEnabledWithMaterialization ? (isFQCreated || isFQUpdated) : true;
    const isDIActionComplete = disableIdentifierEnabled ? (isDICreated || isDIUpdated) : true;
    if (!!questionId && isCapacityUpdated && isFQActionComplete && isDIActionComplete) {
      onClose();
    }
  }, [isCapacityUpdated, questionId, onClose, isFQEnabledWithMaterialization, isFQCreated,
    isFQUpdated, isSuperUser, isDICreated, isDIUpdated, disableIdentifierEnabled]);

  const handleSave = () => {
    if (questionId) {
      // Case for Advanced Settings dropdown button in the question list
      saveCapacity();

      // Handle federated query in CRQ advanced settings
      if (isFQEnabledWithMaterialization) {
        saveFederatedQuery();
      }

      if (disableIdentifierEnabled) {
        saveDisableIdentifier();
      }
    }
    else {
      // Case for Advance Settings in Question Builder
      dispatch(updateQuestion({ computeCapacity: currVal }));
      dispatch(showAlert({ message: `Processing capacity updated for "${question.title}"`, type: 'success' }));
      onClose();
    }
  };

  const getFilteredComputeCapacity = Object.values(COMPUTE_CAPACITY)
    .filter(item => ![COMPUTE_CAPACITY.MEDIUM.value, COMPUTE_CAPACITY.XLARGE.value]
      .includes(item.value)
        || CLEAN_ROOMS_WITH_EXTENDED_CAPACITY.includes(cleanroomType));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='md'
      data-testid='compute-capacity-modal'
    >
      <DialogTitle variant='h3'>Advanced Question Settings</DialogTitle>
      <Divider />
      <DialogContent>
        {!currVal ? (
          <Skeleton variant='rectangular' height={220} />
        ) : (
          <Grid container>
            <Box mb={2} width='100%'>
              <Typography variant='subtitle1' mb={1}>
                Processing capacity needed to run the question (optional)
              </Typography>
              <Typography variant='body2' mb={1}>
                Every time the question runs, the selected capacity will be applied to available
                resources.
                <br />
                These settings can be edited even after publishing the question.
              </Typography>
              {!cleanRoomID && (
                <Alert
                  severity='warning'
                  variant='filled'
                  sx={{
                    color: habuColors.neutral.white,
                    backgroundColor: habuColors.signal.warning['70'],
                  }}
                >
                  <Typography variant='subtitle2'>
                    {`Clicking "Save" will change processing capacity for all associated CRQs to "${COMPUTE_CAPACITY[currVal]?.name}"`}
                  </Typography>
                </Alert>
              )}
            </Box>
            <Grid item xs={12} md={4} pl={4}>
              <RadioGroup>
                {getFilteredComputeCapacity.map(item => (
                  <FormControlLabel
                    key={item.name}
                    value={item.value}
                    control={<Radio checked={currVal === item.value} />}
                    color='primary'
                    onChange={e => setCurrVal(e.target.value)}
                    label={item.name}
                    componentsProps={{
                      typography: { variant: 'body2' },
                    }}
                  />
                ))}
              </RadioGroup>
            </Grid>
            <Grid item xs={12} md={8} pr={4}>
              <Box bgcolor={habuColors.neutral['5']} p={2}>
                <Typography variant='subtitle2' mb={1}>
                  {COMPUTE_CAPACITY[currVal]?.subtitle}
                </Typography>
                <Typography variant='body2'>
                  {COMPUTE_CAPACITY[currVal]?.description}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
        {federatedQueryEnabled && (
          <>
            <Divider sx={{ my: 2 }} />
            <FederatedQuerySwitch
              isLoading={isFQFetching}
              isFederatedQuery={isFederatedQuery}
              setIsFederatedQuery={setIsFederatedQuery}
              isDatasetMaterializationEnabled={isDatasetMaterializationEnabled}
              isCrMaterializationEnabled={isCrMaterializationEnabled}
            />
          </>
        )}
        {
          disableIdentifierEnabled && (
            <>
              <Divider sx={{ my: 2 }} />
              <DisableIdentifierProjectionSwitch
                isLoading={isDIFetching}
                isDisableIdentifier={isDisableIdentifier}
                setIsDisableIdentifier={setIsDisableIdentifier}
              />
            </>
          )
        }
      </DialogContent>
      <Divider />
      <DialogActions>
        <HButton variant='outlined' onClick={onClose}>Cancel</HButton>
        <HButton
          variant='contained'
          onClick={handleSave}
          disabled={
            isCapacityUpdating || !currVal
            || (isFQEnabledWithMaterialization && (isFQCreating || isFQUpdating))
            || isDICreating || isDIUpdating
          }
          endIcon={
            isCapacityUpdating || (isFQEnabledWithMaterialization && (isFQCreating || isFQUpdating))
              ? <HSpinner isButton /> : null
          }
        >
          Save
        </HButton>
      </DialogActions>
    </Dialog>
  );
};

export default ComputeCapacityModal;
