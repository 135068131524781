import React from 'react';
import { CurrentQuestionsPageContextProvider } from 'components/CleanRooms/CurrentQuestions/CurrentQuestionsPageContextProvider';
import { CurrentQuestionsPageLayout } from 'components/CleanRooms/CurrentQuestions/CurrentQuestionsPageLayout';


const CurrentQuestions = () => (
  <CurrentQuestionsPageContextProvider>
    <CurrentQuestionsPageLayout />
  </CurrentQuestionsPageContextProvider>
);


export default CurrentQuestions;
