/* eslint-disable prefer-template */
import * as monaco from 'monaco-editor';
import { DESCRIPTION_TYPES, EDITOR_KEYWORDS } from 'utils/constants/question.constant';

/**
 * Characters that triggers the autocomplete suggestions on editor
 */
export const SUGGESTION_CHAR = {
  LEVEL_1: '@',
  LEVEL_2: '.',
};

/**
 * Generate Monaco editor instance for the given language
 * @param {string} language
 * @returns
 */
export const generateEditorInstance = (language) => monaco.languages.registerCompletionItemProvider(
  language,
  {
    provideCompletionItems: (model, position, context) => {
      if (context?.triggerCharacter !== SUGGESTION_CHAR.LEVEL_1) {
        const standardKeywords = EDITOR_KEYWORDS[language].map(keyword => (
          {
            label: keyword.name,
            insertText: keyword.name + ' ',
            kind: monaco.languages.CompletionItemKind.Keyword,
            detail: `Keyword: ${keyword.details}`,
            documentation: keyword.details,
          }
        ));
        return { suggestions: [...standardKeywords] };
      }
      else {
        return { suggestions: [] };
      }
    },
  },
);

/**
 * Generate editor suggestions based on provided parameters
 * @param {object} question
 * @param {string} language
 * @param {Array} cleanRoomDatasets
 * @param cleanRoomQuestionUDFs
 * @returns
 */
export const generateEditorSuggestions = (
  question, language, cleanRoomDatasets, cleanRoomQuestionUDFs = [],
) => monaco.languages.registerCompletionItemProvider(
  language,
  {
    triggerCharacters: Object.values(SUGGESTION_CHAR),
    provideCompletionItems: (model, position, context) => {
      if (context?.triggerCharacter === SUGGESTION_CHAR.LEVEL_1
        && !model.getWordAtPosition(position)) {
        const datasetTypes = question.dataTypes.filter(dt => dt.datasetID.length === 0)
          .map(datasetType => (
            {
              label: datasetType.macro,
              insertText: datasetType.macro,
              kind: monaco.languages.CompletionItemKind.Class,
              detail: 'Dataset type',
            }
          ));

        const datasets = question.dataTypes.filter(dt => dt.datasetID.length > 0)
          .map(dataset => (
            {
              label: dataset.macro,
              insertText: dataset.macro,
              kind: monaco.languages.CompletionItemKind.Class,
              detail: 'Dataset',
            }
          ));

        const parameters = question.parameters.map(param => (
          {
            label: param.name,
            insertText: param.name + ' ',
            kind: monaco.languages.CompletionItemKind.Variable,
            detail: 'Parameter',
          }
        ));

        const crQuestionUDFs = cleanRoomQuestionUDFs.map(udf => (
          {
            label: udf.macro,
            insertText: udf.macro,
            kind: monaco.languages.CompletionItemKind.Function,
            detail: 'User Defined Functions',
          }
        ));

        return {
          suggestions: [...datasetTypes, ...datasets, ...parameters, ...crQuestionUDFs],
        };
      }
      else if (context?.triggerCharacter === SUGGESTION_CHAR.LEVEL_2) {
        const wordBeforePosition = model.getWordUntilPosition({
          lineNumber: position.lineNumber,
          column: position.column - 1,
        });

        const aliases = question?.dataTypes.find(
          (d) => d.macro === wordBeforePosition.word,
        ) || null;

        // No suggestion found
        if (!aliases) {
          return { suggestions: [] };
        }

        if (aliases?.datasetID) {
          // suggestions for Datasets
          const suggestions = cleanRoomDatasets?.find(
            d => d.ID === aliases.datasetID,
          )?.field;

          return {
            suggestions: [...suggestions?.map(f => ({
              label: f?.name,
              insertText: f?.name + ' ',
              kind: monaco.languages.CompletionItemKind.Property,
              detail: f.type,
            }))],
          };
        }
        else {
          // suggestions for Dataset Types
          const suggestions = aliases?.dataSetTypeFields;

          return {
            suggestions: [...suggestions?.map(f => ({
              label: SUGGESTION_CHAR.LEVEL_1 + f?.name,
              insertText: SUGGESTION_CHAR.LEVEL_1 + f?.name + ' ',
              kind: monaco.languages.CompletionItemKind.Property,
              detail: f.type,
            }))],
          };
        }
      }
      else {
        return { suggestions: [] };
      }
    },
  },
);

/**
 * Returns best match for the description in order - BUSINESS / DATA_SCIENCE / TECHNICAL
 * @param {object} descriptions
 * @returns {string} description
 */
export const getBestMatchDesc = (descriptions) => (
  descriptions.find(
    d => (d.type === DESCRIPTION_TYPES.BUSINESS.key && d?.description?.trim().length)
      || (d.type === DESCRIPTION_TYPES.DATA_SCIENCE.key && d?.description?.trim().length)
      || (d.type === DESCRIPTION_TYPES.TECHNICAL.key && d?.description?.trim().length),
  )?.description ?? ''
);
