import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { datasetAnalysisRule } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';


export const useGetAnalysisRuleRunStatus = (cleanRoomId, crQId) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useQuery(
    ['datasetAnalysisRuleRunStatus', { cleanRoomId, crQId }],
    () => axiosInstance.get(datasetAnalysisRule(orgId).runStatus(cleanRoomId, crQId)),
    {
      select: (res) => res?.data || {},
      refetchOnWindowFocus: false,
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message ?? err.response.statusText,
          type: 'error',
        }));
      },
    },
  );
};

