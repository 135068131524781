import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
import Stack from '@mui/material/Stack';
import { Box, Tooltip } from '@mui/material';
import { HStatusChip } from 'BaseComponents';
import { Ownership } from 'components/CleanRooms/constants';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';


const TruncatedTextBox = styled(Box)({
  typography: 'body2',
  maxWidth: 250,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const DatasetListTooltip = styled(Tooltip)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.primary.main,
  },
}));


const DatasetChip = ({ question }) => {
  const datasets = question.datasets || [];

  // Sort datasets with a name prop first, then rest
  const sortedDatasets = datasets.sort((dataset) => (dataset.name ? -1 : 1));

  return (
    <DatasetListTooltip
      title={sortedDatasets.length ? (
        <Stack rowGap={1} padding={1}>
          {sortedDatasets.map((d, i) => (
            <Stack key={`${d.name || d.macro}${i.toString()}`} direction='row' spacing={1} alignItems='center'>
              {
              d.name ? (
                <>
                  <CheckOutlinedIcon fontSize='small' />
                  <TruncatedTextBox>{d.name}</TruncatedTextBox>
                </>
              ) : (
                <>
                  <InfoOutlinedIcon fontSize='small' />
                  <TruncatedTextBox>{`@${d.macro}`}</TruncatedTextBox>
                </>
              )
            }
              {d.ownership === Ownership.PARTNER.key && (
              <Box sx={{ typography: 'caption', color: 'initial', backgroundColor: (theme) => theme.palette.primary.light }} px={1}>
                {Ownership.PARTNER.label}
              </Box>
              )}
            </Stack>
          ))}
        </Stack>
      ) : ''}
    >
      <HStatusChip label={question.datasetCount} size='small' variant='outlined' />
    </DatasetListTooltip>
  );
};

export default DatasetChip;
