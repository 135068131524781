import * as yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useEffect } from 'react';
import {
  Alert,
  Autocomplete, Divider, FormHelperText, Skeleton, Stack, TextField, Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import {
  HButton, HDialog, HDialogActions, HDialogContent, HDialogTitle,
} from 'BaseComponents';
import { useCreateExportJob } from 'hooks/activationAPI';
import { useFetchOrgPartnerAccounts } from 'hooks/activationChannelsAPI';
import { useFetchQuestionActivationOptions } from 'api/questions';
import { usePrepareTableForAssets } from 'api/questions/usePrepareTableForAssets';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';


// Form validation schema
const validationSchema = yup.object().shape({
  assetDisplayName: yup.string()
    .required()
    .min(3)
    .max(128)
    .matches(/^(?!\d+$)[a-zA-Z0-9_-]+$/, 'Table Name cannot be pure digital, contain spaces, or symbols besides _ and -')
    .label('Table Name'),
  rampIdColumn: yup.string().required().label('Ramp ID Column'),
});


const SelectRampIdModal = ({
  crId, question, dataContextRef, onClose,
}) => {
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);

  const {
    control, handleSubmit, setValue, formState: { errors },
  } = useForm({
    defaultValues: {
      assetDisplayName: question?.assetDisplayName?.length ? question?.assetDisplayName : question?.displayID ?? '',
      rampIdColumn: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    isSuccess,
    isFetching,
    isError,
    data: questionActivationOptions,
  } = useFetchQuestionActivationOptions(
    crId, question?.ID,
  );

  useEffect(() => {
    if (questionActivationOptions) {
      setValue('rampIdColumn', question?.AssetRampIDColumnName?.length ? question?.AssetRampIDColumnName : questionActivationOptions?.identityField?.[0]?.name ?? '');
    }
  }, [questionActivationOptions, question, setValue]);

  const {
    data: partnerAccount,
  } = useFetchOrgPartnerAccounts(orgId, {
    filters: [
      { attribute: 'Partner', values: ['LR-Activation'] },
    ],
    sortBy: {},
    limit: 1,
    offset: 0,
    search: '',
  });

  const {
    mutateAsync: prepareTableForAssets,
    isLoading: isPreparingTableForAssets,
  } = usePrepareTableForAssets();

  const {
    mutateAsync: createExportJob,
    isLoading: isCreatingExportJob,
  } = useCreateExportJob();

  // Handle modal close
  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  // Handle data submit
  const onSubmit = async (values) => {
    await prepareTableForAssets({
      cleanRoomId: crId,
      questionId: question?.ID,
      isUpdate: question?.isAsset,
      payload: {
        assetDisplayName: values.assetDisplayName ?? '',
        rampID: values.rampIdColumn ?? '',
      },
    });

    // create export job if question is not an asset
    if (!question.isAsset) {
      const partnerAccountID = partnerAccount?.data?.partnerAccounts[0]?.ID;
      if (partnerAccountID) {
        await createExportJob({
          cleanRoomID: crId,
          cleanRoomQuestionID: question?.ID,
          partnerAccountIDs: [partnerAccountID],
          exportParameters: [],
        });
      }
    }

    dataContextRef.current.refreshListData();
    handleClose();
  };

  return (
    <HDialog
      fullWidth
      maxWidth='xs'
      open
      onClose={handleClose}
      data-testid='select-rampId-modal'
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <HDialogTitle onClose={handleClose}>
          <Stack direction='row' justifyContent='space-between'>
            <Stack direction='row' spacing={2} alignItems='center'>
              <Typography variant='h5'>Prepare Your Table for Assets</Typography>
            </Stack>
          </Stack>
        </HDialogTitle>
        <Divider />
        <HDialogContent>
          {
            isFetching
              ? (
                <Stack spacing={4}>
                  <Skeleton variant='rectangular' height={40} />
                  <Skeleton variant='rectangular' height={40} />
                </Stack>
              )
              : (
                <>
                  {
                    isSuccess ? (
                      <Stack spacing={1}>
                        <>
                          <Controller
                            name='assetDisplayName'
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                id='input-table-name'
                                label='Table Name *'
                                fullWidth
                                size='small'
                                placeholder='Type table name'
                                variant='outlined'
                                error={errors?.assetDisplayName}
                              />
                            )}
                          />
                          <FormHelperText error>{errors?.assetDisplayName?.message}</FormHelperText>
                        </>
                        <Controller
                          name='rampIdColumn'
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              size='small'
                              id='select-ramp-id-column'
                              data-testid='select-ramp-id-column'
                              options={
                                questionActivationOptions?.identityField?.map(
                                  option => option.name,
                                ) || []
                              }
                              value={field.value || null}
                              getOptionLabel={option => option}
                              disableClearable
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label='Select Ramp ID Column *'
                                  size='small'
                                  variant='outlined'
                                  error={!!errors?.rampIdColumn}
                                />
                              )}
                              onChange={(event, value) => field.onChange(value)}
                            />
                          )}
                        />
                        <FormHelperText error>{errors?.rampIdColumn?.message}</FormHelperText>
                      </Stack>
                    ) : isError && (
                      <Alert severity='error'>
                        <Typography variant='body2'>
                          Error fetching options, please try again later.
                        </Typography>
                      </Alert>
                    )
                  }
                </>
              )
          }
        </HDialogContent>
        <Divider />
        <HDialogActions sx={{ my: 2 }}>
          <HButton
            variant='text'
            data-testid='cancel-button'
            onClick={handleClose}
          >
            Cancel
          </HButton>
          <LoadingButton
            variant='contained'
            color='primary'
            data-testid='share-button'
            loading={isPreparingTableForAssets || isCreatingExportJob}
            type='submit'
          >
            Confirm
          </LoadingButton>
        </HDialogActions>
      </form>
    </HDialog>
  );
};

export default SelectRampIdModal;
