import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { datasetAnalysisRule } from 'utils/urlConstants';
import { fetchCrDatasetGroups } from 'redux/actions/CleanRoomsActions';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';


export const useUpdateAnalysisRule = (cleanRoomId, datasetId) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useMutation(
    'updateAnalysisRule',
    (payload) => axiosInstance.put(
      datasetAnalysisRule(orgId).update(
        cleanRoomId, datasetId, payload.analysisRule.ID,
      ),
      payload,
    ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['analysisRuleAnalytical', { cleanRoomId, datasetId }] });
        queryClient.invalidateQueries({ queryKey: ['analysisRuleList', { cleanRoomId, datasetId }] });
        dispatch(fetchCrDatasetGroups(orgId, cleanRoomId));
        dispatch(showAlert({
          message: 'Analysis Rule updated successfully',
          type: 'success',
        }));
      },
      onError: (err) => {
        dispatch(showAlert({
          message: err.response.data.message ?? err.response.statusText,
          type: 'error',
        }));
      },
    },
  );
};
