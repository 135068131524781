import React from 'react';
import { axiosInstance } from 'utils/http';
import { question } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';


export const useFetchQueryLanguages = (storageProvider) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, isFetching,
  } = useQuery(
    ['QueryLanguages', storageProvider],
    async () => axiosInstance.get(question().queryLanguages(storageProvider)),
    {
      enabled: Boolean(storageProvider),
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        if (res?.data?.questionQueryLanguage) {
          setData(res?.data?.questionQueryLanguage);
        }
        else {
          dispatch(showAlert({ message: 'Error occurred while fetching query languages!', type: 'error' }));
        }
      },
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    isFetching,
    data,
  };
};
