import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  HButton, HDialogActions, HDialogContent, HDialogTitle, HSpinner,
} from 'BaseComponents';
import {
  cleanRoomQuestionPermissions,
  crqPermissionsAccessLevels,
} from 'components/CleanRooms/constants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  useFetchCrQuestionPermissions, useUpdateCrQuestionPermissions,
} from 'components/CleanRooms/CurrentQuestions/service/CrQuestionPermissionsAPI';


const LoadingSkeleton = () => (
  <Stack gap={1}>
    {Object.values(cleanRoomQuestionPermissions).map(p => (
      <Skeleton key={p.name} variant='text' width='100%' height={40} />
    ))}
  </Stack>
);

const ManageQuestionPermissionsModal = ({
  open, onCancel, crId, error, questionID,
}) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [permissions, setPermissions] = React.useState([]);

  const {
    data: questionPermissions,
    isFetching,
  } = useFetchCrQuestionPermissions(orgId, crId, questionID);

  const updatePermissions = useUpdateCrQuestionPermissions();

  const save = () => {
    updatePermissions.mutate({
      orgId,
      crId,
      crqId: questionID,
      payload: { cleanRoomQuestionPermissions: permissions },
    });
  };

  React.useEffect(() => {
    if (updatePermissions.isSuccess) {
      onCancel();
      dispatch(showAlert({ message: 'Clean Room Question permissions have been updated.', type: 'success' }));
      updatePermissions.reset();
    }

    if (updatePermissions.isError) {
      dispatch(showAlert({ message: updatePermissions.data.response.data.message, type: 'error' }));
    }
  }, [dispatch, onCancel, updatePermissions]);

  React.useEffect(() => {
    if (!questionPermissions) return;
    const perms = Object.values(cleanRoomQuestionPermissions).map(p => {
      const questionPermission = questionPermissions.find(qp => qp.permission === p.name);
      if (questionPermission) {
        return questionPermission;
      }
      else {
        return { permission: p.name, access: crqPermissionsAccessLevels.QUESTION_OWNER_ONLY };
      }
    });
    setPermissions(perms);
  }, [questionID, questionPermissions]);

  const isPermissionChecked = (permissionName, currentQuestionPermissions) => {
    const perm = currentQuestionPermissions.find(p => p.permission === permissionName);
    return perm?.access === crqPermissionsAccessLevels.ALL_CLEAN_ROOM_PARTNERS;
  };

  const isPermissionDisabled = (permission, currentQuestionPermissions) => {
    if (permission.name !== cleanRoomQuestionPermissions.VIEW_CODE.name) {
      return false;
    }
    const editPermission = currentQuestionPermissions
      .find(p => p.permission === cleanRoomQuestionPermissions.EDIT.name);
    if (editPermission
      && editPermission.access === crqPermissionsAccessLevels.ALL_CLEAN_ROOM_PARTNERS) {
      return true;
    }
    return false;
  };

  const onPermissionsChange = (permissionName, checked) => {
    if (permissionName === cleanRoomQuestionPermissions.EDIT.name && checked) {
      onPermissionsChange(cleanRoomQuestionPermissions.VIEW_CODE.name, checked);
    }
    setPermissions((prevState) => [
      ...prevState.filter(p => p.permission !== permissionName),
      {
        permission: permissionName,
        access: checked
          ? crqPermissionsAccessLevels.ALL_CLEAN_ROOM_PARTNERS
          : crqPermissionsAccessLevels.QUESTION_OWNER_ONLY,
      },
    ]);
  };

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth
      maxWidth='sm'
      aria-labelledby='manage-question-permissions-dialog'
      aria-describedby='manage-question-permissions-dialog'
    >
      <HDialogTitle onClose={onCancel}>
        <Stack gap={1} direction='row' alignItems='center'>
          <LockOutlinedIcon />
          Question Permissions
        </Stack>
      </HDialogTitle>
      <HDialogContent>
        <Stack gap={1}>
          <Typography variant='caption'>
            Manage actions that partner orgs can take on this question in this Clean Room.
          </Typography>
          <Typography variant='linkText'>
            <Link
              href='https://habu.zendesk.com/hc/en-us/articles/24252345742605-Question-Permissions'
              target='_blank'
              rel='noreferrer'
            >
              Learn more.
            </Link>
          </Typography>

          <Typography variant='subtitle3'>Allow all partner orgs to...</Typography>
          {isFetching ? <LoadingSkeleton /> : (
            <>
              {Object.values(cleanRoomQuestionPermissions).map(p => (
                <React.Fragment key={p.name}>
                  <FormControlLabel
                    label={p.displayName}
                    labelPlacement='end'
                    control={
                      (
                        <Checkbox
                          checked={isPermissionChecked(p.name, permissions)}
                          onChange={e => onPermissionsChange(p.name, e.target.checked)}
                          disabled={isPermissionDisabled(p, permissions)}
                        />
                      )
                    }
                  />
                </React.Fragment>
              ))}
            </>
          )}
          {error && (
            <Typography color='error'>
              {error}
            </Typography>
          )}
        </Stack>
      </HDialogContent>
      <HDialogActions>
        {onCancel ? (
          <HButton variant='outlined' onClick={onCancel} size='small' data-testid='cancel-button'>
            Cancel
          </HButton>
        ) : null}
        <HButton
          variant='contained'
          color='primary'
          size='small'
          onClick={save}
          endIcon={updatePermissions.isLoading ? <HSpinner isButton /> : null}
          disabled={updatePermissions.isLoading}
          data-testid='save-button'
        >
          Save
        </HButton>
      </HDialogActions>
    </Dialog>
  );
};

export default ManageQuestionPermissionsModal;
