import React from 'react';
import {
  CurrentQuestionsContext,
} from 'components/CleanRooms/CurrentQuestions/CurrentQuestionsContextProvider';
import { DropdownMenu } from 'BaseComponents';


const QuestionsActionButton = ({ row }) => {
  const {
    getReportMenuItems,
    getReportBtnTooltipTitle,
  } = React.useContext(CurrentQuestionsContext);

  return (
    <DropdownMenu
      label='Report'
      variant='contained'
      color='secondary'
      menuItems={getReportMenuItems(row)}
      tooltipTitle={getReportBtnTooltipTitle(row)}
    />
  );
};

export default QuestionsActionButton;
