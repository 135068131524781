import Divider from '@mui/material/Divider';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import ShareQuestionResultsTable from '../ShareQuestionResultsTable';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import {
  HDialog as Dialog,
  HDialogActions as DialogActions,
  HDialogContent as DialogContent,
  HDialogTitle as DialogTitle,
} from 'BaseComponents/HDialog';
import { HButton } from 'BaseComponents';
import { habuColors } from 'Theme';
import { useFetchQuestionSharedPartners, useShareQuestionResults } from 'hooks/questionAPIs';

// Current selected query to be shown as Main Query
const MAIN_QUERY = {
  ID: 'main_query',
  name: 'Main Query',
};

const ShareQuestionResultsModal = ({
  orgId, crId, question, partners, onClose,
}) => {
  const { isSuccess, isFetching, data: sharedPartners } = useFetchQuestionSharedPartners(
    orgId, crId, question?.ID,
  );
  const [data, setData] = useState([]);

  // Construct queries data
  const queries = useMemo(() => {
    if (question?.postRunQueries) {
      return [{ ...MAIN_QUERY }, ...question?.postRunQueries?.map(p => ({
        ...p,
        name: p.externalID,
      }))];
    }
    else {
      return [];
    }
  }, [question.postRunQueries]);

  // Transform data into key-value pairs
  useEffect(() => {
    if (isSuccess) {
      const resData = queries?.map(q => {
        const filteredPartners = sharedPartners?.filter(p => {
          // Check for main query conditions
          if (q.ID === MAIN_QUERY.ID && p.postRunQueryID === '') {
            return true;
          }
          // Check for post-run query conditions
          else {
            return p.postRunQueryID === q.ID;
          }
        });
        return {
          ...q,
          sharedWith: filteredPartners?.map(res => res.partnerID),
        };
      });
      setData(resData);
    }
  }, [isSuccess, sharedPartners, queries]);

  const {
    isLoading: isResultsSharing,
    mutate: shareResults,
  } = useShareQuestionResults(onClose);

  // Handle modal close
  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  // Handle data submit
  const onSubmit = () => {
    const resultShares = [];

    // Construct payload
    data.forEach(d => {
      if (d.ID === MAIN_QUERY.ID) {
        d.sharedWith.forEach(s => resultShares.push(_.pickBy({
          ID: sharedPartners.find(item => item.partnerID === s && !item.postRunQueryID)?.ID,
          partnerID: s,
        }, _.identity)));
      }
      else {
        d.sharedWith.forEach(s => resultShares.push(_.pickBy({
          ID: sharedPartners.find(item => item.partnerID === s && item.postRunQueryID === d.ID)?.ID,
          partnerID: s,
          postRunQueryID: d.ID,
        }, _.identity)));
      }
    });

    // Mutate result shares
    shareResults({
      orgId,
      crId,
      questionId: question?.ID,
      payload: { resultShares },
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open
      onClose={handleClose}
      data-testid='share-results-modal'
    >
      <DialogTitle onClose={onClose}>
        <Stack direction='row' gap={1}>
          <InfoOutlinedIcon fontSize='medium' />
          <Typography variant='h5'>Share Question Results</Typography>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={2}>
          <Stack direction='row' spacing={1}>
            <Typography variant='body1' fontWeight={200} sx={{ color: habuColors.neutral['60'] }}>{'Question: '}</Typography>
            <Typography variant='body1' data-testid='question-title'>{question?.title}</Typography>
          </Stack>
          <Stack>
            {isFetching
              ? (Array.from(Array(4), (d, i) => i)).map((item) => (
                <Skeleton
                  key={item}
                  variant='rectangular'
                  width='100%'
                  height={24}
                  sx={{ my: 1 }}
                />
              ))
              : isSuccess
              && sharedPartners && partners && (
                <ShareQuestionResultsTable
                  queries={queries}
                  partners={partners}
                  data={data}
                  onUpdateData={d => setData(d)}
                />
              )}
          </Stack>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ my: 2 }}>
        <HButton
          variant='outlined'
          data-testid='cancel-button'
          onClick={handleClose}
        >
          Cancel
        </HButton>
        <LoadingButton
          variant='contained'
          color='primary'
          onClick={onSubmit}
          data-testid='share-button'
          loading={isResultsSharing}
        >
          Share
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ShareQuestionResultsModal;

ShareQuestionResultsModal.propTypes = {
  orgId: PropTypes.string.isRequired,
  crId: PropTypes.string.isRequired,
  question: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  partners: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
};
