import { axiosInstance } from 'utils/http';
import { rateLimiting } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';


export const useCreateRateLimitConfig = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => axiosInstance.post(rateLimiting.createRateLimitConfig, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['rateLimitConfigAll'] });
        dispatch(showAlert({ message: 'Rate limit added successfully!', type: 'success' }));
      },
      onError: (err) => {
        const errorMessage = err.response?.data?.message || 'Failed to add rate limit';
        dispatch(showAlert({ message: errorMessage, type: 'error' }));
      },
    },
  );
};
