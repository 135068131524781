import AllQuestions from 'components/CleanRooms/AllQuestions/AllQuestions';
import CurrentQuestions from 'components/CleanRooms/CurrentQuestions/CurrentQuestions';
import React from 'react';
import RecommendedQuestions from 'components/CleanRooms/RecommendedQuestions';
import { PageNotFound } from 'pages/ErrorPages/PageNotFound';
import { Taxonomy } from 'components/CleanRooms/Taxonomy/Taxonomy';
import { TemplateProvisionQuestion } from 'components/CleanRooms/TemplateProvisionQuestion';


export const QuestionsPageLayout = ({ authorizedMQ, tabValue, updateTab }) => {
  const goToCurrentQuestions = () => updateTab('currentQuestions');

  if (authorizedMQ && tabValue === 'availableQuestions') {
    return (
      <AllQuestions goToCurrentQuestions={goToCurrentQuestions} />
    );
  }

  if (tabValue === 'currentQuestions') {
    return (
      <CurrentQuestions />
    );
  }

  if (tabValue === 'recommendedQuestions') {
    return (
      <RecommendedQuestions />
    );
  }

  if (tabValue === 'taxonomy') {
    return <Taxonomy />;
  }

  if (tabValue === 'templateProvision') {
    return <TemplateProvisionQuestion />;
  }

  return (
    <PageNotFound />
  );
};
