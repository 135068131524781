
// Preset of Organization Parameters
const PRESET_ORGANIZATION_PARAMETERS = {
  SUPPORTED_CLOUD: 'SUPPORTED_CLOUD',
  CONNECTOR: 'CONNECTOR',
  DEFAULT_SNOWFLAKE_REGION_ID: 'DEFAULT_SNOWFLAKE_REGION_ID',
  RESOLUTION_MAX_TIMEOUT_HOURS: 'RESOLUTION_MAX_TIMEOUT_HOURS',
  IDENTITY_RESOLUTION_CLOUD: 'IDENTITY_RESOLUTION_CLOUD',
  IDENTITY_RESOLUTION_REGION: 'IDENTITY_RESOLUTION_REGION',
};

export const PRESET_ORGANIZATION_PARAMETERS_NAMES = Object.values(PRESET_ORGANIZATION_PARAMETERS);

// Preset of Organization Values by Organization Parameter Name
export const PRESET_ORGANIZATION_VALUES = {
  [PRESET_ORGANIZATION_PARAMETERS.SUPPORTED_CLOUD]: {
    options: ['AWS', 'GCP', 'AZURE'],
    autocompleteProps: {
      multiple: true,
    },
  },
  [PRESET_ORGANIZATION_PARAMETERS.CONNECTOR]: {
    options: ['LINKEDIN', 'MICROSOFT', 'META', 'HYBRID_PG'],
    autocompleteProps: {
      multiple: true,
    },
  },
  [PRESET_ORGANIZATION_PARAMETERS.IDENTITY_RESOLUTION_CLOUD]: {
    options: ['AWS', 'GCP', 'AZURE'],
    autocompleteProps: {
      multiple: false,
    },
    defaultValue: 'AWS',
  },
  [PRESET_ORGANIZATION_PARAMETERS.IDENTITY_RESOLUTION_REGION]: {
    options: ['US', 'EU', 'APAC'],
    autocompleteProps: {
      multiple: false,
    },
    defaultValue: 'US',
  },
};

// Preset of Organization Categories by Organization Parameter Name
export const PRESET_ORGANIZATION_CATEGORIES = {
  [PRESET_ORGANIZATION_PARAMETERS.SUPPORTED_CLOUD]: {
    options: ['TEE'],
  },
  [PRESET_ORGANIZATION_PARAMETERS.CONNECTOR]: {
    options: ['TEE'],
  },
  [PRESET_ORGANIZATION_PARAMETERS.RESOLUTION_MAX_TIMEOUT_HOURS]: {
    options: ['IDENTITY_RESOLUTION'],
  },
  [PRESET_ORGANIZATION_PARAMETERS.IDENTITY_RESOLUTION_CLOUD]: {
    options: ['IDENTITY_RESOLUTION'],
  },
  [PRESET_ORGANIZATION_PARAMETERS.IDENTITY_RESOLUTION_REGION]: {
    options: ['IDENTITY_RESOLUTION'],
  },
};

export const isInputOrgParameter = (item) => item.type === 'add';
