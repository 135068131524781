import ClearIcon from '@mui/icons-material/ClearRounded';
import React from 'react';
import { IconButton } from '@mui/material';


export const MotifCloseButton = (props) => (
  <IconButton size='small' {...props}>
    <ClearIcon />
  </IconButton>
);
