import { axiosInstance } from 'utils/http';
import { rateLimiting } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';


export const useFetchRateLimitConfigAll = () => {
  const dispatch = useDispatch();

  return useQuery(
    ['rateLimitConfigAll'],
    () => axiosInstance.get(rateLimiting.getAllConfig),
    {
      retry: false,
      select: (res) => res?.data ?? [],
      onError: () => {
        dispatch(showAlert({
          message: 'Failed to fetch rate limit config',
          type: 'error',
        }));
      },
    },
  );
};
