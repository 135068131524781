import React from 'react';
import clsx from 'clsx';
import {
  Drawer, drawerClasses, styled, tabsClasses,
} from '@mui/material';


const DrawerStyled = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ theme, width }) => ({
  [`& .${drawerClasses.paper}`]: {
    overflow: 'hidden',
    width: width || 496,
    [`& > .${tabsClasses.root}`]: {
      backgroundColor: theme.palette.grey[100],
    },
    ...theme.elevation1,
  },
}));

export function MotifSidePanel({ className, ...props }) {
  return (
    <DrawerStyled
      anchor='right'
      className={clsx('SidePanel-root', className)}
      data-testid={props['data-testid'] ?? 'sidepanel'}
      {...props}
    />
  );
}
