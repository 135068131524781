import PartitionIcon from 'components/Common/PartitionIcon';
import React from 'react';
import {
  Abc,
  AlternateEmail,
  Event,
  LooksOne,
  PieChart,
  ShortText,
  Sort,
  WatchLater,
} from '@mui/icons-material';
import {
  Grid, Typography, chipClasses, styled,
} from '@mui/material';
import { HChip } from 'BaseComponents';
import { PARAMETER_DATATYPES } from 'pages/Flow/constants';
import { PARTITION_PARAMETER } from 'components/CleanRooms/CurrentQuestions/Parameters';


const DATATYPE_TO_ICON_MAP = {
  [PARAMETER_DATATYPES.STRING]: Abc,
  [PARAMETER_DATATYPES.DATE]: Event,
  [PARAMETER_DATATYPES.TIMESTAMP]: WatchLater,
  [PARAMETER_DATATYPES.DECIMAL]: PieChart,
  [PARAMETER_DATATYPES.INTEGER]: LooksOne,
  [PARAMETER_DATATYPES.DOUBLE]: ShortText,
  [PARAMETER_DATATYPES.LONG]: Sort,
};

const MacroHChip = styled(HChip)(({ theme }) => ({
  [`.${chipClasses.label}`]: {
    color: theme.palette.secondary.dark,
    ...theme.typography.subtitle2,
  },
}));

const DataTypeIcon = ({ type }) => {
  const Icon = DATATYPE_TO_ICON_MAP[type];
  return (Icon !== undefined) ? (
    <Icon color='secondary' sx={{ fontSize: '20px' }} />
  ) : null;
};

export const ParameterInformation = ({ parameter }) => (
  <Grid container gap='6px' alignItems='center'>
    <DataTypeIcon type={parameter.type} />
    {parameter.macro ? (
      <MacroHChip
        tooltipTitle='Macro'
        icon={<AlternateEmail fontSize='small' color='secondary' />}
        label={parameter.macro.name}
      />
    ) : <Typography variant='subtitle2'>{parameter.name}</Typography>}
    {parameter.parameterType === PARTITION_PARAMETER ? (
      <PartitionIcon />
    ) : null}
  </Grid>
);
