import PermissionService from 'components/Common/PermissionService';
import React, {
  createContext, useEffect, useMemo, useState,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  END_DATE_PARAMETER,
  START_DATE_PARAMETER,
  isDateParametersAdded,
} from 'components/InternalAdmin/QuestionConfigure/QuestionParameters';
import {
  cleanRoomQuestionPermissions,
} from 'components/CleanRooms/constants';
import { hasCRQuestionPermission } from 'components/CleanRooms/service';
import { productPerm } from 'utils/appConstants';
import { useFetchQuestion, useGetCrQuestion } from 'hooks/questionAPIs';
import { useSelector } from 'react-redux';


export const QuestionViewModalContext = createContext();

export const QuestionViewModalProvider = ({
  questionId, orgId, crId, crqID, children,
}) => {
  const organizationList = useSelector(state => state.session.user.organizations);

  const [question, setQuestion] = useState(null);

  const [questionUpdated, setQuestionUpdated] = useState(false);
  const { data: existingQuestion } = useFetchQuestion(questionId, crId);

  const { data: { cleanRoomQuestion } = {} } = useGetCrQuestion(crId, questionId, crqID);

  const ownerOrganizationId = useMemo(() => {
    const currentOrg = organizationList.find(
      (org) => (org.organization.ID === cleanRoomQuestion?.ownerOrganizationID));
    return currentOrg?.organization?.name ?? '-';
  }, [cleanRoomQuestion?.ownerOrganizationID, organizationList]);

  const descriptionCount = useMemo(() => (
    question?.descriptions.filter(d => d.description).length), [question]);

  useEffect(() => {
    if (!(isEmpty(existingQuestion) || questionUpdated)) {
      let { parameters } = existingQuestion;
      if (existingQuestion?.hasDateParameters
        && !isDateParametersAdded(existingQuestion.parameters)) {
        parameters = [{
          ...START_DATE_PARAMETER,
        }, {
          ...END_DATE_PARAMETER,
        },
        ...parameters,
        ];
      }
      setQuestion({ ...existingQuestion, parameters });
      setQuestionUpdated(true);
    }
  }, [existingQuestion, questionUpdated]);


  const crqPermissionsEnabled = PermissionService.isProductEnabled([
    productPerm.crQuestionPermissions,
  ]);

  const hasPermissionToViewQuery = hasCRQuestionPermission(
    orgId,
    cleanRoomQuestion?.ownerOrganizationID,
    cleanRoomQuestion?.permissions,
    cleanRoomQuestionPermissions.VIEW_CODE.name,
  );

  const enabledViewQuery = crqPermissionsEnabled && hasPermissionToViewQuery;

  const isQuestionVersioningEnabled = PermissionService.isProductEnabled(
    [productPerm.questionVersioningV1],
  );

  const questionViewModalContextValue = {
    question,
    ownerOrganizationId,
    descriptionCount,
    crqPermissionsEnabled,
    hasPermissionToViewQuery,
    enabledViewQuery,
    isQuestionVersioningEnabled,
  };

  return (
    <QuestionViewModalContext.Provider value={questionViewModalContextValue}>
      {children}
    </QuestionViewModalContext.Provider>
  );
};
