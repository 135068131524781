import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';


export const useCreateOrUpdateCrqConfigParams = (crId, crqId) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useMutation(
    (data) => axiosInstance.put(cleanRoom(orgId)
      .createOrUpdateConfigParameters(crId, crqId), data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getCrQuestionConfigParams', crId, crqId]);
        dispatch(showAlert({
          message: 'CRQ config parameter created/updated successfully!',
          type: 'success',
        }));
      },
      onError: () => {
        dispatch(showAlert({
          message: 'Error in creating/updating CRQ config parameter',
          type: 'error',
        }));
      },
    },
  );
};
