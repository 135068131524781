import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import DataConnectionVersionSelector from 'components/CleanRooms/CurrentQuestions/DataConnectionVersionSelector';
import Grid from '@mui/material/Grid';
import PermissionService from 'components/Common/PermissionService';
import React, { useState } from 'react';
import SectionHeader from 'components/IAM/Admin/SectionHeader';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import getCleanRoomParamters from 'components/CleanRooms/Wizard/helper';
import orderBy from 'lodash/orderBy';
import partitionIcon from 'assets/partition.svg';
import { Alert, Dialog } from '@mui/material';
import { CurrentQuestionsContext } from 'components/CleanRooms/CurrentQuestions/CurrentQuestionsContextProvider';
import {
  END_DATE_PARAMETER,
  START_DATE_PARAMETER,
  isDateParametersAdded,
} from 'components/InternalAdmin/QuestionConfigure/QuestionParameters';
import { HButton, HSpinner } from 'BaseComponents';
import {
  createNewRun,
  createNewRunReset,
  fetchQuestionDetails,
} from 'redux/actions/CleanRoomsActions';
import { productPerm, userPermissions } from 'utils/appConstants';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchCleanRoomQuestionParameters } from 'hooks/questionAPIs';
import { useValidateExclusiveDatasetMode } from 'api/questions/useValidateExclusiveDatasetMode';


const LoadingSkeleton = () => (
  <>
    <Skeleton width={350} />
    <Skeleton width={350} />
    <Box display='flex' justifyContent='flex-end'>
      <CardActions>
        <Skeleton width={50} />
        <Skeleton width={50} />
      </CardActions>
    </Box>
  </>
);

const NewReportRunModal = ({ onReportAdded }) => {
  const [selectedVersions, setSelectedVersions] = useState({});
  const questionDetails = useSelector(state => state.cleanRooms.questionDetails);
  const cleanroomDetail = useSelector(state => state.cleanRooms.detail);
  const crRun = useSelector(state => state.cleanRooms.crRun);
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const { runModal } = React.useContext(CurrentQuestionsContext);
  const [runDetails, updateRunDetails] = React.useState({ parameters: {} });
  const dispatch = useDispatch();
  const crQuestion = runModal.runModalDetails;
  const isDatasetMaterializationEnabled = PermissionService.isProductEnabled(
    [userPermissions.DatasetMaterialization],
  );
  const isIdentityResolutionEnabled = PermissionService.isProductEnabled([
    productPerm.IdentityResolution]);
  const isExclusiveDatasetModeEnabled = PermissionService.isProductEnabled([
    productPerm.ExclusiveDatasetMode,
  ]);
  const isCrMaterializationEnabled = getCleanRoomParamters(cleanroomDetail.parameters).enableDatasetMaterialization || '';
  const { data } = useFetchCleanRoomQuestionParameters(crQuestion.ID);
  const {
    isSuccess: validationSuccess,
    data: { data: validationResp } = {},
    mutate: callValidateExclusiveDatasetMode,
  } = useValidateExclusiveDatasetMode();

  // The date parameters are not stored in the database
  // Front end fills these 2 parameters based on the value of hasDateParameters
  if (questionDetails?.hasDateParameters && !isDateParametersAdded(questionDetails.parameters)) {
    questionDetails.parameters = [
      {
        ...START_DATE_PARAMETER,
      },
      {
        ...END_DATE_PARAMETER,
      },
      ...questionDetails.parameters,
    ];
  }

  const onClose = React.useCallback(() => {
    dispatch(createNewRunReset());
    runModal.setRunModalDetails(undefined);
    onReportAdded();
  }, [dispatch, runModal, onReportAdded]);

  const onSave = (e) => {
    e.preventDefault();

    const newRunDetails = (isIdentityResolutionEnabled && questionDetails.hasLinkedDatasets)
      ? {
        ...runDetails,
        parameters: {
          ...runDetails.parameters,
          RESOLUTION_DATASET_VERSIONS: JSON.stringify(Object.values(selectedVersions)),
        },
      } : runDetails;

    if (isExclusiveDatasetModeEnabled) {
      const payload = {
        crId: crQuestion.cleanRoomID,
        crqId: crQuestion.ID,
      };
      callValidateExclusiveDatasetMode(payload, {
        onSuccess: () => {
          if (validationResp?.isValid) {
            dispatch(createNewRun(orgId, crQuestion.cleanRoomID, crQuestion.ID,
              { runDetails: newRunDetails }));
          }
        },
      });
      return;
    }

    dispatch(createNewRun(orgId, crQuestion.cleanRoomID, crQuestion.ID,
      { runDetails: newRunDetails }));
  };

  React.useEffect(() => {
    dispatch(fetchQuestionDetails(crQuestion.questionID, 0, cleanroomDetail.ID));
  }, [dispatch, crQuestion, cleanroomDetail.ID]);

  React.useEffect(() => {
    if (crRun?.ID) { onClose(); }
  }, [crRun, onClose]);

  React.useEffect(() => {
    if (isDatasetMaterializationEnabled && isCrMaterializationEnabled && data?.length > 0) {
      updateRunDetails(prevRunDetails => {
        const updatedParameters = data.reduce((acc, param) => {
          if (Object.prototype.hasOwnProperty.call(param, 'value')) {
            acc[param.name] = param.value;
          }
          return acc;
        }, { ...prevRunDetails.parameters });
        return { ...prevRunDetails, parameters: updatedParameters };
      });
    }
  }, [data, isDatasetMaterializationEnabled, isCrMaterializationEnabled]);

  return (
    <Dialog
      open={Boolean(crQuestion)}
      onClose={onClose}
      fullWidth
      maxWidth='md'
    >
      <form onSubmit={onSave}>
        <Card>
          <CardContent>
            <SectionHeader title='New Report Run' />
            {(!questionDetails || questionDetails.loading || data?.isFetching)
              ? <LoadingSkeleton />
              : (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        data-testid='report-text-field'
                        fullWidth
                        label='Run Name'
                        margin='normal'
                        onChange={e => updateRunDetails({ ...runDetails, name: e.target.value })}
                        required
                        type='text'
                        value={runDetails.name || ''}
                        variant='outlined'
                      />
                    </Grid>
                    {orderBy(questionDetails.parameters, ['index'], ['asc']).map(i => (
                      <Grid item xs={6} key={i.name}>
                        <TextField
                          fullWidth
                          helperText={i.displayName}
                          margin='dense'
                          onChange={e => updateRunDetails({
                            ...runDetails,
                            parameters: { ...runDetails.parameters, [i.name]: e.target.value },
                          })}
                          required
                          type={i.type}
                          value={runDetails.parameters[i.name] || ''}
                          variant='outlined'
                        />
                      </Grid>
                    ))}
                    {data?.length > 0
                      && (
                        <>
                          <Grid item xs={12} mt={2}>
                            <Box display='flex' gap={1} alignItems='flex-start'>
                              <img alt='' height='24px' src={partitionIcon} width='24px' />
                              <SectionHeader title='Partition Parameters' />
                            </Box>
                          </Grid>
                          {orderBy(data, ['index'], ['asc']).map(i => (
                            <Grid item xs={['DATE', 'TIMESTAMP'].includes(i.dataType) ? 6 : 12} key={i.name}>
                              <TextField
                                fullWidth
                                helperText={i.name}
                                margin='dense'
                                onChange={e => updateRunDetails({
                                  ...runDetails,
                                  parameters: {
                                    ...runDetails.parameters,
                                    [i.name]: e.target.value,
                                  },
                                })}
                                required
                                type={i.dataType === 'TIMESTAMP' ? 'datetime-local' : i.dataType}
                                value={runDetails.parameters[i.name] || ''}
                                variant='outlined'
                                inputProps={{
                                  step: 1,
                                }}
                              />
                            </Grid>
                          ))}
                        </>
                      )}
                  </Grid>
                  {crRun?.error ? (
                    <Box display='flex' justifyContent='flex-end'>
                      <Typography color='error'>{crRun?.error}</Typography>
                    </Box>
                  ) : null}
                  {/* Data Connection version selector for Linked Data Connection */}
                  {
                    (isIdentityResolutionEnabled && questionDetails.hasLinkedDatasets) && (
                      <DataConnectionVersionSelector
                        cleanRoomId={cleanroomDetail.ID}
                        questionId={questionDetails.ID}
                        selectedVersions={selectedVersions}
                        setSelectedVersions={setSelectedVersions}
                      />
                    )
                  }
                  {isExclusiveDatasetModeEnabled
                    && validationSuccess
                    && !validationResp?.isValid && (
                      <Alert
                        severity='error'
                        sx={{
                          p: 2,
                          '& .MuiAlert-message': {
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          },
                          alignItems: 'unset',
                        }}
                      >
                        <Typography variant='body1' sx={{ fontWeight: 'bold', mb: 1 }}>
                          {validationResp.details?.violationMsg}
                        </Typography>
                        <Box component='ul' sx={{ pl: 2, m: 0 }}>
                          <li>
                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                              List of RampID Datasets
                            </Typography>
                            <Box component='ul' sx={{ pl: 2, m: 0 }}>
                              {validationResp.details?.rampIdDatasets?.map((dataset) => (
                                <li key={dataset}>
                                  <Typography variant='body1'>{dataset}</Typography>
                                </li>
                              ))}
                            </Box>
                          </li>
                          <li>
                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                              List of PII Datasets
                            </Typography>
                            <Box component='ul' sx={{ pl: 2, m: 0 }}>
                              {validationResp.details?.piiDatasets?.map((dataset) => (
                                <li key={dataset}>
                                  <Typography variant='body1'>{dataset}</Typography>
                                </li>
                              ))}
                            </Box>
                          </li>
                        </Box>
                      </Alert>
                  )}
                  <Box display='flex' justifyContent='flex-end'>
                    <CardActions>
                      <HButton onClick={onClose} variant='contained' color='inherit'>Cancel</HButton>
                      <HButton
                        data-testid='report-save-button'
                        color='primary'
                        disabled={crRun?.loading}
                        endIcon={crRun?.loading ? <HSpinner isButton /> : null}
                        type='submit'
                        variant='contained'
                      >
                        Save
                      </HButton>
                    </CardActions>
                  </Box>
                </>
              )}
          </CardContent>
        </Card>
      </form>
    </Dialog>
  );
};

export default NewReportRunModal;
