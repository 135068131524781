import React from 'react';
import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from 'react-query';


export const useFetchCrQuestionPermissions = (orgId, crId, crQuestionId) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState();
  const {
    isLoading, isFetching, isError, isSuccess,
  } = useQuery(
    ['CrQuestionPermissions', crQuestionId],
    async () => axiosInstance.get(cleanRoom(orgId).crQuestionPermissions(crId, crQuestionId), {
      params: {
        cleanRoomID: crId,
      },
    }),
    {
      enabled: !!crQuestionId,
      refetchOnWindowFocus: false,
      onSuccess: (res) => setData(res.data.cleanRoomQuestionPermissions),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isFetching,
    isError,
    isSuccess,
    data,
  };
};

export const useUpdateCrQuestionPermissions = () => useMutation(
  async ({
    orgId, crId, crqId, payload,
  }) => axiosInstance
    .post(cleanRoom(orgId).crQuestionPermissions(crId, crqId), payload),
);
