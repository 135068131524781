import {
  QuestionTypeCell,
  TagsCell,
} from 'components/CleanRooms/CurrentQuestions/QuestionsTableLayout/TableCellComponents';
import { cellTypesEnum } from 'BaseComponents';


export const getColumns = () => [{
  field: 'Name',
  name: 'Name',
  width: 350,
  type: cellTypesEnum.LINE_3,
  valueGetter: ({ row }) => (row.title),
}, {
  field: 'displayID',
  name: 'Display Id',
  width: 100,
}, {
  field: 'isUserListQuestion',
  name: 'Type',
  width: 70,
  component: QuestionTypeCell,
}, {
  field: 'tags',
  name: 'Tags',
  width: 70,
  component: TagsCell,
}, {
  field: 'Category',
  name: 'Use Case',
  width: 140,
  valueGetter: ({ row }) => (row.category),
}, {
  field: 'Created On',
  name: 'Created On',
  width: 120,
  type: cellTypesEnum.US_DATE_FORMAT,
  valueGetter: ({ row }) => (row.timeAudit.createdAt),
}, {
  field: 'Last Modified Date',
  name: 'Last Modified Date',
  width: 225,
  type: cellTypesEnum.US_DATE_FORMAT,
  valueGetter: ({ row }) => (row.timeAudit.updatedAt),
}, {
  field: 'Required Datasets',
  name: 'Required Datasets',
  width: 180,
  valueGetter: ({ row }) => ((row.dataTypes.map(({ displayName }) => displayName)).join(', ')),
}].filter(Boolean);
