import Grid from '@mui/material/Grid';
import QuestionQueryBuilder from 'components/Question/QuestionDetails/QuestionQueryBuilder';
import React, { useCallback, useContext, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { Box, Tab } from '@mui/material';
import { ConfigurationPage } from 'components/Question/QuestionDetails/QuestionViewModal/ConfigurationPage';
import { GeneralInfoPage } from 'components/Question/QuestionDetails/QuestionViewModal/GeneralInfoPage';
import { QuestionVersionHistory } from 'components/Question/QuestionVersionHistory';
import { QuestionViewModalContext } from 'components/Question/QuestionDetails/QuestionViewModal/QuestionViewModalProvider';
import { habuColors } from 'Theme';
import { styled } from '@mui/material/styles';


const rootStyles = {
  width: '100%',
  justifyContent: 'flex-start',
  flexDirection: 'row',
};


const StyledTabs = styled(Tabs)({
  width: '20%',
  borderBottom: 'none !important',
  [`.${tabsClasses.indicator}`]: {
    left: 0,
    right: 'auto',
    backgroundColor: habuColors.secondary[60],
  },
});

export const QuestionViewModalLayout = ({ defaultTab = 'generalInfo' }) => {
  const {
    question,
    hasPermissionToViewQuery,
    enabledViewQuery,
    isQuestionVersioningEnabled,
  } = useContext(QuestionViewModalContext);

  const TAB_CONFIG = {
    generalInfo: {
      label: 'General Info',
      component: <GeneralInfoPage />,
      visible: true,
    },
    configuration: {
      label: 'Configuration',
      component: <ConfigurationPage />,
      visible: true,
    },
    query: {
      label: 'Query',
      component: (
        <Grid item xs={12}>
          <QuestionQueryBuilder
            question={question}
          />
        </Grid>
      ),
      visible: enabledViewQuery,
      disabled: !hasPermissionToViewQuery,
    },
    versionHistory: {
      label: 'Version History',
      component: <QuestionVersionHistory question={question} />,
      visible: isQuestionVersioningEnabled,
    },
  };

  const [selectedTab, setSelectedTab] = useState(defaultTab || 'generalInfo');

  const handleTabChange = useCallback((event, newValue) => {
    setSelectedTab(newValue);
  }, []);

  if (!question) {
    return (
      <Skeleton height={300} />
    );
  }

  return (
    <>
      <Box sx={rootStyles} display='flex'>
        <StyledTabs
          orientation='vertical'
          variant='scrollable'
          value={selectedTab}
          onChange={handleTabChange}
        >

          {Object.keys(TAB_CONFIG).map((key) => {
            if (!TAB_CONFIG[key].visible) {
              return null;
            }
            return (
              <Tab
                key={key}
                label={TAB_CONFIG[key].label}
                sx={rootStyles}
                value={key}
                disabled={TAB_CONFIG[key].disabled}
              />
            );
          })}
        </StyledTabs>

        <Box p={2} width='80%'>
          {TAB_CONFIG[selectedTab]?.component}
        </Box>
      </Box>
    </>
  );
};
