import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PermissionService from 'components/Common/PermissionService';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { CLEAN_ROOM_STATUS } from 'components/CleanRooms/constants';
import { DropdownMenu } from 'BaseComponents/DropdownMenu';
import { QUESTION_STATUS } from 'utils/constants/question.constant';
import { cleanRoomPermissions } from 'utils/appConstants';
import { useSelector } from 'react-redux';


const QuestionStatus = ({ question, handlePublish }) => {
  const authorized = PermissionService.isAuthorized([cleanRoomPermissions.questionBuilder]);
  const crDetail = useSelector(state => state.cleanRooms.detail);
  const isExpired = crDetail?.status === CLEAN_ROOM_STATUS.EXPIRED.key;

  const onHandlePublish = (questionId) => {
    handlePublish(questionId);
  };

  let itemsMenu = [{
    label: 'Publish',
    action: () => onHandlePublish(question.questionID),
  }];

  if (isExpired) {
    itemsMenu = [];
  }
  const publishDisabledTooltip = isExpired ? 'Clean Room Expired. Unable to publish.' : '';

  if (question.questionStatus === QUESTION_STATUS.STAGE.key) {
    if (authorized) {
      return (
        <Tooltip title={publishDisabledTooltip}>
          <span>
            <DropdownMenu
              variant='outlined'
              icon={<ArrowDropDownIcon />}
              label={`${QUESTION_STATUS.STAGE.value} v${question.questionVersion}`}
              size='small'
              menuItems={itemsMenu}
            />
          </span>
        </Tooltip>
      );
    }

    return (
      <Typography>{`${QUESTION_STATUS.STAGE.value} v${question.questionVersion}`}</Typography>
    );
  }

  return (
    <Tooltip placement='top-start' title={QUESTION_STATUS.PUBLISHED.value}>
      <Typography noWrap variant='body2' style={{ padding: '5px 5px', height: '27px' }}>{QUESTION_STATUS.PUBLISHED.value}</Typography>
    </Tooltip>
  );
};


export default QuestionStatus;
