import CurrentQuestionCard from 'components/CleanRooms/CurrentQuestions/CurrentQuestionCard';
import PermissionService from 'components/Common/PermissionService';
import React, { useContext } from 'react';
import { CLEAN_ROOM_STATUS, CleanRoomTypes } from 'components/CleanRooms/constants';
import { CurrentQuestionsContext } from 'components/CleanRooms/CurrentQuestions/CurrentQuestionsContextProvider';
import { CurrentQuestionsPageContext } from 'components/CleanRooms/CurrentQuestions/CurrentQuestionsPageContextProvider';
import {
  HDataTable,
  HTableControlPanel,
} from 'BaseComponents';
import { cleanRoom } from 'utils/urlConstants';
import {
  cleanRoomPermissions,
  productPerm,
} from 'utils/appConstants';
import { getColumns } from 'components/CleanRooms/CurrentQuestions/QuestionsTableLayout/getColumns';
import { question as questionURL } from 'utils/spaUrls';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


export const QuestionsTableLayout = () => {
  const orgID = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const crDetail = useSelector(state => state.cleanRooms.detail);

  const { crId } = useParams();
  const isAnalysisRule = PermissionService.isProductEnabled([productPerm.DatasetAnalysisRule]);

  const {
    getActionMenuItems,
    actionButton,
  } = useContext(CurrentQuestionsContext);
  const {
    dataContextRef,
  } = useContext(CurrentQuestionsPageContext);


  const dataURL = cleanRoom(orgID).questionList(crId);
  const filtersURL = cleanRoom(orgID).questionFilters(crId);

  const isExpired = crDetail?.status === CLEAN_ROOM_STATUS.EXPIRED.key;
  const isTemplate = crDetail?.isTemplate === true;
  const isAWSCleanRoom = crDetail?.type === CleanRoomTypes.AWS_CLEAN_ROOM;
  const analysisRuleEnabled = isAnalysisRule && !isAWSCleanRoom;

  const columns = getColumns(analysisRuleEnabled);

  return (
    <HDataTable
      filtersURL={filtersURL}
      dataURL={dataURL}
      tableName='clean-room-questions'
      dataBaseField='questions'
      columns={columns}
      actionButton={actionButton}
      actionMenu={getActionMenuItems}
      cardElement={CurrentQuestionCard}
      activeQuery={false}
      controlPanel={(
        <HTableControlPanel
          searchAttribute
          createButton={{
            text: 'New Question',
            link: questionURL.builder.wizard.cleanRoom('', crId, ''),
          }}
          isCreateButtonVisible={!isExpired && !isTemplate}
          createPermissions={[
            cleanRoomPermissions.questionBuilder,
          ]}
          dataContextRef={dataContextRef}
          onRefresh={analysisRuleEnabled
            ? ({ fetchData, filters }) => fetchData(filters)
            : undefined}
        />
      )}
    />
  );
};
