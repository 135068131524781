import moment from 'moment';
import {
  CleanRoomTypesCell,
  TypeCell,
} from 'pages/QuestionManagement/CellComponents';
import { QUESTION_STATUS } from 'utils/constants/question.constant';
import {
  TagsCell,
} from 'components/CleanRooms/CurrentQuestions/QuestionsTableLayout/TableCellComponents';
import { UI_DATE_FORMAT } from 'utils/appConstants';
import { cellTypesEnum } from 'BaseComponents';


export const columns = [{
  field: 'displayID',
  name: 'Display ID',
  width: 120,
}, {
  field: 'isUserListQuestion',
  name: 'Type',
  width: 80,
  component: TypeCell,
}, {
  field: 'status',
  name: 'Status',
  width: 90,
  valueGetter: ({ row }) => QUESTION_STATUS[row.status].value,
}, {
  field: 'questionName',
  name: 'Question',
  type: cellTypesEnum.LINE_5,
  width: 280,
}, {
  field: 'category',
  name: 'Category',
  width: 100,
}, {
  field: 'tags',
  name: 'Tags',
  type: cellTypesEnum.LINE_5,
  width: 280,
  component: TagsCell,
}, {
  field: 'cleanRoomTypes',
  name: 'Compatible Clean Rooms',
  width: 280,
  component: CleanRoomTypesCell,
}, {
  field: 'numberOfProvisionedAccounts',
  name: 'Accounts',
  width: 100,
}, {
  field: 'numberOfProvisionedOrganizations',
  name: 'Organization',
  width: 100,
}, {
  field: 'createdOn',
  name: 'Created On',
  width: 140,
  valueGetter: ({ row }) => moment(row.createdOn).format(UI_DATE_FORMAT),
}];
