import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { DialogContent, dialogContentClasses, styled } from '@mui/material';
import { pxToRem } from 'MotifTheme';
import { v4 as uuidv4 } from 'uuid';


const FullScreenDialogContentStyled = styled(DialogContent)({
  padding: pxToRem(24),
});

export const MotifFullScreenDialogContent = ({ className, ...props }) => {
  const ref = useRef(null);
  const [scrolledToHeader, setScrolledToHeader] = useState(false);
  const [scrolledToFooter, setScrolledToFooter] = useState(false);

  // The following effect ensures that independent dialog contents can trigger
  // the shadow effect on the dialog title without conflicting with other
  // dialog contents removing the shadow. We're just using epoch time since
  // its an easy, free, unique ID.
  // There is a semi-side effect where the multiple scrolled dialog contents
  // will layer up the shadows, but this is almost impossible to see for most
  // users if we only expect to see at most 3 columns.
  const [titleShadowId, setShadowId] = useState(null);

  const handleScrollToHeader = (target, shadowId) => {
    const prev = scrolledToHeader;
    const current = target.scrollTop !== 0;

    if (prev !== current) {
      const titleElement = document.querySelector(
        '.FullScreenDialog-root .MuiDialogTitle-rootSpan',
      );
      if (!titleElement) {
        setScrolledToHeader(current);
        return;
      }

      if (current && titleElement) {
        titleElement.classList.add(`FullScreenDialogTitle-scrollShadow-${shadowId}`);
      }
      else {
        titleElement.classList.remove(`FullScreenDialogTitle-scrollShadow-${shadowId}`);
      }

      setScrolledToHeader(current);
    }
  };

  const handleScrollToFooter = (target, shadowId) => {
    const prev = scrolledToFooter;
    const current = target.scrollTop + target.clientHeight + 1 < target.scrollHeight;

    if (prev !== current) {
      const footerElement = document.querySelector(
        '.FullScreenDialog-root .MuiDialogActions-rootSpan',
      );
      if (!footerElement) {
        setScrolledToFooter(current);
        return;
      }
      if (current && footerElement) {
        footerElement.classList.add(`FullScreenDialogActions-scrollShadow-${shadowId}`);
      }
      else {
        footerElement.classList.remove(`FullScreenDialogActions-scrollShadow-${shadowId}`);
      }

      setScrolledToFooter(current);
    }
  };

  useEffect(() => {
    if (ref && ref.current) {
      if (ref.current.parentNode) {
        const childNodes = ref.current.parentNode.querySelectorAll(`.${dialogContentClasses.root}`);
        if (childNodes && childNodes.length > 1) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < childNodes.length - 1; i++) {
            childNodes[i]?.classList?.add('FullScreenDialongContent-divider');
          }
        }
      }
      const innerShadowId = titleShadowId || uuidv4();
      setShadowId(innerShadowId);
      handleScrollToHeader(ref.current, innerShadowId);
      handleScrollToFooter(ref.current, innerShadowId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = (e) => {
    handleScrollToHeader(e.target, titleShadowId);
    handleScrollToFooter(e.target, titleShadowId);
  };

  return (
    <FullScreenDialogContentStyled
      className={clsx('FullScreenDialogContent-root', className)}
      ref={ref}
      onScroll={handleScroll}
      {...props}
    />
  );
};
