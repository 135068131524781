import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import {
  Box, Stack, Switch, Tooltip, Typography,
} from '@mui/material';


const FederatedQuerySwitch = ({
  isLoading,
  isFederatedQuery,
  setIsFederatedQuery,
  isDatasetMaterializationEnabled,
  isCrMaterializationEnabled,
}) => {
  const handleSwitchChange = (event) => {
    setIsFederatedQuery(event.target.checked);
  };

  if (isLoading) {
    return <Skeleton variant='rectangular' height={50} />;
  }

  return (
    <Tooltip
      title={isDatasetMaterializationEnabled && isCrMaterializationEnabled
        ? 'Federated Query toggle is disabled as Dataset Materialization is enabled'
        : ''}
      placement='top-start'
    >
      <Stack direction='row' sx={{ opacity: isDatasetMaterializationEnabled && isCrMaterializationEnabled ? 0.5 : 1 }}>
        <Box paddingRight={3}>
          <Typography variant='subtitle1' mb={1}>
            Federated Query
          </Typography>
          <Typography variant='body2'>
            When enabled allows the engine to run on distributed data systems without
            consolidating into one location
          </Typography>
        </Box>
        <Box>
          <Switch
            checked={isFederatedQuery}
            onChange={handleSwitchChange}
            inputProps={{ 'aria-label': 'Federated Query' }}
            disabled={isDatasetMaterializationEnabled && isCrMaterializationEnabled}
          />
        </Box>
      </Stack>
    </Tooltip>
  );
};

export default FederatedQuerySwitch;
