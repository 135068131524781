import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import NoData from 'components/Common/NoData';
import React, { useCallback, useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import {
  Box, Button, IconButton, MenuItem, Skeleton, Stack, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, TextField, Typography,
} from '@mui/material';
import { HDialog, HDialogContent } from 'BaseComponents';
import { useGetLinkedDatasetsWithVersion } from 'api/cleanrooms';


const DataConnectionVersionSelector = ({
  cleanRoomId, questionId, selectedVersions, setSelectedVersions,
}) => {
  const [openVersionSelector, setOpenVersionSelector] = useState(false);
  const [currentSelectedVersion, setCurrentSelectedVersion] = useState({});

  const {
    isLoading, isError, isSuccess, data: linkedDatasetsWithVersion,
  } = useGetLinkedDatasetsWithVersion(cleanRoomId, questionId);

  useEffect(() => {
    if (isSuccess && linkedDatasetsWithVersion?.length) {
      const newSelectedVersion = linkedDatasetsWithVersion.reduce((acc, dataset) => {
        acc[dataset.datasetName] = {
          datasetID: dataset.datasetID,
          importJobRunID: dataset?.latestVersion?.importJobRunID || '',
        };
        return acc;
      }, {});
      setSelectedVersions(newSelectedVersion);
    }
  }, [isSuccess, linkedDatasetsWithVersion, setSelectedVersions]);

  const handleVersionChange = useCallback((dataset, version) => {
    setCurrentSelectedVersion((prev) => ({
      ...prev,
      [dataset.datasetName]: {
        datasetID: dataset.datasetID,
        importJobRunID: version,
      },
    }));
  }, []);

  const onClickClearVersion = () => {
    const defaultVersion = linkedDatasetsWithVersion.reduce((acc, dataset) => {
      acc[dataset.datasetName] = {
        datasetID: dataset.datasetID,
        importJobRunID: dataset?.latestVersion?.importJobRunID || '',
      };
      return acc;
    }, {});

    setSelectedVersions(defaultVersion);
  };

  const handleDialogOpen = () => {
    setCurrentSelectedVersion(selectedVersions);
    setOpenVersionSelector(true);
  };

  const handleDialogClose = () => {
    setOpenVersionSelector(false);
  };

  const handleDialogSave = () => {
    if (JSON.stringify(currentSelectedVersion) !== JSON.stringify(selectedVersions)) {
      setSelectedVersions(currentSelectedVersion);
    }
    setOpenVersionSelector(false);
  };

  if (isLoading) {
    return <Skeleton variant='rounded' height={60} sx={{ my: 1 }} />;
  }

  if (isError) {
    return <NoData text='Failed to load version data. Please try again.' />;
  }

  return (
    <>
      {Object.keys(selectedVersions).length > 0 && (
        <Box my={1}>
          <Stack direction='row' spacing={1} mb={1}>
            <Typography variant='body1' fontWeight={600} flex={1}>
              Identity Data Connection Version
            </Typography>
            <Button
              size='small'
              variant='text'
              color='secondary'
              onClick={handleDialogOpen}
            >
              Edit
            </Button>
            <Tooltip title='Clear to set default version' enterDelay={700} enterNextDelay={700}>
              <IconButton size='small' onClick={onClickClearVersion}>
                <DeleteOutlineIcon />
              </IconButton>
            </Tooltip>
          </Stack>
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell width='50%'>Data Connection</TableCell>
                  <TableCell width='50%'>Data Connection Version</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(selectedVersions).map((connection) => (
                  <TableRow key={connection}>
                    <TableCell>{connection}</TableCell>
                    <TableCell>
                      {selectedVersions[connection]?.importJobRunID || 'Default to Latest Version'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      {/* Dialog to select a version */}
      <HDialog
        open={openVersionSelector}
        fullWidth
        maxWidth='md'
        title='Select Identity Data Connection Version'
        onClose={handleDialogClose}
        onSubmit={handleDialogSave}
        submitText='Save'
      >
        <HDialogContent>
          <Typography variant='body1' mb={2}>
            The most recent identity data connection version will be used by default.
            To change this, select a different version below.
          </Typography>

          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell width='50%'>Data Connection</TableCell>
                  <TableCell width='50%'>Data Connection Version</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {linkedDatasetsWithVersion.map((dataset) => (
                  <TableRow key={dataset.datasetID}>
                    <TableCell>{dataset.datasetName}</TableCell>
                    <TableCell>
                      <TextField
                        select
                        fullWidth
                        value={currentSelectedVersion[dataset.datasetName]?.importJobRunID}
                        onChange={(e) => handleVersionChange(dataset, e.target.value)}
                        variant='outlined'
                      >
                        {dataset.linkedDatasetVersions.map((version) => (
                          <MenuItem key={version.importJobRunID} value={version.importJobRunID}>
                            {version.importJobRunID}
                          </MenuItem>
                        ))}
                      </TextField>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </HDialogContent>
      </HDialog>
    </>
  );
};

export default DataConnectionVersionSelector;
