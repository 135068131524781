import React from 'react';
import clsx from 'clsx';
import { MotifCloseButton } from 'BaseComponents/MotifCloseButton';
import { DialogTitle as MuiDialogTitle, styled } from '@mui/material';
import { pxToRem } from 'MotifTheme';


const DialogTitleSpanStyled = styled('div')`
  display: flex;
`;

const CloseButtonStyled = styled(MotifCloseButton)({
  padding: pxToRem(6),
  margin: `${pxToRem(-8)} ${pxToRem(-8)} 0 0`,
  borderRadius: pxToRem(4),
  '&:hover': {
    backgroundColor: (theme) => theme.palette.secondary.light,
  },
});

const StyledDialogTitle = styled(MuiDialogTitle)({
  width: `calc(100% - ${pxToRem(24)}`,
});

export const MotifDialogTitle = React.forwardRef(
  (
    {
      children,
      className,
      onClose,
      renderContentRight = null,
      ...props
    },
    ref,
  ) => (
    <DialogTitleSpanStyled className={clsx('MuiDialogTitle-rootSpan', className)} ref={ref}>
      <StyledDialogTitle {...props}>{children}</StyledDialogTitle>
      {renderContentRight || onClose ? (
        <span className='MuiDialogTitle-contentRight'>
          {renderContentRight}
          {onClose && <CloseButtonStyled onClick={onClose} />}
        </span>
      ) : null}
    </DialogTitleSpanStyled>
  ),
);
