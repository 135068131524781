import React from 'react';
import clsx from 'clsx';
import { MotifCloseButton } from 'BaseComponents/MotifCloseButton';
import { MotifTruncate } from 'BaseComponents/MotifFullScreenDialog/MotifTruncate';
import { pxToRem } from 'MotifTheme';
import { styled } from '@mui/material';


const ActionItemsStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;

  .SidePanelHeader-assetUrlButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${pxToRem(30)};
    height: ${pxToRem(30)};

    a {
      height: ${pxToRem(20)};
      color: #222;
    }
  }
`;

const SidePanelHeaderStyled = styled('div')(({ theme }) => ({
  background: theme.palette.grey[100],
  display: 'flex',
  gap: pxToRem(8),
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: `${pxToRem(14)} ${pxToRem(8)} ${pxToRem(14)} ${pxToRem(16)}`,
  boxSizing: 'border-box',
}));

const StartDivStyled = styled('div')({
  padding: `${pxToRem(4)} 0`,
  display: 'flex',
  overflowY: 'hidden',
  gap: pxToRem(8),
  boxSizing: 'border-box',
  overflowWrap: 'break-word',
});

const IconDivStyled = styled('div')({
  display: 'flex',
  width: pxToRem(24),
  height: pxToRem(24),
  marginTop: pxToRem(2),
  boxSizing: 'border-box',
  '& svg': {
    width: 'auto',
    height: 'auto',
  },
});

export function MotifSidePanelHeader({
  className,
  children = '',
  onClose,
  assetUrl,
  truncateProps = {
    wrapped: true,
  },
  icon,
  ...props
}) {
  return (
    <SidePanelHeaderStyled
      className={clsx('SidePanelHeader-root', className)}
      data-testid={props['data-testid'] ?? 'sidepanel-header'}
      {...props}
    >
      <StartDivStyled>
        {icon && <IconDivStyled>{icon}</IconDivStyled>}
        {typeof children === 'string' ? (
          <MotifTruncate
            className='SidePanelHeader-title'
            color='textPrimary'
            tooltipPosition='left'
            variant='h3'
            {...truncateProps}
          >
            {children}
          </MotifTruncate>
        ) : (
          children
        )}
      </StartDivStyled>
      <ActionItemsStyled>
        <MotifCloseButton onClick={onClose} />
      </ActionItemsStyled>
    </SidePanelHeaderStyled>
  );
}
