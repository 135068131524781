import azureLogo from 'assets/partners/azure.svg';
import facebookLogo from 'assets/partners/facebook.svg';
import googleCloudLogo from 'assets/partners/google-cloud.svg';
import googleDV360Icon from 'assets/partners/google-dv360.svg';
import googleLogo from 'assets/partners/google.svg';
import linkedIn from 'assets/partners/linkedin.svg';
import liveRampLogoBlack from 'assets/logo/liveRampLogoBlack.svg';
import snapchatLogo from 'assets/partners/snapchat.svg';
import tiktokLogo from 'assets/partners/tiktok.svg';
import twitterLogo from 'assets/partners/twitter.svg';
import { habuColors as color } from 'Theme';


export const PARTNERS = {
  'Google DV360': { logoSrc: googleDV360Icon },
  'Google Customer Match': { logoSrc: googleLogo },
  'Google Customer Match - MAID': { logoSrc: googleLogo },
  'Google Offline Conversions': { logoSrc: googleLogo },
  'Google Enhanced Conversions': { logoSrc: googleLogo },
  'Google Enhanced Conversions - Beta': { logoSrc: googleLogo },
  'Facebook Custom Audiences': { logoSrc: facebookLogo },
  'Facebook Direct Custom Audiences': { logoSrc: facebookLogo },
  'Facebook Conversions': { logoSrc: facebookLogo },
  'Facebook Offline Conversions': { logoSrc: facebookLogo },
  FacebookAMAPI: { logoSrc: facebookLogo },
  'Twitter Tailored Audiences': { logoSrc: twitterLogo },
  'Twitter Conversions': { logoSrc: twitterLogo },
  'Snapchat Audience Match': { logoSrc: snapchatLogo },
  'Snapchat Offline Conversions': { logoSrc: snapchatLogo },
  Tiktok: { logoSrc: tiktokLogo },
  'Tiktok Offline': { logoSrc: tiktokLogo },
  'Tiktok Offline Conversions': { logoSrc: tiktokLogo },
  BigQueryExport: { logoSrc: googleCloudLogo },
  OnSiteActivationPartner: { logoSrc: liveRampLogoBlack },
  'LinkedIn Matched Audience': { logoSrc: linkedIn },
  LiveRamp: { logoSrc: liveRampLogoBlack },
  'Azure Export': { logoSrc: azureLogo },
};

export const PARTNER_DEFAULT_LOGO_SRC = liveRampLogoBlack;

export const LINKEDIN_MATCHED_AUDIENCE = 'LinkedIn Matched Audience';

export const LIVERAMP = 'LiveRamp';

export const LAYOUTS = {
  tile: 'tile',
  list: 'list',
};

export const JOB_STATUS = {
  numSuccess: { key: 'numSuccess', value: 'Completed', status: 3 },
  numProcessing: { key: 'numProcessing', value: 'Running', status: 2 },
  numPartiallyFailed: { key: 'numPartiallyFailed', value: 'Partially Failed', status: 5 },
  numThresholdWarning: { key: 'numThresholdWarning', value: 'Below Threshold', status: 6 },
  numQueued: { key: 'numQueued', value: 'Queued', status: 1 },
  numFailed: { key: 'numFailed', value: 'Failed', status: 4 },
};

export const PARTNER_ACCOUNT_STATUS = {
  CONFIG_SUCCESS: { key: 'CONFIG_SUCCESS', label: 'Config Success', color: color.signal.success['70'] },
  CONFIG_PENDING: { key: 'CONFIG_PENDING', label: 'Pending', color: color.signal.warning['70'] },
  CONFIG_FAILED: { key: 'CONFIG_FAILED', label: 'Failed', color: color.signal.alert['70'] },
  CONFIG_CONFIGURED: { key: 'CONFIG_CONFIGURED', label: 'Configured', color: color.signal.success['70'] },
};

export const SORT_DIRECTION = {
  asc: 'ASCENDING',
  desc: 'DESCENDING',
};

export const PARTNER_STATUS = {
  pending: { key: 'PENDING', label: 'Pending...', color: color.signal.warning['70'] },
};

export const DEFAULT_FILTER = {
  index: -1,
  key: 'all',
  value: 'All',
};

export const SORT_BY = {
  DEFAULT: { key: 'default', label: 'Default', index: -1 },
  NAME: { key: 'name', label: 'Name', index: 0 },
  PARTNER_ACCOUNTS: { key: 'partnerAccountCount', label: 'No. of Partner Accounts', index: 1 },
};

export const PARTNERS_WITH_PUBLIC_KEY = [
  'S3 IAM Role Export',
  'GCS Export',
];
