import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { datasetAnalysisRule } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';


export const useGetAnalysisRuleAnalytical = (cleanRoomId, datasetId, enabled = true) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useQuery({
    queryKey: ['analysisRuleAnalytical', { cleanRoomId, datasetId }],
    queryFn: () => axiosInstance.get(datasetAnalysisRule(orgId).list(cleanRoomId, datasetId), {
      params: {
        analysisType: 'ANALYTICS',
      },
    }),
    select: (res) => res?.data || {},
    refetchOnWindowFocus: false,
    enabled: !!datasetId && enabled,
    onError: (err) => {
      dispatch(showAlert({
        message: err.response.data.message ?? err.response.statusText,
        type: 'error',
      }));
    },
  });
};

export const useGetAnalysisRuleList = (cleanRoomId, datasetId, enabled = true) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useQuery({
    queryKey: ['analysisRuleList', { cleanRoomId, datasetId }],
    queryFn: () => axiosInstance.get(datasetAnalysisRule(orgId).list(cleanRoomId, datasetId), {
      params: {
        analysisType: 'LIST',
      },
    }),
    select: (res) => res?.data || {},
    refetchOnWindowFocus: false,
    enabled: !!datasetId && enabled,
    onError: (err) => {
      dispatch(showAlert({
        message: err.response.data.message ?? err.response.statusText,
        type: 'error',
      }));
    },
  });
};
