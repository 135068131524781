import React from 'react';
import { activationChannels, dataOut } from 'utils/urlConstants';
import { axiosInstance } from 'utils/http';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery } from 'react-query';


// TODO: deprecate DV-6093
const useCreateQuestionActivation = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async (payload) => axiosInstance.post(dataOut().activateQuestion, payload),
    {
      onSuccess: (res) => {
        setData(res.data.questionUserList);
        dispatch(showAlert({ message: 'Question has been activated.', type: 'success' }));
      },
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

const useFetchActivationPartnerAccounts = (crId) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    (payload) => axiosInstance.post(activationChannels(orgId).partnerAccountList(crId), payload),
    {
      onSuccess: (res) => setData(res.data.partnerAccounts),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

const useFetchCRProvisionChannels = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async (payload) => axiosInstance.post(dataOut().cleanRoomProvisionChannelList, payload),
    {
      onSuccess: (res) => setData(res.data.crProvisions),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

// TODO: deprecate DV-6093
const useFetchQuestionActivations = (questionID) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [data, setData] = React.useState([]);
  const { isLoading, isError, isSuccess } = useQuery(
    ['QuestionActivatedChannels', questionID],
    async () => axiosInstance.get(dataOut(orgId).questionActivatedChannels(questionID)),
    {
      enabled: !!questionID,
      onSuccess: (res) => setData(res.data.questionUserList),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
  };
};

const useFetchQuestionRunActivations = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async (payload) => axiosInstance.post(dataOut(orgId).activations, payload),
    {
      onSuccess: (res) => setData(res.data.jobs),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

const useProvisionChannels = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async (payload) => axiosInstance.post(dataOut().provisionCRChannels, payload),
    {
      onSuccess: (res) => setData(res.data.activationChannelProvisions),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

const useDeprovisionChannels = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async (payload) => axiosInstance.post(dataOut().deprovisionCRChannels, payload),
    {
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

const useRerunActivationJob = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    async (payload) => axiosInstance.post(dataOut().rerunActivationJob, payload),
    {
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

const useUpdateActivationStatus = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [data, setData] = React.useState([]);
  const {
    isLoading, isError, isSuccess, mutate, mutateAsync,
  } = useMutation(
    async ({ jobID, payload }) => axiosInstance.post(
      dataOut(orgId).updateActivationStatus(jobID), payload,
    ),
    {
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
    mutateAsync,
  };
};

const useFetchActivationJobList = (enabled, orgId, sourceID) => {
  const dispatch = useDispatch();
  return useQuery(
    ['ActivationJobList', sourceID],
    () => axiosInstance.get(activationChannels(orgId).activationJobList(sourceID)),
    {
      enabled: enabled && Boolean(orgId) && Boolean(sourceID),
      refetchOnWindowFocus: false,
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};

const useFetchExportJobsList = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [data, setData] = React.useState([]);

  const {
    isLoading, isError, isSuccess, mutate,
  } = useMutation(
    (payload) => axiosInstance.post(dataOut(orgId).exportJobsList, payload),
    {
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
  };
};

const useFetchExportJobsFilters = (jobType) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);

  return useQuery(
    ['ExportJobsFilters', orgId, jobType],
    async () => axiosInstance.get(dataOut(orgId).activationFilters(jobType)),
    {
      enabled: Boolean(orgId) && Boolean(jobType),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};

const useFetchExportJobsRuns = (jobId) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  return useMutation(
    (payload) => axiosInstance.post(dataOut(orgId).exportJobRuns(jobId), payload),
    {
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};

const useDeleteExportJob = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  return useMutation(
    (payload) => axiosInstance.post(dataOut(orgId).deleteExportJob, payload),
    {
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};

const useCreateExportJob = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  return useMutation(
    (payload) => axiosInstance.post(dataOut(orgId).createExport, payload),
    {
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};

export {
  useCreateQuestionActivation,
  useFetchActivationPartnerAccounts,
  useFetchCRProvisionChannels,
  useFetchQuestionActivations,
  useFetchQuestionRunActivations,
  useProvisionChannels,
  useDeprovisionChannels,
  useRerunActivationJob,
  useUpdateActivationStatus,
  useFetchActivationJobList,
  useFetchExportJobsList,
  useFetchExportJobsFilters,
  useFetchExportJobsRuns,
  useDeleteExportJob,
  useCreateExportJob,
};
