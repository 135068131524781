import AnalysisRuleRunStatusChip from 'components/CleanRooms/CurrentQuestions/AnalysisRuleRunStatusChip';
import React, { memo } from 'react';
import { Box } from '@mui/material';


export const AnalysisRuleRunStatusCell = memo(({ row }) => (
  <Box>
    <AnalysisRuleRunStatusChip question={row} />
  </Box>
));

AnalysisRuleRunStatusCell.displayName = 'AnalysisRuleRunStatusCell';

