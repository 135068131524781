import FlowBuilderHeader from 'components/Flow/FlowBuilderHeader';
import FlowRender from 'pages/Flow/FlowRender';
import FlowSidePanel from 'pages/Flow/FlowSidePanel/FlowSidePanel';
import Grid from '@mui/material/Grid';
import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { FlowBuilderContext } from 'pages/Flow/FlowBuilder/FlowBuilderContextProvider';
import { Redirect } from 'react-router-dom';
import { cleanRoom } from 'utils/spaUrls';


export const FlowBuilderLayout = () => {
  const {
    flowId,
    crId,
    flow,
    editMode,
    isViewMode,
    flowUpdateSuccess,
    handleFlowSubmit,
    handleNameInput,
    handleDescriptionInput,
  } = useContext(FlowBuilderContext);

  if (flowUpdateSuccess) {
    // TODO - check in about logic of updating nodes and if we should even redirect
    return <Redirect to={cleanRoom.flowLanding(crId)} />;
  }

  if (!flow.displayID) {
    return <>loading</>;
  }

  return (
    <Box xs={{ pl: '10px' }}>
      <form onSubmit={handleFlowSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FlowBuilderHeader
              flow={flow}
              onNameChange={handleNameInput}
              onDescriptionChange={handleDescriptionInput}
            />
          </Grid>
        </Grid>
        <FlowRender
          flowId={flowId}
          editMode={editMode}
          isViewMode={isViewMode}
        />
      </form>
      <FlowSidePanel />
    </Box>
  );
};
