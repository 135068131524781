import React, { useCallback } from 'react';
import moment from 'moment';
import { PARAMETER_DATATYPES } from 'pages/Flow/constants';
import { TextField } from '@mui/material';


const HTML_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
const DATATYPE_TO_INPUT_TYPE_MAP = {
  [PARAMETER_DATATYPES.STRING]: 'text',
  [PARAMETER_DATATYPES.DATE]: 'date',
  [PARAMETER_DATATYPES.TIMESTAMP]: 'datetime-local',
  [PARAMETER_DATATYPES.DECIMAL]: 'number',
  [PARAMETER_DATATYPES.INTEGER]: 'number',
  [PARAMETER_DATATYPES.DOUBLE]: 'number',
  [PARAMETER_DATATYPES.LONG]: 'number',
};

export const ParameterInput = ({
  disabledMacroIDs, isEditingDisabled, parameter, onParameterValueChange,
}) => {
  const isMacroEditingDisabled = useCallback((param) => (
    disabledMacroIDs.includes(param?.macro?.ID ?? '')
  ), [disabledMacroIDs]);

  // Special handling for TIMESTAMP
  const parseToInputValue = useCallback(({ type, value }) => {
    if (type === PARAMETER_DATATYPES.TIMESTAMP) {
      return moment.unix(Number(value)).format(HTML_DATETIME_FORMAT);
    }
    // Fallback for all other types
    return value;
  }, []);

  const parseToParameterValue = useCallback(({ type, value }) => {
    if (type === PARAMETER_DATATYPES.TIMESTAMP) {
      return String(moment(value).unix());
    }
    return value;
  }, []);

  const handleParameterValChange = ({ newValue }) => {
    const shouldAllowValueChange = !isEditingDisabled;
    if (shouldAllowValueChange) {
      onParameterValueChange({ ...parameter, value: newValue });
    }
  };

  return (
    <TextField
      fullWidth
      type={DATATYPE_TO_INPUT_TYPE_MAP[parameter.type] ?? 'text'}
      helperText={parameter?.macro?.description ?? ''}
      disabled={isEditingDisabled || isMacroEditingDisabled(parameter)}
      value={parseToInputValue({
        type: parameter.type,
        value: parameter.value,
      })}
      onChange={(e) => {
        if (!isEditingDisabled) {
          handleParameterValChange({
            newValue: parseToParameterValue({
              type: parameter.type,
              value: e.target.value,
            }),
          });
        }
      }}
    />
  );
};
