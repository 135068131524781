import { axiosInstance } from 'utils/http';
import { questionManagement } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import { useState } from 'react';


const useFetchAvailableEntities = (orgID, qIDs) => {
  const dispatch = useDispatch();
  const [data, setData] = useState();

  const { isLoading, isError, isSuccess } = useQuery(
    [],
    async () => axiosInstance.get(questionManagement.availableEntities(orgID, qIDs)),
    {
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
  };
};

const useQuestionsProvision = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const {
    isLoading, isError, isSuccess, mutate, mutateAsync,
  } = useMutation(
    async (payload) => axiosInstance.post(questionManagement.provisionQuestions,
      { provisionRequests: payload }),
    {
      onSuccess: (res) => {
        setData(res.data);
        dispatch(showAlert({ message: 'Question(s) have been provisioned', type: 'success' }));
      },
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    mutate,
    mutateAsync,
  };
};

const useFetchCleanRoomsProvisioned = (orgId, qId) => {
  const dispatch = useDispatch();
  const [data, setData] = useState();

  const {
    isLoading, isError, isSuccess, refetch,
  } = useQuery(
    [],
    async () => axiosInstance.get(questionManagement.crQProvisioned(orgId, qId)),
    {
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.response, type: 'error' })),
    },
  );

  return {
    isLoading, isError, isSuccess, data, refetch,
  };
};

const useFetchQuestionProvisionedEntities = (orgID, qID) => {
  const dispatch = useDispatch();
  const [data, setData] = useState();

  const {
    isLoading, isError, isSuccess, refetch,
  } = useQuery(
    [],
    async () => axiosInstance.get(questionManagement.provisionedQuestionEntities(orgID, qID)),
    {
      onSuccess: (res) => setData(res.data),
      onError: (err) => dispatch(showAlert({ message: err.response.data.response, type: 'error' })),
    },
  );
  return {
    isLoading, isError, isSuccess, data, refetch,
  };
};


export {
  useFetchAvailableEntities,
  useFetchCleanRoomsProvisioned,
  useFetchQuestionProvisionedEntities,
  useQuestionsProvision,
};
