import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const useUpdateTaxonomy = () => {
  const { ID: orgId } = useSelector(activeOrgSelector);
  const dispatch = useDispatch();

  return useMutation(
    async ({ crId, payload }) => axiosInstance.put(cleanRoom(orgId).taxonomy(crId), payload),
    {
      onSuccess: (_, { metadata: { datasetName, fieldNames = [], refreshData } }) => {
        refreshData();

        dispatch(
          showAlert({
            message: `Successfully updated '${datasetName}' dataset taxonomy fields: ${fieldNames.join(
              ', ',
            )}`,
            type: 'success',
          }),
        );
      },
      onError: (err) => {
        dispatch(
          showAlert({
            message: err.response.data.message,
            type: 'error',
          }),
        );
      },
    },
  );
};
