import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { MotfiFullScreenDialogTitle } from 'BaseComponents/MotifFullScreenDialog/MotfiFullScreenDialogTitle';
import { MotifFullScreenDialog } from 'BaseComponents/MotifFullScreenDialog/MotifFullScreenDialog';
import { MotifFullScreenDialogActions } from 'BaseComponents/MotifFullScreenDialog/MotifFullScreenDialogActions';
import { MotifFullScreenDialogContent } from 'BaseComponents/MotifFullScreenDialog/MotifFullScreenDialogContent';
import { PLACEHOLDER_FORMAT } from './constants';
import { getFieldFormats, hasFormat } from './utils';
import { useParams } from 'react-router-dom';
import { useUpdateTaxonomy } from 'api/taxonomy';


export const EditTaxonomyFullScreenDialog = ({
  open, row, onClose, onSuccess,
}) => {
  const { crId } = useParams();
  const { mutate: updateTaxonomy } = useUpdateTaxonomy();
  const [taxonomy, setTaxonomy] = useState(() => ({
    IDs: row?.IDs,
    description: row?.description,
    datasetName: row?.datasetName,
    dataType: row?.dataType,
    fields: row?.datasetTypes || [],
  }));

  useEffect(() => {
    setTaxonomy({
      IDs: row?.IDs,
      description: row?.description,
      datasetName: row?.datasetName,
      dataType: row?.dataType,
      fields: row?.datasetTypes || [],
    });
  }, [row?.IDs, row?.datasetName, row?.dataType, row?.description, row?.datasetTypes]);

  const updateDescription = useCallback(
    (ID, description) => {
      if (ID === taxonomy.IDs?.[0]) {
        return setTaxonomy((prevTax) => ({
          ...prevTax,
          description,
        }));
      }

      return setTaxonomy((prevTax) => ({
        ...prevTax,
        fields: prevTax.fields?.map((field) => {
          if (ID === field.IDs?.[0]) {
            return {
              ...field,
              description,
            };
          }
          return field;
        }),
      }));
    },
    [taxonomy.IDs],
  );

  const updateFormat = useCallback((e) => {
    const format = e.target.value;
    const isPlaceholderSelected = format === PLACEHOLDER_FORMAT.value;

    return setTaxonomy((prevTax) => ({
      ...prevTax,
      fields: prevTax.fields?.map((field) => {
        const fieldHasFormat = hasFormat(field);
        if (fieldHasFormat) {
          return {
            ...field,
            format: isPlaceholderSelected ? '' : format,
          };
        }
        return field;
      }),
    }));
  }, []);

  return (
    <MotifFullScreenDialog
      open={open}
      onClose={onClose}
      anchor='bottom'
      style={{ margin: '6.25rem 0px 0px' }}
    >
      <MotfiFullScreenDialogTitle onClose={onClose}>
        Edit Taxonomy:
        {' '}
        @
        {taxonomy?.datasetName}
      </MotfiFullScreenDialogTitle>
      <MotifFullScreenDialogContent>
        <Box
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 3fr 1fr',
            gridTemplateAreas: '". one ."',
          }}
        >
          <Grid container justifyContent='space-between' spacing={1} gridArea='one'>
            <Grid item xs={12}>
              <Typography variant='h4' mb={2} style={{ fontWeight: 'bold' }}>
                Dataset Macro
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography>Dataset Macro Name</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography>Expected Data Type</Typography>
            </Grid>
            <Grid item xs={5} mb={2}>
              <Typography variant='bold'>
                @
                {taxonomy?.datasetName}
              </Typography>
            </Grid>
            <Grid item xs={7} mb={2}>
              <Typography variant='bold'>{taxonomy?.dataType || '-'}</Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <TextField
                label='Description'
                size='small'
                onChange={(e) => {
                  const description = e.target.value;
                  updateDescription(taxonomy.IDs?.[0], description);
                }}
                value={taxonomy?.description}
                fullWidth
              />
            </Grid>

            {taxonomy.fields.length ? (
              <Grid item xs={12} mt={2} mb={2}>
                <Typography variant='h4'>Field Macros</Typography>
              </Grid>
            ) : null}

            {taxonomy.fields.map((field) => {
              const formats = getFieldFormats(field);

              return (
                <React.Fragment key={field.ID}>
                  <Grid item xs={5} mt={2}>
                    <Typography>Field Macro Name</Typography>
                  </Grid>
                  <Grid item xs={7} mt={2}>
                    <Typography>Expected Data Type</Typography>
                  </Grid>
                  <Grid item xs={5} mb={2}>
                    <Typography variant='bold'>
                      @
                      {field?.field}
                    </Typography>
                  </Grid>
                  <Grid item xs={7} mb={2}>
                    <Typography variant='bold'>{field?.type || '-'}</Typography>
                  </Grid>

                  {!!formats.length && (
                    <Grid item xs={6} mb={2}>
                      <FormControl fullWidth margin='dense' variant='outlined'>
                        <Select
                          label='Expected Format'
                          fullWidth
                          multiline
                          size='small'
                          onChange={updateFormat}
                          value={field?.format || PLACEHOLDER_FORMAT.value}
                        >
                          {formats.map(({ value, label }) => (
                            <MenuItem key={value} value={value}>
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12} mb={2}>
                    <TextField
                      label='Description'
                      size='small'
                      onChange={(e) => {
                        const description = e.target.value;
                        updateDescription(field?.IDs?.[0], description);
                      }}
                      value={field?.description}
                      fullWidth
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </MotifFullScreenDialogContent>
      <MotifFullScreenDialogActions>
        <Button onClick={onClose} color='primary' variant='outlined'>
          Close
        </Button>
        <Button
          onClick={() => {
            const taxonomyPayload = {
              IDs: taxonomy.IDs,
              description: taxonomy.description,
              datasetTypes: taxonomy.fields,
            };
            onClose();
            updateTaxonomy({
              crId,
              payload: taxonomyPayload,
              metadata: {
                datasetName: taxonomy.datasetName,
                fieldNames: taxonomy.fields.map(({ field }) => field),
                refreshData: onSuccess,
              },
            });
          }}
          color='primary'
          variant='contained'
        >
          Save
        </Button>
      </MotifFullScreenDialogActions>
    </MotifFullScreenDialog>
  );
};
