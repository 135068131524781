import NoData from 'components/Common/NoData';
import React, { useCallback } from 'react';
import {
  FormControl, MenuItem, Select, Skeleton, Stack,
} from '@mui/material';
import { HButton } from 'BaseComponents';
import { QUESTION_STATUS } from 'utils/constants/question.constant';
import { question as questionURL } from 'utils/spaUrls';
import { useFetchQuestionVersions } from 'hooks/questionAPIs';
import { useHistory, useParams } from 'react-router-dom';


const QuestionVersionHistorySelect = ({ question, selectedVersion, onVersionChange }) => {
  const history = useHistory();
  const { crId } = useParams();
  const {
    isLoading,
    isError,
    isSuccess,
    data: versions,
  } = useFetchQuestionVersions({ questionId: question?.ID });

  const handleVersionChange = (version) => {
    onVersionChange(version);
  };

  const generateMenuItem = useCallback((version) => (
    <MenuItem
      key={version}
      value={version}
    >
      {question.version === version
        ? `${question?.questionStatus === QUESTION_STATUS.PUBLISHED.key ? 'Published' : 'Draft'} v${version} (current version)`
        : `v${version}`}
    </MenuItem>
  ), [question]);

  const onVersionSelect = () => {
    history.push(
      crId ? questionURL.builder.wizard.cleanRoom(
        question.ID,
        crId,
        '',
        selectedVersion,
      ) : questionURL.builder.wizard.organization(question.ID, selectedVersion),
    );
  };

  if (isLoading) {
    return <Skeleton variant='rounded' width={200} height={36} />;
  }
  else if (isError) {
    return <NoData text='Error while fetching question version history.' />;
  }
  else if (isSuccess) {
    return versions?.length ? (
      <Stack direction='row' justifyContent='space-between'>
        <FormControl style={{ minWidth: 220 }}>
          <Select
            id='question-version-select'
            value={selectedVersion}
            fullWidth
            onChange={e => handleVersionChange(e.target.value)}
          >
            {
              versions.map(v => generateMenuItem(v))
            }
          </Select>
        </FormControl>
        <HButton
          variant='contained'
          data-testid='cancel-button'
          disabled={question.version === selectedVersion}
          onClick={onVersionSelect}
        >
          Use This Version
        </HButton>
      </Stack>
    ) : <NoData />;
  }
  else {
    return null;
  }
};

export default QuestionVersionHistorySelect;
