import QuestionStatus from 'components/CleanRooms/CurrentQuestions/QuestionStatus';
import React, { memo, useContext, useEffect } from 'react';
import { DataContext } from 'BaseComponents/HDataTable/DataContextProvider';
import { usePublishQuestion } from 'hooks/questionAPIs';


export const PublishStatusCell = memo(({ row }) => {
  const {
    refreshListData,
  } = useContext(DataContext);

  const {
    isSuccess: isPublished,
    mutate: publishQuestion,
    reset: resetPublishQuestion,
  } = usePublishQuestion();

  useEffect(() => {
    if (isPublished) {
      resetPublishQuestion();
      refreshListData();
    }
  }, [isPublished, refreshListData, resetPublishQuestion]);

  const handlePublish = (questionId) => {
    publishQuestion({ questionId });
  };

  return (
    <QuestionStatus
      question={row}
      handlePublish={handlePublish}
    />
  );
});

PublishStatusCell.displayName = 'PublishStatusCell';
