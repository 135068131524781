import React, { useContext } from 'react';
import { AllQuestionsContext } from 'components/CleanRooms/AllQuestions/AllQuestionsContextProvider';
import { HButton } from 'BaseComponents';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { addQuestion } from 'redux/actions/CleanRoomsActions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


export const ActionButton = ({ row }) => {
  const dispatch = useDispatch();
  const { crId } = useParams();
  const { ID: orgId } = useSelector(activeOrgSelector);
  const {
    setSubmitted,
  } = useContext(AllQuestionsContext);

  const handleClick = () => {
    setSubmitted(true);
    dispatch(addQuestion(orgId, crId, row.ID));
  };

  return (
    <HButton
      data-testid='use-this-question-button'
      color='primary'
      onClick={handleClick}
      size='small'
      variant='contained'
    >
      Use This Question
    </HButton>
  );
};
