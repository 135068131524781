import React from 'react';
import {
  Box, Skeleton, Stack, Switch, Typography,
} from '@mui/material';


const DisableIdentifierProjectionSwitch = ({
  isLoading,
  isDisableIdentifier,
  setIsDisableIdentifier,
}) => {
  const handleSwitchChange = (event) => {
    setIsDisableIdentifier(event.target.checked);
  };

  if (isLoading) {
    return <Skeleton variant='rectangular' height={50} />;
  }

  return (
    <Stack direction='row'>
      <Box paddingRight={3} flex={1}>
        <Typography variant='subtitle1' mb={1}>
          Disable Identifier Projection
        </Typography>
        <Typography variant='body2'>
          Prevent PII columns from datasets being projected from questions except via COUNT and
          COUNT_DISTINCT analytical functions. This is enabled for all questions by default.
        </Typography>
      </Box>
      <Box>
        <Switch
          checked={isDisableIdentifier}
          onChange={handleSwitchChange}
          inputProps={{ 'aria-label': 'Disable Identifier Projection Switch' }}
        />
      </Box>
    </Stack>
  );
};

export default DisableIdentifierProjectionSwitch;
