import React from 'react';
import clsx from 'clsx';
import { MotifDialogTitle } from 'BaseComponents/MotifFullScreenDialog/MotifDialogTitle';
import { dialogTitleClasses, styled } from '@mui/material';
import { pxToRem } from 'MotifTheme';


const FullScreenDialogTitleStyled = styled(MotifDialogTitle)(({ theme }) => ({
  borderBottom: `${pxToRem(1)} solid ${theme.palette.secondary.light}`,
  padding: `${pxToRem(24)} ${pxToRem(40)} 0 ${pxToRem(24)}`,
  transition: theme.transitions.common?.enteringScreen,
  '&[class*="FullScreenDialogTitle-scrollShadow-"]': {
    boxShadow: theme.elevation1?.boxShadow,
    transition: theme.transitions.common?.leavingScreen,
    zIndex: theme.zIndex.dialog,
  },
  [`& .${dialogTitleClasses.root}`]: {
    paddingBottom: pxToRem(20),
    lineHeight: pxToRem(20),
  },
}));

export const MotfiFullScreenDialogTitle = React.forwardRef(({ className, ...props }, ref) => (
  <FullScreenDialogTitleStyled
    className={clsx('FullScreenDialogTitle-root', className)}
    ref={ref}
    {...props}
  />
));
