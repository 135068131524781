import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import { ParametersList } from './ParametersList';


const getParametersByLevel = (runParameters, level) => (
  runParameters.filter((param) => (
    param.nodeLevel === level
  ))
);

const LevelAccordion = ({
  isEditingDisabled = false,
  level,
  parameters,
  disabledMacroIDs,
  onParameterValueChange,
}) => (
  <Grid item container key={level}>
    <Accordion
      defaultExpanded
      disableGutters
      expandIcon={<ExpandMoreIcon />}
      elevation={0}
      sx={{ width: '100%' }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ backgroundColor: (theme) => theme.palette.secondary.light, height: 40 }}
      >
        <Typography variant='h4Branding'>
          Level
          {' '}
          {level}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        <ParametersList
          isEditingDisabled={isEditingDisabled}
          parameters={parameters}
          disabledMacroIDs={disabledMacroIDs}
          onParameterValueChange={onParameterValueChange}
        />
      </AccordionDetails>
    </Accordion>
  </Grid>
);


export const FlowCachedRunParameters = ({
  levels, selectedStartLevel = 0, selectedPauseLevel, runParameters, onParameterValueChange,
}) => {
  const cachedLevels = useMemo(() => (
    levels.filter((level) => level < selectedStartLevel)
  ), [levels, selectedStartLevel]);
  const runLevels = useMemo(() => (
    levels.filter((level) => (selectedPauseLevel > 0
      ? level >= selectedStartLevel && level < selectedPauseLevel
      : level >= selectedStartLevel))
  ), [levels, selectedStartLevel, selectedPauseLevel]);

  // List of all Macro IDs that's in at least one cached level
  const disabledMacroIDs = useMemo(() => runParameters.reduce((accumulator, parameter) => {
    const doesParameterHaveMacro = parameter.macro !== null;

    if (doesParameterHaveMacro) {
      const isParameterCached = cachedLevels.includes(parameter.nodeLevel);
      const hasMacroBeenAddedAsDisabled = accumulator.includes(parameter.macro.ID);

      if (isParameterCached || hasMacroBeenAddedAsDisabled) {
        return [...accumulator, parameter.macro.ID];
      }
    }
    return accumulator;
  }, []), [runParameters, cachedLevels]);

  return (
    <Grid container height='100%' gap={2} pl='30px'>
      <Grid item direction='column' marginBottom={1}>
        <Typography display='block' variant='h3Branding'>
          Set Parameters
        </Typography>
      </Grid>
      {runLevels.map((level) => (
        <LevelAccordion
          level={level}
          parameters={getParametersByLevel(runParameters, level)}
          disabledMacroIDs={disabledMacroIDs}
          onParameterValueChange={onParameterValueChange}
        />
      ))}
      <Grid item direction='column' marginBottom={1}>
        <Typography display='block' variant='h3Branding'>
          Cached Parameters
        </Typography>
      </Grid>
      {cachedLevels.map((level) => (
        <LevelAccordion
          level={level}
          parameters={getParametersByLevel(runParameters, level)}
          disabledMacroIDs={disabledMacroIDs}
          isEditingDisabled
        />
      ))}
    </Grid>
  );
};
