import GPGHeader from 'pages/GPGKeyManagement/GPGHeader';
import GPGKeyList from 'pages/GPGKeyManagement/GPGKeyList';
import NoData from 'components/Common/NoData';
import PageHeader from 'components/Common/PageHeader';
import React from 'react';
import {
  AddCircleOutline, FileUploadOutlined,
} from '@mui/icons-material';
import {
  Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Skeleton,
  TextField,
} from '@mui/material';
import { CLIENT_GPG_PUBLIC_KEY, HABU_GPG_PUBLIC_KEY } from 'pages/GPGKeyManagement/constant';
import { HButton } from 'BaseComponents';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { decodedBase64, downloadFile, encodeBase64 } from 'utils/jsUtils';
import {
  useDownloadGPGKey, useFetchGPGKey, useGenerateGPGKey, useUploadGPGKey,
} from 'hooks/GPGKeyManagement/gpgKeyManagementAPIs';
import { useSelector } from 'react-redux';


const Loader = () => (
  <Grid container wrap='wrap'>
    <Skeleton height={35} width='100%' />
    <Skeleton variant='rectangular' my={2} height={150} width='100%' />
  </Grid>
);

const ConfirmDialog = ({
  open, dialogTitle, dialogContent, buttons, handleClose,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'
  >
    <DialogTitle id='alert-dialog-title'>
      {dialogTitle}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id='alert-dialog-description' align='justify'>
        {dialogContent}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {buttons}
    </DialogActions>
  </Dialog>
);

// TODO: DV-10680, deprecate GPG Encryption but keep the code for now
const GPGKeyManagement = () => {
  const [openConfirm, setOpenConfirm] = React.useState('');
  const [openUpload, setOpenUpload] = React.useState(false);
  const [clientPublicKey, setClientPublicKey] = React.useState('');
  const [validationError, setValidationError] = React.useState(false);
  const [download, setDownload] = React.useState(false);
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);

  const {
    isLoading: fetching,
    data: {
      habuGPGKey,
      clientGPGKey,
    },
    refetch: fetchGPGKey,
  } = useFetchGPGKey(orgId);

  const {
    isLoading: generatingKey,
    isSuccess: isKeyGenerated,
    mutate: generateKey,
  } = useGenerateGPGKey(orgId);

  const {
    isLoading: uploadingKey,
    isSuccess: isKeyUploaded,
    mutate: uploadKey,
  } = useUploadGPGKey(orgId);

  const {
    isSuccess: isKeyDownloaded,
    isError: isDownloadError,
    data: downloadedKey,
    mutate: downloadGPGKey,
  } = useDownloadGPGKey(orgId);


  const isHabuGPGKeyAvailable = () => Array.isArray(habuGPGKey) && habuGPGKey.length > 0;
  const isClientGPGKeyAvailable = () => Array.isArray(clientGPGKey) && clientGPGKey.length > 0;

  const handleConfirmOpen = (state) => {
    setOpenConfirm(state);
  };
  const handleConfirmClose = () => {
    setOpenConfirm('');
  };

  const handleUploadOpen = () => {
    setOpenUpload(true);
  };

  const handleUploadClose = () => {
    setClientPublicKey('');
    setValidationError(false);
    setOpenUpload(false);
  };

  const onGenerateKey = () => {
    if (isHabuGPGKeyAvailable()) {
      generateKey({ organizationID: orgId, secretID: habuGPGKey[0].secretID });
      handleConfirmClose();
    }
    else {
      generateKey({ organizationID: orgId });
    }
  };

  const onUploadKey = () => {
    if (clientPublicKey && clientPublicKey !== '') {
      const encodedClientPublicKey = encodeBase64(clientPublicKey);
      if (isClientGPGKeyAvailable()) {
        uploadKey({
          organizationID: orgId,
          secretID: clientGPGKey[0].secretID,
          clientPublicKey: encodedClientPublicKey,
        });
        handleUploadClose();
      }
      else {
        uploadKey({
          organizationID: orgId,
          clientPublicKey: encodedClientPublicKey,
        });
        handleUploadClose();
      }
    }
    else {
      setValidationError(true);
    }
  };

  const onDownloadKey = (key) => {
    setDownload(true);
    if (key.source === HABU_GPG_PUBLIC_KEY.source) {
      downloadGPGKey({
        secretID: key.secretID,
        name: HABU_GPG_PUBLIC_KEY.keyName,
      });
    }
    else if (key.source === CLIENT_GPG_PUBLIC_KEY.source) {
      downloadGPGKey({
        secretID: key.secretID,
        name: CLIENT_GPG_PUBLIC_KEY.keyName,
      });
    }
  };

  React.useEffect(() => {
    if (isKeyGenerated) {
      fetchGPGKey();
    }
  }, [fetchGPGKey, isKeyGenerated]);

  React.useEffect(() => {
    if (isKeyUploaded) {
      fetchGPGKey();
    }
  }, [fetchGPGKey, isKeyUploaded]);

  React.useEffect(() => {
    if (isKeyDownloaded && downloadedKey.value && download) {
      const fileData = decodedBase64(downloadedKey.value);
      downloadFile(fileData, 'gpg-key');
      setDownload(false);
    }
    else if (isDownloadError) {
      setDownload(false);
    }
  }, [download, isDownloadError, downloadedKey, isKeyDownloaded]);

  const renderHabuPublicKeyList = () => {
    if (fetching || generatingKey) return <Loader />;
    if (isHabuGPGKeyAvailable()) {
      return <GPGKeyList keys={habuGPGKey} onDownloadKey={onDownloadKey} />;
    }
    return <NoData />;
  };

  const renderClientPublicKeyList = () => {
    if (fetching || uploadingKey) return <Loader />;
    if (isClientGPGKeyAvailable()) {
      return <GPGKeyList keys={clientGPGKey} onDownloadKey={onDownloadKey} />;
    }
    return <NoData />;
  };

  return (
    <>
      <PageHeader title='Manage GPG Key' breadcrumbs={[crumbs.home]} />
      <GPGHeader
        title='Habu GPG Public Key'
        tooltipText={'Files for Data-In can be encrypted using the below Habu\'s public key'}
        button={{
          text: isHabuGPGKeyAvailable() ? 'Regenerate Key' : 'Generate key',
          icon: <AddCircleOutline />,
          onClick: () => {
            if (isHabuGPGKeyAvailable()) {
              handleConfirmOpen('regenerate');
            }
            else {
              onGenerateKey();
            }
          },
        }}
      />
      <Box my={3}>
        { renderHabuPublicKeyList() }
      </Box>
      <GPGHeader
        title='Client GPG Public Key'
        tooltipText={'Files for Data-Out can be encrypted by Habu using the below Client\'s public key'}
        button={{
          text: isClientGPGKeyAvailable() ? 'Reupload Key' : 'Upload Key',
          icon: <FileUploadOutlined />,
          onClick: () => {
            if (isClientGPGKeyAvailable()) {
              handleConfirmOpen('reupload');
            }
            else {
              handleUploadOpen();
            }
          },
        }}
      />
      <Box my={3}>
        { renderClientPublicKeyList() }
      </Box>
      <Dialog
        open={openUpload}
        onClose={handleUploadClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle id='alert-dialog-title' sx={{ pb: 0 }}>
          Upload Key
        </DialogTitle>
        <DialogContent sx={{ paddingTop: '8px !important' }}>
          <TextField
            id='standard-multiline-static'
            label='Key'
            multiline
            rows={12}
            fullWidth
            required
            error={validationError}
            helperText={validationError && 'Not a valid key'}
            value={clientPublicKey}
            onChange={(event) => {
              event.preventDefault();
              setClientPublicKey(event.target.value);
            }}
            inputProps={{
              style: {
                fontSize: '12px',
                fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, monospace, "Courier New"',
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <HButton
            variant='outlined'
            onClick={handleUploadClose}
          >
            Cancel
          </HButton>
          <HButton onClick={onUploadKey} variant='contained' autoFocus>
            Upload
          </HButton>
        </DialogActions>
      </Dialog>
      {
        (openConfirm === 'regenerate')
          && (
          <ConfirmDialog
            open={Boolean(openConfirm)}
            dialogTitle='Regenerate Key'
            dialogContent='If keys are regenerated, Habu will fail to decrypt files encrypted using previous public key.
            Files for Data-In should be encrypted using newly generated public key. Are you sure you want to regenerate key?'
            handleClose={handleConfirmClose}
            buttons={[
              <HButton key='cancel' variant='outlined' onClick={handleConfirmClose}>Cancel</HButton>,
              <HButton key='yes' variant='contained' autoFocus onClick={onGenerateKey}>
                Yes
              </HButton>,
            ]}
          />
          )
      }
      {
        (openConfirm === 'reupload')
        && (
          <ConfirmDialog
            open={Boolean(openConfirm)}
            dialogTitle='Reupload Key'
            dialogContent='For Data-Out going forward Habu will encrypt the files using newly uploaded public key. Are you sure you want to reupload key?'
            handleClose={handleConfirmClose}
            buttons={[
              <HButton key='cancel' variant='outlined' onClick={handleConfirmClose}>Cancel</HButton>,
              <HButton
                key='yes'
                variant='contained'
                autoFocus
                onClick={() => {
                  handleUploadOpen();
                  handleConfirmClose();
                }}
              >
                Yes
              </HButton>,
            ]}
          />
        )
      }
    </>
  );
};

export default GPGKeyManagement;
