import React, {
  createContext,
} from 'react';


export const CurrentQuestionsContext = createContext({});

export const CurrentQuestionsContextProvider = ({
  getDatasetsManagement,
  getActionMenuItems,
  getReportMenuItems,
  getReportBtnTooltipTitle,
  runModal,
  updateStatus,
  children,
  actionButton,
  fetchQuestions,
}) => {
  const contextValue = {
    fetchQuestions,
    getDatasetsManagement,
    getActionMenuItems,
    getReportMenuItems,
    getReportBtnTooltipTitle,
    runModal,
    updateStatus,
    actionButton,
  };

  return (
    <CurrentQuestionsContext.Provider value={contextValue}>
      {children}
    </CurrentQuestionsContext.Provider>
  );
};

