
// This is the structure that react flow uses to render nodes
export const mutateSingleNode = (node) => ({
  id: node.ID,
  cleanRoomQuestionID: node.cleanRoomQuestionID,
  position: {
    x: node.positionX,
    y: node.positionY,
  },
  data: {
    label: node.label,
    level: node.level,
    referenceID: node.referenceID,
    flowID: node.flowID,
    ...node.timeAudit,
    ...node.userAudit,
  },
  type: node.nodeType,
});


export const mutateNodeForAPI = (node, flowID, crId) => ({
  node: {
    ID: node.id,
    flowID,
    label: node.data.label,
    nodeType: node.type,
    referenceID: node.data.referenceID,
    positionX: parseInt(node.position.x, 10),
    positionY: parseInt(node.position.y, 10),
    cleanRoomID: crId,
  },
});
