import React from 'react';
import { Typography as MuiTypography } from '@mui/material';


const bodyTypography = ['body1', 'body2', 'body3', 'body4'];

export const MotifTypography = React.forwardRef(({ variant, ...props }, ref) => {
  // Custom variants need to know which components to semantically map to
  const getComponent = (componentsVariant) => {
    if (componentsVariant === 'h1' || componentsVariant === 'jumbo') return 'h1';
    if (componentsVariant === 'h2' || componentsVariant === 'h2Branding') return 'h2';
    if (componentsVariant === 'h3' || componentsVariant === 'h3Branding') return 'h3';
    if (componentsVariant === 'h4' || componentsVariant === 'h4Branding') return 'h4';
    if (componentsVariant === 'h5') return 'h5';
    if (componentsVariant === 'h6') return 'h6';
    if (
      componentsVariant === 'caption'
      || componentsVariant === 'subtitle1'
      || componentsVariant === 'subtitle2'
      || componentsVariant === 'subtitle3'
      || componentsVariant === 'code1'
      || componentsVariant === 'code2'
    ) return 'span';
    if (componentsVariant && bodyTypography.includes(componentsVariant)) return 'p';
    return 'div';
  };

  return (
    <MuiTypography
      variant={variant}
      component={getComponent(variant)}
      paragraph={variant && bodyTypography.includes(variant)}
      ref={ref}
      data-testid={props['data-testid'] ?? 'typography'}
      {...props}
    />
  );
});
