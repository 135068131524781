import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';


export const useCloneCRQ = () => {
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    async ({
      orgId, crId, qId, payload,
    }) => axiosInstance
      .post(cleanRoom(orgId).cloneQuestion(crId, qId), payload),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Question has been cloned to Intelligence.', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );

  return {
    isLoading,
    isError,
    isSuccess,
    mutate,
    reset,
  };
};
