import AlertBuilder from './pages/Alerts/AlertBuilder';
import Alerts from './pages/Alerts/Alerts';
import AlertsRequest from 'pages/InternalAdmin/organization/pages/AlertsRequest';
import CleanRoomQuestions from 'pages/InternalAdmin/organization/pages/CleanRoomQuestions/CleanRoomQuestions';
import CleanRoomTypes from './pages/CleanRoomTypes/CleanRoomTypes';
import CleanRooms from 'pages/InternalAdmin/organization/pages/CleanRooms/CleanRooms';
import DataSourceAccess
  from 'pages/InternalAdmin/organization/pages/DataIngestion/DataSourceAccess';
import DataTypeAccess
  from 'pages/InternalAdmin/organization/pages/DataIngestion/DataTypeAccess';
import DemoData from 'pages/InternalAdmin/organization/DemoData/DemoData';
import IdentityConfiguration from 'pages/InternalAdmin/organization/pages/IdentityConfiguration';
import List from 'pages/InternalAdmin/organization/pages/ProductAccess/list';
import OrganizationParameters from './pages/OrganizationParameters/OrganizationParameters';
import OrganizationProperties
  from 'pages/InternalAdmin/organization/pages/OrganizationProperties/OrganizationProperties';
import OrganizationPropertyConfiguration
  from 'pages/InternalAdmin/organization/pages/OrganizationPropertyConfiguration/OrganizationPropertyConfiguration';
import OrganizationPropertyDetails
  from 'pages/InternalAdmin/organization/pages/OrganizationPropertyDetails/OrganizationPropertyDetails';
import PrivateRoute from 'components/Common/PrivateRoute';
import RateLimiting from 'pages/InternalAdmin/organization/pages/RateLimiting';
import React from 'react';
import SyntheticDatasets from 'pages/InternalAdmin/organization/pages/SyntheticData/SyntheticDatasets';
import { Switch } from 'react-router-dom';
import { internalAdmin } from 'utils/spaUrls';


const InternalAdminOrganizationRouter = () => (
  <Switch>
    <PrivateRoute
      path={internalAdmin.organization.importDataSourceAccess}
      component={DataSourceAccess}
      exact
    />
    <PrivateRoute
      path={internalAdmin.organization.importDataTypeAccess}
      component={DataTypeAccess}
      exact
    />
    <PrivateRoute
      path={internalAdmin.organization.organizationProductAccess}
      component={List}
      exact
    />
    <PrivateRoute
      path={internalAdmin.organization.organizationProperties}
      component={OrganizationProperties}
      exact
    />
    <PrivateRoute
      path={internalAdmin.organization.organizationPropertyDetails()}
      component={OrganizationPropertyDetails}
      exact
    />
    <PrivateRoute
      path={internalAdmin.organization.organizationPropertyConfigurationDetails()}
      component={OrganizationPropertyConfiguration}
      exact
    />
    <PrivateRoute
      path={internalAdmin.organization.demoData}
      component={DemoData}
      exact
    />
    <PrivateRoute
      path={internalAdmin.organization.syntheticData}
      component={SyntheticDatasets}
      exact
    />
    <PrivateRoute
      path={internalAdmin.organization.organizationParameters}
      component={OrganizationParameters}
      exact
    />
    <PrivateRoute
      path={internalAdmin.organization.cleanRoomTypes}
      component={CleanRoomTypes}
      exact
    />
    <PrivateRoute
      path={internalAdmin.organization.cleanRooms}
      component={CleanRooms}
      exact
    />
    <PrivateRoute
      path={internalAdmin.organization.cleanRoomQuestions}
      component={CleanRoomQuestions}
      exact
    />
    <PrivateRoute
      path={internalAdmin.organization.alerts()}
      component={Alerts}
      exact
    />
    <PrivateRoute
      path={internalAdmin.organization.alertBuilder()}
      component={AlertBuilder}
      exact
    />
    <PrivateRoute
      path={internalAdmin.organization.alertBuilderEdit()}
      component={AlertBuilder}
      exact
    />
    <PrivateRoute
      path={internalAdmin.organization.alertsRequest()}
      component={AlertsRequest}
      exact
    />
    <PrivateRoute
      path={internalAdmin.organization.identityConfiguration}
      component={IdentityConfiguration}
      exact
    />
    <PrivateRoute
      path={internalAdmin.organization.rateLimiting}
      component={RateLimiting}
      exact
    />
  </Switch>
);

export default InternalAdminOrganizationRouter;
