import { LOOKUPS } from 'redux/actions/Types';
import { lookups } from 'utils/urlConstants';


export const receiveContentVertical = payload => ({
  type: LOOKUPS.RECEIVE_CONTENT_VERTICALS,
  payload,
});

export const receiveContentCategories = payload => ({
  type: LOOKUPS.RECEIVE_CONTENT_CATEGORIES,
  payload,
});

export const fetchDomains = orgId => ({
  type: LOOKUPS.FETCH_DOMAINS,
  url: lookups(orgId).domains,
});

export const receiveDomains = payload => ({
  type: LOOKUPS.RECEIVE_DOMAINS,
  payload,
});

export const fetchQuestionTypes = orgId => ({
  type: LOOKUPS.FETCH_QUESTION_TYPES,
  url: lookups(orgId).questionTypes,
});

export const receiveQuestionTypes = payload => ({
  type: LOOKUPS.RECEIVE_QUESTION_TYPES,
  payload,
});

export const fetchPropertyTypes = () => ({
  type: LOOKUPS.FETCH_PROPERTY_TYPES,
  url: lookups().propertyTypes,
});

export const fetchPropertyTypesSuccess = payload => ({
  type: LOOKUPS.FETCH_PROPERTY_TYPES_SUCCESS,
  payload,
});

export const fetchPropertyTypesFailure = payload => ({
  type: LOOKUPS.FETCH_PROPERTY_TYPES_FAILURE,
  payload,
});
