import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const useUpdateCleanRoomDefaultQuestionPermissions = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  return useMutation(
    ({ crId, payload }) => axiosInstance.post(
      cleanRoom(orgId).crDefaultQuestionPermissions(crId),
      payload,
    ),
    {
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};
