import React, {
  createContext, useMemo,
  useState,
} from 'react';
import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { useAddQuestionBulk } from 'api/cleanrooms';
import { useFetchCrCurrentQuestions } from 'hooks/questionAPIs';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


export const AllQuestionsContext = createContext({});

export const AllQuestionsContextProvider = ({ goToCurrentQuestions, children }) => {
  const [submitted, setSubmitted] = useState(false);
  const addQuestionResponse = useSelector(state => state.cleanRooms.addQuestion);

  const [qDetailModal, setQDetailModal] = useState(false);
  const [qDetailsID, setQDetailsID] = useState(null);
  const { crId } = useParams();
  const { ID: orgId } = useSelector(activeOrgSelector);
  const [qDetailModalTab, setQDetailModalTab] = useState('');

  const {
    data: currentQuestions,
  } = useFetchCrCurrentQuestions(orgId, crId);
  const {
    mutate: addQuestionBulkMutate,
  } = useAddQuestionBulk();

  const questionMap = useMemo(
    () => currentQuestions
      .reduce((acc, item) => ({ ...acc, [item.questionID]: item }), {}),
    [currentQuestions],
  );

  const seeQuestionDetails = (qID, defaultTab = '') => {
    setQDetailModalTab(defaultTab);
    setQDetailModal(true);
    setQDetailsID(qID);
  };

  const onClose = () => {
    setQDetailModal(false);
    setQDetailsID(null);
  };

  const handleAddBulkQuestion = (selectedRows) => {
    const questionIDs = Object.keys(selectedRows);
    addQuestionBulkMutate({
      crId,
      questionIDs,
    });
  };

  if (addQuestionResponse?.success && submitted) {
    goToCurrentQuestions();
  }

  const allQuestionsContextValue = {
    questionMap,
    setSubmitted,
    seeQuestionDetails,
    qDetailModal,
    qDetailsID,
    onClose,
    handleAddBulkQuestion,
    qDetailModalTab,
  };

  return (
    <AllQuestionsContext.Provider value={allQuestionsContextValue}>
      {children}
    </AllQuestionsContext.Provider>
  );
};
