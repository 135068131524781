import React from 'react';
import { MotifSidePanel } from 'BaseComponents/MotifSidePanel/MotifSidePanel';
import { MotifSidePanelContent } from 'BaseComponents/MotifSidePanel/MotifSidePanelContent';
import { MotifSidePanelHeader } from 'BaseComponents/MotifSidePanel/MotifSidePanelHeader';
import {
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead as TableHeader,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';


export const UsedInQuestionsSidePanel = ({ open, row, onClose }) => {
  const questionsCount = row?.questions?.length || 1;

  return (
    <MotifSidePanel open={open} onClose={onClose}>
      <MotifSidePanelHeader onClose={onClose}>
        @
        {row?.datasetName}
      </MotifSidePanelHeader>
      <Tabs
        value='0'
        aria-label='side panel tabs'
        style={{ backgroundColor: 'white' }}
      >
        <Tab value='0' label='Clean Room Questions' aria-label='Clean Room Questions' />
      </Tabs>
      <MotifSidePanelContent>
        <Typography>
          This dataset macro used in the following
          {' '}
          {questionsCount}
          {' '}
          question
          {questionsCount === 1 ? '' : 's'}
        </Typography>

        <Table stickyHeader size='small'>
          <TableHeader>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {row?.questions.map((question) => (
              <TableRow key={question.ID}>
                <TableCell>{question.title}</TableCell>
                <TableCell>{question.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </MotifSidePanelContent>
    </MotifSidePanel>
  );
};
