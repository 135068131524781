import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';


export const useGetCrQuestionConfigParams = (cleanRoomID, cleanRoomQuestionID) => {
  const dispatch = useDispatch();
  const { ID: orgId } = useSelector(activeOrgSelector);

  return useQuery(
    ['getCrQuestionConfigParams', cleanRoomID, cleanRoomQuestionID],
    () => axiosInstance.get(cleanRoom(orgId)
      .crQuestionConfigParameters(cleanRoomID, cleanRoomQuestionID)),
    {
      enabled: !!cleanRoomID && !!cleanRoomQuestionID,
      select: res => res.data,
      onError: () => {
        dispatch(showAlert({
          message: 'Error fetching cleanroom question config parameters',
          type: 'error',
        }));
      },
    },
  );
};
