import React, { createContext, useRef } from 'react';
import { Q_PROVISION_LEVEL } from 'utils/constants/question.constant';
import { useFetchCRTQuestionsIdList } from 'components/CleanRooms/TemplateProvisionQuestion/useFetchCRTQuestionsIdList';
import { useParams } from 'react-router-dom';
import { useQuestionsProvision } from 'hooks/questionManagementAPI';


export const TemplateProvisionQuestionContext = createContext({});

export const TemplateProvisionQuestionProvider = ({ children }) => {
  const { crId } = useParams();
  const dataContextRef = useRef();

  const {
    data, mutate: fetchTemplateQuestionsIdList,
  } = useFetchCRTQuestionsIdList(crId);
  const {
    mutateAsync: provisionQuestion,
  } = useQuestionsProvision(Q_PROVISION_LEVEL.CR, crId);

  const handleProvisionQuestion = async (questionId) => {
    await provisionQuestion([
      {
        entityID: crId,
        entityType: Q_PROVISION_LEVEL.CR,
        questionID: questionId,
        addCleanRoomQuestion: true,
        status: 'ACTIVE',
      },
    ]);

    fetchTemplateQuestionsIdList();
  };

  const templateProvisionQuestionContextValue = {
    dataContextRef,
    questionIds: data,
    handleProvisionQuestion,
  };

  return (
    <TemplateProvisionQuestionContext.Provider value={templateProvisionQuestionContextValue}>
      {children}
    </TemplateProvisionQuestionContext.Provider>
  );
};
