import React from 'react';
import { TemplateProvisionQuestionProvider } from 'components/CleanRooms/TemplateProvisionQuestion/TemplateProvisionQuestionProvider';
import { TemplateProvisionQuestionTable } from 'components/CleanRooms/TemplateProvisionQuestion/TemplateProvisionQuestionTable';


export const TemplateProvisionQuestion = () => (
  <TemplateProvisionQuestionProvider>
    <TemplateProvisionQuestionTable />
  </TemplateProvisionQuestionProvider>
);
