import { FIELD_FORMATS, PLACEHOLDER_FORMAT } from './constants';


const isEmail = (field) => field?.match(/email/i) !== null;
const isDate = (type) => type?.match(/date/i) !== null;

export const hasFormat = ({ field, type }) => isEmail(field) || isDate(type);

export const getFieldFormats = ({ field, type }) => {
  if (isEmail(field)) {
    return [PLACEHOLDER_FORMAT, ...FIELD_FORMATS.EMAIL];
  }
  if (isDate(type)) {
    return [PLACEHOLDER_FORMAT, ...FIELD_FORMATS.DATE];
  }

  return [];
};
