import { axiosInstance } from 'utils/http';
import { flow } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';


export const useFetchFlowVersionHistory = ({ flowId, offset = 0, limit = 25 }) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const {
    isLoading, isError, isSuccess, data,
  } = useQuery(
    ['fetch-flow-versions', orgId, flowId, offset, limit],
    async (payload) => axiosInstance.get(flow(orgId).flowVersions(flowId, offset, limit), payload),
    {
      refetchOnWindowFocus: false,
      enabled: !!flowId, // Enable the query only if flowId is truthy
      onSuccess: () => {
        dispatch(showAlert({ message: 'Flow version history fetched successfully', type: 'success' }));
      },
      onError: (error) => dispatch(showAlert({ message: error.response.data.message, type: 'error' })),
    },
  );
  return {
    isLoading, isError, isSuccess, data,
  };
};
