import { axiosInstance } from 'utils/http';
import { flow } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const useSubmitFlowRun = ({ crId, flowId }) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);

  const {
    isLoading, isError, isSuccess, mutate, reset,
  } = useMutation(
    ['submit-flow-run', orgId, crId, flowId],
    async (payload) => axiosInstance.post(flow(orgId).createFlowRun(crId, flowId), payload),
    {
      onSuccess: () => {
        dispatch(showAlert({ message: 'Run has been submitted.', type: 'success' }));
      },
      onError: (error) => dispatch(showAlert({ message: error.response.data.message, type: 'error' })),
    },
  );
  return {
    isLoading, isError, isSuccess, mutate, reset,
  };
};
