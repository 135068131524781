import NoData from 'components/Common/NoData';
import PageHeader from 'components/Common/PageHeader';
import React, { useEffect } from 'react';
import Toast from 'components/Common/Toast';
import { PageSkeleton } from 'BaseComponents/LoadingScreens';
import { Questions } from 'pages/CleanRooms/Questions';
import { SESSION_STORAGE_KEYS } from 'utils/appConstants';
import { VerifiedUser } from '@mui/icons-material';
import { useSelector } from 'react-redux';


export const IdContext = React.createContext({});

export const CrDetailContext = React.createContext({});

const QuestionDetails = (props) => {
  const { crId } = props.match.params;
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const crDetail = useSelector(state => state.cleanRooms.detail);
  const userDetail = useSelector(state => state.cleanRooms.userDetail);

  useEffect(() => {
    if (crDetail) {
      const { ID, ownership, type } = crDetail;
      sessionStorage.setItem(SESSION_STORAGE_KEYS.QB_CR_ID, ID);
      sessionStorage.setItem(SESSION_STORAGE_KEYS.QB_CR_OWNERSHIP, ownership);
      sessionStorage.setItem(SESSION_STORAGE_KEYS.QB_CR_TYPE, type);
    }
  }, [crId, crDetail, userDetail]);

  if (!crDetail) { return <PageSkeleton />; }
  else if (crDetail?.error) {
    return (
      <>
        <NoData />
        <Toast type='error' message={crDetail.error} />
      </>
    );
  }

  return (
    <>
      <PageHeader
        Icon={VerifiedUser}
        title='Questions'
      />
      <CrDetailContext.Provider value={{ crDetail, userDetail }}>
        <IdContext.Provider value={{ orgId, crId }}>
          <Questions />
        </IdContext.Provider>
      </CrDetailContext.Provider>
    </>
  );
};

export default QuestionDetails;
