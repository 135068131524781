import React, { memo } from 'react';
import { CLEAN_ROOM_LOGOS } from 'components/CleanRooms/constants';
import { HAvatarGroup } from 'BaseComponents/HAvatarGroup';


export const CleanRoomTypesCell = memo(({ row }) => (
  <HAvatarGroup
    list={row.cleanRoomTypes.map(cr => ({
      src: CLEAN_ROOM_LOGOS[cr.name]?.logoSrc,
      label: CLEAN_ROOM_LOGOS[cr.name]?.label,
    }))}
  />
));

CleanRoomTypesCell.displayName = 'CleanRoomTypesCell';
