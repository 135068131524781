import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import React from 'react';
import {
  Alert, Dialog, Divider, FormControlLabel, Link, Skeleton, Stack, Switch, Typography,
} from '@mui/material';
import {
  HButton, HDialogActions, HDialogContent, HDialogTitle, HSpinner,
} from 'BaseComponents';
import { cleanRoom } from 'utils/spaUrls';
import {
  cleanRoomQuestionPermissions,
  crqPermissionsAccessLevels,
} from 'components/CleanRooms/constants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  useFetchCrQuestionPermissions, useUpdateCrQuestionPermissions,
} from 'components/CleanRooms/CurrentQuestions/service/CrQuestionPermissionsAPI';


const LoadingSkeleton = () => (
  <Stack
    my={2}
    divider={<Divider />}
    spacing={2}
  >
    {Object.values(cleanRoomQuestionPermissions).map((p) => (
      <Stack key={p.name} direction='row' justifyContent='space-between'>
        <Skeleton variant='text' width='60%' height={40} />
        <Skeleton variant='text' width='10%' height={40} />
      </Stack>
    ))}
  </Stack>
);

const QuestionPermissionsOverridesModal = ({
  open, onCancel, crId, questionID, questionName,
}) => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [permissions, setPermissions] = React.useState([]);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const {
    data: questionPermissions,
    isFetching,
    isError,
  } = useFetchCrQuestionPermissions(orgId, crId, questionID);

  const updatePermissions = useUpdateCrQuestionPermissions();

  React.useEffect(() => {
    if (updatePermissions.isSuccess) {
      onCancel();
      dispatch(showAlert({ message: 'Clean Room Question permissions have been updated.', type: 'success' }));
      updatePermissions.reset();
    }

    if (updatePermissions.isError) {
      dispatch(showAlert({ message: updatePermissions.data.response.data.message, type: 'error' }));
    }
  }, [dispatch, onCancel, updatePermissions]);

  React.useEffect(() => {
    if (!questionPermissions) return;
    const perms = Object.values(cleanRoomQuestionPermissions).map(p => {
      const questionPermission = questionPermissions.find(qp => qp.permission === p.name);
      if (questionPermission) {
        return questionPermission;
      }
      else {
        return { permission: p.name, access: crqPermissionsAccessLevels.QUESTION_OWNER_ONLY };
      }
    });
    setPermissions(perms);
  }, [questionID, questionPermissions]);

  const isPermissionChecked = (permissionName, currentQuestionPermissions) => {
    const perm = currentQuestionPermissions.find(p => p.permission === permissionName);
    return perm?.access === crqPermissionsAccessLevels.ALL_CLEAN_ROOM_PARTNERS;
  };

  const isPermissionDisabled = (permission, currentQuestionPermissions) => {
    if (permission.name !== cleanRoomQuestionPermissions.VIEW_CODE.name) {
      return false;
    }
    const editPermission = currentQuestionPermissions
      .find(p => p.permission === cleanRoomQuestionPermissions.EDIT.name);
    if (editPermission
      && editPermission.access === crqPermissionsAccessLevels.ALL_CLEAN_ROOM_PARTNERS) {
      return true;
    }
    return false;
  };

  const onPermissionsChange = (permissionName, checked) => {
    if (permissionName === cleanRoomQuestionPermissions.EDIT.name && checked) {
      onPermissionsChange(cleanRoomQuestionPermissions.VIEW_CODE.name, checked);
    }
    setPermissions((prevState) => [
      ...prevState.filter(p => p.permission !== permissionName),
      {
        permission: permissionName,
        access: checked
          ? crqPermissionsAccessLevels.ALL_CLEAN_ROOM_PARTNERS
          : crqPermissionsAccessLevels.QUESTION_OWNER_ONLY,
      },
    ]);
  };

  const save = () => {
    setOpenDeleteModal(false);
    updatePermissions.mutate({
      orgId,
      crId,
      crqId: questionID,
      payload: { cleanRoomQuestionPermissions: permissions },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth
      maxWidth='sm'
      aria-labelledby='manage-question-permissions-dialog'
      aria-describedby='manage-question-permissions-dialog'
    >
      <HDialogTitle onClose={onCancel}>
        <Stack gap={1}>
          <Typography variant='h3'>Question Permissions</Typography>
          <Typography variant='body2'>{questionName}</Typography>
        </Stack>
      </HDialogTitle>
      <HDialogContent>
        <Stack gap={1}>
          <Typography variant='body2'>
            Enable or disable how partners interact with questions.
          </Typography>
          <Typography variant='body2' display='inline-flex' gap={0.5}>
            Set Clean Room level permissions in
            <Typography variant='body2'>
              <Link
                href={cleanRoom.cleanRoomPermissions(crId)}
                target='_self'
                rel='noreferrer'
              >
                Parameters and Permissions
              </Link>
              .
            </Typography>
          </Typography>

          {isFetching ? <LoadingSkeleton /> : (
            <Stack
              my={2}
              divider={<Divider />}
              spacing={2}
            >
              {Object.values(cleanRoomQuestionPermissions).map(p => (
                <React.Fragment key={p.name}>
                  <FormControlLabel
                    label={p.displayName}
                    labelPlacement='start'
                    sx={{ justifyContent: 'space-between' }}
                    control={
                      (
                        <Switch
                          checked={isPermissionChecked(p.name, permissions)}
                          onChange={e => onPermissionsChange(p.name, e.target.checked)}
                          disabled={isPermissionDisabled(p, permissions)}
                        />
                      )
                    }
                  />
                </React.Fragment>
              ))}

              {isError && (
                <Alert severity='error'>Something went wrong.</Alert>
              )}
            </Stack>
          )}
        </Stack>
      </HDialogContent>
      <HDialogActions>
        {onCancel ? (
          <HButton variant='outlined' onClick={onCancel} size='small' data-testid='cancel-button'>
            Cancel
          </HButton>
        ) : null}
        <HButton
          variant='contained'
          color='primary'
          size='small'
          onClick={() => setOpenDeleteModal(true)}
          endIcon={updatePermissions.isLoading ? <HSpinner isButton /> : null}
          disabled={updatePermissions.isLoading}
          data-testid='save-button'
        >
          Save
        </HButton>
      </HDialogActions>

      <ConfirmationDialog
        open={openDeleteModal}
        title='Are you sure?'
        message='Question permissions will override clean room permissions.'
        confirmActionLabel='Yes'
        onConfirm={save}
        onCancel={() => setOpenDeleteModal(false)}
      />
    </Dialog>
  );
};

export default QuestionPermissionsOverridesModal;
