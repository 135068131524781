export const CRQ_CONFIG_TYPES = {
  CRQ_CONFIG_PARAMETER: 'crqConfigParameter',
  CRQ_DETAIL: 'crqDetail',
};

export const CRQ_CONFIG_PARAMS = [
  'QUERY_FEDERATION',
  'DATASET_MATERIALIZATION',
  'DISABLE_IDENTIFIER_PROJECTION',
  'RUN_CONFIG_OVERRIDES',
  'RUN_CONNECTOR',
  'STORAGE_OVERRIDES',
];

export const getCrqConfigs = (qDetails, crqConfigParams) => [
  {
    name: 'title',
    value: qDetails?.title,
    type: CRQ_CONFIG_TYPES.CRQ_DETAIL,
  },
  {
    name: 'computeCapacity',
    value: qDetails?.computeCapacity,
    type: CRQ_CONFIG_TYPES.CRQ_DETAIL,
  },
  {
    name: 'category',
    value: qDetails?.category,
    type: CRQ_CONFIG_TYPES.CRQ_DETAIL,
  },
  ...(crqConfigParams?.crqConfigParameters?.map(param => ({
    name: param.parameterName,
    value: param.parameterValue,
    type: CRQ_CONFIG_TYPES.CRQ_CONFIG_PARAMETER,
  })) || []),
];
