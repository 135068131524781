import * as yup from 'yup';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { HButton } from 'BaseComponents';
import {
  HDialog, HDialogActions, HDialogContent, HDialogTitle,
} from 'BaseComponents/HDialog';
import { useCreateRateLimitConfig } from 'api/internalAdmin/rateLimiting';
import { yupResolver } from '@hookform/resolvers/yup';


const schema = yup.object().shape({
  type: yup.string().required('Type is required'),
  key: yup.string().required('API Key is required'),
  method: yup.string().required('Method is required'),
  replenishRate: yup
    .number()
    .required('Replenish Rate is required')
    .min(1, 'Minimum value is 1')
    .max(100, 'Maximum value is 100'),
  burstCapacity: yup
    .number()
    .required('Burst Capacity is required')
    .min(1, 'Minimum value is 1')
    .max(100, 'Maximum value is 100')
    .test('is-greater', 'Burst Capacity must be greater than or equal to Replenish Rate', function isGreater(value) {
      const { replenishRate } = this.parent;
      return value >= replenishRate;
    }),
});

const AddRateLimitingConfigDialog = ({ open, onClose, initialValues }) => {
  const {
    handleSubmit, control, formState: { errors }, reset,
  } = useForm({
    defaultValues: initialValues || {
      strategy: 'PATH',
      type: 'API',
      method: 'GET',
      key: '',
      replenishRate: 10,
      burstCapacity: 10,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [initialValues, reset]);

  const { mutateAsync: createRateLimitConfig } = useCreateRateLimitConfig();

  const onSubmit = async (values) => {
    await createRateLimitConfig(values);
    onClose();
  };

  return (
    <HDialog maxWidth='xs' open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HDialogTitle>
          {initialValues ? 'Edit' : 'Add'}
          {' '}
          Rate Limiting Configuration
        </HDialogTitle>
        <HDialogContent>
          <FormControl fullWidth margin='normal' error={!!errors.type}>
            <InputLabel>Type</InputLabel>
            <Controller
              name='type'
              control={control}
              render={({ field }) => (
                <Select {...field} fullWidth>
                  <MenuItem value='API'>API</MenuItem>
                  <MenuItem value='REGEX'>REGEX</MenuItem>
                </Select>
              )}
            />
            {errors.type && <FormHelperText>{errors.type.message}</FormHelperText>}
          </FormControl>

          <FormControl fullWidth margin='normal' error={!!errors.key}>
            <Controller
              name='key'
              control={control}
              render={({ field }) => (
                <TextField {...field} fullWidth label='API Key' />
              )}
            />
            {errors.key && <FormHelperText>{errors.key.message}</FormHelperText>}
          </FormControl>

          <FormControl fullWidth margin='normal' error={!!errors.method}>
            <InputLabel>Method</InputLabel>
            <Controller
              name='method'
              control={control}
              render={({ field }) => (
                <Select {...field} fullWidth>
                  <MenuItem value='GET'>GET</MenuItem>
                  <MenuItem value='POST'>POST</MenuItem>
                  <MenuItem value='PUT'>PUT</MenuItem>
                  <MenuItem value='PATCH'>PATCH</MenuItem>
                  <MenuItem value='DELETE'>DELETE</MenuItem>
                </Select>
              )}
            />
            {errors.method && <FormHelperText>{errors.method.message}</FormHelperText>}
          </FormControl>

          <FormControl fullWidth margin='normal' error={!!errors.replenishRate}>
            <Controller
              name='replenishRate'
              control={control}
              render={({ field }) => (
                <TextField {...field} fullWidth label='Replenish Rate' type='number' />
              )}
            />
            {errors.replenishRate
              && <FormHelperText>{errors.replenishRate.message}</FormHelperText>}
          </FormControl>

          <FormControl fullWidth margin='normal' error={!!errors.burstCapacity}>
            <Controller
              name='burstCapacity'
              control={control}
              render={({ field }) => (
                <TextField {...field} fullWidth label='Burst Capacity' type='number' />
              )}
            />
            {errors.burstCapacity
              && <FormHelperText>{errors.burstCapacity.message}</FormHelperText>}
          </FormControl>
        </HDialogContent>

        <HDialogActions>
          <HButton onClick={onClose} color='primary'>Cancel</HButton>
          <HButton variant='contained' type='submit' color='primary'>Submit</HButton>
        </HDialogActions>
      </form>
    </HDialog>
  );
};

export default AddRateLimitingConfigDialog;
