import AuthenticationSaga from 'redux/saga/AuthenticationSaga';
import CleanRoomsSaga from 'redux/saga/CleanRoomsSaga';
import DataCredentialManagementSaga from 'redux/saga/DataCredentialSaga';
import DataInAttrsSaga from 'redux/saga/DataInAttrsSaga';
import DataInSaga from 'redux/saga/DataInSaga';
import DataLocationSaga from 'redux/saga/DataLocationSaga';
import DataOutSaga from 'redux/saga/DataOutSaga';
import DownloadSaga from 'redux/saga/DownloadSaga';
import ExperimentSaga from 'redux/saga/ExperimentSaga';
import FlowsSaga from 'redux/saga/FlowsSaga';
import FullViewAutomationSaga from 'redux/saga/FullViewAutomationSaga';
import GenericSaga from 'redux/saga/GenericSaga';
import IamSaga from 'redux/saga/IamSaga';
import InternalAdminSaga from 'pages/InternalAdmin/redux/saga';
import JoinedDatasetSaga from 'ProjectHelium/JoinedDataSet/redux/saga';
import LookupSaga from 'redux/saga/LookupsSaga';
import PropertiesSaga from 'redux/saga/PropertiesSaga';
import QuestionBuilderSaga from 'redux/saga/QuestionBuilderSaga';
import UserListsSaga from 'redux/saga/UserListsSaga';
import { DataConnectionsSaga } from 'ProjectHelium/DataConnections/redux';
import { all, fork } from 'redux-saga/effects';
import { crPartnersSaga } from 'pages/CleanRooms/redux/CrPartners';
import { crUserListSaga } from 'pages/CleanRooms/redux/CrUserList';
import { demoDataSaga } from 'pages/InternalAdmin/organization/DemoData/redux';
import { questionManagementSaga } from 'pages/QuestionManagement/redux';
import { syntheticDatasetsSaga } from 'pages/InternalAdmin/organization/redux/SyntheticDatasets';


const sagas = {
  AuthenticationSaga,
  CleanRoomsSaga,
  crPartnersSaga,
  crUserListSaga,
  DataConnectionsSaga,
  DataCredentialManagementSaga,
  DataInAttrsSaga,
  DataInSaga,
  DataLocationSaga,
  DataOutSaga,
  demoDataSaga,
  DownloadSaga,
  ExperimentSaga,
  FullViewAutomationSaga,
  FlowsSaga,
  GenericSaga,
  IamSaga,
  InternalAdminSaga,
  JoinedDatasetSaga,
  LookupSaga,
  PropertiesSaga,
  questionManagementSaga,
  syntheticDatasetsSaga,
  UserListsSaga,
  QuestionBuilderSaga,
};

export default function* RootSaga() {
  yield all(Object.values(sagas).map(saga => fork(saga)));
}
