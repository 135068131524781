import { CleanRoomQuestionConfigParameters } from 'components/CleanRooms/constants';
import {
  useCreateCrQuestionConfigParams,
  useFetchCrQuestionFederatedQuery,
  useUpdateCrQuestionConfigParams,
} from 'api/cleanrooms';
import { useEffect, useState } from 'react';


const useFederatedQuery = ({ crId, crqId, enabled }) => {
  const [isFederatedQuery, setIsFederatedQuery] = useState(false);

  const {
    isFetching: isFQFetching,
    isSuccess,
    data,
  } = useFetchCrQuestionFederatedQuery(
    crId, crqId, enabled,
  );

  const {
    isLoading: isFQCreating,
    isSuccess: isFQCreated,
    mutate: createParams,
  } = useCreateCrQuestionConfigParams(
    crId, CleanRoomQuestionConfigParameters.QUERY_FEDERATION,
  );

  const {
    isLoading: isFQUpdating,
    isSuccess: isFQUpdated,
    mutate: updateParams,
  } = useUpdateCrQuestionConfigParams(
    crId, CleanRoomQuestionConfigParameters.QUERY_FEDERATION,
  );

  useEffect(() => {
    if (isSuccess && data) {
      setIsFederatedQuery(data.federatedQuery);
    }
  }, [isSuccess, data]);

  const saveFederatedQuery = () => {
    const payload = {
      cleanRoomID: crId,
      cleanRoomQuestionID: crqId,
      parameterName: CleanRoomQuestionConfigParameters.QUERY_FEDERATION,
      parameterValue: isFederatedQuery.toString(),
    };
    if (data.isCleanRoomQuestionParameterValue) {
      updateParams(payload);
    }
    else {
      createParams(payload);
    }
  };

  return {
    isFQFetching,
    isFQCreating,
    isFQUpdating,
    isFQCreated,
    isFQUpdated,
    isFederatedQuery,
    setIsFederatedQuery,
    saveFederatedQuery,
  };
};

export default useFederatedQuery;
