import LoadingButton from '@mui/lab/LoadingButton';
import React from 'react';
import { Divider, Typography } from '@mui/material';
import {
  HButton, HDialog, HDialogActions, HDialogContent, HDialogTitle,
} from 'BaseComponents';
import { useDeleteAssets } from 'api/questions/useDeleteAssets';


const DeleteAssetModal = ({
  crId, question, dataContextRef, onClose,
}) => {
  const {
    mutateAsync: deleteAssets,
    isLoading: isDeletingAssets,
  } = useDeleteAssets();

  // Handle modal close
  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const handleConfirm = async () => {
    await deleteAssets({
      cleanRoomId: crId,
      questionId: question.ID,
    });
    dataContextRef.current.refreshListData();
    handleClose();
  };

  return (
    <HDialog
      fullWidth
      maxWidth='xs'
      open
      onClose={handleClose}
      data-testid='select-rampId-modal'
    >
      <HDialogTitle onClose={handleClose}>
        <Typography variant='h5'>Are you sure you want to stop adding Assets from this question?</Typography>
      </HDialogTitle>
      <Divider />
      <HDialogContent>
        All tables generated after this change will not be visible in the Assets for this question.
      </HDialogContent>
      <Divider />
      <HDialogActions sx={{ my: 2 }}>
        <HButton
          variant='text'
          data-testid='cancel-button'
          onClick={handleClose}
        >
          Cancel
        </HButton>
        <LoadingButton
          loading={isDeletingAssets}
          variant='contained'
          color='primary'
          data-testid='cancel-button'
          onClick={handleConfirm}
        >
          Confirm
        </LoadingButton>
      </HDialogActions>
    </HDialog>
  );
};

export default DeleteAssetModal;
