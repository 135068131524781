import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';


export const EllipsisTypography = styled(
  Typography,
  { shouldForwardProp: prop => prop !== 'webkitLineClamp' },
)(
  ({ webkitLineClamp = 1 }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: webkitLineClamp,
    WebkitBoxOrient: 'vertical',
  }),
);

export const GreyChip = styled(Chip)({
  fontWeight: 'bolder',
  borderRadius: '8px',
});
