import React from 'react';
import clsx from 'clsx';
import { Dialog as MuiDialog, Slide } from '@mui/material';


export const SlideTransition = React.forwardRef((props, ref) => (
  <Slide direction='up' ref={ref} {...props} />
));

// This component does not share a base with Dialog because they do not share
// default props, however, because we want full screen dialogs to look consistent,
// the styling for this has already been defined under components/Dialog/theme.js
//
// Other FullScreenDialog components re-export and style themselves in
// components/FullScreenDialog
export const MotifFullScreenDialog = React.forwardRef(({ className, ...props }, ref) => (
  <MuiDialog
    className={clsx('FullScreenDialog-root', className)}
    fullScreen
    maxWidth={false}
    ref={ref}
    TransitionComponent={SlideTransition}
    data-testid={props['data-testid'] ?? 'FullScreenDialog'}
    {...props}
  />
));
