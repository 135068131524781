import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import ListFilters from 'components/Common/ListFilters';
import ListViewTable from 'components/Common/ListViewTable';
import PageHeader from 'components/Common/PageHeader';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import TableContainer from '@mui/material/TableContainer';
import moment from 'moment/moment';
import {
  Autocomplete,
  Box, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select,
  Table, TableBody, TableCell, TableHead, TableRow, TextField,
} from '@mui/material';
import {
  CRQ_CONFIG_PARAMS,
  CRQ_CONFIG_TYPES,
  getCrqConfigs,
} from 'pages/InternalAdmin/organization/pages/CleanRoomQuestions/getCrqConfigs';
import { FilterList } from '@mui/icons-material';
import { HButton, HTablePagination } from 'BaseComponents';
import {
  TagsCell,
} from 'components/CleanRooms/CurrentQuestions/QuestionsTableLayout/TableCellComponents';
import { TypeCell } from 'pages/QuestionManagement/CellComponents';
import { US_DATE_FORMAT } from 'utils/appConstants';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import { debounce } from 'lodash';
import { getFilterFromQueryParams } from 'utils/filterUtils';
import { useCreateOrUpdateCrqConfigParams } from 'api/cleanrooms/useCreateOrUpdateCrqConfigParams';
import { useDeleteCrqConfigParam } from 'api/cleanrooms/useDeleteCrqConfigParam';
import { useFetchCleanRoomList } from 'hooks/cleanRoomAPIs';
import {
  useFetchCleanRoomQuestionFilters,
  useFetchCleanRoomQuestionList, useFetchQuestion,
} from 'hooks/questionAPIs';
import { useGetCrQuestionConfigParams } from 'api/cleanrooms/useGetCrQuestionConfigParams';
import { useQuery } from 'utils/jsUtils';
import { useSearchUrl } from 'BaseComponents/HDataTable/utils';
import { useSelector } from 'react-redux';


const getFilter = (query, defaultFilter, activeQuery) => {
  const filtersObject = getFilterFromQueryParams(query, activeQuery);
  return {
    ...filtersObject,
    filters: [...filtersObject.filters, ...defaultFilter],
  };
};

function Row({
  row, open, handleOnClick, configList, handleDelete, handleEdit, handleAddNewConfig,
  handleOnChange, selectedConfig, disabled, isFetchingConfigList, isUpsertParamsSuccess,
}) {
  const [newConfig, setNewConfig] = useState({ name: '', value: '' });
  const [deleteConfigName, setDeleteConfigName] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    if (isUpsertParamsSuccess) {
      setNewConfig({ name: '', value: '' });
    }
  }, [isUpsertParamsSuccess]);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={handleOnClick}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {row.status}
        </TableCell>
        <TableCell align='right'>{row.displayID}</TableCell>
        <TableCell align='right'>{row.title}</TableCell>
        <TableCell align='right'><TypeCell row={row} /></TableCell>
        <TableCell align='right'>{row.category}</TableCell>
        <TableCell align='right'><TagsCell row={row} /></TableCell>
        <TableCell align='right'>{row.questionStatus}</TableCell>
        <TableCell align='right'>{moment.utc(row.timeAudit?.createdAt).format(US_DATE_FORMAT)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1, width: '100%' }}>
              <ListViewTable list={!isFetchingConfigList && configList} skeletonHeight={350}>
                <Table size='small' aria-label='clean room config'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Configuration</TableCell>
                      <TableCell>Value</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {configList.map((config) => (
                      <TableRow key={config.name}>
                        <TableCell component='th' scope='row'>
                          {config.name}
                        </TableCell>
                        <TableCell>
                          {selectedConfig?.name === config?.name ? (
                            <TextField
                              type='text'
                              value={selectedConfig.value}
                              onChange={(e) => handleOnChange(e.target.value)}
                              disabled={disabled}
                            />
                          ) : (
                            config.value
                          )}
                        </TableCell>
                        <TableCell>
                          {config.type === CRQ_CONFIG_TYPES.CRQ_CONFIG_PARAMETER
                            && (
                            <>
                              <Button
                                onClick={() => handleEdit(config)}
                                disabled={disabled && selectedConfig?.name === config?.name}
                              >
                                {selectedConfig?.name === config?.name ? 'Save' : 'Edit'}
                              </Button>
                              <Button
                                onClick={() => {
                                  setOpenDeleteDialog(true);
                                  setDeleteConfigName(config?.name);
                                }}
                                disabled={disabled && selectedConfig?.name === config?.name}
                              >
                                Delete
                              </Button>
                            </>
                            )}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell component='th' scope='row'>
                        <Select
                          value={newConfig.name}
                          onChange={(e) => setNewConfig({ ...newConfig, name: e.target.value })}
                          disabled={disabled}
                          displayEmpty
                        >
                          <MenuItem value='' disabled>
                            Config Name
                          </MenuItem>
                          {CRQ_CONFIG_PARAMS.map((item) => (
                            <MenuItem
                              key={item}
                              value={item}
                              disabled={configList.some(config => config.name === item)}
                            >
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <TextField
                          type='text'
                          placeholder='Config Value'
                          value={newConfig.value}
                          onChange={(e) => setNewConfig({ ...newConfig, value: e.target.value })}
                          disabled={disabled}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => handleAddNewConfig(newConfig)}
                          disabled={disabled || newConfig.name === '' || newConfig.value === ''}
                        >
                          Add
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </ListViewTable>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        componentsProps={{
          backdrop: {
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        }}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete
            {' '}
            {deleteConfigName}
            {' '}
            config?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDelete(deleteConfigName);
              setOpenDeleteDialog(false);
            }}
            variant='contained'
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const CleanRoomQuestions = () => {
  const updateQueryUrl = useSearchUrl(true);
  const query = useQuery();
  const [filters, setFilters] = useState(getFilter(query, [], true));
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const [crId, setCrId] = useState(null);
  const [crqId, setCrqId] = useState(null);
  const [questionId, setQuestionId] = useState(null);
  const [openRowId, setOpenRowId] = useState(null);
  const [selectedConfig, setSelectedConfig] = useState({});
  const [showFilters, toggleShowFilters] = React.useState(false);

  const {
    data: crList,
    isSuccess: isCrListSuccess,
  } = useFetchCleanRoomList(orgId);

  const {
    data: crqList,
    isLoading: isCrqListLoading,
    isFetching: isCrqListFetching,
    mutate: mutateCrqList,
  } = useFetchCleanRoomQuestionList(crId);

  const {
    data: dataFilters,
    isLoading: isFilterLoading,
    isFetching: isFilterFetching,
  } = useFetchCleanRoomQuestionFilters(crId);

  const {
    data: questionDetails,
    isFetching: isFetchingQuestionDetails,
  } = useFetchQuestion(questionId, crId);

  const {
    data: crqConfigParams,
    isFetching: crqConfigParamsFetching,
  } = useGetCrQuestionConfigParams(crId, crqId);

  const {
    isLoading: isUpsertParamsLoading,
    isSuccess: isUpsertParamsSuccess,
    mutate: upsertParams,
  } = useCreateOrUpdateCrqConfigParams(crId, crqId);

  const {
    isLoading: isDeleteLoading,
    mutateAsync: deleteCrqParamAsync,
  } = useDeleteCrqConfigParam(crId, crqId);

  const configList = getCrqConfigs(questionDetails, crqConfigParams);

  useEffect(() => {
    if (crId) {
      mutateCrqList();
    }
  }, [crId, mutateCrqList]);

  useEffect(() => {
    if (isCrListSuccess) {
      setCrId(crList[0]?.ID);
    }
  }, [crList, isCrListSuccess]);

  const handleDelete = async (paramName) => {
    await deleteCrqParamAsync({ paramName });
  };

  const handleAddNewConfig = (param) => {
    const payload = {
      cleanRoomID: crId,
      organizationID: orgId,
      cleanRoomQuestionID: crqId,
      parameters: [
        {
          parameterName: param.name,
          parameterValue: param.value,
        },
      ],
    };
    upsertParams(payload);
  };


  const handleEdit = (config) => {
    if (config?.name === selectedConfig?.name) {
      const payload = {
        organizationID: orgId,
        cleanRoomID: crId,
        cleanRoomQuestionID: crqId,
        parameters: [
          {
            parameterName: selectedConfig.name,
            parameterValue: selectedConfig.value,
          },
        ],
      };
      upsertParams(payload, { onSuccess: () => setSelectedConfig({}) });
    }
    else {
      setSelectedConfig(config);
    }
  };

  const handleOnChange = val => {
    setSelectedConfig({ ...selectedConfig, value: val });
  };

  const handlePaginationChange = (newFilters) => {
    mutateCrqList(newFilters);
    setFilters(newFilters);

    updateQueryUrl(newFilters);
  };

  const handleFilterChange = (newFilters) => {
    const updatedFilters = {
      ...newFilters,
      offset: 0,
    };
    setFilters(updatedFilters);
    mutateCrqList(updatedFilters);

    updateQueryUrl(updatedFilters);
  };

  const debouncedUpdateSearch = useMemo(() => debounce(mutateCrqList, 500), [mutateCrqList]);

  const handleSearchChange = useCallback(
    (attribute, search) => {
      const newFilters = {
        ...filters,
        offset: 0,
        search,
      };

      updateQueryUrl(newFilters);

      setFilters(newFilters);

      debouncedUpdateSearch(newFilters);
    },
    [debouncedUpdateSearch, filters, updateQueryUrl],
  );


  return (
    <>
      <PageHeader title='Manage Clean Room Questions' breadcrumbs={[crumbs.home, crumbs.internalAdmin]} />
      <Card>
        <CardContent>
          <Box display='flex' gap={1} mb={2} justifyContent='space-between'>
            <Autocomplete
              sx={{ width: '40%', padding: 0 }}
              options={crList || []}
              getOptionLabel={(option) => option.name}
              value={crList?.find((cr) => cr.ID === crId) || null}
              onChange={(event, newValue) => setCrId(newValue ? newValue.ID : '')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder='Search Clean Room'
                  inputProps={{
                    ...params.inputProps,
                    'aria-label': 'Select Clean Room',
                  }}
                />
              )}
            />
            <HButton
              onClick={() => toggleShowFilters(!showFilters)}
              size='small'
              variant='outlined'
            >
              <FilterList fontSize='small' style={{ marginRight: '5px' }} />
              Filters & Search
            </HButton>
          </Box>
          {showFilters
        && (
        <ListFilters
          allFilters={dataFilters}
          appliedFilters={filters}
          onChange={handleFilterChange}
          searchAttribute
          onSearch={handleSearchChange}
          searchText={filters.search}
        />
        )}
          <ListViewTable
            list={!(isCrqListLoading && isFilterLoading || isCrqListFetching && isFilterFetching)
          && crqList.questions}
            skeletonHeight={350}
          >
            <TableContainer>
              <Table aria-label='collapsible table'>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell width='18%'>Status</TableCell>
                    <TableCell align='right'>Display ID</TableCell>
                    <TableCell align='right'>Name</TableCell>
                    <TableCell align='right'>Type</TableCell>
                    <TableCell align='right'>Category</TableCell>
                    <TableCell align='right'>Tags</TableCell>
                    <TableCell align='right'>Publish Status</TableCell>
                    <TableCell align='right'>Created On</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {crqList.questions?.map((row) => (
                    <Row
                      key={row.ID}
                      row={row}
                      setCrId={setCrId}
                      open={openRowId === row.ID}
                      handleOnClick={() => {
                        setCrqId(row.ID);
                        setQuestionId(row.questionID);
                        setOpenRowId(openRowId === row.ID ? null : row.ID);
                      }}
                      configList={configList}
                      handleDelete={handleDelete}
                      handleOnChange={handleOnChange}
                      selectedConfig={selectedConfig}
                      handleEdit={handleEdit}
                      handleAddNewConfig={handleAddNewConfig}
                      disabled={isDeleteLoading || isUpsertParamsLoading}
                      isFetchingConfigList={isFetchingQuestionDetails || crqConfigParamsFetching}
                      isUpsertParamsSuccess={isUpsertParamsSuccess}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <HTablePagination
              count={crqList.count}
              filters={filters}
              onChange={handlePaginationChange}
            />
          </ListViewTable>
        </CardContent>
      </Card>
    </>
  );
};

export default CleanRoomQuestions;
