import { axiosInstance } from 'utils/http';
import { question } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';


export const useDeleteAssets = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  return useMutation(
    ({
      cleanRoomId, questionId,
    }) => axiosInstance.delete(question(orgId).deleteAssets(cleanRoomId, questionId)),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Successfully disabled segmentation', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};
