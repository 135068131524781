import CardLoader from 'components/CleanRooms/RecommendedQuestions/CardLoader';
import Grid from '@mui/material/Grid';
import NoData from 'components/Common/NoData';
import React from 'react';
import RecommendedQuestionCard from 'components/CleanRooms/RecommendedQuestions/RecommendedQuestionCard';
import { qmEntityType } from 'components/QuestionManagement/constants';
import {
  useFetchCleanRoomAvailableQuestionList,
  useRemoveQuestion,
  useRequestQuestion,
} from 'hooks/questionAPIs';
import { useParams } from 'react-router-dom';


const RecommendedQuestions = () => {
  const { crId } = useParams();

  const {
    data: { questions, count },
    mutate: fetchQuestionList,
    isLoading,
    isError,
  } = useFetchCleanRoomAvailableQuestionList(crId);

  const { mutate: requestQuestion } = useRequestQuestion(crId);
  const { mutate: removeQuestion } = useRemoveQuestion(qmEntityType.CLEAN_ROOM.key, crId);


  const fetchQuestions = React.useCallback(() => {
    fetchQuestionList({ isSuggested: true });
  }, [fetchQuestionList]);

  React.useEffect(() => {
    fetchQuestions();
  }, [fetchQuestions]);

  if (isLoading) {
    return <CardLoader />;
  }

  if (isError) {
    return <NoData text='Error in Fetching Questions' />;
  }

  if (count === 0) {
    return <NoData />;
  }


  return (
    <Grid container spacing={2}>
      {
        (questions || []).map((question) => (
          <Grid item xs={12} md={6} lg={4} xl={3} key={question.ID}>
            <RecommendedQuestionCard
              question={question}
              requestQuestion={requestQuestion}
              removeQuestion={removeQuestion}
              fetchQuestions={fetchQuestions}
            />
          </Grid>
        ))
      }
    </Grid>
  );
};

export default RecommendedQuestions;
