import * as spaUrls from 'utils/spaUrls';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import ConfirmationDialog from 'components/Common/ConfirmationDialog';
import CredentialsKeysModal
  from 'pages/OrganizationManagement/organization/pages/OrganizationCredentails/CredentialsKeysModal';
import GenerateCredentialsModal, { MODE_ACTIVATE, MODE_REGENERATE } from 'pages/OrganizationManagement/organization/pages/OrganizationCredentails/GenerateCredentialsModal';
import Grid from '@mui/material/Grid';
import ListViewTable from 'components/Common/ListViewTable';
import OrgCredentialModal from 'components/DataCredential/OrgCredentialModal';
import OrganizationCredentialsTable from 'pages/OrganizationManagement/organization/pages/OrganizationCredentails/OrganizationCredentialsTable';
import PageHeader from 'components/Common/PageHeader';
import React, { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import { ListControls } from 'components/Common/ListControls';
import { crumbs } from 'components/Common/BreadcrumbsNavigation';
import {
  deleteOrgCredentialSource,
  fetchOrgDataCredentialList,
} from 'redux/actions/DataCredentialManagementAction';
import { showAlert } from 'redux/actions/AlertActions';
import { useCreateDataKeys } from 'hooks/OrganizationManagement/credentialAPI';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';


const isViewSourceVisible = (source) => [
  'AWS S3 Credential',
  'Snowflake S3 Role',
  'Snowflake GCS',
  'Snowflake Azure',
].includes(source);

const OrganizationCredentials = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.session.user.primaryOrganization.organization.ID);
  const meta = useSelector(state => state.dataCredential.meta);
  const dataCredentialList = useSelector(state => state.dataCredential.list);
  const [openGenerateModal, toggleOpenGenerateModal] = React.useState(false);
  const [openKeysModal, toggleKeysModal] = React.useState(false);
  const [openCredDetailsModal, setOpenCredDetailsModal] = useState(false);
  const [credDetailsID, setCredDetailsID] = useState('');
  const [currentCredential, setCurrentCredential] = React.useState('');
  const [generateMode, setGenerateMode] = React.useState(MODE_ACTIVATE);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [deleteOrgCredID, setDeleteOrgCredID] = React.useState(null);
  const history = useHistory();

  const {
    data, isLoading, isSuccess, mutate: createDataKeys,
  } = useCreateDataKeys();


  React.useEffect(() => {
    dispatch(fetchOrgDataCredentialList(orgId, { client: 'topgallant' }));
  }, [dispatch, orgId]);

  React.useEffect(() => {
    if (meta.message) {
      dispatch(showAlert({
        message: meta.message,
        type: meta.refresh ? 'success' : 'error',
      }));
    }
  }, [dispatch, meta, meta.message, meta.refresh]);

  useEffect(() => {
    if (isSuccess) {
      toggleOpenGenerateModal(false);
      toggleKeysModal(isSuccess);
    }
  }, [isSuccess]);

  const handleOpen = (credentialSourceID, isRegenerationRequest) => {
    if (isRegenerationRequest) { setGenerateMode(MODE_REGENERATE); }
    else { setGenerateMode(MODE_ACTIVATE); }
    setCurrentCredential(credentialSourceID);
    toggleOpenGenerateModal(true);
  };

  const createDataCredentials = (credentialID) => {
    createDataKeys({ credentialID });
  };

  const onViewCredDetails = (crID) => {
    setCredDetailsID(crID);
    setOpenCredDetailsModal(true);
  };

  const onCloseViewCredDetails = () => {
    setCredDetailsID('');
    setOpenCredDetailsModal(false);
  };

  const onDeleteModalOpen = (dataCredential) => {
    setOpenDeleteModal(true);
    setDeleteOrgCredID(dataCredential.ID);
  };

  const onDeleteModalClose = () => {
    setOpenDeleteModal(false);
    setDeleteOrgCredID(null);
  };

  const getActions = (dataCredential) => {
    const {
      credentialSource, name, ID, managedCredential,
    } = dataCredential;

    if (credentialSource.managedCredential) {
      if (name === '') {
        return [{ name: 'Activate', action: () => handleOpen(credentialSource.ID, false) }];
      }
      else {
        return [{ name: 'Regenerate', action: () => handleOpen(credentialSource.ID, true) }];
      }
    }
    else {
      const actions = managedCredential
        ? [{ name: 'Delete Source', action: () => onDeleteModalOpen(dataCredential) }]
        : [
          { name: 'Edit Source', action: () => history.push(`${spaUrls.organizationManagement.organization.orgDataCredential}/${ID}`) },
          { name: 'Delete Source', action: () => onDeleteModalOpen(dataCredential) },
        ];

      if (isViewSourceVisible(credentialSource.name)) {
        actions.push({
          name: 'View Source',
          action: () => onViewCredDetails(ID),
        });
      }

      return actions;
    }
  };

  return (
    <>
      <PageHeader
        title='Organization Credentials'
        hideBackIcon
        breadcrumbs={[crumbs.home]}
      />
      <Box paddingBottom={2}>
        <ListControls
          createButton={{
            onClick: () => history.push(
              spaUrls.organizationManagement.organization.orgDataCredential,
            ),
            text: 'Add Credential',
          }}
          onRefresh={() => dispatch(fetchOrgDataCredentialList(orgId, { client: 'topgallant' }))}
        />
      </Box>
      <Grid container spacing={0} direction='column'>
        <Grid item width='100%'>
          <Card>
            <CardContent>
              <ListViewTable list={dataCredentialList}>
                <OrganizationCredentialsTable
                  list={dataCredentialList}
                  getActions={getActions}
                />
              </ListViewTable>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {openCredDetailsModal && credDetailsID
        && (
          <OrgCredentialModal
            modalHeading='Credential Details'
            crID={credDetailsID}
            onOpen={openCredDetailsModal}
            onClose={onCloseViewCredDetails}
          />
        )}
      <GenerateCredentialsModal
        open={openGenerateModal}
        onClose={() => toggleOpenGenerateModal(false)}
        onSubmit={() => createDataCredentials(currentCredential)}
        isLoading={isLoading}
        mode={generateMode}
      />
      <CredentialsKeysModal
        open={openKeysModal}
        onClose={() => {
          toggleKeysModal(false);
          dispatch(fetchOrgDataCredentialList(orgId, { client: 'topgallant' }));
        }}
        credentials={data?.credential?.credentials}
      />
      <ConfirmationDialog
        open={openDeleteModal}
        title='Delete?'
        message='Are you sure you want to delete the Organization credentials?'
        confirmActionLabel='Delete'
        onConfirm={() => {
          dispatch(deleteOrgCredentialSource(orgId, deleteOrgCredID));
          onDeleteModalClose();
        }}
        onCancel={onDeleteModalClose}
      />
    </>
  );
};

export default OrganizationCredentials;
