import { axiosInstance } from 'utils/http';
import { rateLimiting } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';


export const useDeleteRateLimitConfig = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => axiosInstance.post(rateLimiting.deleteRateLimitConfig, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['rateLimitConfigAll']);
        dispatch(showAlert({
          message: 'Rate limit deleted successfully',
          type: 'success',
        }));
      },
      onError: () => {
        dispatch(showAlert({
          message: 'Failed to delete rate limit',
          type: 'error',
        }));
      },
    },
  );
};
