import Grid from '@mui/material/Grid';
import React from 'react';
import { Skeleton } from '@mui/material';


const CardLoader = () => (
  <Grid container spacing={2}>
    {
      Array.from({ length: 4 }, (_, i) => (
        <Grid item xs={12} md={6} lg={4} xl={3} key={i}>
          <Skeleton variant='rectangular' height={280} />
        </Grid>
      ))
    }
  </Grid>
);

export default CardLoader;
