import React, { useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHeader from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ParameterInformation } from './ParameterInformation';
import { ParameterInput } from './ParameterInput';
import { ParameterQuestionList } from './ParameterQuestionList';
import {
  Typography, styled,
} from '@mui/material';


const TableHeadCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `2px solid ${theme.palette.secondary.main}`,
}));
const TableHeadColSpan = styled(TableHeadCell)({
  width: '145px',
});
const TableBodyCell = styled(TableCell)({
  borderColor: 'transparent',
});
const HeadTypography = (props) => <Typography variant='subtitle2' color='secondary' {...props} />;


export const ParametersList = ({
  disabledMacroIDs, parameters, isEditingDisabled, onParameterValueChange,
}) => {
  // Prevents multiple parameters with the same macro (only used for rendering)
  const filteredParameters = useMemo(
    () => parameters.reduce((acc, parameter) => {
      if (parameter?.macro) {
        const isMacroInAcc = acc.some(
          (param) => param?.macro?.ID === parameter?.macro?.ID,
        );
        return isMacroInAcc ? acc : [...acc, parameter];
      }
      return [...acc, parameter];
    }, []),
    [parameters],
  );

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeadCell>
            <HeadTypography>
              Parameter
            </HeadTypography>
          </TableHeadCell>
          <TableHeadColSpan />
          <TableHeadCell>
            <HeadTypography>
              Value
            </HeadTypography>
          </TableHeadCell>
          <TableHeadColSpan />
          <TableHeadColSpan />
          <TableHeadColSpan />
          <TableHeadColSpan />
          <TableHeadCell>
            <HeadTypography>
              Question
            </HeadTypography>
          </TableHeadCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {filteredParameters.map((parameter) => (
          <TableRow key={parameter.ID} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.disabled.dark}` }}>
            <TableBodyCell colSpan={2} style={{ border: 'none' }}>
              <ParameterInformation parameter={parameter} />
            </TableBodyCell>
            <TableBodyCell colSpan={5} style={{ border: 'none' }}>
              <ParameterInput
                disabledMacroIDs={disabledMacroIDs}
                isEditingDisabled={isEditingDisabled}
                parameter={parameter}
                onParameterValueChange={onParameterValueChange}
              />
            </TableBodyCell>
            <TableBodyCell style={{ border: 'none' }}>
              <ParameterQuestionList questionsDetails={parameter?.questionsDetails} />
            </TableBodyCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
