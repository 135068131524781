import { axiosInstance } from 'utils/http';
import { cleanRoom } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';


export const useDuplicateCRQ = (orgId, crId) => {
  const dispatch = useDispatch();

  return useMutation(
    'DuplicateCRQ',
    async (qId) => axiosInstance.post(cleanRoom(orgId).duplicateQuestion(crId, qId), {
      organizationID: orgId,
      cleanRoomID: crId,
      cleanRoomQuestionID: qId,
    }),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Question has been duplicated', type: 'success' })),
      onError: (err) => dispatch(showAlert({ message: err.response.data.message, type: 'error' })),
    },
  );
};
