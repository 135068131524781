import React from 'react';
import { DeleteFlowListContextProvider } from 'pages/Flow/DeleteFlowListContextProvider';
import { FlowContextProvider } from 'pages/Flow/FlowContextProvider';
import { FlowListViewLayout } from 'pages/Flow/FlowListViewLayout';
import { FlowVersionHistoryProvider } from './FlowRun/FlowVersionHistoryProvider';


const FlowListView = () => (
  <FlowContextProvider>
    <FlowVersionHistoryProvider>
      <DeleteFlowListContextProvider>
        <FlowListViewLayout />
      </DeleteFlowListContextProvider>
    </FlowVersionHistoryProvider>
  </FlowContextProvider>
);

export default FlowListView;
