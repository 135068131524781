import React, { createContext, useState } from 'react';


export const FlowVersionHistoryContext = createContext({});

export const FlowVersionHistoryProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalDetails, setModalDetails] = useState(null);
  const openModal = (flowDetails) => {
    setIsOpen(true);
    setModalDetails(flowDetails);
  };
  const closeModal = () => {
    setIsOpen(false);
    setModalDetails(null);
  };
  const value = {
    isOpen,
    modalDetails,
    setModalDetails,
    openModal,
    closeModal,
  };
  return (
    <FlowVersionHistoryContext.Provider value={value}>
      {children}
    </FlowVersionHistoryContext.Provider>
  );
};
