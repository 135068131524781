export const FLOW = {
  CLEAR_FLOW_CACHE: 'CLEAR_FLOW_CACHE',
  FETCH_FLOW_DETAILS: 'FETCH_FLOW_DETAILS',
  FETCH_FLOW_DETAILS_SUCCESS: 'FETCH_FLOW_DETAILS_SUCCESS',
  FETCH_FLOW_DETAILS_ERROR: 'FETCH_FLOW_DETAILS_ERROR',
  FETCH_FLOW_SUCCESS: 'FETCH_FLOW_SUCCESS',
  SUBMIT_FLOW_SUCCESS: 'SUBMIT_FLOW_SUCCESS',
  UPDATE_FLOW_EDGES: 'UPDATE_FLOW_EDGES',
  UPDATE_FLOW_NODES: 'UPDATE_FLOW_NODES',
  ADD_TEMPORARY_NODE_TO_FLOW: 'ADD_TEMPORARY_NODE_TO_FLOW',
  UPDATE_FLOW_NODES_QUESTION: 'UPDATE_FLOW_NODES_QUESTION',
  UPDATE_FLOW_NODES_QUESTION_SUCCESS: 'UPDATE_FLOW_NODES_QUESTION_SUCCESS',
  UPDATE_FLOW_NODES_QUESTION_ERROR: 'UPDATE_FLOW_NODES_QUESTION_ERROR',
  CONNECT_FLOW_EDGES: 'CONNECT_FLOW_EDGES',
  CREATE_NODE: 'CREATE_NODE',
  CREATE_NODE_SUCCESS: 'CREATE_NODE_SUCCESS',
  CREATE_NODE_ERROR: 'CREATE_NODE_ERROR',
  UPDATE_SELECTED_FLOW: 'UPDATE_SELECTED_FLOW',
  UPDATE_SELECTED_FLOW_INFORMATION: 'UPDATE_SELECTED_FLOW_INFORMATION',
  DELETE_SELECTED_FLOW_INFORMATION_TAGS: 'DELETE_SELECTED_FLOW_INFORMATION_TAGS',
  DELETE_SELECTED_FLOW_INFORMATION_ALL_TAGS: 'DELETE_SELECTED_FLOW_INFORMATION_ALL_TAGS',
  DELETE_FLOW_NODE: 'DELETE_FLOW_NODE',
  DELETE_FLOW_NODE_SUCCESS: 'DELETE_FLOW_NODE_SUCCESS',
  DELETE_FLOW_NODE_ERROR: 'DELETE_FLOW_NODE_ERROR',
  CHECK_FLOW_EDGES_CONNECTION: 'CHECK_FLOW_EDGES_CONNECTION',
  CLOSE_CHECK_FLOW_EDGES_CONNECTION: 'CLOSE_CHECK_FLOW_EDGES_CONNECTION',
  CONNECT_NODES_WITH_EDGE: 'CONNECT_NODES_WITH_EDGE',
  CONNECT_NODES_WITH_EDGE_ERROR: 'CONNECT_NODES_WITH_EDGE_ERROR',
  CONNECT_NODES_WITH_EDGE_SUCCESS: 'CONNECT_NODES_WITH_EDGE_SUCCESS',
  EDIT_QUESTION_EDGE: 'EDIT_QUESTION_EDGE',
  OPEN_SIDE_PANEL: 'OPEN_SIDE_PANEL',
  CLOSE_SIDE_PANEL: 'CLOSE_SIDE_PANEL',
  NODE_SIZE_CHANGE: 'NODE_SIZE_CHANGE',
  SELECT_START_LEVEL_FOR_CACHED_RUN: 'SELECT_START_LEVEL_FOR_CACHED_RUN',
  SELECT_PAUSE_LEVEL_FOR_CACHED_RUN: 'SELECT_PAUSE_LEVEL_FOR_CACHED_RUN',
  SET_FLOW_CACHED_MODE: 'SET_FLOW_CACHED_MODE',
};
