import AllQuestionsCard from 'components/CleanRooms/AllQuestions/AllQuestionsCard';
import React, { useContext, useState } from 'react';
import { ActionButton } from 'components/CleanRooms/AllQuestions/ActionButton';
import { AllQuestionsContext } from 'components/CleanRooms/AllQuestions/AllQuestionsContextProvider';
import { HDataTable, HTableControlPanel } from 'BaseComponents';
import { IdContext } from 'pages/CleanRooms/QuestionDetails';
import { QuestionViewModal } from 'components/Question/QuestionDetails/QuestionViewModal';
import { cleanRoom } from 'utils/urlConstants';
import { getColumns } from 'components/CleanRooms/AllQuestions/getColumns';
import { useActionMenuItems } from 'components/CleanRooms/AllQuestions/useActionMenuItems';


export const AllQuestionsTableLayout = () => {
  const { orgId, crId } = useContext(IdContext);
  const {
    qDetailModal,
    onClose,
    qDetailsID,
    handleAddBulkQuestion,
    qDetailModalTab,
  } = useContext(AllQuestionsContext);
  const dataURL = cleanRoom(orgId).listAvailableQuestions(crId);
  const filtersURL = cleanRoom(orgId).availableQuestionFilters(crId);
  const columns = getColumns();
  const getActionMenuItems = useActionMenuItems();
  const [selectedRows, setSelectedRows] = useState({});

  const multiProvisionButton = [{
    text: `Use ${Object.keys(selectedRows ?? []).length} selected Questions`,
    onClick: () => handleAddBulkQuestion(selectedRows),
    disabled: Boolean(!Object.keys(selectedRows ?? []).length),
    permission: [],
  }].filter(Boolean);

  return (
    <>
      <HDataTable
        filtersURL={filtersURL}
        dataURL={dataURL}
        tableName='available-questions'
        dataBaseField='questions'
        columns={columns}
        actionButton={ActionButton}
        actionMenu={getActionMenuItems}
        cardElement={AllQuestionsCard}
        activeQuery={false}
        multiSelect
        controlPanel={(
          <HTableControlPanel
            searchAttribute
            createPermissions={[
            ]}
            multipleCreateButton={multiProvisionButton}
            onSelectionChange={setSelectedRows}
          />
        )}
      />
      {qDetailModal && (
        <QuestionViewModal
          open={qDetailModal}
          onClose={onClose}
          questionId={qDetailsID}
          defaultTab={qDetailModalTab}
        />
      )}
    </>
  );
};
