import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { MotifTypography } from 'BaseComponents/MotifTypography';
import { Tooltip, styled } from '@mui/material';


const DEFAULT_SPLIT_INDEX = 0.75;

const TruncateRootStyled = styled('span', {
  shouldForwardProp: (prop) => prop !== 'wrapped',
})(({ wrapped }) => ({
  display: wrapped ? 'block' : 'flex',
}));

const StartStyled = styled('span', {
  shouldForwardProp: (prop) => !['splitIndexProp', 'wrapped'].includes(prop),
})(({ splitIndexProp, wrapped }) => ({
  display: 'block',
  width: splitIndexProp && !wrapped ? `${splitIndexProp * 100}%` : '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre',
}));

const EndBoxStyled = styled('span', {
  shouldForwardProp: (prop) => !['splitIndexProp', 'wrapped'].includes(prop),
})(({ splitIndexProp, wrapped }) => ({
  display: 'flex',
  alignItems: 'center',
  width: splitIndexProp && !wrapped ? `${100 - splitIndexProp * 100}%` : '100%',
  position: 'relative',
}));

const EndStyled = styled('span')({
  display: 'block',
  flex: '1 0 auto',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textAlign: 'left',
});

const WrapStyled = styled('div')({
  position: 'relative',
});

const ShadowEndText = styled('span')({
  position: 'absolute',
  visibility: 'hidden',
  whiteSpace: 'nowrap',
});

const TruncateStyled = styled('div')({
  overflow: 'auto',
});

export const MotifTruncate = ({
  children,
  className,
  variant,
  forceTooltip = false,
  splitIndex: splitIndexProp = DEFAULT_SPLIT_INDEX,
  tooltipPosition = 'top',
  typographyClassName,
  wrapped = false,
  ...props
}) => {
  const validTooltips = ['top', 'bottom', 'left', 'right'];
  if (!validTooltips.includes(tooltipPosition)) {
    throw new Error(`Invalid tooltip position! Must be ${validTooltips.join(', ')}.`);
  }

  if (wrapped !== true && wrapped !== false) {
    throw new Error('Invalid wrapped!');
  }

  if (forceTooltip !== true && forceTooltip !== false) {
    throw new Error('Invalid forceTooltip!');
  }

  const typographyRef = useRef(null);
  const shadowEndTextRef = useRef(null);
  const endTextRef = useRef(null);

  const [firstText, setFirstText] = useState('');
  const [secondText, setSecondText] = useState('');
  const [truncate, setTruncate] = useState(false);
  const [endText, setEndText] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const checkHeight = () => {
      setIsChecked(false);
      setEndText('');
      setTimeout(() => {
        if (typographyRef?.current) {
          const { scrollHeight } = typographyRef.current;
          const { clientHeight } = typographyRef.current;
          const { scrollWidth } = typographyRef.current;
          const { clientWidth } = typographyRef.current;

          const isTextOverflow = scrollHeight > clientHeight || scrollWidth > clientWidth;
          setTruncate(isTextOverflow);
          setIsChecked(true);
        }
      }, 0);
    };

    checkHeight();

    window.addEventListener('resize', checkHeight);
    return () => {
      window.removeEventListener('resize', checkHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  useEffect(() => {
    const checkEndText = () => {
      let splitIndex = splitIndexProp;
      // If splitIndexProp is not a whole number, assume percentage of the child text
      if (typeof children === 'string' || Array.isArray(children)) {
        if (splitIndexProp % 1 !== 0) {
          splitIndex = Math.round(children.length * splitIndexProp);
        }
        const firstHalf = children.slice(0, splitIndex);
        const secondHalf = children.slice(splitIndex);

        setFirstText(firstHalf);
        setSecondText(secondHalf);
      }
      if (isChecked && truncate && endTextRef?.current && shadowEndTextRef?.current) {
        const endTextWidth = endTextRef.current.getBoundingClientRect().width;
        const shadowEndTextWidth = shadowEndTextRef.current.getBoundingClientRect().width;

        const ratio = endTextWidth / shadowEndTextWidth;
        const estimatedChars = Math.max(1, Math.floor(ratio * String(secondText).length) - 1);
        const effectEndText = String(secondText).slice(-estimatedChars);
        setEndText(effectEndText);
      }
    };

    checkEndText();

    window.addEventListener('resize', checkEndText);
    return () => {
      window.removeEventListener('resize', checkEndText);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    truncate,
    children,
    splitIndexProp,
    endTextRef?.current,
    shadowEndTextRef?.current,
    isChecked,
  ]);

  return (
    <TruncateStyled
      style={{ opacity: isChecked ? 1 : 0 }}
      className={clsx('Truncate-tooltipRef', className)}
    >
      <Tooltip
        title={children}
        placement={tooltipPosition}
        disableHoverListener={!truncate && !forceTooltip}
      >
        <WrapStyled
          sx={{
            // eslint-disable-next-line radix
            height: (theme) => parseInt(theme.typography[variant || 'body1'].fontSize) * 1.4 * (wrapped ? 2 : 1),
          }}
          ref={typographyRef}
        >
          <MotifTypography
            className={clsx('Truncate-typography', typographyClassName)}
            variant={variant}
            data-testid={props['data-testid'] ?? 'truncate-typography'}
            {...props}
          >
            {truncate && isChecked ? (
              <TruncateRootStyled className='Truncate-root' wrapped={wrapped}>
                <StartStyled
                  className='Truncate-start'
                  splitIndexProp={splitIndexProp}
                  wrapped={wrapped}
                >
                  {firstText}
                </StartStyled>
                <EndBoxStyled splitIndexProp={splitIndexProp} wrapped={wrapped}>
                  <EndStyled className='Truncate-end' ref={endTextRef}>
                    {endText}
                  </EndStyled>
                  {!endText && <ShadowEndText ref={shadowEndTextRef}>{secondText}</ShadowEndText>}
                </EndBoxStyled>
              </TruncateRootStyled>
            ) : (
              children
            )}
          </MotifTypography>
        </WrapStyled>
      </Tooltip>
    </TruncateStyled>
  );
};
