import React from 'react';
import clsx from 'clsx';
import { DialogActions as MuiDialogActions } from '@mui/material';


export const MotifDialogActions = React.forwardRef(({ className, children, ...props }, ref) => (
  <MuiDialogActions className={clsx('MuiDialogActions-rootSpan', className)} ref={ref} {...props}>
    {children}
  </MuiDialogActions>
));
