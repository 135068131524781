import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import ChartSample from 'assets/chart-sample.svg';
import React from 'react';
import Typography from '@mui/material/Typography';
import { AutoGraph as AutoGraphIcon, Block as BlockIcon } from '@mui/icons-material';
import { Card, CardContent } from '@mui/material';
import { EllipsisTypography, GreyChip } from 'components/CleanRooms/RecommendedQuestions/style';


const RecommendedQuestionCard = ({
  question, requestQuestion, removeQuestion, fetchQuestions,
}) => {
  const {
    ID, title, description, isRequested,
  } = question;

  const onRequestQuestion = () => {
    requestQuestion(ID, {
      onSuccess: fetchQuestions,
    });
  };

  const onRemoveQuestion = () => {
    removeQuestion(ID, {
      onSuccess: fetchQuestions,
    });
  };

  return (
    <Card>
      <CardContent>
        <Typography
          component='div'
          variant='caption'
          color='link.inactive'
          gutterBottom
          display='inline-flex'
          alignContent='center'
          gap={1}
        >
          <AutoGraphIcon sx={{ fontSize: '16px' }} />
          <Box component='span'>Analytics Questions</Box>
        </Typography>
        <EllipsisTypography
          variant='body1'
          gutterBottom
          webkitLineClamp={2}
        >
          {title}
        </EllipsisTypography>
        <EllipsisTypography
          variant='body2'
          gutterBottom
          webkitLineClamp={2}
        >
          {description}
        </EllipsisTypography>
      </CardContent>
      <CardMedia
        component='img'
        src={ChartSample}
        alt='chart-sample'
        loading='lazy'
        width='100%'
      />
      <CardActions sx={{ alignItems: 'stretch', justifyContent: 'end', p: 2 }}>
        {
          isRequested
            ? (
              <GreyChip component='button' label='Question Requested' />
            ) : (
              <Button variant='contained' color='secondary' size='small' onClick={onRequestQuestion}>
                Request Question
              </Button>
            )
        }
        {/* removed edit button, will be added once functionality is ready */}
        {/* <Button variant='outlined' color='primary' size='small'> */}
        {/*   Edit */}
        {/* </Button> */}
        <Button variant='outlined' color='primary' size='small' sx={{ minWidth: 0 }} onClick={onRemoveQuestion}>
          <Box component='span' display='inherit'>
            <BlockIcon />
          </Box>
        </Button>
      </CardActions>
    </Card>
  );
};

export default RecommendedQuestionCard;
