import Bouncer from 'components/Common/Bouncer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/BorderColorOutlined';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import PermissionService from 'components/Common/PermissionService';
import QuestionsActionButton from 'components/CleanRooms/CurrentQuestions/QuestionsTableLayout/QuestionsActionButton';
import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import ShareIcon from '@mui/icons-material/Share';
import Tooltip from '@mui/material/Tooltip';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import get from 'lodash/get';
import {
  CLEAN_ROOM_STATUS, CleanRoomTypes, Ownership, Parameters,
  cleanRoomQuestionPermissions as crqPerm, permissionControlledMessage,
} from 'components/CleanRooms/constants';
import { CurrentQuestionsContextProvider } from 'components/CleanRooms/CurrentQuestions/CurrentQuestionsContextProvider';
import { FALLBACK_QUESTION_VERSION, QUESTION_STATUS } from 'utils/constants/question.constant';
import { HButton } from 'BaseComponents';
import { IdContext } from 'pages/CleanRooms/QuestionDetails';
import { Link, useHistory } from 'react-router-dom';
import {
  PRODUCT, cleanRoomPermissions, productPerm, userTypes,
} from 'utils/appConstants';
import { cleanRoom, metabase, question as questionURL } from 'utils/spaUrls';
import { crPrivacyType } from 'components/CleanRooms/Wizard/constants';
import {
  crQuestionStage,
  crqAnalysisRuleStatus,
  status as statusEnum,
} from 'utils/ignoramusEnums';
import {
  fetchCrCurrentQuestions,
  updateCrQuestionStatus,
} from 'redux/actions/CleanRoomsActions';
import { hasCRQuestionPermission } from 'components/CleanRooms/service';
import { isActivationAuthorized } from 'pages/CleanRooms/Activation/permissionChecker';
import { useCloneCRQ } from 'components/CleanRooms/CurrentQuestions/service/CloneAPI';
import { useCreateQuestionActivation } from 'hooks/activationAPI';
import { useDispatch, useSelector } from 'react-redux';
import { useDuplicateCRQ } from 'api/questions/useDuplicateCRQ';
import { useFetchCleanRoomAssetMapping, useFetchCleanRoomShareablePartners } from 'hooks/cleanRoomAPIs';
import { useTriggerHabuIntelligenceRun } from 'hooks/habuIntelligenceAPI';


export const CurrentQuestionsPageContext = createContext({});

export const CurrentQuestionsPageContextProvider = ({ children }) => {
  const { orgId, crId } = useContext(IdContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const { detail: crDetail, userDetail: crUserDetail } = useSelector(state => state.cleanRooms);
  const isCRPairEnabled = crDetail?.parameters.find(p => p.name === Parameters.ENABLE_PAIR)?.value === 'true';
  const meta = useSelector(state => state.cleanRooms.meta);
  const [runModalDetails, setRunModalDetails] = useState();
  const [managePermissionsDetails, setManagePermissionsDetails] = useState();
  const [activateTarget, setActivatTarget] = useState();
  const [removeQuestionId, setRemoveQuestionId] = useState();
  const [duplicateQuestionId, setDuplicateQuestionId] = useState(null);
  const [syncQuestionId, setSyncQuestionId] = useState();
  const [cloneQuestion, setCloneQuestion] = useState();
  const [showShareQuestionResultsModal, setShowShareQuestionResultsModal] = useState(false);
  const [qDetailModal, setQDetailModal] = useState(false);
  const [qDetailModalTab, setQDetailModalTab] = useState('');
  const [qDetailsID, setQDetailsID] = useState(null);
  const [crQuestionID, setCrQuestionID] = useState(null);
  const [advSettingsModal, setAdvSettingsModal] = useState(false);
  const [toggle, setToggle] = useState(false);
  const cloneQuestionToHI = useCloneCRQ();
  const dataContextRef = useRef();
  const [analysisRuleRunStatus, setAnalysisRuleRunStatus] = useState('');
  const [showSelectRampIdModal, setShowSelectRampIdModal] = useState(false);
  const [showDeleteAssetsModal, setShowDeleteAssetsModal] = useState(false);

  const {
    isLoading: isDuplicating,
    mutate: duplicateCRQ,
  } = useDuplicateCRQ(orgId, crId);

  const cleanRoomExpired = crDetail?.status === CLEAN_ROOM_STATUS.EXPIRED.key;
  const isOwner = crDetail?.ownership === Ownership.OWNER.key;

  const isCrQuestionPermissionsEnabled = PermissionService.isProductEnabled(
    [productPerm.crQuestionPermissions],
  );

  const isQuestionVersioningEnabled = PermissionService.isProductEnabled(
    [productPerm.questionVersioningV1],
  );

  const isSuperUser = PermissionService.isAuthorized([userTypes.superuser]);
  const isSupportModeUser = PermissionService.isCXUser();

  useEffect(() => {
    if (cloneQuestionToHI.isSuccess) {
      setCloneQuestion(null);
      cloneQuestionToHI.reset();
    }
  }, [cloneQuestionToHI]);

  const {
    mutate: createQuestionActivation,
  } = useCreateQuestionActivation();

  const fetchQuestions = useCallback(() => {
    dataContextRef.current.refreshListData();
  }, []);

  const { isSuccess, data: partners } = useFetchCleanRoomShareablePartners(
    orgId, crId,
  );

  const { isSuccess: isAssetMappingSuccess, data: assetMapping } = useFetchCleanRoomAssetMapping(
    orgId, crId,
  );

  const shareablePartners = useMemo(() => (isSuccess && partners ? [{
    ID: 'HABU',
    name: 'LiveRamp',
  }, ...partners]
    : []), [isSuccess, partners]);

  useEffect(() => {
    if (meta && meta.deleted) {
      setRemoveQuestionId(undefined);
      dispatch(fetchCrCurrentQuestions(orgId, crId));
    }
  }, [dispatch, meta, orgId, crId]);

  const updateStatus = (row, status) => {
    dispatch(
      updateCrQuestionStatus(orgId, row.cleanRoomID, row.ID, { status }),
    );
  };

  const { refetch } = useTriggerHabuIntelligenceRun(orgId, crId, syncQuestionId);

  const handleSyncRequest = (question) => {
    setSyncQuestionId(question.ID);
    setToggle(prev => !prev);
  };

  useEffect(() => {
    if (syncQuestionId) {
      refetch();
    }
  }, [refetch, toggle, syncQuestionId]);

  const onDelete = (question) => {
    setRemoveQuestionId(question.ID);
  };

  useEffect(() => {
    if (meta && meta.deleted) {
      setRemoveQuestionId(undefined);
      fetchQuestions();
    }
  }, [dispatch, meta, fetchQuestions]);

  const handleShareQuestionResults = (question) => {
    setShowShareQuestionResultsModal(question);
  };

  // TODO: deprecate DV-6093
  // const openActivationConfigDialog = (question) => {
  //   setActivatTarget({
  //     ID: question.ID,
  //     type: ACTIVATION_TYPE.QUESTION,
  //     questionID: question.questionID,
  //   });
  // };

  const onActivate = ({ identityField, identityType, selectedChannel }) => {
    const payload = {
      organizationID: orgId,
      cleanRoomQuestionID: activateTarget.ID,
      identityType,
      partnerAccountIDs: selectedChannel,
    };
    if (identityField) {
      payload.identityFieldID = identityField;
    }
    createQuestionActivation(payload);
  };

  const seeQuestionDetails = (qID, crqID, defaultTab = '') => {
    setQDetailModalTab(defaultTab);
    setQDetailModal(true);
    setQDetailsID(qID);
    setCrQuestionID(crqID);
  };
  const onClose = () => {
    setQDetailModal(false);
    setQDetailsID(null);
  };

  const openAdvSettings = (qID, crqID) => {
    setAdvSettingsModal(true);
    setQDetailsID(qID);
    setCrQuestionID(crqID);
  };

  const onAdvSettingsClose = () => {
    setAdvSettingsModal(false);
    setQDetailsID(null);
    setCrQuestionID(null);
  };

  const handleAddToAssets = (question) => {
    setShowSelectRampIdModal(question);
  };

  const handleDeleteAssets = (question) => {
    setShowDeleteAssetsModal(question);
  };

  const allowActivation = (question) => {
    const { isUserListQuestion } = question;
    const isAuthorized = isActivationAuthorized(crUserDetail?.role?.rolePermissions);
    const validCleanRoomTypes = [
      CleanRoomTypes.HABU_CR,
      CleanRoomTypes.SNOWFLAKE_CR,
      CleanRoomTypes.LINKEDIN_CLEAN_ROOM,
      CleanRoomTypes.HYBRID_V2_CLEAN_ROOM,
      CleanRoomTypes.GOOGLE_CLOUD_BIG_QUERY_CLEAN_ROOM,
    ];
    const isCorrectCleanRoomType = validCleanRoomTypes.includes(crDetail.type);
    const isCleanRoomExpired = crDetail.status === CLEAN_ROOM_STATUS.EXPIRED.key;

    return isUserListQuestion && isAuthorized && isCorrectCleanRoomType && !isCleanRoomExpired;
  };

  const hasPermission = (question, permission) => hasCRQuestionPermission(
    orgId, question.ownerOrganizationID, question.permissions, permission,
  );

  const getActionMenuItems = (question) => {
    const menuItems = [];
    const isAuthorizedQB = PermissionService.isAuthorized([
      cleanRoomPermissions.questionBuilder,
    ]);
    const isAuthorizedMD = PermissionService.isAuthorized([
      cleanRoomPermissions.manageDatasets,
    ]);
    const isAuthorizedHiCloning = PermissionService
      .isAuthorizedSuperUser([PRODUCT.HABU_INTELLIGENCE_CLONING.name]);
    const ENABLED_CAPACITY_MANAGEMENT = PermissionService
      .isProductEnabled([productPerm.CapacityManagement]);
    const ENABLED_CAPACITY_MANAGEMENT_SF = PermissionService.isProductEnabled(
      [productPerm.CapacityManagementSnowflakePattern],
    );
    const isSnowflakeCleanRoom = crDetail.type === CleanRoomTypes.SNOWFLAKE_CR;
    const isQuestionOwner = question.ownerOrganizationID === orgId;

    const isHabuIntelligenceEnabled = crDetail?.parameters.find(p => p.name === Parameters.ENABLE_HABU_INTELLIGENCE)?.value === 'true';

    const getCrExpiredTooltip = (action) => {
      const message = `Clean Room Expired. Unable to ${action}.`;
      if (cleanRoomExpired) {
        return message;
      }
      if (!hasPermission(question, crqPerm.CLONE.name)) {
        return permissionControlledMessage;
      }
      return '';
    };

    const isCapacityManagementEnabled = isSnowflakeCleanRoom
      ? ENABLED_CAPACITY_MANAGEMENT_SF
      : ENABLED_CAPACITY_MANAGEMENT;

    const enableAdvancedSettings = isSuperUser || (isCapacityManagementEnabled && isAuthorizedQB
      && (isOwner || isSupportModeUser));

    const canDuplicateQuestion = (isAuthorizedQB || isAuthorizedMD)
      && question.questionStatus === QUESTION_STATUS.PUBLISHED.key;

    menuItems.push({
      label: 'See Details',
      icon: <VisibilityOutlinedIcon />,
      action: () => seeQuestionDetails(question.questionID, question.ID),
    });

    if (isQuestionVersioningEnabled) {
      menuItems.push({
        label: 'See Version History',
        action: () => seeQuestionDetails(question.questionID, question.ID, 'versionHistory'),
      });
    }

    if (allowActivation(question)) {
      menuItems.push({
        label: 'Activate',
        action: () => history.push(
          cleanRoom.questionActivation.list(crId, question.questionID, question.ID),
        ),
      });
    }

    if (isAuthorizedQB) {
      if (question.isEditable) {
        menuItems.push({
          label: 'Edit',
          icon: <EditIcon />,
          action: () => history.push(questionURL.builder.wizard.cleanRoom(
            question.questionID,
            crId,
            question.ID,
            question.questionVersion || FALLBACK_QUESTION_VERSION,
          )),
          disabled: cleanRoomExpired || !hasPermission(question, crqPerm.EDIT.name),
          tooltipTitle: getCrExpiredTooltip('edit'),
        });
      }
      menuItems.push({
        label: 'Clone',
        icon: <CopyIcon />,
        action: () => {
          history.push(questionURL.builder.clone.cleanRoom(question.questionID, crId));
        },
        disabled: cleanRoomExpired || !hasPermission(question, crqPerm.CLONE.name),
        tooltipTitle: getCrExpiredTooltip('clone'),
        infoTooltip: 'Create a new Question using this one as a starting point (name, datasets, code, etc.)',
      });
    }

    if (canDuplicateQuestion) {
      menuItems.push({
        label: 'Duplicate',
        action: () => setDuplicateQuestionId(question.ID),
        disabled: cleanRoomExpired,
        tooltipTitle: getCrExpiredTooltip('duplicate'),
        infoTooltip: 'Add this Question again (only available for Published Questions)',
      });
    }

    if (isQuestionOwner
      && PermissionService.isProductEnabled([productPerm.crQuestionPermissions])) {
      menuItems.push({
        label: isCrQuestionPermissionsEnabled ? 'Permissions Override' : 'Manage Permissions',
        action: () => setManagePermissionsDetails({
          questionName: question.title,
          questionID: question.ID,
          questionPermissions: question.permissions || [],
        }),
      });
    }

    if (isAuthorizedHiCloning && isHabuIntelligenceEnabled) {
      menuItems.push({
        label: 'Clone to Intelligence',
        icon: <CopyIcon />,
        action: () => setCloneQuestion(question),
      });
    }

    if (
      question.isUserListQuestion
      && PermissionService.isProductEnabled([productPerm.cleanRoomActivationV1])
    ) {
      if (isAssetMappingSuccess && assetMapping?.allowSegmentation) {
        if (question.isAsset) {
          menuItems.push(...[{
            label: 'Update Segmentation',
            action: () => handleAddToAssets(question),
          }, {
            label: 'Delete Segmentation',
            action: () => handleDeleteAssets(question),
          }]);
        }
        else {
          menuItems.push({
            label: 'Enable Segmentation',
            action: () => handleAddToAssets(question),
          });
        }
      }
    }

    if (PermissionService.isAuthorized([cleanRoomPermissions.manageQuestions])) {
      menuItems.push({
        label: 'Delete',
        icon: <DeleteIcon />,
        action: () => onDelete(question),
        disabled: cleanRoomExpired || !hasPermission(question, crqPerm.EDIT.name),
        tooltipTitle: getCrExpiredTooltip('delete'),
      });
    }

    if (
      PermissionService.isAuthorized([productPerm.crAdmin])
      && PermissionService.isProductEnabled([productPerm.snowflakeResultShare])
      && isSnowflakeCleanRoom
    ) {
      menuItems.push({
        label: 'Share Question Results',
        icon: <ShareIcon />,
        action: () => handleShareQuestionResults(question),
      });
    }

    if (enableAdvancedSettings) {
      menuItems.push({
        label: 'Advanced Settings',
        link: false,
        action: () => openAdvSettings(question.questionID, question.ID),
        to: '#',
      });
    }
    return menuItems;
  };

  const getDatasetsManagement = (question) => (
    <>
      <Bouncer allowList={[cleanRoomPermissions.manageDatasets]}>
        <HButton
          data-testid='current-question-manage-dataset-button'
          component={Link}
          to='#'
          onClick={() => history.push(cleanRoom.assignDatasetsWizard(crId, question.ID))}
          variant='outlined'
        >
          Manage Datasets
        </HButton>
      </Bouncer>
      <Tooltip title={get(crQuestionStage, `${question.stage}.label`)} placement='top'>
        <IconButton data-testid='check-circle-icon'>
          {(question.stage === crQuestionStage.READY.key) ? (
            <CheckCircleIcon color='success' />
          ) : (
            <InfoIcon color='action' />
          )}
        </IconButton>
      </Tooltip>
    </>
  );

  const getReportMenuItems = (question) => {
    const {
      stage, runCount, status, questionID, ID, questionStatus, analysisRuleStatus,
    } = question;
    const isDatasetNotAssignedAndNoReport = stage !== crQuestionStage.READY.key && runCount === 0;
    const isDatasetNotAssignedAndReport = stage !== crQuestionStage.READY.key && runCount > 0;
    const isQuestionStatusActive = status === statusEnum.ACTIVE.key;
    const isDatasetMissingReconfigure = stage === crQuestionStage.MISSING_DATASETS_RECONFIGURE.key;
    const isAnalysisRuleStatusComplete = analysisRuleStatus === crqAnalysisRuleStatus.COMPLETED.key;

    const canManageReports = PermissionService.isAuthorized([cleanRoomPermissions.manageReports]);
    const canScheduleRuns = PermissionService.isAuthorized([cleanRoomPermissions.scheduleRuns]);
    const canViewReports = PermissionService.isAuthorized([
      cleanRoomPermissions.viewReports,
      cleanRoomPermissions.manageReports,
    ]);
    const canReadHabuIntelligence = PermissionService.isAuthorized([
      cleanRoomPermissions.habuIntelligenceRead,
    ]);
    const canWriteHabuIntelligence = PermissionService.isAuthorized([
      cleanRoomPermissions.habuIntelligenceWrite,
    ]);

    const isAnalysisRule = PermissionService.isProductEnabled([
      productPerm.DatasetAnalysisRule]);
    const isAWSCleanRoom = crDetail?.type === CleanRoomTypes.AWS_CLEAN_ROOM;
    const isAnalysisRuleEnabled = isAnalysisRule && !isAWSCleanRoom;

    const isHabuIntelligenceEnabled = crDetail?.parameters.find(p => p.name === Parameters.ENABLE_HABU_INTELLIGENCE)?.value === 'true';
    const customDiffPrivacyCleanRoom = crDetail.cleanRoomPrivate
      && crDetail.cleanRoomPrivacyType === crPrivacyType.CUSTOM_DIFFERENTIAL_PRIVACY;

    const menuItems = [];

    if (isDatasetNotAssignedAndNoReport || customDiffPrivacyCleanRoom) {
      return menuItems;
    }

    const isAnalysisRuleCheckNotValid = !(isAnalysisRuleEnabled
      ? (!analysisRuleStatus || isAnalysisRuleStatusComplete)
      : true);

    const isReportDisabled = () => (
      isDatasetNotAssignedAndReport || cleanRoomExpired || isAnalysisRuleCheckNotValid
    );


    const getTooltipTitleForNewReport = () => {
      if (cleanRoomExpired) {
        return 'Clean Room Expired. No new runs allowed.';
      }
      if (isDatasetNotAssignedAndReport) {
        return 'All datasets must be assigned before a Question can be Run.';
      }
      if (isAnalysisRuleCheckNotValid) {
        return 'You can run reports once the question passes the Analysis Rules validation.';
      }
      if (!hasPermission(question, crqPerm.RUN.name)) {
        return permissionControlledMessage;
      }
      return '';
    };

    const getTooltipTitleForScheduleRun = () => {
      if (!hasPermission(question, crqPerm.RUN.name)) {
        return permissionControlledMessage;
      }
      if (isDatasetNotAssignedAndReport) {
        return 'All datasets must be assigned before a Scheduled Run can be created';
      }
      if (isAnalysisRuleCheckNotValid) {
        return 'You can schedule reports once the question passes the Analysis Rules validation.';
      }
      return '';
    };

    if (isQuestionStatusActive && !isDatasetMissingReconfigure) {
      if (canManageReports) {
        menuItems.push({
          label: 'New Report',
          link: false,
          action: () => setRunModalDetails(question),
          to: undefined,
          disabled: isReportDisabled() || !hasPermission(question, crqPerm.RUN.name),
          tooltipTitle: getTooltipTitleForNewReport(),
        });
      }

      if (canScheduleRuns) {
        menuItems.push({
          label: 'Schedule Runs',
          link: true,
          action: undefined,
          to: cleanRoom.scheduledRunsList(crId, questionID, ID),
          disabled: (
            isDatasetNotAssignedAndReport || !hasPermission(question, crqPerm.RUN.name)
            || isAnalysisRuleCheckNotValid
          ),
          tooltipTitle: getTooltipTitleForScheduleRun(),
        });
      }
      // TODO: deprecate DV-6093
      // if (allowActivation(question)) {
      //   menuItems.push({
      //     label: 'Activate',
      //     link: false,
      //     action: () => openActivationConfigDialog(question),
      //     to: undefined,
      //   });
      // }
    }

    if (canViewReports && runCount > 0) {
      menuItems.push({
        label: 'View Reports',
        link: true,
        action: undefined,
        to: cleanRoom.runList(crId, questionID, ID),
        disabled: !hasPermission(question, crqPerm.VIEW_RESULTS.name),
        tooltipTitle: !hasPermission(question, crqPerm.VIEW_RESULTS.name) ? permissionControlledMessage : '',
      });
    }

    if (isHabuIntelligenceEnabled && (canReadHabuIntelligence || canWriteHabuIntelligence)) {
      menuItems.push({
        label: 'Go to Intelligence',
        link: true,
        action: undefined,
        to: metabase.crHabuIntelligence(crId),
      });
    }

    if (canWriteHabuIntelligence && isQuestionStatusActive
      && questionStatus === QUESTION_STATUS.PUBLISHED.key) {
      menuItems.push({
        label: 'Sync to Intelligence',
        link: false,
        action: () => handleSyncRequest(question),
        to: undefined,
      });
    }

    return menuItems;
  };

  const getReportBtnTooltipTitle = (question) => {
    const customDiffPrivacyCleanRoom = crDetail.cleanRoomPrivate
      && crDetail.cleanRoomPrivacyType === crPrivacyType.CUSTOM_DIFFERENTIAL_PRIVACY;

    if (question.stage !== crQuestionStage.READY.key && question.runCount === 0) {
      return 'All datasets must be assigned before a Question can be Run.';
    }

    if (customDiffPrivacyCleanRoom) {
      return 'Single question runs are not allowed in differential privacy-enabled Clean Rooms. Please run the relevant Flow from the Flows menu';
    }

    return '';
  };

  const handleDuplicateQuestion = () => {
    duplicateCRQ(duplicateQuestionId, {
      onSuccess: () => {
        fetchQuestions();
        setDuplicateQuestionId(null);
      },
    });
  };

  const handleOpenAnalysisRuleRunStatus = (qID, analysisRuleStatus) => {
    setQDetailsID(qID);
    setAnalysisRuleRunStatus(analysisRuleStatus);
  };

  const handleCloseAnalysisRuleRunStatus = () => {
    setQDetailsID(null);
    setAnalysisRuleRunStatus('');
  };

  const contextCurrentQuestionsPagesValue = {
    isCRPairEnabled,
    duplicateQuestionId,
    removeQuestionId,
    cloneQuestion,
    showShareQuestionResultsModal,
    fetchQuestions,
    shareablePartners,
    onActivate,
    qDetailModal,
    qDetailModalTab,
    qDetailsID,
    crQuestionID,
    onClose,
    advSettingsModal,
    onAdvSettingsClose,
    crId,
    runModalDetails,
    activateTarget,
    setActivatTarget,
    setRemoveQuestionId,
    setDuplicateQuestionId,
    orgId,
    meta,
    setCloneQuestion,
    cloneQuestionToHI,
    setShowShareQuestionResultsModal,
    dataContextRef,
    isDuplicating,
    analysisRuleRunStatus,
    handleDuplicateQuestion,
    handleOpenAnalysisRuleRunStatus,
    handleCloseAnalysisRuleRunStatus,
    showSelectRampIdModal,
    setShowSelectRampIdModal,
    managePermissions: { managePermissionsDetails, setManagePermissionsDetails },
    showDeleteAssetsModal,
    setShowDeleteAssetsModal,
  };

  const contextCurrentQuestionsValue = {
    fetchQuestions,
    getActionMenuItems,
    getDatasetsManagement,
    getReportMenuItems,
    getReportBtnTooltipTitle,
    runModal: { runModalDetails, setRunModalDetails },
    updateStatus,
    actionButton: QuestionsActionButton,
  };

  return (
    <CurrentQuestionsPageContext.Provider value={contextCurrentQuestionsPagesValue}>
      <CurrentQuestionsContextProvider {...contextCurrentQuestionsValue}>
        {children}
      </CurrentQuestionsContextProvider>
    </CurrentQuestionsPageContext.Provider>
  );
};
