import React, {
  createContext, useCallback, useEffect, useMemo, useState,
} from 'react';
import { HTableSkeleton } from 'BaseComponents/HDataTable/HTableSkeleton';
import { LayoutStyles } from 'components/Common/ListControls';
import { capitalizeFirstLetter, useDefaultLayout, useQuery } from 'utils/jsUtils';
import { debounce } from 'lodash';
import { getFilterFromQueryParams } from 'utils/filterUtils';
import { useExpandCollapse } from 'BaseComponents/HDataTable/utils/useExpandCollapse';
import {
  useFiltersApply,
  useLocalStorageTable,
  useSearchUrl,
  useTableAPI,
} from 'BaseComponents/HDataTable/utils';
import { useRowSelect } from 'BaseComponents/HDataTable/useRowSelect';


const getFilter = (query, defaultFilter, activeQuery) => {
  const filtersObject = getFilterFromQueryParams(query, activeQuery);
  return {
    ...filtersObject,
    filters: [...filtersObject.filters, ...defaultFilter],
  };
};

export const DataContext = createContext({});

export const DataContextProvider = ({
  height = 0,
  filtersURL,
  defaultFilter,
  dataURL,
  dataURLPayload = {},
  tableName,
  dataBaseField,
  zeroState,
  columns,
  actionMenu,
  actionButton,
  cardElement,
  activeQuery = true,
  multiSelect = false,
  multiSelectField = 'ID',
  subRowsProp,
  onDataFetchSuccess,
  children,
}) => {
  const {
    columns: mergeColumns,
    getColumnsLocalStorage,
    setColumnsLocalStorage,
  } = useLocalStorageTable({ columns, tableName });

  const updateQueryUrl = useSearchUrl(activeQuery);
  const query = useQuery();
  const [filters, setFilters] = useState(getFilter(query, defaultFilter, activeQuery));
  const isFiltersApply = useFiltersApply(filters);

  const {
    isLoading,
    isError,
    isSuccess,
    isFetching,
    mutate,
    reset,
    filtersOptions,
    count,
    data: rows,
  } = useTableAPI(filtersURL, dataURL, tableName, dataBaseField, { ...dataURLPayload, ...filters });
  const expandCollapseState = useExpandCollapse(rows || [], subRowsProp);
  const tableHasSubRows = rows?.some((row) => row[subRowsProp]?.length);

  const {
    selectAll, setSelectAll, selectedList, handleOnSelectRow,
  } = useRowSelect(multiSelectField);

  const handleDeselectAllRow = useCallback(() => {
    if (count >= filters.limit) {
      setSelectAll(false);
    }
  }, [count, filters, setSelectAll]);

  const [layout, toggleLayout] = useState(useDefaultLayout() || LayoutStyles.card.text);
  const handleLayoutToggle = (l) => {
    toggleLayout(l.text);
  };

  const fetchData = useCallback(
    (newFilters) => {
      mutate(newFilters);
    },
    [mutate],
  );

  const refreshListData = () => {
    fetchData(filters);
  };

  const handleOnClickSort = (field, newDirection) => {
    const newFilters = {
      ...filters,
      sortBy: {
        attribute: capitalizeFirstLetter(field),
        direction: newDirection,
      },
    };

    fetchData(newFilters);
    setFilters(newFilters);
    handleDeselectAllRow();

    updateQueryUrl(newFilters);
  };

  const columnsMap = useMemo(
    () => new Map(mergeColumns.map((col) => [col.field, col])),
    [mergeColumns],
  );

  const handlePaginationChange = (newFilters) => {
    fetchData(newFilters);
    setFilters(newFilters);
    handleDeselectAllRow();

    updateQueryUrl(newFilters);
  };

  const handleFilterChange = (newFilters) => {
    const updatedFilters = {
      ...newFilters,
      offset: 0,
    };
    setFilters(updatedFilters);
    fetchData(updatedFilters);
    handleDeselectAllRow();

    updateQueryUrl(updatedFilters);
  };

  const debouncedUpdateSearch = useMemo(() => debounce(mutate, 500), [mutate]);

  const handleSearchChange = useCallback(
    (attribute, search) => {
      const newFilters = {
        ...filters,
        offset: 0,
        search,
      };

      updateQueryUrl(newFilters);

      setFilters(newFilters);
      handleDeselectAllRow();

      debouncedUpdateSearch(newFilters);
    },
    [debouncedUpdateSearch, filters, handleDeselectAllRow, updateQueryUrl],
  );

  // On data fetch success, trigger an event
  useEffect(() => {
    onDataFetchSuccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, onDataFetchSuccess]);

  if (isLoading || isFetching) {
    return <HTableSkeleton height={height} />;
  }

  return (
    <DataContext.Provider
      value={{
        height,
        layout,
        handleLayoutToggle,
        zeroState,
        columns: mergeColumns,
        getColumnsLocalStorage,
        setColumnsLocalStorage,
        columnsMap,
        count,
        rows,
        isFiltersApply,
        filtersOptions,
        filters,
        fetchData,
        refreshListData,
        handleOnClickSort,
        handleSearchChange,
        handleFilterChange,
        handlePaginationChange,
        reset,
        isError,
        isSuccess,
        isFetching,
        actionMenu,
        actionButton,
        cardElement,
        multiSelect,
        multiSelectField,
        selectAll,
        handleOnSelectRow,
        selectedList,
        subRowsProp,
        tableHasSubRows,
        ...expandCollapseState,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
