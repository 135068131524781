import { activeOrgSelector } from 'redux/reducer/AuthenticationReducer';
import { axiosInstance } from 'utils/http';
import { flow } from 'utils/urlConstants';
import { showAlert } from 'redux/actions/AlertActions';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';


export const useFetchCleanRoomFlowRun = ({ runId, crId }) => {
  const { ID: orgId } = useSelector(activeOrgSelector);
  const dispatch = useDispatch();
  const {
    isLoading, isError, isSuccess, data,
  } = useQuery(
    ['Cr-Flow-Run', runId, orgId],
    async () => axiosInstance.get(flow(orgId).run(crId, runId)),
    {
      onSuccess: () => dispatch(showAlert({ message: 'Flow run fetched successfully.', type: 'success' })),
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading, isError, isSuccess, data,
  };
};
