import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ListIcon from '@mui/icons-material/List';
import React, { memo } from 'react';
import { ReactComponent as LiveRampLogoBlack } from 'assets/logo/liveRampLogoBlack.svg';
import { Stack, Tooltip } from '@mui/material';


export const QuestionTypeCell = memo(({ row }) => {
  const text = row.isUserListQuestion ? 'List Question' : 'Analytical Question';

  return (
    <Stack direction='row' gap={1}>
      <Tooltip
        title={text}
        arrow
        placement='top'
      >
        {row.isUserListQuestion ? (
          <ListIcon />
        ) : (
          <AutoGraphIcon />
        )}
      </Tooltip>

      {row.isUserListQuestion && row.isAsset && (
        <Tooltip
          title='Segmentation Enabled'
          arrow
          placement='top'
        >
          <LiveRampLogoBlack width={20} />
        </Tooltip>
      )}
    </Stack>
  );
});

QuestionTypeCell.displayName = 'QuestionTypeCell';
